import {
  CreateButton,
  CreateProps,
  useNotify,
  useRefresh,
  useTranslate,
} from "react-admin";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import ReplyIcon from "@mui/icons-material/Reply";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import HttpDataService from "../dataProvider/http.service";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import * as sourceTypeService from "../Services/customerSourcesService";
import { id } from "date-fns/locale";

const styles = makeStyles((theme: any) =>
  createStyles({
    root: {
      "& .css-k4qjio-MuiFormHelperText-root.Mui-error": {
        position: "absolute",
        paddingTop: "41px",
      },
    },
  })
);
// type listSourceType = {
//     id: any;
//     name: String;
//   };
export const CustomerSourcesAdd = (props: any) => {
  const classes = styles();
  const { onCloseDialog, openDialog } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const translate = useTranslate();

  const dataStatus = [
    { label: translate("resources.common.fields.active"), id: 1 },
    { label: translate("resources.common.fields.block"), id: 0 },
  ];

  const [name, setName] = useState<string>("");
  const [sourceType, setSourceType] = useState<any>([]);
  const [parentSource, setParentSource] = useState<any>([]);
  const [listSourceType, setListSourceType] = useState<any>([]);
  const [listParentSource, setListParentSource] = useState<any>([]);
  const [nameError, setNameError] = useState(false);
  const [sourceTypeError, setSourceTypeError] = useState(false);

  const [textNameError, setTextNameError] = useState("");
  const [textSourceTypeError, setTextSourceTypeError] = useState("");
  const [status, setStatus] = useState<any>(dataStatus[0]);

  const refresh = useRefresh();
  const notify = useNotify();

  /**
   * handle change name
   * @param e
   */
  const handleNameChange = (e: any) => {
    setNameError(false);
    setTextNameError("");
    setName(e.target.value);
  };
  const handleSourceTypeChange = (e: any) => {
    setSourceTypeError(false);
    setTextSourceTypeError("");
    setSourceType(e);
  };
  const handleParentSourceChange = (e: any) => {
    setParentSource(e);
  };

  const handleChangeStatus = (e: any) => {
    setStatus(e);
  };

  useEffect(() => {
    if (openDialog) {
      const fetchAPI = async () => {
        const listSourceTypeResult =
          await sourceTypeService.getAllCustomerSourcesType();
        setListSourceType(listSourceTypeResult);
        const listParentSourceResult =
          await sourceTypeService.getParentSource();
        setListParentSource(listParentSourceResult);
      };
      fetchAPI();
      fnResetFrm();
    }
  }, [openDialog]);

  /**
   * handle save
   */
  const handleSave = async () => {
    if (!fnValidation()) {
      let data = {
        name: name.trim(),
        status: status.id,
        source_type_id: sourceType?.id,
        parent_id: parentSource?.id || null,
      };
      await HttpDataService.create("customer_source/addSource", data)
        .then((response: any) => {
          if (response.data.status) {
            // @ts-ignore
            notify(response.data.message, { type: "success" });
            fnResetFrm();
            onCloseDialog();
            refresh();
          } else {
            // @ts-ignore
            notify(response.data.message, { type: "error" });
          }
        })
        .catch((e: any) => {
          console.log(e);
          setNameError(true);
          setTextNameError(e?.response.data.errors.name[0]);
        });
    }
  };

  const handleCloseDialog = () => {
    fnResetFrm();
    onCloseDialog();
  };

  /**
   * fn validation form
   */
  function fnValidation() {
    let error = false;
    if (name.trim().length === 0) {
      error = true;
      setNameError(true);
      setTextNameError("Tên nguồn không được để trống");
    }
    if (sourceType.length === 0) {
      error = true;
      setSourceTypeError(true);
      setTextSourceTypeError("Loại nguồn không được để trống");
    }

    return error;
  }

  function fnResetFrm() {
    setName("");
    setNameError(false);
    setSourceTypeError(false);
    setTextNameError("");
    setTextSourceTypeError("");
    setStatus(dataStatus[0]);
    setParentSource([])
  }

  return (
    <>
      {loading ? <></> : <></>}
      <Dialog
        open={openDialog}
        fullWidth
        PaperProps={{
          style: {
            borderRadius: 2,
          },
        }}
      >
        <div className={"common__dialogTitle_area"}>
          <DialogTitle className={"member__dialogTitle"}>
            <div>{"Thêm mới nguồn"}</div>
          </DialogTitle>
          <div className={"common__dialogTitle_actions"}>
            <IconButton
              aria-label="exists"
              color={"error"}
              onClick={() => {
                onCloseDialog(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <Box className={"dialog_box"}>
          <DialogContent className={"dialog_content"}>
            <TextField
              className={`${classes.root} mb-23`}
              fullWidth
              label={
                <span className={"span_style"}>
                  {"Tên nguồn"}
                  <span style={{ color: "red" }}>(*)</span>
                </span>
              }
              value={name}
              error={nameError}
              helperText={textNameError}
              onChange={handleNameChange}
              variant="outlined"
            />
            <Autocomplete
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={
                    <span className={"span_style"}>
                      {"Loại nguồn"}
                      <span style={{ color: "red" }}>(*)</span>
                    </span>
                  }
                  error={sourceTypeError}
                  helperText={textSourceTypeError}
                />
              )}
              sx={{ width: "100%" }}
              onChange={(event, value) => {
                handleSourceTypeChange(value);
              }}
              options={listSourceType}
              getOptionLabel={(option: any) => option.name}
            />
            <Autocomplete
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={
                    <span className={"span_style"}>
                      {"Cấp cha"}
                    </span>
                  }
                />
              )}
              sx={{ width: "100%" }}
              onChange={(event, value) => {
                handleParentSourceChange(value);
              }}
              options={listParentSource}
              getOptionLabel={(option: any) => option.name}
            />
            <Autocomplete
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={
                    <span className={"span_style"}>
                      {translate("resources.common.fields.status")}
                    </span>
                  }
                />
              )}
              sx={{ width: "100%" }}
              value={status}
              onChange={(event, value) => handleChangeStatus(value)}
              options={dataStatus}
            />
          </DialogContent>
        </Box>
        <Toolbar>
          <Box display="flex" justifyContent="center" width={"100%"}>
            <Button
              variant="contained"
              startIcon={<SaveIcon />}
              onClick={handleSave}
            >
              {translate("resources.common.fields.add")}
            </Button>
            &nbsp;&nbsp;
            <Button
              startIcon={<ReplyIcon />}
              className={"button-close"}
              variant="contained"
              onClick={handleCloseDialog}
            >
              {translate("resources.common.fields.cancel")}
            </Button>
          </Box>
        </Toolbar>
      </Dialog>
    </>
  );
};
