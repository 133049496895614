import React from 'react';
import { Box, TextField, Typography, InputAdornment, Autocomplete, Button } from '@mui/material';
import { styled } from '@mui/system';
import CircularProgress from '@mui/material/CircularProgress';
import SearchIcon from '@mui/icons-material/Search';

//Hooks
import { useDebounce } from '../../../hooks';

//Components
import ProjectTable from '../../components/Table';
import DialogCustom from '../../../component/DialogCustom';

//Service
import * as projectService from '../../../Services/projectService';
import { useRecordContext } from 'react-admin';

//Shared data
import { TRANG_THAI } from '../../../shared/status';

const Wrapper = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 400,
    overflowY: 'auto',
});

const LoadingWrapper = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 450,
});

const FillterWrapper = styled(Box)({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    padding: 20,
});

const FillterItem = styled(Box)({
    width: 300,
    padding: '0 20px',
});

type Props = {
    isOpen: boolean;
    onClose: () => void;
};

const LIST_STATUS = [{ id: 0, label: 'Tất cả' }, ...TRANG_THAI];

function ProjectGood({ isOpen, onClose }: Props) {
    const [initialState, setInitialState] = React.useState<any[]>([]);
    const [loadingData, setLoadingData] = React.useState<boolean>(false);

    //Searching
    const [code, setCode] = React.useState<string>();
    const [status, setStatus] = React.useState<any>();

    //Debound
    const codeDebound = useDebounce(code, 500);

    //Hooks
    const record = useRecordContext();

    React.useEffect(() => {
        if (isOpen) {
            setStatus(LIST_STATUS[0]);
        }
    }, [isOpen]);

    React.useEffect(() => {
        if (isOpen) {
            setLoadingData(true);
            const fetchApi = async () => {
                const data = await projectService.getListProjectDetail(
                    Number(record.id),
                    codeDebound ? codeDebound : null,
                    status?.id ? status.id : null,
                );
                setInitialState([...data]);
                setTimeout(() => {
                    setLoadingData(false);
                }, 500);
            };
            fetchApi();
        } else {
            setCode('');
            setStatus(null);
        }
    }, [isOpen, record.id, codeDebound, status?.id]);

    const handleSearchValue = (e: any) => {
        const searchValue = e.target.value;
        if (!searchValue.startsWith(' ')) {
            setCode(searchValue);
        }
    };

    return (
        <DialogCustom title="Danh sách sản phẩm" isOpen={isOpen} onClose={onClose} size="xl">
            <>
                <FillterWrapper>
                    <FillterItem>
                        <TextField
                            autoFocus
                            margin="normal"
                            fullWidth
                            label="Mã lô"
                            variant="outlined"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            value={code}
                            onChange={handleSearchValue}
                        />
                    </FillterItem>
                    <FillterItem>
                        <Autocomplete
                            fullWidth
                            id="combo-box-demo"
                            options={LIST_STATUS}
                            renderInput={(params) => (
                                <TextField
                                    margin="normal"
                                    {...params}
                                    variant="outlined"
                                    label="Trạng thái"
                                />
                            )}
                            value={status}
                            onChange={(e, value) => {
                                setStatus(value);
                            }}
                            getOptionLabel={(option) => option.label}
                        />
                    </FillterItem>
                </FillterWrapper>
                {loadingData ? (
                    <LoadingWrapper>
                        <CircularProgress />
                    </LoadingWrapper>
                ) : (
                    <>
                        <ProjectTable data={initialState} />
                        {initialState.length === 0 && (
                            <Wrapper>
                                <Typography>Chưa có sản phẩm nào</Typography>
                            </Wrapper>
                        )}
                    </>
                )}
            </>
        </DialogCustom>
    );
}
export default React.memo(ProjectGood);
