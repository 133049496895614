import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import React, { useEffect, useState, useContext } from 'react';
import {
    Pagination,
    List,
    Datagrid,
    DatagridProps,
    useListContext,
    TextField,
    FunctionField,
    FilterLiveSearch,
    useTranslate,
} from 'react-admin';
import { Box, Button, Chip, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Form } from 'react-final-form';
import { PermissionAdd } from './PermissionAdd';
import { PermissionEdit } from './PermissionEdit';
import HttpDataService from '../dataProvider/http.service';
import { PermissionDelete } from './PermissionDelete';
import { commonFunction } from '../component/Common';

const Item = styled(Paper)(({ theme }) => ({
    textAlign: 'right',
}));
import PaginationCustom from '../component/PaginationCustom';

// @ts-ignore
const PermissionPolicyContext = React.createContext();

export const PermissionList = (props: any) => {
    const PostPagination = (props: any) => <PaginationCustom {...props} />;
    const translate = useTranslate();
    // @ts-ignore
    const [nameContext, setNameContext] = useState<string>();

    return (
        <Box className="header-channel-sales">
            <Box className={'box_header'}>
                <Typography className={'title_header'}>
                    {translate('resources.permission.list_permission')}
                </Typography>
            </Box>
            <div className={`header-top-detail header-top-tdn`}>
                <PermissionPolicyContext.Provider
                    value={{
                        nameContext: [nameContext, setNameContext],
                    }}
                >
                    <List
                        {...props}
                        filter={{
                            name: nameContext,
                        }}
                        actions={false}
                        empty={false}
                        exporter={false}
                        filters={<FilterAndActions />}
                        sort={{ field: '', order: '' }}
                        pagination={<PostPagination />}
                        filterDefaultValues={{
                            name: '',
                        }}
                    >
                        <PermissionDatagrid {...props} />
                    </List>
                </PermissionPolicyContext.Provider>
            </div>
        </Box>
    );
};

interface ButtonProps {
    basePath?: string;
    label: string;
    record?: any;
}

const EditButton = (props: any) => {
    const [onCloseDialog, setOpenEditDialog] = useState(false);
    return (
        <>
            <IconButton onClick={() => setOpenEditDialog(true)}>
                <EditIcon className={'btn_edit'} />
            </IconButton>
            {onCloseDialog && (
                <PermissionEdit
                    {...props}
                    openDialog={onCloseDialog}
                    onCloseDialog={() => setOpenEditDialog(false)}
                />
            )}
        </>
    );
};

const DeleteButton = (props: any) => {
    const [openDialog, setOpenEditDialog] = useState(false);
    return (
        <>
            <IconButton onClick={() => setOpenEditDialog(true)}>
                <DeleteIcon className={'btn_del'} />
            </IconButton>
            {openDialog && (
                <PermissionDelete
                    {...props}
                    openDialog={openDialog}
                    onCloseDialog={() => setOpenEditDialog(false)}
                />
            )}
        </>
    );
};

const RowActions = ({ record }: ButtonProps) => {
    const per_edit = commonFunction.checkPermission('PERMISSION_EDIT');
    const per_delete = commonFunction.checkPermission('PERMISSION_DELETE');
    return (
        <Box>
            {per_edit && <EditButton />}
            {per_delete && <DeleteButton />}
        </Box>
    );
};

const FilterAndActions = (props: any) => {
    // @ts-ignore
    const { filterValues } = useListContext();
    const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);
    // @ts-ignore
    const { nameContext } = useContext(PermissionPolicyContext);
    const [name, setName] = nameContext;
    const translate = useTranslate();
    /**
     * handle change name
     * @param e
     */
    const handleNameChange = (e: any) => {
        setName(e == '' ? '' : e.target.value);
    };

    return (
        <Box
            sx={{
                p: 1,
                m: 1,
                bgcolor: 'background.paper',
                borderRadius: 1,
            }}
            className={'box_search'}
        >
            <Item className="shadow-none">
                <Form onSubmit={() => undefined} initialValues={filterValues}>
                    {() => (
                        <FilterLiveSearch
                            onChange={handleNameChange}
                            value={name}
                            variant="outlined"
                            source="name"
                            label="resources.common.fields.search"
                        />
                    )}
                </Form>
            </Item>
            <Item className="shadow-none">
                {commonFunction.checkPermission('PERMISSION_ADD') && (
                    <>
                        <Button
                            variant="outlined"
                            startIcon={<AddIcon />}
                            color="primary"
                            onClick={() => setOpenCreateDialog(true)}
                        >
                            {translate('resources.permission.fields.add_permission')}
                        </Button>
                        {openCreateDialog && (
                            <PermissionAdd
                                openDialog={openCreateDialog}
                                onCloseDialog={() => setOpenCreateDialog(false)}
                            />
                        )}
                    </>
                )}
            </Item>
        </Box>
    );
};

const PermissionDatagrid = (props: DatagridProps) => {
    const translate = useTranslate();
    return (
        <>
            <div>
                <Datagrid optimized bulkActionButtons={false}>
                    <TextField
                        source={'name'}
                        label={translate('resources.permission.fields.name_permission')}
                    />
                    <TextField
                        source={'code'}
                        label={translate('resources.permission.fields.code_permission')}
                    />
                    <TextField
                        source={'parent_name'}
                        label={translate('resources.permission.fields.father_permission')}
                    />
                    <TextField
                        source={'slug'}
                        label={translate('resources.permission.fields.slug_permission')}
                    />
                    <FunctionField
                        label={translate('resources.common.fields.status')}
                        render={(record: any) => {
                            if (record.status === 1) {
                                return (
                                    <Chip
                                        label={translate('resources.common.fields.active')}
                                        color="success"
                                        variant="outlined"
                                    />
                                );
                            } else {
                                return (
                                    <Chip
                                        label={translate('resources.common.fields.block')}
                                        color="error"
                                        variant="outlined"
                                    />
                                );
                            }
                        }}
                    />
                    <RowActions label={translate('resources.common.fields.action')} />
                </Datagrid>
            </div>
        </>
    );
};
