import React, { useMemo, CSSProperties } from "react";
import { useState, useEffect } from "react";
import { useMediaQuery, Theme, Box, Card } from "@mui/material";
import NewCustomers from "./NewCustomers";
import ProjectBarChart from "./ProjectBarChart";
import ProjectPieChart from "./ProjectPieChart";
import NbNewOrders from "./NbNewOrders";
import * as request from "../utils/httpRequest";
import { styled } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { TextField as InputText, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";

const styles = {
	flex: { display: "flex", marginTop: "1em" },
	flexColumn: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
	},
	leftCol: {
		marginRight: "0.5em",
		marginLeft: "0.5em",
		marginBottom: "1em",
		width: "75%",
	},
	rightCol: { flex: 1, marginLeft: "0.5em" },
	singleCol: { marginTop: "1em" },
	xsmall: { width: "100%" },
	heightPie: { height: "200px" },
	heightBar: { height: "300px" },
};
const PieResponsive = styled(Box)({
	width: "40%",
	"@media (max-width: 1800px)": {
		width: "50%",
	},
	"@media (max-width: 1500px)": {
		width: "60%",
	},
	"@media (max-width: 1300px)": {
		width: "75%",
	},
});
const Spacer = () => <span style={{ width: "1em" }} />;
const VerticalSpacer = () => <span style={{ height: "1em" }} />;

const today = dayjs();

const FillterItem = styled(Box)({
	width: 160,
	marginLeft: 10,
	marginRight: 10,
});

const Dashboard = () => {
	const [dateStart, setDateStart] = React.useState<any | null>(null);
	const [dateEnd, setDateEnd] = React.useState<any | null>(today?.format("YYYY-MM-DD"));

	const handleDateStartChange = (e: any) => {
		if (!Number.isNaN(e?.$D) && !Number.isNaN(e?.$W) && !Number.isNaN(e?.$y)) {
			setDateStart(e?.format("YYYY-MM-DD"));
		}
	};
	const handleDateEndChange = (e: any) => {
		if (!Number.isNaN(e?.$D) && !Number.isNaN(e?.$W) && !Number.isNaN(e?.$y)) {
			setDateEnd(e?.format("YYYY-MM-DD"));
		}
	};
	const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
	const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));
	const [loading, setLoading] = useState<boolean>(false);
	const [listProjectByDistrict, setListProjectByDistrict] = useState<any>([]);
	const [listProjectByStatus, setListProjectByStatus] = useState<any>([]);
	const [listCustomer, setListCustomer] = useState<any>([]);

	const DatePick = () => {
		return (
			<Card style={{ display: "flex", justifyContent: "center", width: "100%" }}>
				{" "}
				<div style={{ display: "flex", margin: "8px 0" }}>
					<FillterItem>
						<LocalizationProvider
							dateAdapter={AdapterDayjs}
							adapterLocale={"vi"}
						>
							<DatePicker
								label={"Từ ngày"}
								value={dateStart}
								inputFormat="DD/MM/YYYY"
								onChange={(newValue, keyBoardValue) => {
									handleDateStartChange(newValue ? newValue : keyBoardValue);
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										fullWidth
										variant="outlined"
									/>
								)}
							/>
						</LocalizationProvider>
					</FillterItem>

					<span style={{ margin: "auto 0" }}>-</span>
					<FillterItem>
						<LocalizationProvider
							dateAdapter={AdapterDayjs}
							adapterLocale="vi"
						>
							<DatePicker
								label="Đến ngày:"
								inputFormat="DD/MM/YYYY"
								value={dateEnd}
								onChange={(newValue, keyBoardValue) => {
									handleDateEndChange(newValue ? newValue : keyBoardValue);
								}}
								renderInput={(params) => (
									<InputText
										className="mb-23"
										{...params}
										fullWidth
										variant="outlined"
									/>
								)}
							/>
						</LocalizationProvider>
					</FillterItem>
				</div>
			</Card>
		);
	};

	useEffect(() => {
		const fetchAPI = async () => {
			setLoading(true);
			await request
				.get(`dashboard/project-by-district?date_from=${dateStart ?? ""}&date_to=${dateEnd ?? ""}`)
				.then((res) => {
					let data = res.data;
					if (data.length > 0) {
						setListProjectByDistrict(data);
					}
					// setloading(false);
				});
			await request
				.get(`dashboard/project-by-status?date_from=${dateStart ?? ""}&date_to=${dateEnd ?? ""}`)
				.then((res) => {
					let data = res.data;
					if (data.length > 0) {
						setListProjectByStatus(data);
					}
				});
			await request.get("dashboard/list-customer").then((res) => {
				let data = res;
				if (data.length > 0) {
					setListCustomer(data);
				}
			});
			if (listProjectByDistrict && listProjectByStatus && listCustomer) {
				setLoading(false);
			}
		};
		fetchAPI();
	}, [dateStart, dateEnd]);

	return isXSmall ? (
		<div style={styles.flexColumn as CSSProperties}>
			<div style={styles.xsmall as CSSProperties}>
				<VerticalSpacer />
				<DatePick />
				<NbNewOrders data={listProjectByStatus} />
				<VerticalSpacer />
				<NewCustomers data={listCustomer} />
				<VerticalSpacer />
				{!loading ? <ProjectPieChart data={listProjectByStatus} /> : <Card style={styles.heightPie}></Card>}
				<VerticalSpacer />
				{!loading ? <ProjectBarChart data={listProjectByDistrict} /> : <Card style={styles.heightBar}></Card>}
			</div>
		</div>
	) : isSmall ? (
		<div style={styles.flexColumn as CSSProperties}>
			<div style={styles.singleCol}>
				{!loading ? <ProjectBarChart data={listProjectByDistrict} /> : <Card style={styles.heightBar}></Card>}
			</div>
			<div style={styles.flex}>
				<div style={styles.flexColumn as CSSProperties}>
					<DatePick />
					<VerticalSpacer />
					{!loading ? (
						<ProjectPieChart
							style={{ width: "100%" }}
							data={listProjectByStatus}
						/>
					) : (
						<Card style={styles.heightPie}></Card>
					)}
				</div>
				<div style={styles.rightCol}>
					<div style={styles.flexColumn as CSSProperties}>
						<NbNewOrders data={listProjectByStatus} />
						<VerticalSpacer />
						<NewCustomers data={listCustomer} />
					</div>
				</div>
			</div>
		</div>
	) : (
		<div style={styles.flex}>
			<div style={styles.leftCol}>
				<div style={styles.flexColumn as CSSProperties}>
					{!loading ? (
						<ProjectBarChart data={listProjectByDistrict} />
					) : (
						<Card style={styles.heightBar}></Card>
					)}
					<VerticalSpacer />
					{!loading ? (
						<PieResponsive>
							<ProjectPieChart data={listProjectByStatus} />
						</PieResponsive>
					) : (
						<Card style={{ width: "50%", height: "300px" }}></Card>
					)}
				</div>
			</div>
			<div style={styles.rightCol}>
				<div style={styles.flexColumn as CSSProperties}>
					<DatePick />
					<VerticalSpacer />
					<NbNewOrders data={listProjectByStatus} />
					<VerticalSpacer />
					<NewCustomers data={listCustomer} />
				</div>
			</div>
		</div>
	);
};
export default Dashboard;
