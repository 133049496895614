import {
    DefaultDataProvider,
    httpClient,
    serialize,
} from '../default-data-provider';
import {constants} from "../../shared/constants";

export const baseApiUrl = 'office';

export default class OfficeService extends DefaultDataProvider {

}

export const officeService = new OfficeService(
    constants.RESOURCE.OFFICE,
    constants.BASE_MODULE_URL,
    baseApiUrl
);