import http from "../http-common";
import {object} from "prop-types";

class HttpDataService {
    getAll($resource: string) {
        return http.get<any>($resource);
    }

    // get(id: string) {
    //     return http.get<ITutorialData>(`/tutorials/${id}`);
    // }

    create(resource: string, data: any) {
        return http.post<any>(resource, data);
    }

    update(resource: string, data: any) {
        return http.put<any>(`/${resource}`, data);
    }

    delete(resource: string, id: string) {
        return http.delete<any>(`/${resource}?id=${id}`);
    }

    lock(resource: string, id: string) {
        return http.post<any>(`/${resource}/${id}`);
    }

    //
    // deleteAll() {
    //     return http.delete<any>(`/tutorials`);
    // }
    //
    findById(resource: string, id: string) {
        let test = http.get<any>(`/${resource}/${id}`);
        return test;

    }
}

export default new HttpDataService();