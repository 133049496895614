import {
  EditProps,
  useNotify,
  useRecordContext,
  useRefresh,
  useTranslate,
} from "react-admin";
import {
  Autocomplete,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import SaveIcon from "@mui/icons-material/Save";
import ReplyIcon from "@mui/icons-material/Reply";
import Item from "antd/es/list/Item";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import * as departmentService from "../Services/departmentService";
import * as officeService from "../Services/officeService";
import * as partsService from "../Services/partsService";
import * as rolesService from "../Services/rolesService";
import * as userService from "../Services/userService";
import PageLoadingAPI from "../component/PageLoadingAPI";
import Image from "../component/Image";
import HttpDataService from "../dataProvider/http.service";

import ImagePreview from "../component/ImgPreview";
import classnames from "classnames/bind";
import styles from "./User.module.scss";
import * as request from "../utils/httpRequest";
import { STATUS, WORKING, SEX, PERMISSION } from "../shared/status";
const cx = classnames.bind(styles);
import { createStyles, makeStyles } from "@material-ui/core/styles";

const styled = makeStyles((theme: any) =>
  createStyles({
    root: {
      "& .css-k4qjio-MuiFormHelperText-root.Mui-error": {
        position: "absolute",
        paddingTop: "41px",
      },
    },
  })
);

const locales = ["vi"] as const;

const dataShowHome = [
  { label: "Không hiện", id: 0 },
  { label: "Hiện trang chủ", id: 1 },
];
let dataListManager: any[] = [{ label: "--Lựa chọn--", id: "" }];

const dataPermission_data = [
  { label: "--Lựa chọn--", id: "" },
  { label: "Tất cả", id: 1 },
  { label: "Quản lý", id: 2 },
  { label: "Nhân viên", id: 3 },
];

interface Add extends EditProps {
  openDialog: boolean;
  onCloseDialog: (openDialog: boolean) => void;
}
export const UserEdit = ({ openDialog, onCloseDialog, ...rest }: Add) => {
  const classes = styled();
  const translate = useTranslate();
  // image
  const [image, setImage] = React.useState<any[]>([]);
  //first_name
  const [first_name, setFirst_name] = useState<string>("");
  const [first_nameError, setFirst_nameError] = useState(false);
  const [textFirst_nameError, setTextFirst_nameError] = useState("");
  //last_name
  const [last_name, setLast_name] = useState<string>("");
  const [last_nameError, setLast_nameError] = useState(false);
  const [textLast_nameError, setTextLast_nameError] = useState("");
  //birthday
  const [birthday, setBirthday] = React.useState<Dayjs | null>(null);
  // Phone
  const [phone, setPhone] = useState<string>("");
  // Địa chỉ tạm trú
  const [temporary_address, setTemporary_address] = useState<string>("");
  // Địa chỉ thường trú
  const [permanent_address, setPermanent_address] = useState<string>("");
  // Tài khoản ngân hàng
  const [bank, setBank] = useState<string>("");
  // Bảo hiểm xã hội
  const [bhxh, setBhxh] = useState<string>("");
  // Căn cước công dân
  const [cccd, setCccd] = useState<string>("");
  // Nơi cấp
  const [noicap, setNoicap] = useState<string>("");
  // Ngày cấp
  const [ngaycap, setNgaycap] = React.useState<Dayjs | null>(null);
  // Dân tộc
  const [ethnic, setEthnic] = useState<string>("");
  // email
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState(false);
  const [textEmailError, setTextEmailError] = useState("");
  // Email cá nhân
  const [email_ca_nhan, setEmail_ca_nhan] = useState<string>("");
  //username
  const [username, setUsername] = useState<string>("");
  const [usernameError, setUsernameError] = useState(false);
  const [textUsernameError, setTextUsernameError] = useState("");
  // password
  const [password, setPassword] = useState<string>("");
  const [passwordError, setPasswordError] = useState(false);
  const [textPasswordError, setTextPasswordError] = useState("");
  // password-confirm
  const [password_confirm, setPassword_confirm] = useState<string>("");
  const [password_confirmError, setPassword_confirmError] = useState(false);
  const [textPassword_confirmError, setTextPassword_confirmError] =
    useState("");
  //status
  const [status, setStatus] = useState<any>();
  //show home
  const [showHome, setShowHome] = useState<any>();
  // permission_data
  const [permission_data, setPermission_data] = useState<any>();
  // hình thức làm việc
  const [working_form, setWorking_form] = useState<any>();
  // Giới tính
  const [sex, setSex] = useState<any>();
  // phòng ban
  // const [department, setDepartment] = useState<any>(null);
  const [department, setDepartment] = React.useState<any>();
  // Chức vụ
  const [office, setOffice] = useState<any>(null);
  // Bộ phận
  const [parts, setParts] = useState<any>(null);
  // Nhóm quyền
  const [roles, setRoles] = useState<any>(null);
  //danh sách quản lý
  const [manage, setManage] = useState<any>(dataListManager[0]);

  const refresh = useRefresh();
  const notify = useNotify();
  const record = useRecordContext();

  const removeImageUtilities = (imgIndex: number) => {
    const newListImage = image.filter((_, index) => index !== imgIndex);
    if (image.length === 1) {
      setImage([]);
    }
    setImage(newListImage);
  };

  // ################## -API- ##############
  // api phòng ban
  const [listDe, setListDe] = React.useState<any[]>([]);
  const [listOff, setListOff] = useState<any[]>([]);
  const [listPa, setListPa] = useState<any[]>([]);
  const [listRo, setListRo] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [initialState, setInitialState] = React.useState<any>();
  useEffect(() => {
    const fetchApi = async () => {
      const data = await userService.getUserDetail(Number(record.id));
      setInitialState(data);
      const lisDepartmentResult = await departmentService.getListDepartment();

      const departmentObj = await lisDepartmentResult.find(
        (item: any) => item.id === data.department_id
      );
      const lisOfficeResult = await officeService.getListOffice();
      const officeObj = await lisOfficeResult.find(
        (item: any) => item.id === data.office_id
      );
      const lisPartsResult = await partsService.getListParts();
      const partsObj = await lisPartsResult.find(
        (item: any) => item.id === data.parts_id
      );
      const lisRolesResult = await rolesService.getListRoles();
      const rolesObj = await lisRolesResult.find(
        (item: any) => item.id === data.roles_id
      );
      const statusObj = await STATUS.find(
        (item: any) => item.id === data.status
      );
      const showHomeObj = await dataShowHome.find(
        (item: any) => item.id === data.show_home
      );
      const workingObj = await WORKING.find(
        (item: any) => item.id === data.working_form
      );
      const managerObj = await dataListManager.find(
        (item: any) => item.id === data.parent_id
      );

      const sexObj = await SEX.find((item: any) => item.id === data.sex);
      const permissionObj = await dataPermission_data.find(
        (item: any) => item.id === data.permission_data
      );
      setListDe(lisDepartmentResult);
      setDepartment(departmentObj);
      setListOff(lisOfficeResult);
      setOffice(officeObj);
      setListPa(lisPartsResult);
      setParts(partsObj);
      setListRo(lisRolesResult);
      setRoles(rolesObj);
      setNgaycap(initialState?.ngaycap);
      setBirthday(initialState?.birthday);
      setStatus(statusObj);
      setShowHome(showHomeObj);
      setWorking_form(workingObj);
      setSex(sexObj);
      setPermission_data(permissionObj);
      setManage(managerObj)

      setLoading(false);
    };
    fetchApi();
  }, []);

  // api danh sách quản lý
  useEffect(() => {
    if(openDialog) {
      HttpDataService.getAll("users/selectUserByType?type=2").then((res) => {
        dataListManager = [{ label: "--Lựa chọn--", id: "" }];
        let data = res.data.data;
        data.map((item: any) => {
          dataListManager.push({
            id: item.id,
            label: item.name,
          });
        });
      });
    }
  }, [openDialog]);

  const editUser = async (data: any) => {
    const submitObj = new FormData();
    console.log(Object.keys(data));
    Object.keys(data).map((key) => submitObj.append(key, data[key]));
    if (image) {
      image.map((item: any) => {
        submitObj.append("image", item);
      });
    }
    try {
      const id = submitObj.get("id");
      const response = await request.post(
        `/users/updateUser?id=${id}`,
        submitObj
      );
      return response.status;
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * handle save
   */
  const handleSave = () => {
    if (!fnValidation()) {
      let data = {
        id: record.id,
        first_name: initialState?.first_name.trim(),
        last_name: initialState?.last_name.trim(),
        username: initialState?.username.trim(),
        email: initialState?.email.trim(),
        // password: password.trim(),
        password: initialState?.password || "",
        // password_confirm: password_confirm.trim(),
        password_confirm: initialState?.password_confirm,
        status: status?.id ? status.id : initialState?.status || 0,
        show_home: showHome?.id ? showHome.id : 0,
        phone: initialState?.phone || "",
        working_form: working_form?.id
          ? working_form.id
          : initialState?.working_form || 0,
        department_id: department?.id || 0,
        ethnic: initialState?.ethnic || "",
        temporary_address: initialState?.temporary_address || "",
        bank: initialState?.bank || "",
        bhxh: initialState?.bhxh || "",
        noicap: initialState?.noicap || "",
        ngaycap: initialState?.ngaycap || "",
        cccd: initialState?.cccd || "",
        permanent_address: initialState?.permanent_address || "",
        email_ca_nhan: initialState?.email_ca_nhan,
        sex: sex?.id ? sex.id : initialState?.sex || 0,
        parts_id: parts?.id || 0,
        office_id: office?.id || 0,
        permission_data: permission_data?.id
          ? permission_data.id
          : initialState?.permission_data || 0,
        birthday: initialState?.birthday,
        roles_id: roles?.id || 0,
        image: initialState?.image,
        manager_id: manage?.id || 0
      };
      const submit = async (data: any) => {
        const result = await editUser(data);
        if (result === 200) {
          notify(`Cập nhật thành công`, {
            type: "success",
            undoable: true,
          });
          refresh();
        } else {
          notify(`Cập nhật thất bại`, {
            type: "error",
            undoable: true,
          });
        }
      };
      submit(data);
      fnResetFrm();
      onCloseDialog(false);
    }
  };

  const handleCloseDialog = () => {
    fnResetFrm();
    onCloseDialog(false);
  };

  /**
   * fn validation form
   */
  function fnValidation() {
    let error = false;
    if (initialState?.first_name.trim().length === 0) {
      error = true;
      setFirst_nameError(true);
      setTextFirst_nameError(translate("resources.user.fields.val_first_name"));
    }
    if (initialState?.last_name.trim().length === 0) {
      error = true;
      setLast_nameError(true);
      setTextLast_nameError(translate("resources.user.fields.val_last_name"));
    }
    if (initialState?.email.trim().length === 0) {
      error = true;
      setEmailError(true);
      setTextEmailError(translate("resources.user.fields.val_email"));
    }
    if (initialState?.username.trim().length === 0) {
      error = true;
      setUsernameError(true);
      setTextUsernameError(translate("resources.user.fields.val_account"));
    }
    return error;
  }

  function fnResetFrm() {
    setEmail("");
    setEmailError(false);
    setTextEmailError("");
    setFirst_name("");
    setFirst_nameError(false);
    setTextFirst_nameError("");
    setLast_name("");
    setLast_nameError(false);
    setTextLast_nameError("");
    setStatus(null);
    setShowHome(null);
    setBirthday(null);
    setPhone("");
    setTemporary_address("");
    setPermanent_address("");
    setBank("");
    setBhxh("");
    setCccd("");
    setNoicap("");
    setNgaycap(null);
    setEthnic("");
    setEmail_ca_nhan("");
    setUsername("");
    setUsernameError(false);
    setTextUsernameError("");
    setPassword("");
    setPasswordError(false);
    setTextPasswordError("");
    setPassword_confirm("");
    setPassword_confirmError(false);
    setTextPassword_confirmError("");
    setPermission_data(null);
    setWorking_form(null);
    setSex(null);
    setDepartment(null);
    setOffice(null);
    setParts(null);
    setRoles(null);
  }

  return (
    <>
      <Dialog
        open={openDialog}
        fullWidth
        PaperProps={{
          style: {
            borderRadius: 2,
            minWidth: "85%",
          },
        }}
      >
        <div className={"common__dialogTitle_area"}>
          <DialogTitle className={"member__dialogTitle"}>
            <div>{translate("resources.user.edit_user")}</div>
          </DialogTitle>
          <div className={"common__dialogTitle_actions"}>
            <IconButton
              aria-label="exists"
              color={"error"}
              onClick={() => {
                onCloseDialog(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        {loading ? (
          <PageLoadingAPI />
        ) : (
          <Box className={"dialog_box"}>
            <DialogContent className={"dialog_content"}>
              <p className={"title-add-user"}>
                {translate("resources.user.info_contact_user")}
              </p>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={6}>
                  <Item className={"item_li"}>
                    <TextField
                      className={`${classes.root} mb-23`}
                      fullWidth
                      label={
                        <span className={"span_style"}>
                          {translate("resources.user.fields.first_name_user")}
                          <span style={{ color: "red" }}>(*)</span>
                        </span>
                      }
                      error={first_nameError}
                      helperText={textFirst_nameError}
                      variant="outlined"
                      value={initialState?.first_name}
                      onChange={(e) => {
                        setInitialState({
                          ...initialState,
                          first_name: e.target.value,
                        });
                      }}
                    />
                  </Item>
                  <Item className={"item_li"}>
                    <TextField
                      className={`${classes.root} mb-23`}
                      fullWidth
                      label={
                        <span className={"span_style"}>
                          {translate("resources.user.fields.last_name_user")}
                          <span style={{ color: "red" }}>(*)</span>
                        </span>
                      }
                      error={last_nameError}
                      helperText={textLast_nameError}
                      variant="outlined"
                      value={initialState?.last_name}
                      onChange={(e) => {
                        setInitialState({
                          ...initialState,
                          last_name: e.target.value,
                        });
                      }}
                    />
                  </Item>
                  <Item className={"item_li"}>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={"vi"}
                    >
                      <DatePicker
                        label={
                          <span className={"span_style"}>
                            {translate("resources.user.fields.birthday_user")}
                          </span>
                        }
                        value={initialState?.birthday}
                        inputFormat="DD/MM/YYYY"
                        onChange={(newValue) => {
                          setInitialState({
                            ...initialState,
                            birthday: newValue,
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            className="mb-23"
                            {...params}
                            fullWidth
                            variant="outlined"
                          />
                        )}
                        className={"birthday"}
                      />
                    </LocalizationProvider>
                  </Item>
                  <Item className={"item_li"}>
                    <TextField
                      className="mb-23"
                      fullWidth
                      label={
                        <span className={"span_style"}>
                          {translate("resources.user.fields.phone")}
                        </span>
                      }
                      value={initialState?.phone}
                      onChange={(e) => {
                        setInitialState({
                          ...initialState,
                          phone: e.target.value,
                        });
                      }}
                      variant="outlined"
                      type="number"
                    />
                  </Item>
                  <Item className={"item_li"}>
                    <TextField
                      className="mb-23"
                      fullWidth
                      label={
                        <span className={"span_style"}>
                          {translate("resources.user.fields.ethnic_user")}
                        </span>
                      }
                      value={initialState?.ethnic}
                      onChange={(e) => {
                        setInitialState({
                          ...initialState,
                          ethnic: e.target.value,
                        });
                      }}
                      variant="outlined"
                    />
                  </Item>
                  <Item className={"item_li"}>
                    <TextField
                      className="mb-23"
                      fullWidth
                      label={
                        <span className={"span_style"}>
                          {translate("resources.user.fields.dctt_user")}
                        </span>
                      }
                      value={initialState?.temporary_address}
                      onChange={(e) => {
                        setInitialState({
                          ...initialState,
                          temporary_address: e.target.value,
                        });
                      }}
                      variant="outlined"
                    />
                  </Item>
                  <Item className={"item_li"}>
                    <TextField
                      className="mb-23"
                      fullWidth
                      label={
                        <span className={"span_style"}>
                          {translate("resources.user.fields.dcttru_user")}
                        </span>
                      }
                      value={initialState?.permanent_address}
                      onChange={(e) => {
                        setInitialState({
                          ...initialState,
                          permanent_address: e.target.value,
                        });
                      }}
                      variant="outlined"
                    />
                  </Item>

                  <Item className={"item_li"}>
                    <TextField
                      className="mb-23"
                      fullWidth
                      label={
                        <span className={"span_style"}>
                          {translate(
                            "resources.user.fields.personal_email_user"
                          )}
                        </span>
                      }
                      value={initialState?.email_ca_nhan}
                      onChange={(e) => {
                        setInitialState({
                          ...initialState,
                          email_ca_nhan: e.target.value,
                        });
                      }}
                      variant="outlined"
                    />
                  </Item>
                  <Item className={"item_li"}>
                    <TextField
                      className="mb-23"
                      fullWidth
                      label={
                        <span className={"span_style"}>
                          {translate("resources.user.fields.bank")}
                        </span>
                      }
                      value={initialState?.bank}
                      onChange={(e) => {
                        setInitialState({
                          ...initialState,
                          bank: e.target.value,
                        });
                      }}
                      variant="outlined"
                      type="number"
                    />
                  </Item>
                  <Item className={"item_li"}>
                    <TextField
                      className="mb-23"
                      fullWidth
                      label={
                        <span className={"span_style"}>
                          {translate("resources.user.fields.bhxh")}
                        </span>
                      }
                      value={initialState?.bhxh}
                      onChange={(e) => {
                        setInitialState({
                          ...initialState,
                          bhxh: e.target.value,
                        });
                      }}
                      variant="outlined"
                    />
                  </Item>
                </Grid>
                <Grid item xs={6}>
                  <Item className={"item_li"}>
                    <TextField
                      className="mb-23"
                      fullWidth
                      label={
                        <span className={"span_style"}>
                          {translate("resources.user.fields.id_user")}
                        </span>
                      }
                      value={initialState?.cccd}
                      onChange={(e) => {
                        setInitialState({
                          ...initialState,
                          cccd: e.target.value,
                        });
                      }}
                      variant="outlined"
                      type="number"
                    />
                  </Item>

                  <Item className={"item_li"}>
                    <TextField
                      className="mb-23"
                      fullWidth
                      label={
                        <span className={"span_style"}>
                          {translate("resources.user.fields.noicap")}
                        </span>
                      }
                      value={initialState?.noicap}
                      onChange={(e) => {
                        setInitialState({
                          ...initialState,
                          noicap: e.target.value,
                        });
                      }}
                      variant="outlined"
                    />
                  </Item>
                  <Item className={"item_li"}>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={"vi"}
                    >
                      <DatePicker
                        label={
                          <span className={"span_style"}>
                            {translate("resources.user.fields.ngaycap")}
                          </span>
                        }
                        value={initialState?.ngaycap}
                        inputFormat="DD/MM/YYYY"
                        onChange={(newValue) => {
                          setInitialState({
                            ...initialState,
                            ngaycap: newValue,
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            className="mb-23"
                            {...params}
                            fullWidth
                            variant="outlined"
                          />
                        )}
                        className={"ngaycap"}
                      />
                    </LocalizationProvider>
                  </Item>
                  <Item className={"item_li"}>
                    <Autocomplete
                      renderInput={(params) => (
                        <TextField
                          className="mb-23"
                          {...params}
                          variant="outlined"
                          label={
                            <span className={"span_style"}>
                              {translate("resources.user.fields.sex_user")}
                            </span>
                          }
                        />
                      )}
                      sx={{ width: "100%" }}
                      value={sex}
                      onChange={(e, value) => {
                        setSex(value);
                      }}
                      options={SEX}
                    />
                  </Item>
                  <Item className={"item_li"}>
                    <Autocomplete
                      renderInput={(params) => (
                        <TextField
                          className="mb-23"
                          {...params}
                          variant="outlined"
                          label={
                            <span className={"span_style"}>
                              {translate("resources.user.fields.working_form")}
                            </span>
                          }
                        />
                      )}
                      sx={{ width: "100%" }}
                      value={working_form}
                      onChange={(e, value) => {
                        setWorking_form(value);
                      }}
                      getOptionLabel={(option) => option.label}
                      options={WORKING}
                    />
                  </Item>
                  <Item className={"item_li"}>
                    <Autocomplete
                      fullWidth
                      id="combo-box-demo"
                      options={listDe}
                      renderInput={(params) => (
                        <TextField
                          className="mb-23"
                          {...params}
                          variant="outlined"
                          label={
                            <span className={"span_style"}>Phòng ban</span>
                          }
                        />
                      )}
                      value={department}
                      onChange={(e, value) => {
                        setDepartment(value);
                      }}
                      getOptionLabel={(option) => option.name}
                    />
                  </Item>
                  <Item className={"item_li"}>
                    <Autocomplete
                      fullWidth
                      id="combo-box-demo"
                      options={listOff}
                      renderInput={(params) => (
                        <TextField
                          className="mb-23"
                          {...params}
                          variant="outlined"
                          label={
                            <span className={"span_style"}>
                              {translate("resources.user.fields.office")}
                            </span>
                          }
                        />
                      )}
                      value={office}
                      onChange={(e, value) => {
                        setOffice(value);
                      }}
                      getOptionLabel={(option) => option.name}
                    />
                  </Item>
                  <Item className={"item_li"}>
                    <Autocomplete
                      fullWidth
                      id="combo-box-demo"
                      options={listPa}
                      renderInput={(params) => (
                        <TextField
                          className="mb-23"
                          {...params}
                          variant="outlined"
                          label={
                            <span className={"span_style"}>
                              {translate("resources.user.fields.parts")}
                            </span>
                          }
                        />
                      )}
                      value={parts}
                      onChange={(e, value) => {
                        setParts(value);
                      }}
                      getOptionLabel={(option) => option.name}
                    />
                  </Item>

                  <Item className={"item_li"}>
                    <Autocomplete
                      renderInput={(params) => (
                        <TextField
                          className="mb-23"
                          {...params}
                          variant="outlined"
                          label={
                            <span className={"span_style"}>
                              {translate("resources.common.fields.status")}
                            </span>
                          }
                        />
                      )}
                      sx={{ width: "100%" }}
                      value={status}
                      onChange={(e, value) => {
                        setStatus(value);
                      }}
                      options={STATUS}
                    />
                  </Item>
                  <Item className={"item_li"}>
                  <Autocomplete
                    renderInput={(params) => (
                      <TextField
                        className="mb-23"
                        {...params}
                        variant="outlined"
                        label={
                          <span className={"span_style"}>
                            {`Hiện trang chủ`}
                          </span>
                        }
                      />
                    )}
                    sx={{ width: "100%" }}
                    value={showHome}
                    onChange={(event, value) => setShowHome(value)}
                    options={dataShowHome}
                  />
                </Item>
                </Grid>
              </Grid>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <div className={"main-image"}>
                  <div className={cx("upload-image-wrapper")}>
                    <div className={"title-image"}>Hình ảnh</div>
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg"
                      onChange={(e: any) => {
                        setImage([...e.target.files]);
                        e.target.value = null;
                      }}
                    ></input>
                    {image?.length > 0 ? (
                      <div className={cx("image-show-wrapper")}>
                        {image.map((image: any, index: number) => (
                          <ImagePreview
                            key={index}
                            src={URL.createObjectURL(image)}
                            onRemove={() => removeImageUtilities(index)}
                          />
                        ))}
                      </div>
                    ) : (
                      <Image
                        src={initialState?.image}
                        alt={""}
                        className={cx("img-user-edit")}
                      />
                    )}
                  </div>
                </div>
              </Grid>
              <p className={"Section_addUser title-add-user"}>
                {translate("resources.user.info_log")}
              </p>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                {/*Thông tin đăng nhập*/}
                <Grid item xs={6}>
                  <Item className={"item_li"}>
                    <TextField
                      className={`${classes.root} mb-23`}
                      fullWidth
                      label={
                        <span className={"span_style"}>
                          {translate("resources.user.fields.account_user")}
                          <span style={{ color: "red" }}>(*)</span>
                        </span>
                      }
                      error={usernameError}
                      helperText={textUsernameError}
                      value={initialState?.username}
                      onChange={(e) => {
                        setInitialState({
                          ...initialState,
                          username: e.target.value,
                        });
                      }}
                      variant="outlined"
                    />
                  </Item>
                  <Item className={"item_li"}>
                    <TextField
                      className={`${classes.root} mb-23`}
                      fullWidth
                      label={
                        <span className={"span_style"}>
                          {translate("resources.user.fields.email_user")}
                          <span style={{ color: "red" }}>(*)</span>
                        </span>
                      }
                      error={emailError}
                      helperText={textEmailError}
                      value={initialState?.email}
                      onChange={(e) => {
                        setInitialState({
                          ...initialState,
                          email: e.target.value,
                        });
                      }}
                      variant="outlined"
                    />
                  </Item>
                  <Item className={"item_li"}>
                    <Autocomplete
                      fullWidth
                      id="combo-box-demo"
                      options={listRo}
                      renderInput={(params) => (
                        <TextField
                          className="mb-23"
                          {...params}
                          variant="outlined"
                          label={
                            <span className={"span_style"}>
                              {translate(
                                "resources.user.fields.group_permission"
                              )}
                            </span>
                          }
                        />
                      )}
                      value={roles}
                      onChange={(e, value) => {
                        setRoles(value);
                      }}
                      getOptionLabel={(option) => option.name}
                    />
                  </Item>
                </Grid>
                <Grid item xs={6}>
                  <Item className={"item_li"}>
                    <TextField
                      className={`${classes.root} mb-23`}
                      fullWidth
                      label={
                        <span className={"span_style"}>
                          {translate("resources.user.fields.password_user")}
                          <span style={{ color: "red" }}>(*)</span>
                        </span>
                      }
                      error={passwordError}
                      helperText={textPasswordError}
                      value={initialState?.password}
                      onChange={(e) => {
                        setInitialState({
                          ...initialState,
                          password: e.target.value,
                        });
                      }}
                      variant="outlined"
                      type="password"
                    />
                  </Item>
                  <Item className={"item_li"}>
                    <TextField
                      className={`${classes.root} mb-23`}
                      fullWidth
                      label={
                        <span className={"span_style"}>
                          {translate(
                            "resources.user.fields.retype_password_user"
                          )}
                          <span style={{ color: "red" }}>(*)</span>
                        </span>
                      }
                      error={password_confirmError}
                      helperText={textPassword_confirmError}
                      value={initialState?.password_confirm}
                      onChange={(e) => {
                        setInitialState({
                          ...initialState,
                          password_confirm: e.target.value,
                        });
                      }}
                      variant="outlined"
                      type="password"
                    />
                  </Item>
                  <Item className={"item_li"}>
                    <Autocomplete
                      renderInput={(params) => (
                        <TextField
                          className="mb-23"
                          {...params}
                          variant="outlined"
                          label={
                            <span className={"span_style"}>
                              {translate("resources.user.fields.permission")}
                            </span>
                          }
                        />
                      )}
                      sx={{ width: "100%" }}
                      value={permission_data}
                      onChange={(event, value) => setPermission_data(value)}
                      options={dataPermission_data}
                    />
                  </Item>
                  {permission_data?.id == 3 && (
                  <Item className={"item_li"}>
                  <Autocomplete
                    renderInput={(params) => (
                      <TextField
                        className="mb-23"
                        {...params}
                        variant="outlined"
                        label={
                          <span className={"span_style"}>
                            Quản lý
                          </span>
                        }
                      />
                    )}
                    sx={{ width: "100%" }}
                    value={manage}
                    onChange={(event, value) =>
                      setManage(value)
                    }
                    options={dataListManager}
                  />
                </Item>

                )}
                </Grid>
              </Grid>
            </DialogContent>
          </Box>
        )}

        <Toolbar>
          <Box display="flex" justifyContent="end" width={"100%"}>
            <Button
              variant="contained"
              startIcon={<SaveIcon />}
              onClick={handleSave}
            >
              {translate("resources.common.fields.save")}
            </Button>
            &nbsp;&nbsp;
            <Button
              startIcon={<ReplyIcon />}
              className={"button-close"}
              variant="contained"
              onClick={handleCloseDialog}
            >
              {translate("resources.common.fields.cancel")}
            </Button>
          </Box>
        </Toolbar>
      </Dialog>
    </>
  );
};
