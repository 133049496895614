import {
	Autocomplete,
	Box,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Toolbar, useNotify, useRecordContext, useRefresh, useTranslate } from "react-admin";
import HttpDataService from "../../../dataProvider/http.service";
import SaveIcon from "@mui/icons-material/Save";
import ReplyIcon from "@mui/icons-material/Reply";
import CloseIcon from "@mui/icons-material/Close";

// call api

import DialogSubmitLoading from "../../../component/DialogSubmitLoading";

const Wrapper = styled(Box)({});

const HeaderWrapper = styled(Box)({
	backgroundColor: "whitesmoke",
});

const ContentWrapper = styled(Box)({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	padding: "30px 24px",
});

const WarningText = styled(Typography)({
	color: "#ed6c02",
	fontSize: "1.5rem",
});

const ButtonWrapper = styled(Box)({
	display: "flex",
	justifyContent: "flex-end",
	backgroundColor: "whitesmoke",
	padding: 20,
});

type Props = {
	isOpen: boolean;
	onClose: () => void;
};

function ProjectChangeUser({ isOpen, onClose }: Props) {
	//LOADING STATE
	const [submitLoading, setSubmitLoading] = React.useState<boolean>(false);
	const [dataUser, setDataUser] = useState<any>([]);

	const [createdBy, setCreatedBy] = useState<any>(null);
	const [createdError, setCreatedError] = useState(false);
	const [textErrorCreatedBy, setTextErrorCreatedBy] = useState("");

	//HOOKS
	const translate = useTranslate();
	const record = useRecordContext();
	const refresh = useRefresh();
	const notify = useNotify();

	useEffect(() => {
		const fetchAPI = async () => {
			await HttpDataService.getAll("users/getAll?size=10&page=1")
				.then((response: any) => {
					if (response.data.data) {
						setDataUser(
							response.data.data.items.map((x: any) => ({
								id: x.id,
								label: x.name,
							}))
						);
						setCreatedBy({
							id: record.created_by,
							label: record.created_name,
						});
					}
				})
				.catch((e: Error) => {
					// @ts-ignore
					notify("Lỗi", { type: "error" });
				});
		};

		fetchAPI();
	}, [record.created_by]);

	/**
	 * handleChangeCreatedBy
	 * @param e
	 */
	const handleChangeCreatedBy = (e: any, value: any) => {
		setCreatedError(false);
		setTextErrorCreatedBy("");
		setCreatedBy(value);
	};

	const handleCloseDialog = () => {
		fnResetForm();
		onClose();
	};

	/**
	 * handleSave
	 */
	const handleSave = () => {
		if (!fnValidation()) {
			let data = {
				user_id: createdBy.id,
			};
			HttpDataService.update(`project_estate/update_created_by/${record.id}`, data)
				.then((response: any) => {
					if (response.data.status) {
						// @ts-ignore
						notify(response.data.message, { type: "success" });

						fnResetForm();
						handleCloseDialog();
						refresh();
					} else {
						// @ts-ignore
						notify(response.data.message, { type: "error" });
					}
				})
				.catch((error) => {
					console.log(error);
					setCreatedError(true);
					setTextErrorCreatedBy(error?.response.data.errors.name[0]);
				});
		}
	};

	/**
	 * fnValidation
	 */
	const fnValidation = () => {
		let hasError = false;

		// if (createdBy.trim().length == 0) {
		// 	hasError = true;
		// 	setTextErrorCreatedBy("Người tạo không được để trống");
		// 	setCreatedError(true);
		// }

		return hasError;
	};

	/**
	 * fnResetForm
	 */
	const fnResetForm = () => {
		setCreatedBy({
			id: record.created_by,
			label: record.created_name,
		});
		setCreatedError(false);
		setTextErrorCreatedBy("");
	};

	return (
		<Dialog
			open={isOpen}
			fullWidth
			maxWidth="sm"
			PaperProps={{
				style: {
					borderRadius: 2,
				},
			}}
		>
			<div className={"common__dialogTitle_area"}>
				<DialogTitle className={"member__dialogTitle"}>
					<div>{"Thay đổi người tạo"}</div>
				</DialogTitle>
				<div className={"common__dialogTitle_actions"}>
					<IconButton
						aria-label="exists"
						color={"error"}
						onClick={handleCloseDialog}
					>
						<CloseIcon />
					</IconButton>
				</div>
			</div>
			<Box className={"dialog_box"}>
				<DialogContent className={"dialog_content"}>
					<Autocomplete
						renderInput={(params) => (
							<TextField
								{...params}
								variant="outlined"
								label={
									<span className={"span_style"}>
										{"Người tạo"}
										<span style={{ color: "red" }}>(*)</span>
									</span>
								}
							/>
						)}
						fullWidth
						value={createdBy || null}
						onChange={handleChangeCreatedBy}
						options={dataUser}
					/>
				</DialogContent>
			</Box>
			<Toolbar>
				<Box
					display="flex"
					justifyContent="center"
					width={"100%"}
				>
					<Button
						variant="contained"
						startIcon={<SaveIcon />}
						onClick={handleSave}
					>
						{translate("resources.common.fields.update")}
					</Button>
					&nbsp;&nbsp;
					<Button
						startIcon={<ReplyIcon />}
						className={"button-close"}
						variant="contained"
						onClick={handleCloseDialog}
					>
						{translate("resources.common.fields.cancel")}
					</Button>
				</Box>
			</Toolbar>
		</Dialog>
	);
}

export default React.memo(ProjectChangeUser);
