import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CircularProgress,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import {
  Form,
  required,
  TextInput,
  useTranslate,
  useLogin,
  useNotify,
} from "react-admin";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { useScript } from "../hooks/useScrript";
import jwt_deocde from "jwt-decode";
import FacebookIcon from "@mui/icons-material/Facebook";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { loginFacebook } from "../Services/LoginService";
import { constants } from "../shared/constants";
const _window = window as any;

const styles = makeStyles((theme: any) =>
  createStyles({
    root: {
      ".css-i4bv87-MuiSvgIcon-root": {
        with: 0,
      },
    },
  })
);
const Login = () => {
  const classes = styles();
  const authorizationToken = localStorage.getItem("token");
  const navigate = useNavigate();
  React.useEffect(() => {
    if (authorizationToken) {
      navigate("/");
    }
  }, [authorizationToken]);
  const [loading, setLoading] = useState(false);
  const translate = useTranslate();
  const notify = useNotify();
  const login = useLogin();
  const location = useLocation();
  const googlebuttonref = React.useRef<any>();
  const facebookRef = React.useRef<any>();

  const validateUserName = [required("Vui lòng nhập tên")];
  const validatePassword = [required("Vui lòng nhập mật khẩu")];

  const handleSubmit = (auth: FormValues) => {
    setLoading(true);
    login(
      auth,
      location.state ? (location.state as any).nextPathname : "/"
    ).catch((error: Error) => {
      setLoading(false);
      notify(
        typeof error === "string"
          ? error
          : typeof error === "undefined" || !error.message
          ? "ra.auth.sign_in_error"
          : error.message,
        {
          type: "warning",
          messageArgs: {
            _:
              typeof error === "string"
                ? error
                : error && error.message
                ? error.message
                : undefined,
          },
        }
      );
    });
  };
  // trả về data gọi api nhận data
  const onGoogleSignIn = async (user: any) => {
    const userCred = await user.credential;
    const payload = await jwt_deocde<any>(userCred);
    login({ token: payload });
  };
  useScript(process.env.REACT_APP_API_GOOGLE, () => {
    // lấy id google gợi mockup google
    _window.google.accounts.id.initialize({
      client_id: `${process.env.REACT_APP_GOOGLE_APP_ID}`, // here's your Google ID
      callback: onGoogleSignIn,
      auto_select: false,
    });
    // render button login google
    _window.google.accounts.id.renderButton(googlebuttonref.current, {
      type: "standard",
      scope: "",
      width: 270,
      longtitle: true,
      theme: "light",
    });
  });

  //   function handleLoginFacebook() {
  //     //@ts-ignore
  //     FB.login(
  //       function (response) {
  //         //@ts-ignore  //gọi api trả về nhưng tham số đã gửi lên
  //         FB.api(
  //           "/me",
  //           { fields: "email,name,picture" },
  //           async function (response) {
  //             try {
  //               const { data } = await loginFacebook(response);
  //               const token = await data.access_token;
  //               localStorage.setItem(constants.LOCAL_STORAGE.TOKEN, token);
  //               localStorage.setItem(
  //                 constants.LOCAL_STORAGE.USER_IDENTITY,
  //                 JSON.stringify(data.user)
  //               );
  //               setLoading(!loading);
  //             } catch (error) {
  //               console.log(error);
  //             }
  //           }
  //         );
  //       },
  //       {
  //         scope: "public_profile,email",
  //         return_scopes: true,
  //         auth_type: "rerequest",
  //       }
  //     );
  //   }
  //   useScript(process.env.REACT_APP_API_FACEBOOK, () => {
  //     if (_window.FB) {
  //       _window.FB.XFBML.parse();
  //     }
  //     _window.fbAsyncInit = function () {
  //       //@ts-ignore
  //       FB.init({
  //         appId: process.env.REACT_APP_FACEBOOK_APP_ID, //ID facebook
  //         autoLogAppEvents: true,
  //         xfbml: true,
  //         version: "v15.0",
  //       });
  //     };
  //     //Lắng nghe sự kiện rồi thực thi
  //     _window.FB.Event.subscribe("auth.statusChange", handleLoginFacebook);
  //   });

  return (
    <>
      <Form onSubmit={handleSubmit} noValidate>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
            alignItems: "center",
            justifyContent: "flex-start",
            background: "url(https://source.unsplash.com/random/1600x900)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <Card sx={{ minWidth: 300, marginTop: "6em" }}>
            <Box
              sx={{
                margin: "1em",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Avatar sx={{ bgcolor: "secondary.main" }}>
                <LockIcon />
              </Avatar>
            </Box>
            <Box
              sx={{
                marginTop: "1em",
                display: "flex",
                justifyContent: "center",
                color: (theme) => theme.palette.grey[500],
              }}
            >
              {/*Hint: demo / demo*/}
            </Box>
            <Box sx={{ padding: "0 1em 1em 1em" }}>
              <Box sx={{ marginTop: "1em" }}>
                <TextInput
                  autoFocus
                  source="username"
                  label={translate("ra.auth.username")}
                  disabled={loading}
                  validate={validateUserName}
                  fullWidth
                />
              </Box>
              <Box sx={{ marginTop: "1em" }}>
                <TextInput
                  source="password"
                  label={translate("ra.auth.password")}
                  type="password"
                  disabled={loading}
                  validate={validatePassword}
                  fullWidth
                />
              </Box>
            </Box>
            <CardActions sx={{ padding: "0 1em 1em 1em" }}>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={loading}
                fullWidth
              >
                {loading && <CircularProgress size={25} thickness={2} />}
                {translate("ra.auth.sign_in")}
              </Button>
            </CardActions>

            {/* <CardActions sx={{ padding: '0 1em 1em 1em' }}>
                        <div
                            onClick={handleLoginFacebook}
                            style={{maxHeight: 40 }}
                            className="select-login-facebook" 
                            data-width="270" data-size="large" 
                            data-auto-logout-link="false" 
                            data-use-continue-as="true"
                         >
                            <FacebookIcon style={{color: "rgb(10, 128, 236)"}}/>
                            <span style={{paddingRight: 21}}>Đăng nhập bằng Facebook</span></div>
                    </CardActions> */}

            <CardActions sx={{ padding: "0 1em 1em 1em" }}>
              <div ref={googlebuttonref} />
            </CardActions>
          </Card>
        </Box>
      </Form>
    </>
  );
};

Login.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

export default Login;

interface FormValues {
  username?: string;
  password?: string;
  token?: string;
}
