import {
    DefaultDataProvider,
} from '../default-data-provider';
import {constants} from "../../shared/constants";

export const baseApiUrl = 'streets';

class StreetService extends DefaultDataProvider {

}

export const streetService = new StreetService(
    constants.RESOURCE.STREET,
    constants.BASE_MODULE_URL,
    baseApiUrl
);