import {
  CreateButton,
  CreateProps,
  useNotify,
  useRefresh,
  useTranslate,
} from "react-admin";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
} from "@mui/material";
import { styled as styleSystem } from "@mui/system";
import Editor from "../component/Editor";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import React, { useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import ReplyIcon from "@mui/icons-material/Reply";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import HttpDataService from "../dataProvider/http.service";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import ImagePreview from "../component/ImgPreview";

//Reformat Style
const styles = makeStyles((theme: any) =>
  createStyles({
    root: {
      "& .css-k4qjio-MuiFormHelperText-root.Mui-error": {
        position: "absolute",
        paddingTop: "41px",
      },
      "& .css-1z10yd4-MuiFormControl-root-MuiTextField-root": {
        marginTop: 20,
        marginBottom: 20,
      },
    },
  })
);
export const ItemWrapper = styleSystem("div")({
  width: "100%",
  "@media (max-width: 1024px)": {
    width: "100%",
  },
});
export const SpamAdd = (props: any) => {
  const classes = styles();
  const { onCloseDialog, openDialog } = props;
  const [name, setName] = useState<string>("");
  const [note, setNote] = useState<string>("");
  const [link, setLink] = useState<string>("");
  const status = "0";

  //avatar
  const [image, setImage] = React.useState<any[]>([]);
  // checker

  const [nameError, setNameError] = useState(false);
  const [linkError, setLinkError] = useState(false);
  const [textNameError, setTextNameError] = useState("");
  const [textLinkError, setTextLinkError] = useState("");
  const translate = useTranslate();
  const refresh = useRefresh();
  const notify = useNotify();
  const [imageCustomerProject, setImageCustomerProject] = React.useState<any[]>(
    []
  );
  const removeImageUtilities = (imgIndex: number) => {
    const newListImage = imageCustomerProject.filter(
      (_, index) => index !== imgIndex
    );
    if (imageCustomerProject.length === 1) {
      setImageCustomerProject([]);
    }
    setImageCustomerProject(newListImage);
  };
  /**
   * handle change name
   * @param e
   */
  const handleNameChange = (e: any) => {
    setNameError(false);
    setTextNameError("");
    setName(e.target.value);
  };

  const handleLinkChange = (e: any) => {
    setLink(e.target.value);
  };
  const handleNoteChange = (e: any) => {
    setNote(e.target.value);
  };
  /**
   * handle save
   */

  const handleSave = async () => {
    if (!fnValidation()) {
      // let data = {
      //   name: name.trim(),
      //   note: note,
      //   link: link,
      //   image: image,
      //   status: 0,
      // };
      const submitObj = new FormData();
      // if (imageCustomerProject) {
      //   imageCustomerProject.map((item: any) => {
      //     submitObj.append("image", item);
      //   });
      // }
      submitObj.append("name", name);
      submitObj.append("note", note);
      submitObj.append("link", link);
      submitObj.append("status", status);
      await HttpDataService.create("spam/add", submitObj)
        .then((response: any) => {
          if (response.data.status) {
            console.log(response.data, "response");
            // @ts-ignore
            notify(response.data.message, { type: "success" });
          } else {
            // @ts-ignore
            notify(response.data.message, { type: "error" });
          }
        })
        .catch((e: Error) => {
          console.log(e);
        });

      fnResetFrm();
      onCloseDialog();
      refresh();
    }
  };

  const handleCloseDialog = () => {
    fnResetFrm();
    onCloseDialog();
  };

  /**
   * fn validation form
   */
  function fnValidation() {
    let error = false;
    if (name.trim().length === 0) {
      error = true;
      setNameError(true);
      setTextNameError(translate("resources.news.fields.val_name"));
    }
    if (link.trim().length === 0) {
      error = true;
      setLinkError(true);
      setTextLinkError("Đường dẫn không được để trống");
    }
    if (!link.includes(".")) {
      error = true;
      setLinkError(true);
      setTextLinkError("Định dạng đường dẫn không đúng");
    }
    return error;
  }

  function fnResetFrm() {
    setName("");
    setNameError(false);
    setTextNameError("");
    setNote("");
  }

  return (
    <>
      <Dialog
        open={openDialog}
        fullWidth
        disableEnforceFocus
        PaperProps={{
          style: {
            borderRadius: 2,
            minWidth: "35%",
          },
        }}
      >
        <div className={"common__dialogTitle_area"}>
          <DialogTitle className={"member__dialogTitle"}>
            <div>{"Thêm mới spam"}</div>
          </DialogTitle>
          <div className={"common__dialogTitle_actions"}>
            <IconButton
              aria-label="exists"
              color={"error"}
              onClick={() => {
                onCloseDialog(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <Box className={"dialog_box"}>
          <DialogContent className={"dialog_content"}>
            <Grid item xs={8}>
              <TextField
                className={`${classes.root} mb-23`}
                fullWidth
                label={
                  <span className={"span_style"}>
                    {translate("resources.news.fields.title")}
                    <span style={{ color: "red" }}>(*)</span>
                  </span>
                }
                value={name}
                error={nameError}
                helperText={textNameError}
                onChange={handleNameChange}
                variant="outlined"
              />

              <TextField
                className={`${classes.root} mb-23`}
                fullWidth
                label={
                  <span className={"span_style"}>
                    {translate("resources.news.fields.slug")}
                    <span style={{ color: "red" }}>(*)</span>
                  </span>
                }
                value={link}
                error={linkError}
                helperText={textLinkError}
                onChange={handleLinkChange}
                variant="outlined"
              />
              <TextField
                className={`${classes.root} mb-23`}
                fullWidth
                label={<span className={"span_style"}>{"Note"}</span>}
                minRows={3}
                multiline
                value={note}
                onChange={handleNoteChange}
                variant="outlined"
              />
            </Grid>

            {/* <ItemWrapper>
              <div className={"upload-image-wrapper"}>
                <div className={"title-image"}>Hình ảnh</div>
                <input
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  onChange={(e: any) => {
                    setImageCustomerProject([
                      ...e.target.files,
                    ]);
                    // e.target.value = null;
                  }}
                ></input>
                {imageCustomerProject.length > 0 && (
                  <div className={"image-show-wrapper"}>
                    {imageCustomerProject.map((image: any, index: number) => (
                      <ImagePreview
                        key={index}
                        src={URL.createObjectURL(image)}
                        onRemove={() => removeImageUtilities(index)}
                      />
                    ))}
                  </div>
                )}
              </div>
            </ItemWrapper> */}
           
          </DialogContent>
        </Box>
        <Toolbar>
          <Box display="flex" justifyContent="center" width={"100%"}>
            <Button
              variant="contained"
              startIcon={<SaveIcon />}
              onClick={handleSave}
            >
              {translate("resources.common.fields.add")}
            </Button>
            &nbsp;&nbsp;
            <Button
              startIcon={<ReplyIcon />}
              className={"button-close"}
              variant="contained"
              onClick={handleCloseDialog}
            >
              {translate("resources.common.fields.cancel")}
            </Button>
          </Box>
        </Toolbar>
      </Dialog>
    </>
  );
};
