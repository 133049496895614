// @ts-ignore
import { DataProvider } from 'react-admin';
import { DefaultDataProvider } from './default-data-provider';
import { constants } from '../shared/constants';

export interface DataProviderFactor {
    getDataProvider(resource: string): DataProvider;
}

export type CacheConfig = {
    cache?: boolean;
    durationInMs: number;
};

type ProviderMap = { [resource: string]: DataProvider };

type CacheConfigMap = { [resource: string]: CacheConfig };

class DataProviderFactorImpl implements DataProviderFactor {
    providerMap_: ProviderMap = {};
    cacheConfigMap_: CacheConfigMap = {};
    cacheDataProviderMap_: ProviderMap = {};

    isHasDataProvider(resource: string) {
        return !!this.getDataProvider(resource);
    }

    getDataProvider(resource: string): DataProvider {
        return !this.isCache(resource)
            ? this.getDataProvider_(resource)
            : this.getCacheDataProvider_(resource);
    }

    private getDataProvider_(resource: any) {
        return (
            this.providerMap_[resource] ||
            (this.providerMap_[
                resource
            ] = DataProviderFactorImpl.defaultDataProvider(resource))
        );
    }

    private getCacheDataProvider_(resource: any) {
        // return (
        //     this.cacheDataProviderMap_[resource] ||
        //     (this.cacheDataProviderMap_[
        //         resource
        //     ] = DataProviderFactorImpl.defaultCacheDataProvider(resource))
        // );
        return this.cacheDataProviderMap_[resource];
    }

    registerDataProvider(providerMap: ProviderMap) {
        this.providerMap_ = {
            ...this.providerMap_,
            ...providerMap,
        };
        return this;
    }

    cacheConfigMap(cacheConfigMap: CacheConfigMap) {
        this.cacheConfigMap_ = {
            ...cacheConfigMap,
            ...this.cacheConfigMap_,
        };
        this.initCacheDataProviderMap(cacheConfigMap);
        return this;
    }

    private isCache(resource: string) {
        return this.cacheConfigMap_[resource]?.cache;
    }

    /**
     * khởi tạo cacheDataProviderProxy cho các provider được đánh dấu là được cache
     * @param cacheConfigMap
     * @private
     */
    private initCacheDataProviderMap(cacheConfigMap: CacheConfigMap) {
        let resource: string;
        for (resource in cacheConfigMap) {
            if (cacheConfigMap.hasOwnProperty(resource)) {
                const cacheConfig = cacheConfigMap[resource];
                if (cacheConfig.cache) {
                    // this.cacheDataProviderMap_[
                    //     resource
                    // ] = cacheDataProviderProxy(
                    //     this.providerMap_[resource] ||
                    //         DataProviderFactorImpl.defaultDataProvider(
                    //             resource
                    //         ),
                    //     cacheConfig.durationInMs
                    // );
                }
            }
        }
    }

    private static defaultDataProvider(resource: string) {
        return new DefaultDataProvider(
            resource,
            constants.BASE_MODULE_URL,
            `api/${resource}s`
        );
    }

    private static defaultCacheDataProvider(resource: string) {
        // return cacheDataProviderProxy(
        //     DataProviderFactorImpl.defaultDataProvider(resource)
        // );
        return null;
    }
}

export const dataProviderFactor = new DataProviderFactorImpl();
