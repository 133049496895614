import React, { useEffect, useState } from "react";
import { Autocomplete, Box, Container, TextField, Grid } from "@mui/material";

import * as customersourceService from "../../../../Services/customerSourcesService";
import * as districtService from "../../../../Services/districtsService";
import { useForm, SubmitHandler, NestedValue } from "react-hook-form";
import InputLabel from "../../../../component/InputLabel";
import { useNotify, useRecordContext, useRefresh, useTranslate } from "react-admin";
import DialogFormCustomer from "../../../../component/DialogFormCustomer";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { styled as styleSystem } from "@mui/system";
import LimitTags from "../../components/LimitTags";
import * as request from "../../../../utils/httpRequest";
import { addCustomer, createTransaction } from "../../../../Services/customerService";
import { commonFunction } from "../../../../component/Common";
import ImagePreview from "../../../../component/ImgPreview";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import HttpDataService from "../../../../dataProvider/http.service";
import { EditorWrapper } from "../../../../general/styledMui";
import Editor from "../../../../component/Editor";
import { InputNumber } from "antd";
import PriceInput from "../../../../component/PriceInput";

// const [submitLoading, setSubmitLoading] = React.useState<boolean>(false);

export const InputRowWrapper = styleSystem(Box)({
	display: "flex",
	flexDirection: "row",
	width: "100%",
	"@media (max-width: 1024px)": {
		display: "flex",
		flexDirection: "column",
	},
});
export const ItemWrapper = styleSystem("div")({
	padding: "10px 20px",
	width: "50%",
	"@media (max-width: 1024px)": {
		width: "100%",
	},
});

export const Wrapper = styleSystem(Box)({
	display: "flex",
	flexDirection: "column",
	backgroundColor: "#fff",
	"@media (max-width: 1024px)": {
		width: "100%",
	},
});

const styled = makeStyles((theme: any) =>
	createStyles({
		root: {
			"& .css-k4qjio-MuiFormHelperText-root.Mui-error": {
				position: "absolute",
				paddingTop: "41px",
			},
		},
		box_image: {
			"& ": {
				border: "1px solid #ccc",
				padding: "10px",
				borderRadius: "10px",
				marginBottom: "10px",
				marginTop: "15px",
			},
		},
	})
);

type Props = {
	isOpen: boolean;
	onClose: () => void;
};

type ErrorTypes = {
	isError: boolean;
	message: String;
};

type Customer = {
	time: any;
	image: [];
	note: string;
	project_id: any;
	revenue: any;
	source_id: any;
	rent_cost: any;
	projectSource_id: {
		project_id: "";
		source_id: "";
	};
};

const STATUS = [
	{
		value: 0,
		label: "Chưa giao dịch",
	},
	{
		value: 1,
		label: "Đã giao dịch",
	},
];

function CreateTransaction({ isOpen, onClose }: Props) {
	const classes = styled();
	const notify = useNotify();
	const refresh = useRefresh();
	const record = useRecordContext();
	const [loading, setLoading] = React.useState(false);
	const [initialState, setInitialState] = useState<Customer>({
		time: "",
		image: [],
		note: "",
		project_id: "",
		revenue: "",
		source_id: "",
		rent_cost: "",
		projectSource_id: {
			project_id: "",
			source_id: "",
		},
	});
	const removeImageUtilities = (imgIndex: number) => {
		const newListImage = initialState.image.filter((_, index) => index !== imgIndex);
		const newImageState = newListImage.length < 1 ? [] : newListImage;
		handleChange(newImageState, "image");
	};

	//ERROR STATE
	const [timeErr, setTimeErr] = useState<ErrorTypes | null>();
	const [revenueErr, setRevenueErr] = useState<ErrorTypes | null>();
	const [rentCostErr, setRentCostErr] = useState<ErrorTypes | null>();

	const [projectErr, setProjectErr] = React.useState<ErrorTypes | null>();
	const [listProject, setListProject] = useState<any[]>([]);
	useEffect(() => {
		const fetchAPI = async () => {
			await HttpDataService.findById(`customer/showProjetcByCustormer`, record.id as string)
				.then((response: any) => {
					if (response.data.data) {
						setListProject(response.data.data);
					}
				})
				.catch((e: Error) => {
					console.log(e);
				});
		};

		fnResetFrm();
		fetchAPI();
	}, []);

	const onSubmit: SubmitHandler<any> = async () => {
		if (!validateForm()) return;
		setLoading(true);
		const formData = new FormData();

		formData.append("time", initialState.time.format("YYYY-MM-DD"));
		formData.append("note", initialState.note);
		// formData.append("image", initialState.image);
		formData.append("revenue", initialState.revenue);
		formData.append("rent_cost", initialState.rent_cost);
		formData.append("project_id", initialState.projectSource_id.project_id);
		formData.append("customer_id", record.id as string);
		formData.append("sale_id", record.person_charge as string);
		formData.append("source_id", initialState.projectSource_id.source_id);
		formData.append("status", "0");

		for (let i = 0; i < initialState.image.length; i++) {
			formData.append("image[]", initialState.image[i]);
		}
		const statusAdd = await createTransaction(formData);
		if (statusAdd.status == true) {
			// @ts-ignore
			notify(`Thêm giao dịch thành công`, {
				type: "success",
				undoable: true,
			});
			fnResetFrm();
			setLoading(false);
		} else {
			// @ts-ignore
			notify(`Vui lòng thử lại sau`, {
				type: "error",
				undoable: true,
			});
			setLoading(false);
		}
		// setStatus(dataStatus[0])
		refresh();
		onClose();
	};

	const validateForm = () => {
		let pass = true;
		if (!initialState?.revenue.trim()) {
			pass = false;
			setRevenueErr({
				isError: true,
				message: "Doanh thu không được để trống!",
			});
		}

		if (!initialState?.rent_cost.trim()) {
			pass = false;
			setRentCostErr({
				isError: true,
				message: "Giá thuê không được để trống!",
			});
		}

		if (!initialState?.time) {
			pass = false;
			setTimeErr({
				isError: true,
				message: "Thời gian giao dịch không được để trống",
			});
		}
		if (!initialState?.projectSource_id.project_id) {
			pass = false;
			setProjectErr({
				isError: true,
				message: "Khu vực không được để trống!",
			});
		}

		return pass;
	};

	function fnResetFrm() {
		setInitialState({
			time: "",
			image: [],
			note: "",
			project_id: "",
			revenue: "",
			source_id: "",
			rent_cost: "",
			projectSource_id: {
				project_id: "",
				source_id: "",
			},
		});
		setTimeErr({
			isError: false,
			message: "",
		});
		setRevenueErr({
			isError: false,
			message: "",
		});
		setProjectErr({
			isError: false,
			message: "",
		});
	}

	const handleChange = (value: any, fieldName: string) => {
		setInitialState({ ...initialState, [fieldName]: value });
	};
	const [imageCustomerProject, setImageCustomerProject] = React.useState<any[]>([]);

	return (
		<DialogFormCustomer
			loading={loading}
			minHeight="auto"
			title="Tạo giao dịch"
			size="md"
			isOpen={isOpen}
			onClose={onClose}
			onSubmit={onSubmit}
		>
			<Wrapper>
				<InputRowWrapper>
					<ItemWrapper>
						<Autocomplete
							renderInput={(params) => (
								<TextField
									{...params}
									variant="outlined"
									label={
										<InputLabel
											title="Dự án"
											required
										/>
									}
									value={initialState?.project_id}
									error={projectErr?.isError}
									helperText={projectErr?.message}
								/>
							)}
							sx={{ width: "100%" }}
							onChange={(event, value) => {
								setInitialState({
									...initialState,
									projectSource_id: {
										project_id: value.project_id,
										source_id: value.project_created_by,
									},
								});
								// handleChange(value.project_created_by, "source_id");
								// handleChange(value.project_id, "project_id");

								setProjectErr(null);
							}}
							options={listProject}
							getOptionLabel={(option) => option?.name}
						/>
					</ItemWrapper>

					<ItemWrapper>
						<LocalizationProvider
							dateAdapter={AdapterDayjs}
							adapterLocale="vi"
						>
							<DatePicker
								label={
									<InputLabel
										title="Thời gian giao dịch"
										required
									/>
								}
								inputFormat="DD/MM/YYYY"
								value={initialState.time || null}
								onChange={(value) => {
									handleChange(value, "time");
									setTimeErr(null);
								}}
								renderInput={(params) => (
									<TextField
										className="mb-23"
										{...params}
										fullWidth
										variant="outlined"
										error={timeErr?.isError}
										helperText={timeErr?.message}
									/>
								)}
							/>
						</LocalizationProvider>
					</ItemWrapper>
				</InputRowWrapper>
				<InputRowWrapper>
					<ItemWrapper>
						<PriceInput
							title="Doanh thu"
							val={initialState.revenue}
							onChange={(value) => {
								handleChange(value, "revenue");
								setRevenueErr(null);
							}}
							required={true}
							statusError={revenueErr}
						/>
					</ItemWrapper>
					<ItemWrapper>
						<PriceInput
							title="Giá thuê"
							val={initialState.rent_cost}
							onChange={(value) => {
								handleChange(value, "rent_cost");
								setRentCostErr(null);
							}}
							required={true}
							statusError={rentCostErr}
						/>
					</ItemWrapper>
				</InputRowWrapper>
				<InputRowWrapper>
					<ItemWrapper>
						<div className={"title-image"}>Ghi chú</div>
						<Editor
							data={initialState?.note}
							onChange={(event: any, editor: any) => {
								const data = editor.getData();
								setInitialState({
									...initialState,
									note: data,
								});
							}}
						/>
					</ItemWrapper>

					{/* <ItemWrapper>
            <div className={`${classes.box_image}`}>
              <div className={"title-image"}>Hợp đồng</div>
              <input
                type="file"
                accept=".png, .jpg, .jpeg"
                onChange={(e: any) => {
                  handleChange(e.target.files[0], "image");
                  e.target.value = null;
                }}
              ></input>
              {initialState.image && (
                <div>
                  <ImagePreview
                    src={URL.createObjectURL(initialState?.image as any)}
                    onRemove={() => handleChange(null, "image")}
                  />
                </div>
              )}
            </div>
          </ItemWrapper> */}
					<ItemWrapper>
						<div className={`${classes.box_image}`}>
							<div className={"title-image"}>Hợp đồng</div>
							<input
								type="file"
								accept=".png, .jpg, .jpeg"
								multiple
								onChange={
									(e: any) => {
										handleChange([...e.target.files], "image");
										e.target.value = null;
									}
									//   setImageCustomerProject([
									//     ...imageCustomerProject,
									//     ...e.target.files,
									//   ]);
									// e.target.value = null;
								}
							></input>
							{initialState.image.length > 0 &&
								initialState?.image.map((image: any, index: number) => (
									<ImagePreview
										key={index}
										src={URL.createObjectURL(image)}
										onRemove={() => removeImageUtilities(index)}
									/>
								))}
						</div>
					</ItemWrapper>
				</InputRowWrapper>
			</Wrapper>
		</DialogFormCustomer>
	);
}
export default React.memo(CreateTransaction);
