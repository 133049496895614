import React from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import classNames from 'classnames/bind';
import styles from './InputPassword.module.scss';

import InputLabel from '../InputLabel';

type Props = {
    margin?: 'normal' | 'none' | 'dense' | undefined;
    variant?: 'outlined' | 'standard' | 'filled' | undefined;
    error?: {
        isError: boolean;
        message: string;
    } | null;
    label: string;
    showPassword?: boolean;
    onShow?: () => void;
    value?: string;
    onChange?: (e?: any) => void;
};

const cx = classNames.bind(styles);

function InputPassword(
    {
        margin = 'normal',
        variant = 'outlined',
        error,
        label,
        showPassword = false,
        onShow,
        value,
        onChange,
    }: Props,
    ref?: any,
    ...props: any
) {
    return (
        <TextField
            {...props}
            className={cx('input-wrapper')}
            fullWidth
            ref={ref}
            margin={margin}
            variant={variant}
            error={error?.isError}
            helperText={error?.message}
            type={showPassword ? 'text' : 'password'}
            label={<InputLabel title={label} required />}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="start">
                        <IconButton onClick={onShow}>
                            {!showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            value={value}
            onChange={onChange}
        />
    );
}

export default React.forwardRef(InputPassword);
