import React from 'react';
import {
    Dialog,
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';
import { TransitionProps } from '@mui/material/transitions';
import SaveButton from './SaveButton';

const Navbar = styled(AppBar)({
    position: 'relative',
    border: 0,
    backgroundColor: '#1976d2',
});

const Wrapper = styled(Box)({
    paddingBottom: 40,
    overflowY: 'auto',
});

type Props = {
    title: String;
    isOpen: boolean;
    onClose: () => void;
    onSubmit?: () => void;
    children: JSX.Element;
    isSubmit: boolean;
};

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function FullScreenDialogWrapper({ title, isOpen, onClose, onSubmit, children, isSubmit }: Props) {
    return (
        <Dialog
            fullScreen
            open={isOpen}
            onClose={onClose}
            TransitionComponent={Transition}
            disableEnforceFocus
        >
            <Navbar>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        {title}
                    </Typography>
                    {isSubmit && <SaveButton
                        varian="text"
                        color="inherit"
                        title="Lưu"
                        onClick={onSubmit}
                    />}
                </Toolbar>
            </Navbar>
            <Wrapper>{children}</Wrapper>
        </Dialog>
    );
}

export default React.memo(FullScreenDialogWrapper);
