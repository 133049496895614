import React from 'react';
import {
    Wrapper,
    InputRowWrapper,
    ItemWrapper,
    EditorWrapper,
    FileWrapper,
    LabelText,
    ImagePreviewWrapper,
    FilesPreviewWrapper,
    TableWrapper,
    TableHeaderWrapper,
} from '../styledMui';
import { TextField, Autocomplete, Container } from '@mui/material';


//Library
import { useNotify, useRefresh } from 'react-admin';

//Services
import * as departmentService from '../../../Services/departmentService';
import * as projectTypeService from '../../../Services/projectTypeService';
import * as projectService from '../../../Services/projectService';

//Components
import ImagePreview from '../../../component/ImgPreview';
import InputLabel from '../../../component/InputLabel';
import PDFPreview from '../../../component/PDFPreview';
import ProjectTable from '../../components/Table';
import CreateButtonMUI from '../../../component/CreateButtonMUI';
import ProjectDetail from '../../dialogs/ProjectDetail';
import PageLoadingAPI from '../../../component/PageLoadingAPI';
import PageSubmitLoading from '../../../component/PageSubmitLoading';
import FullScreenDialogWrapper from '../../../component/FullScreenDialogWrapper';

//Shared data
import { STATUS } from '../../../shared/status';
import Editor from '../../../component/Editor';

//Reformat Style 
import { createStyles, makeStyles } from '@material-ui/core/styles';


const styles = makeStyles((theme: any) =>
    createStyles({
        root: {
            '& .css-k4qjio-MuiFormHelperText-root.Mui-error':{
                position: 'absolute',
                paddingTop: '41px',
            },
            '& .css-1z10yd4-MuiFormControl-root-MuiTextField-root':{
                marginTop: 20,
                marginBottom: 20,
            }
        }
    }));

type Props = {
    isOpen: boolean;
    onClose: () => void;
};
type ErrorTypes = {
    isError: boolean;
    message: String;
};

type Product = {
    name: String;
    area: String;
    department: any;
    type: any;
    legal: String;
    content: any;
    status: any;
};
function ProjectCreate({ isOpen, onClose }: Props) {
    const classes = styles()
    //STATE
    const [inittialState, setInittialState] = React.useState<Product>({
        name: '',
        area: '',
        department: {},
        type: {},
        legal: '',
        content: '',
        status: {},
    });
    const [listImageUtilities, setListImageUtilities] = React.useState<any[]>([]);
    const [listImage3D, setListImage3D] = React.useState<string[]>([]);
    const [listPDFJuridical, setListPDFJuridical] = React.useState<string[]>([]);
    const [listPDFSalePolicy, setListPDFSalePolicy] = React.useState<string[]>([]);
    const [listHDCN, setListHDCN] = React.useState<string[]>([]);
    const [tableData, setTableData] = React.useState<any>();
    const [listTable, setListTable] = React.useState<any[]>([]);
    const [listTableSubmit, setListTableSubmit] = React.useState<any[]>([]);

    //STATE REST API
    const [listDepartment, setListDepartment] = React.useState<any[]>([]);
    const [listProjectType, setListProjectType] = React.useState<any[]>([]);

    //DIALOG
    const [isOpenDetail, setIsOpenDetail] = React.useState<boolean>(false);

    //LOADING
    const [pageLoading, setPageLoading] = React.useState<boolean>(false);
    const [submitLoading, setSubmitLoading] = React.useState<boolean>(false);

    //ERROR STATE
    const [nameErr, setNameErr] = React.useState<ErrorTypes | null>();
    const [areaErr, setAreaErr] = React.useState<ErrorTypes | null>();
    const [legalErr, setLegalErr] = React.useState<ErrorTypes | null>();
    const [typeErr, setTypeErr] = React.useState<ErrorTypes | null>();
    const [departmentErr, setDepartmentErr] = React.useState<ErrorTypes | null>();
    const [statusErr, setStatusErr] = React.useState<ErrorTypes | null>();

    //HOOKS
    const notify = useNotify();
    const refresh = useRefresh();

    //CALL API
    React.useEffect(() => {
        if (isOpen) {
            setPageLoading(true);
            resetData();
            resetError();
            const fetchAPI = async () => {
                const lisDepartmentResult = await departmentService.getListDepartment();
                const listProjectTypeResult =
                    await projectTypeService.getListProjectType();
                setListDepartment(lisDepartmentResult);
                setListProjectType(listProjectTypeResult);
                setPageLoading(false);
            };
            fetchAPI();
        }
    }, [isOpen]);

    React.useEffect(() => {
        if (tableData) {
            setListTable([...listTable, tableData]);
        }
    }, [tableData]);

    React.useEffect(() => {
        let arr: any[] = [];
        if (listTable.length > 0) {
            listTable.map((item: any) => {
                const {
                    ma_lo,
                    chieu_sau,
                    mat_tien,
                    dien_tich,
                    mat_duong,
                    gia_tri,
                    muc_dich,
                    trang_thai,
                    ghi_nhan,
                } = item;
                const submitObj = {
                    staff: '1',
                    code: ma_lo,
                    road_surface: mat_duong,
                    land_frontage: mat_tien,
                    depth: chieu_sau,
                    area: dien_tich,
                    uses: muc_dich?.id,
                    price: gia_tri,
                    note: ghi_nhan,
                    status: trang_thai?.id,
                };

                arr.push(submitObj);
                setListTableSubmit(arr);
            });
        } else {
            setListTableSubmit([]);
        }
    }, [listTable]);

    const removeItem = (itemIndex: number, list: any[], setList: any) => {
        const newList = list.filter((_, index) => index !== itemIndex);
        if (list.length === 1) {
            setList([]);
        }
        setList(newList);
    };

    const handleDeleteItem = React.useCallback(
        (itemIndex: number) => {
            const newList = listTable.filter((_, index) => index !== itemIndex);
            if (listTable.length === 1) {
                setListTable([]);
            }
            setListTable(newList);
        },
        [listTable],
    );

    const resetError = () => {
        setNameErr(null);
        setAreaErr(null);
        setLegalErr(null);
        setTypeErr(null);
        setStatusErr(null);
        setDepartmentErr(null);
    };

    const resetData = () => {
        setInittialState({
            name: '',
            area: '',
            department: {},
            type: {},
            legal: '',
            content: '',
            status: {},
        });
        setListImageUtilities([]);
        setListImage3D([]);
        setListPDFJuridical([]);
        setListPDFSalePolicy([]);
        setListHDCN([]);
        setListTable([]);
        setListTableSubmit([]);
    };

    const handleSubmit = () => {
        let pass = true;
        resetError();

        if (!inittialState?.name.trim()) {
            pass = false;
            setNameErr({
                isError: true,
                message: 'Tên dự án không được để trống!',
            });
        } else if (inittialState?.name.length < 3) {
            pass = false;
            setNameErr({
                isError: true,
                message: 'Tên dự án phải chứa ít nhât 3 ký tự!',
            });
        }
        if (!inittialState?.department.id) {
            pass = false;
            setDepartmentErr({
                isError: true,
                message: 'Tên đơn vị không được để trống!',
            });
        }

        if (!inittialState?.area.trim()) {
            pass = false;
            setAreaErr({
                isError: true,
                message: 'Diện tich không được để trống!',
            });
        } else if (Number(inittialState?.area) < 0) {
            pass = false;
            setSubmitLoading(false);
            setAreaErr({
                isError: true,
                message: 'Diện tich phải lớn hơn hoặc bằng 0!',
            });
        }
        if (!inittialState?.legal.trim()) {
            pass = false;
            setLegalErr({
                isError: true,
                message: 'Pháp lý không được để trống!',
            });
        }
        if (!inittialState?.type.id) {
            pass = false;
            setTypeErr({
                isError: true,
                message: 'Loại hình không được để trống!',
            });
        }
        if (!inittialState?.status?.label) {
            pass = false;
            setStatusErr({
                isError: true,
                message: 'Trạng thái không được để trống!',
            });
        }
        if (pass && !submitLoading) {
            setSubmitLoading(true);
            const { name, content, department, legal, area, status, type } =
                inittialState;
            const data = {
                name: name,
                department: department?.id,
                type: type?.id,
                legal: legal,
                content: content,
                status: status.id === 1 ? 1 : 0,
                area: area,
                lst_file: listHDCN.length > 0 ? listHDCN : null,
                lst_img_ground: listImageUtilities.length > 0 ? listImageUtilities : null,
                lst_file_legal: listPDFJuridical.length > 0 ? listPDFJuridical : null,
                lst_img_location: listImage3D.length > 0 ? listImage3D : null,
                lst_file_sales: listPDFSalePolicy.length > 0 ? listPDFSalePolicy : null,
                project_goods: [...listTableSubmit],
            };

            const submit = async (data: any) => {
                const result: any = await projectService.createProject(data);
                if (result === 200) {
                    resetData();
                    notify(`Thêm mới thành công`, {
                        type: 'success',
                        undoable: true,
                    });
                    setTimeout(() => {
                        setSubmitLoading(false);
                    }, 500);
                    onClose();
                    refresh();
                } else if (result?.status === 401) {
                    setSubmitLoading(false);
                    setNameErr({
                        isError: true,
                        message: result.data.errors.name[0],
                    });
                    notify(result.data.errors.name[0], {
                        type: 'error',
                        undoable: true,
                    });
                } else {
                    notify(`Thêm mới thất bại`, {
                        type: 'error',
                        undoable: true,
                    });
                    setSubmitLoading(false);
                }
            };
            submit(data);
        }
    };

    const onChangeFiles = (setData?: any) => {
        // @ts-ignore
        CKFinder.popup({
            chooseFiles: true,
            width: 800,
            height: 600,
            onInit: function (finder: any) {
                finder.on('files:choose', function (evt: any) {
                    let file = evt.data.files.first();
                    setData((prev: any) => [...prev, file.getUrl()]);
                });
            },
        });
    };

    return (
        <FullScreenDialogWrapper
            isOpen={isOpen}
            onClose={onClose}
            title="Thêm dự án"
            onSubmit={handleSubmit}
            isSubmit={true}
        >
            {pageLoading ? (
                <PageLoadingAPI />
            ) : (
                <Wrapper>
                    {submitLoading && <PageSubmitLoading />}
                    <Container>
                        <InputRowWrapper>
                            <ItemWrapper>
                                <TextField
                                className={classes.root}
                                    disabled={submitLoading}
                                    fullWidth
                                    error={nameErr?.isError}
                                    helperText={nameErr?.message}
                                    id="outlined-basic"
                                    label={<InputLabel title="Tên dự án" required />}
                                    variant="outlined"
                                    value={inittialState.name}
                                    onChange={(e) =>
                                        setInittialState({
                                            ...inittialState,
                                            name: e.target.value,
                                        })
                                    }
                                />
                            </ItemWrapper>
                            <ItemWrapper>
                                <TextField
                                className={classes.root}
                                    disabled={submitLoading}
                                    fullWidth
                                    type="number"
                                    error={areaErr?.isError}
                                    helperText={areaErr?.message}
                                    id="outlined-basic"
                                    label={<InputLabel title="Diện tich" required />}
                                    variant="outlined"
                                    value={inittialState.area}
                                    onChange={(e) =>
                                        setInittialState({
                                            ...inittialState,
                                            area: e.target.value,
                                        })
                                    }
                                />
                            </ItemWrapper>
                        </InputRowWrapper>
                        <InputRowWrapper>
                            <ItemWrapper>
                                <Autocomplete
                                    disabled={submitLoading}
                                    fullWidth
                                    id="combo-box-demo"
                                    options={listDepartment}
                                    renderInput={(params) => (
                                        <TextField
                                        className={classes.root}
                                            error={departmentErr?.isError}
                                            helperText={departmentErr?.message}
                                            {...params}
                                            variant="outlined"
                                            value={inittialState.department}
                                            label={<InputLabel title="Đơn vị" required />}
                                        />
                                    )}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(e, value) =>
                                        setInittialState({
                                            ...inittialState,
                                            department: value,
                                        })
                                    }
                                />
                            </ItemWrapper>
                            <ItemWrapper>
                                <Autocomplete
                                    disabled={submitLoading}
                                    fullWidth
                                    id="combo-box-demo"
                                    options={listProjectType}
                                    renderInput={(params) => (
                                        <TextField
                                        className={classes.root}
                                            error={typeErr?.isError}
                                            helperText={typeErr?.message}
                                            value={inittialState.type}
                                            {...params}
                                            variant="outlined"
                                            label={
                                                <InputLabel title="Loại hình" required />
                                            }
                                        />
                                    )}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(e, value) =>
                                        setInittialState({
                                            ...inittialState,
                                            type: value,
                                        })
                                    }
                                />
                            </ItemWrapper>
                        </InputRowWrapper>
                        <InputRowWrapper>
                            <ItemWrapper>
                                <TextField
                                className={classes.root}
                                    disabled={submitLoading}
                                    fullWidth
                                    error={legalErr?.isError}
                                    helperText={legalErr?.message}
                                    id="outlined-basic"
                                    label={<InputLabel title="Pháp lý" required />}
                                    variant="outlined"
                                    value={inittialState.legal}
                                    onChange={(e) =>
                                        setInittialState({
                                            ...inittialState,
                                            legal: e.target.value,
                                        })
                                    }
                                />
                            </ItemWrapper>
                            <ItemWrapper>
                                <Autocomplete
                                    disabled={submitLoading}
                                    fullWidth
                                    id="combo-box-demo"
                                    options={STATUS}
                                    renderInput={(params) => (
                                        <TextField
                                        className={classes.root}
                                            error={statusErr?.isError}
                                            helperText={statusErr?.message}
                                            {...params}
                                            variant="outlined"
                                            value={inittialState.status}
                                            label={
                                                <InputLabel title="Trạng thái" required />
                                            }
                                        />
                                    )}
                                    getOptionLabel={(option) => option.label}
                                    onChange={(e, value) =>
                                        setInittialState({
                                            ...inittialState,
                                            status: value,
                                        })
                                    }
                                />
                            </ItemWrapper>
                        </InputRowWrapper>
                        <EditorWrapper>
                            <Editor
                                data={inittialState?.content}
                                onChange={(event: any, editor: any) => {
                                    const data = editor.getData();
                                    setInittialState({
                                        ...inittialState,
                                        content: data,
                                    });
                                }}
                            />
                        </EditorWrapper>
                        <FileWrapper>
                            <LabelText>Vị trí, các tiện ích liền kề:</LabelText>
                            <input
                                disabled={submitLoading}
                                type="file"
                                multiple
                                accept=".png, .jpg, .jpeg"
                                onClick={() => onChangeFiles(setListImageUtilities)}
                            ></input>
                            {listImageUtilities.length > 0 && (
                                <ImagePreviewWrapper>
                                    {listImageUtilities.map(
                                        (image: any, index: number) => (
                                            <ImagePreview
                                                key={index}
                                                src={image}
                                                onRemove={() =>
                                                    removeItem(
                                                        index,
                                                        listImageUtilities,
                                                        setListImageUtilities,
                                                    )
                                                }
                                            />
                                        ),
                                    )}
                                </ImagePreviewWrapper>
                            )}
                        </FileWrapper>
                        <FileWrapper>
                            <LabelText>Mặt bằng phân lô 3D:</LabelText>
                            <input
                                disabled={submitLoading}
                                type="file"
                                multiple
                                accept=".png, .jpg, .jpeg"
                                onClick={() => onChangeFiles(setListImage3D)}
                            ></input>
                            {listImage3D.length > 0 && (
                                <ImagePreviewWrapper>
                                    {listImage3D.map((image: any, index: number) => (
                                        <ImagePreview
                                            key={index}
                                            src={image}
                                            onRemove={() =>
                                                removeItem(
                                                    index,
                                                    listImage3D,
                                                    setListImage3D,
                                                )
                                            }
                                        />
                                    ))}
                                </ImagePreviewWrapper>
                            )}
                        </FileWrapper>
                        <FileWrapper>
                            <LabelText>Mẫu HĐCN:</LabelText>
                            <input
                                disabled={submitLoading}
                                type="file"
                                accept=".xls ,.pdf"
                                onClick={() => onChangeFiles(setListHDCN)}
                            ></input>
                            {listHDCN.length > 0 && (
                                <FilesPreviewWrapper>
                                    {listHDCN.map((item: string, index: number) => (
                                        <PDFPreview
                                            key={index}
                                            fileName={item
                                                .split('/uploads/files/')
                                                .join('')}
                                            onRemove={() =>
                                                removeItem(index, listHDCN, setListHDCN)
                                            }
                                        />
                                    ))}
                                </FilesPreviewWrapper>
                            )}
                        </FileWrapper>
                        <FileWrapper>
                            <LabelText>Pháp lý:</LabelText>
                            <input
                                disabled={submitLoading}
                                type="file"
                                accept=".pdf"
                                onClick={() => onChangeFiles(setListPDFJuridical)}
                            ></input>
                            {listPDFJuridical.length > 0 && (
                                <FilesPreviewWrapper>
                                    {listPDFJuridical.map(
                                        (item: string, index: number) => (
                                            <PDFPreview
                                                key={index}
                                                fileName={item
                                                    .split('/uploads/files/')
                                                    .join('')}
                                                onRemove={() =>
                                                    removeItem(
                                                        index,
                                                        listPDFJuridical,
                                                        setListPDFJuridical,
                                                    )
                                                }
                                            />
                                        ),
                                    )}
                                </FilesPreviewWrapper>
                            )}
                        </FileWrapper>
                        <FileWrapper>
                            <LabelText>Chính sách bán hàng:</LabelText>
                            <input
                                disabled={submitLoading}
                                type="file"
                                accept=".xls, .pdf"
                                onClick={() => onChangeFiles(setListPDFSalePolicy)}
                            ></input>
                            {listPDFSalePolicy.length > 0 && (
                                <FilesPreviewWrapper>
                                    {listPDFSalePolicy.map(
                                        (item: string, index: number) => (
                                            <PDFPreview
                                                key={index}
                                                fileName={item
                                                    .split('/uploads/files/')
                                                    .join('')}
                                                onRemove={() =>
                                                    removeItem(
                                                        index,
                                                        listPDFSalePolicy,
                                                        setListPDFSalePolicy,
                                                    )
                                                }
                                            />
                                        ),
                                    )}
                                </FilesPreviewWrapper>
                            )}
                        </FileWrapper>
                        <TableWrapper>
                            <TableHeaderWrapper>
                                <CreateButtonMUI
                                    title="Thêm bản ghi"
                                    onClick={() => setIsOpenDetail(true)}
                                />
                                <ProjectDetail
                                    isOpen={isOpenDetail}
                                    onClose={() => setIsOpenDetail(false)}
                                    submitData={setTableData}
                                />
                            </TableHeaderWrapper>
                            <ProjectTable
                                data={listTable}
                                deleteItem={(id: number) => handleDeleteItem(id)}
                            />
                        </TableWrapper>
                    </Container>
                </Wrapper>
            )}
        </FullScreenDialogWrapper>
    );
}

export default React.memo(ProjectCreate);
