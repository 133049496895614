import React, { useEffect, useState } from "react";
import {
	Dialog,
	DialogTitle,
	Button,
	Stack,
	Box,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Chip,
	IconButton,
	Tooltip,
	SelectChangeEvent,
	FormControlLabel,
	MenuItem,
	Select,
	FormControl,
} from "@mui/material";
import { styled } from "@mui/system";
import { useNotify, useRecordContext, useRefresh, FunctionField } from "react-admin";
import HttpDataService from "../../../../dataProvider/http.service";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import DialogSubmitLoading from "../../../../component/DialogSubmitLoading";
import { CustomerProjectDel } from "./CustomerProjectDel";
import { CustomerProjectEdit } from "./CustomerProjectEdit";
import { format } from "date-fns";
import classNames from "classnames/bind";
import styles from "../../../../projectEstate/Project.module.scss";
import { Height } from "@mui/icons-material";
import { commonFunction } from "../../../../component/Common";

const cx = classNames.bind(styles);

// import listProject from "../../components/LimitTags";
const Wrapper = styled(Box)({
	// width: "500px",
});

const HeaderWrapper = styled(Box)({
	backgroundColor: "whitesmoke",
});

const ContentWrapper = styled(Box)({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	padding: "30px 0",
});

const TableResponsive = styled(Box)({
	width: "40%",
	"@media (max-width: 1800px)": {
		width: "50%",
	},
	"@media (max-width: 1500px)": {
		width: "60%",
	},
	"@media (max-width: 1300px)": {
		width: "75%",
	},
});

const ButtonWrapper = styled(Box)({
	display: "flex",
	justifyContent: "flex-end",
	backgroundColor: "whitesmoke",
	padding: 20,
});
export const ResponsiveItemWrapper = styled(Box)({
	"@media (max-width: 1000px)": {
		width: "800px",
	},
});
type Props = {
	isOpen: boolean;
	onClose: () => void;
};

function CustomerProject({ isOpen, onClose }: Props) {
	//LOADING STATE
	const [submitLoading, setSubmitLoading] = React.useState<boolean>(false);
	const [openModal, setOpenModal] = React.useState<boolean>(false);
	const [listCustomerProJect, setListCustomerProject] = React.useState<any>([]);
	const notify = useNotify();
	// const image = useState<any>();
	const [image, setImage] = React.useState<any[]>([]);

	const removeImageUtilities = (imgIndex: number) => {
		const newListImage = image.filter((_, index) => index !== imgIndex);
		if (image.length === 1) {
			setImage([]);
		}
		setImage(newListImage);
	};
	const record = useRecordContext();
	const refresh = useRefresh();

	React.useEffect(() => {
		if (isOpen) {
			fetchAPI();
		}
	}, [isOpen]);
	const refreshParentModal = () => {
		fetchAPI();
	};
	const fetchAPI = async () => {
		await HttpDataService.findById("customer/showProjetcByCustormer", record.id as string)
			.then((response: any) => {
				if (response.data.data) {
					setListCustomerProject(response.data.data);
				}
			})
			.catch((e: Error) => {
				console.log(e);
			});
	};
	const RowProject = ({ items }: any) => {
		return (
			<Box className="care_plan_rowActions">
				{commonFunction.checkPermission("CUSTOMER_PROJECT_EDIT") && <ViewProjectButton items={items} />}
				{commonFunction.checkPermission("CUSTOMER_PROJECT_DELETE") && <DelProjectButton items={items} />}
			</Box>
		);
	};
	const ViewProjectButton = ({ items }: any) => {
		const [openEditDialog, setOpenEditDialog] = useState(false);
		return (
			<>
				<Tooltip
					title="Sửa"
					arrow
				>
					<IconButton
						onClick={() => {
							setOpenEditDialog(true);
						}}
					>
						<ModeEditIcon className={"btn_edit"} />
					</IconButton>
				</Tooltip>
				<CustomerProjectEdit
					items={items}
					openDialog={openEditDialog}
					onCloseDialog={() => {
						setOpenEditDialog(false);
					}}
					onSaveSuccess={() => refreshParentModal()}
				/>
			</>
		);
	};
	const DelProjectButton = ({ items }: any) => {
		const [openDelDialog, setOpenDelDialog] = useState(false);

		return (
			<>
				<Tooltip
					title="Xoá"
					arrow
				>
					<IconButton onClick={() => setOpenDelDialog(true)}>
						<DeleteIcon className={"btn_del"} />
					</IconButton>
				</Tooltip>
				<CustomerProjectDel
					items={items}
					openDialog={openDelDialog}
					onCloseDialog={() => {
						setOpenDelDialog(false);
					}}
					onSaveSuccess={() => refreshParentModal()}
				/>
			</>
		);
	};
	const handleChangeCustomProjectStatus = async (event: SelectChangeEvent, idRecord: Number) => {
		let data = {
			id: idRecord,
			status: event.target.value,
		};
		await HttpDataService.update(`customer/updateStatusProjectCustomer/${data.id}`, data)
			.then((response: any) => {
				if (response.data.status) {
					notify(response.data.message, { type: "success" });
				} else {
					notify(response.data.message, { type: "error" });
				}
			})
			.catch((e: Error) => {
				notify("Cập nhật không thành công", { type: "error" });
			});
		refresh();
		refreshParentModal();
	};
	return (
		<Dialog
			open={isOpen}
			onClose={onClose}
			maxWidth={"lg"}
		>
			<ResponsiveItemWrapper>
				<Wrapper>
					{submitLoading && <DialogSubmitLoading />}
					<HeaderWrapper>
						<DialogTitle>Danh sách sản phẩm</DialogTitle>
					</HeaderWrapper>
					<ContentWrapper>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>{"Sản phẩm"}</TableCell>
									<TableCell>{"Hình ảnh"}</TableCell>
									<TableCell>{"Thời gian"}</TableCell>
									<TableCell>{"Trạng thái"}</TableCell>
									<TableCell>{"Thao tác"}</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{listCustomerProJect &&
									listCustomerProJect?.map((item: any, index: any) => (
										<TableRow key={index}>
											<TableCell>
												<a
													href={`${process.env.REACT_APP_BASE_LINK_URL}${item?.slug}`}
													target="_blank"
												>
													{item?.name}
												</a>
											</TableCell>
											<TableCell>
												{item?.image && (
													<a
														href={`${process.env.REACT_APP_BASE_END_POINT_URL}${item?.image}`}
														target="_blank"
													>
														<img
															width={100}
															height={100}
															style={{ objectFit: "contain" }}
															src={`${process.env.REACT_APP_BASE_END_POINT_URL}${item?.image}`}
														/>
													</a>
												)}
											</TableCell>
											<TableCell>
												{item?.view_time ? format(new Date(item.view_time), "dd/MM/yyyy") : "-"}
											</TableCell>
											<TableCell>
												{!commonFunction.checkPermission("CUSTOMER_PROJECT_STATUS") ? (
													<FunctionField
														render={(record: any) => {
															if (item.status === 1) {
																return (
																	<Chip
																		label="Đã xem"
																		color="success"
																		variant="outlined"
																	/>
																);
															} else {
																return (
																	<Chip
																		label="Chưa xem"
																		color="error"
																		variant="outlined"
																	/>
																);
															}
														}}
													/>
												) : (
													<FunctionField
														className={cx("status")}
														label={"Trạng thái"}
														render={(record: any) => {
															const isStatus0 = item.status === 0;
															const isStatus1 = item.status === 1;
															const isStatus2 = item.status === 2;
															return (
																<FormControlLabel
																	control={
																		<FormControl variant="standard">
																			<Select
																				// labelId="demo-select-small-label"
																				// id="demo-select-small"
																				value={item.status}
																				onChange={(e) =>
																					handleChangeCustomProjectStatus(
																						e,
																						item.id
																					)
																				}
																				sx={{
																					...(isStatus0 && { color: "red" }),
																					...(isStatus1 && { color: "blue" }),
																					...(isStatus2 && {
																						color: "green",
																					}),
																				}}
																			>
																				<MenuItem
																					style={{ color: "red" }}
																					value={0}
																				>
																					Chưa Xem
																				</MenuItem>
																				<MenuItem
																					style={{ color: "blue" }}
																					value={1}
																				>
																					Đã Xem
																				</MenuItem>
																				{commonFunction.checkPermission(
																					"CUSTOMER_PROJECT_APPROVE"
																				) && (
																					<MenuItem
																						style={{ color: "green" }}
																						value={2}
																					>
																						Đã Duyệt
																					</MenuItem>
																				)}
																			</Select>
																		</FormControl>
																	}
																	label=""
																	labelPlacement="end"
																/>
															);
														}}
													/>
												)}
											</TableCell>
											<TableCell>
												<RowProject items={item} />
											</TableCell>
										</TableRow>
									))}
							</TableBody>
						</Table>
					</ContentWrapper>
					<ButtonWrapper>
						<Stack
							direction="row"
							spacing={2}
						>
							<Button
								variant="outlined"
								color="error"
								onClick={onClose}
							>
								Đóng
							</Button>
						</Stack>
					</ButtonWrapper>
				</Wrapper>
			</ResponsiveItemWrapper>
		</Dialog>
	);
}

export default React.memo(CustomerProject);
