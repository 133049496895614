import AddIcon from "@mui/icons-material/Add";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import React, { useState } from "react";
import { useTranslate } from "react-admin";
import { List, Datagrid, DatagridProps, FunctionField, useRefresh, useNotify } from "react-admin";
import { Box, Button, IconButton, Typography, TextField as InputText } from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Form } from "react-final-form";
import { KpiDelete } from "./KpiDelete";
import { KpiAdd } from "./KpiAdd";
import { KpiEdit } from "./KpiEdit";
import { commonFunction } from "../component/Common";
import PaginationCustom from "../component/PaginationCustom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
const ProjectPolicyContext = React.createContext<any>("");

import moment from "moment";

const Item = styled(Paper)(({ theme }) => ({
	textAlign: "right",
}));

const FillterWrapper = styled(Box)({
	display: "flex",
	flexWrap: "wrap",
	gap: "0 15px",
	margin: "7px 15px",
});

export const KpiList = (props: any) => {
	const PostPagination = (props: any) => <PaginationCustom {...props} />;
	const today = dayjs();
	const [dateContext, setDateContext] = useState<Dayjs | null>(today);

	return (
		<Box className="header-channel-sales">
			<Box className={"box_header"}>
				<Typography className={"title_header"}>{"Kpi"}</Typography>
			</Box>
			<div className={`header-top-detail header-top-tdn`}>
				<ProjectPolicyContext.Provider
					value={{
						dateContext: [dateContext, setDateContext],
					}}
				>
					<List
						{...props}
						actions={false}
						empty={false}
						exporter={false}
						filters={<FilterAndActions />}
						filter={{
							date: dateContext ? dateContext.format("YYYY-MM") : undefined,
						}}
						filterDefaultValues={{
							date: "",
						}}
						sort={{ field: "", order: "" }}
						pagination={<PostPagination />}
						perPage={50}
					>
						<KpiDatagrid {...props} />
					</List>
				</ProjectPolicyContext.Provider>
			</div>
		</Box>
	);
};

interface ButtonProps {
	basePath?: string;
	label: string;
	record?: any;
}

const EditButton = (props: any) => {
	const [onCloseDialog, setOpenEditDialog] = useState(false);
	return (
		<>
			<IconButton
				style={{ padding: "0px" }}
				onClick={() => setOpenEditDialog(true)}
			>
				<EditIcon className={"btn_edit"} />
			</IconButton>
			{onCloseDialog && (
				<KpiEdit
					{...props}
					openDialog={onCloseDialog}
					onCloseDialog={() => setOpenEditDialog(false)}
				/>
			)}
		</>
	);
};

const DeleteButton = (props: any) => {
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	return (
		<>
			<IconButton
				style={{ padding: "0px" }}
				onClick={() => setOpenDeleteDialog(true)}
			>
				<DeleteIcon className={"btn_del"} />
			</IconButton>
			<KpiDelete
				openDialog={openDeleteDialog}
				onCloseDialog={() => setOpenDeleteDialog(false)}
			/>
		</>
	);
};

const RowActions = ({ record }: ButtonProps) => {
	const per_edit = commonFunction.checkPermission("KPI_UPDATE");
	const per_delete = commonFunction.checkPermission("KPI_DELETE");
	return (
		<Box>
			{per_edit && <EditButton />}
			{per_delete && <DeleteButton />}
		</Box>
	);
};

const FilterAndActions = (props: any) => {
	const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);
	const translate = useTranslate();
	const { dateContext } = React.useContext(ProjectPolicyContext);
	const [date, setDate] = dateContext;

	return (
		<Box
			sx={{
				p: 1,
				m: 1,
				bgcolor: "background.paper",
				borderRadius: 1,
			}}
			className={"box_search"}
		>
			<Item className="shadow-none">
				<Form onSubmit={() => undefined}>
					{() => (
						<FillterWrapper>
							<LocalizationProvider
								dateAdapter={AdapterDayjs}
								adapterLocale="vi"
							>
								<DatePicker
									label="Tháng"
									inputFormat="MM/YYYY"
									views={["month", "year"]}
									disableFuture
									value={date}
									onChange={(newValue) => setDate(newValue)}
									renderInput={(params) => (
										<InputText
											className="mb-23"
											{...params}
											fullWidth
											variant="outlined"
										/>
									)}
								/>
							</LocalizationProvider>
						</FillterWrapper>
					)}
				</Form>
			</Item>
			<Item className="shadow-none">
				{commonFunction.checkPermission("KPI_ADD") && (
					<>
						<Button
							variant="outlined"
							startIcon={<AddIcon />}
							color="primary"
							onClick={() => setOpenCreateDialog(true)}
						>
							{translate("resources.common.fields.add")}
						</Button>
						{openCreateDialog && (
							<KpiAdd
								openDialog={openCreateDialog}
								onCloseDialog={() => setOpenCreateDialog(false)}
							/>
						)}
					</>
				)}
			</Item>
		</Box>
	);
};

const KpiDatagrid = (props: DatagridProps) => {
	// @ts-ignore
	const refresh = useRefresh();
	const notify = useNotify();

	const translate = useTranslate();
	return (
		<>
			<div className={`list-data-tdn`}>
				<Datagrid
					optimized
					bulkActionButtons={false}
				>
					<FunctionField
						label={""}
						render={(record: any) => {
							return (
								<div>
									<div className="mx-2">Chỉ tiêu</div>
									<div className="fw-6 mx-2">Tỷ trọng</div>
								</div>
							);
						}}
					/>
					<FunctionField
						label={"Doanh số bán"}
						render={(record: any) => {
							return (
								<div>
									<div className="mx-2">
										{record
											? record.kpi_sales?.toLocaleString("vi", {
													style: "decimal",
													currency: "VND",
											  }) + " đ"
											: "Đang cập nhật"}
									</div>
									<div className="fw-6 mx-2">{record.density_sales}%</div>
								</div>
							);
						}}
					/>
					<FunctionField
						label={"Nguồn dự án"}
						render={(record: any) => {
							return (
								<div>
									<div className="mx-2">{record.kpi_source}</div>
									<div className="fw-6 mx-2">{record.density_source}%</div>
								</div>
							);
						}}
					/>
					<FunctionField
						label={"Bài đăng"}
						render={(record: any) => {
							return (
								<div>
									<div className="mx-2">{record.kpi_spam}</div>
									<div className=" fw-6 mx-2">{record.density_spam}%</div>
								</div>
							);
						}}
					/>
					<FunctionField
						label={"Dẫn khách đi xem"}
						render={(record: any) => {
							return (
								<div>
									<div className="mx-2">{record.kpi_go_see}</div>
									<div className="fw-6 mx-2">{record.density_go_see}%</div>
								</div>
							);
						}}
					/>

					<FunctionField
						label={"Video ngắn"}
						render={(record: any) => {
							return (
								<div>
									<div className="mx-2">{record.kpi_video}</div>
									<div className=" fw-6 mx-2">{record.density_video}%</div>
								</div>
							);
						}}
					/>
					<RowActions label={translate("resources.common.fields.action")} />
				</Datagrid>
			</div>
		</>
	);
};
