import axios, { AxiosError } from "axios";
import { AnyRecord } from "dns";
import { constants } from "../shared/constants";
import { InittialState } from "../types";
import request from "../utils/httpRequest";

export const getCustomer = async (authorizationToken: any) => {
	try {
		const response = await request.get(`/customer/getAll`, {
			headers: {
				Authorization: `Bearer ${authorizationToken}`,
			},
		});
		return response.data;
	} catch (error) {
		console.log(error);
	}
};
export const getOneCustomer = async (id: any) => {
	const authorizationToken = await localStorage.getItem(constants.LOCAL_STORAGE.TOKEN);
	try {
		const response = await request.get(`/customer/getCustomer/${id}`, {
			headers: {
				Authorization: `Bearer ${authorizationToken}`,
			},
		});
		return response.data;
	} catch (error) {
		console.log(error);
	}
};

export const deleteCustomer = async (id: number) => {
	try {
		const response = await request.delete(`/customer/delete/${id}`);
		return response.data;
	} catch (error) {
		const err = error as AxiosError;
		console.log(err.response);
		return err.response;
	}
};

export const addCustomer = async (data: any) => {
	try {
		const response = await request.post(`/customer/addCustomer`, data, {
			headers: { "Content-Type": "multipart/form-data" },
		});
		return response.data;
	} catch (error) {
		const err = error as AxiosError;
		console.log(err.response);
		return err.response;
	}
};



// export const createTransaction = async (id: any, data: any) => {
// 	try {
// 		const response = await request.post(`/customer/createTransactionCustomer/${id}`, data, {
// 			headers: { "Content-Type": "multipart/form-data" },
// 		});
// 		return response.data;
// 	} catch (error) {
// 		const err = error as AxiosError;
// 		console.log(err.response);
// 		return err.response;
// 	}
// };
export const createTransaction = async (data: any) => {
	try {
		const response = await request.post(`/transaction/add-transaction/`, data, {
			headers: { "Content-Type": "multipart/form-data" },
		});
		return response.data;
	} catch (error) {
		const err = error as AxiosError;
		console.log(err.response);
		return err.response;
	}
};


export const updateCustomer = async (id: any, data: any) => {
	try {
		const res = await request.post(`/customer/updateCustomer?id=${id}`, data, {
			headers: { "Content-Type": "multipart/form-data" },
		});
		return res.data;
	} catch (error) {
		const err = error as AxiosError;
		console.log(err.response);
		return err.response;
	}
};
export const uploadImportCustomer = async (data: any) => {
	try {
		const res = await request.post(`/customer/uploadImportCustomer`, data);
		return res.data;
	} catch (error) {
		const err = error as AxiosError;
		console.log(err.response);
		return err.response;
	}
};

export const updateProjectCustomer = async (data: any) => {
	try {
		const res = await request.post(`/customer/updateProjectCustomer`, data);
		return res.data;
	} catch (error) {
		const err = error as AxiosError;
		console.log(err.response);
		return err.response;
	}
};
