import React from "react";
// @ts-ignore
import { CKEditor } from "@ckeditor/ckeditor5-react";
// @ts-ignore
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Editor5 from "ckeditor5-custom-build/build/ckeditor";

type Props = {
	data: string;
	onChange?: (event: any, editor: any) => void;
	submitLoading?: boolean;
};

function Editor({ data, onChange, submitLoading = false }: Props) {
	return (
		<CKEditor
			editor={Editor5}
			data={data}
			config={{
				toolbar: [
					"undo",
					"redo",
					"bold",
					"italic",
					"blockQuote",
					"imageTextAlternative",
					"imageUpload",
					"heading",
					"imageStyle:side",
					"link",
					"fontColor",
					"fontBackgroundColor",
					"numberedList",
					"bulletedList",
					"mediaEmbed",
					"insertTable",
					"tableColumn",
					"tableRow",
					"mergeTableCells",
				],
				ckfinder: {
					// Upload the images to the server using the CKFinder QuickUpload command.
					// uploadUrl: `/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Images&responseType=json`,
				},
			}}
			onReady={(editor: any) => {
				// You can store the "editor" and use when it is needed.
			}}
			onChange={onChange}
			disabled={submitLoading}
		/>
	);
}
export default React.memo(Editor);
