import { AxiosError } from 'axios';
import * as request from '../utils/httpRequest';

type ChangePassType = {
    username: string;
    oldPassword: string;
    newPassword: string;
};

export const changePassword = async (data: ChangePassType) => {
    try {
        const response = await request.put('changePassword', data);
        return response.status;
    } catch (error) {
        const err = error as AxiosError;
        console.log(error);
        return err.response?.status;
    }
};
