import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import React, { useState } from 'react';
import { FilterLiveSearch, useTranslate } from 'react-admin';
import {
    Pagination,
    List,
    Datagrid,
    DatagridProps,
    useListContext,
    TextField,
    FunctionField,
} from 'react-admin';
import { Box, Button, Chip, IconButton, Typography } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Form } from 'react-final-form';
import { PartsDelete } from './PartsDelete';
import { PartCreate } from './PartsAdd';
import { PartsEdit } from './PartsEdit';
import { commonFunction } from '../component/Common';
import { DepartmentCreate } from '../department/DepartmentAdd';
import PaginationCustom from '../component/PaginationCustom';

const Item = styled(Paper)(({ theme }) => ({
    textAlign: 'right',
}));

export const PartsList = (props: any) => {
    const PostPagination = (props: any) => <PaginationCustom {...props} />;
    // const PostPagination = (props: any) => <Pagination rowsPerPageOptions={[]} {...props} />;
    const translate = useTranslate();
    return (
        <Box className="header-channel-sales">
            <Box className={'box_header'}>
                <Typography className={'title_header'}>
                    {translate('resources.parts.list_parts')}
                </Typography>
            </Box>
            <div className={`header-top-detail header-top-tdn`}>
                <List
                    {...props}
                    actions={false}
                    empty={false}
                    exporter={false}
                    filters={<FilterAndActions />}
                    sort={{ field: '', order: '' }}
                    pagination={<PostPagination />}
                    perPage={50}
                >
                    <PartsDatagrid {...props} />
                </List>
            </div>
        </Box>
    );
};

interface ButtonProps {
    basePath?: string;
    label: string;
    record?: any;
}

const EditButton = (props: any) => {
    const [onCloseDialog, setOpenEditDialog] = useState(false);
    return (
        <>
            <IconButton onClick={() => setOpenEditDialog(true)}>
                <EditIcon className={'btn_edit'} />
            </IconButton>
            {onCloseDialog && (
                <PartsEdit
                    {...props}
                    openDialog={onCloseDialog}
                    onCloseDialog={() => setOpenEditDialog(false)}
                />
            )}
        </>
    );
};

const DeleteButton = (props: any) => {
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    return (
        <>
            <IconButton onClick={() => setOpenDeleteDialog(true)}>
                <DeleteIcon className={'btn_del'} />
            </IconButton>
            <PartsDelete
                openDialog={openDeleteDialog}
                onCloseDialog={() => setOpenDeleteDialog(false)}
            />
        </>
    );
};

const RowActions = ({ record }: ButtonProps) => {
    const per_edit = commonFunction.checkPermission('PARTS_EDIT');
    const per_delete = commonFunction.checkPermission('PARTS_DELETE');
    return (
        <Box>
            {per_edit && <EditButton />}
            {per_delete && <DeleteButton />}
        </Box>
    );
};
const FilterAndActions = (props: any) => {
    const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);
    const translate = useTranslate();
    return (
        <Box
            sx={{
                p: 1,
                m: 1,
                bgcolor: 'background.paper',
                borderRadius: 1,
            }}
            className={'box_search'}
        >
            <Item className="shadow-none">
                <Form onSubmit={() => undefined}>
                    {() => (
                        <FilterLiveSearch
                            variant="outlined"
                            source="name"
                            label="resources.common.fields.search"
                        />
                    )}
                </Form>
            </Item>
            <Item className="shadow-none">
                {commonFunction.checkPermission('PARTS_ADD') && (
                    <>
                        <Button
                            variant="outlined"
                            startIcon={<AddIcon />}
                            color="primary"
                            onClick={() => setOpenCreateDialog(true)}
                        >
                            {translate('resources.common.fields.add')}
                        </Button>
                        {openCreateDialog && (
                            <PartCreate
                                openDialog={openCreateDialog}
                                onCloseDialog={() => setOpenCreateDialog(false)}
                            />
                        )}
                    </>
                )}
            </Item>
        </Box>
    );
};

const PartsDatagrid = (props: DatagridProps) => {
    // @ts-ignore
    const { page, perPage } = useListContext();
    const translate = useTranslate();
    return (
        <>
            <div className={`list-data-tdn`}>
                <Datagrid
                    optimized
                    bulkActionButtons={false}
                    className={`table-default criteria_type_datagrid table_department`}
                >
                    <TextField
                        source={'name'}
                        label={translate('resources.parts.fields.name_parts')}
                    />
                    <TextField
                        source={'created_name'}
                        label={translate('resources.common.fields.created_by')}
                    />
                    <TextField
                        source={'updated_name'}
                        label={translate('resources.common.fields.updated_by')}
                    />
                    <FunctionField
                        label={translate('resources.common.fields.status')}
                        render={(record: any) => {
                            if (record.status === 1) {
                                return (
                                    <Chip
                                        label={translate('resources.common.fields.active')}
                                        color="success"
                                        variant="outlined"
                                    />
                                );
                            } else {
                                return (
                                    <Chip
                                        label={translate('resources.common.fields.block')}
                                        color="error"
                                        variant="outlined"
                                    />
                                );
                            }
                        }}
                    />
                    <RowActions label={translate('resources.common.fields.action')} />
                </Datagrid>
            </div>
        </>
    );
};
