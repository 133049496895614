import {
    DefaultDataProvider,
    httpClient,
    serialize,
} from '../default-data-provider';
import {constants} from "../../shared/constants";

export const baseApiUrl = 'parts';

export default class PartsService extends DefaultDataProvider {

}

export const partsService = new PartsService(
    constants.RESOURCE.PARTS,
    constants.BASE_MODULE_URL,
    baseApiUrl
);