import TextField from "@mui/material/TextField";
import React from "react";
import {Autocomplete} from "@mui/material";

interface Props {
    value?: string;
    onChange?: any;
    dataOptions: any
}

const AutoCompleteNoLabel = ({ value, onChange, dataOptions }: Props) => {
    const handleChange = (e: any) => {
        if (onChange) {
            if(e==null) {
                onChange(dataOptions[0]);
            } else {
                onChange(e);
            }
        }
    };

    return (
        <Autocomplete
            className={'c_auto_com'}
            fullWidth
            value={value}
            options={dataOptions}
            onChange={(event, value) => handleChange(value)}
            renderInput={(params) => <TextField {...params} label={false} variant="outlined" />}
        />
    )
}

export default AutoCompleteNoLabel;