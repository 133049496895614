import React from 'react';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton } from '@mui/material';
import { styled } from '@mui/system';

const Wrapper = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'whitesmoke',
    borderRadius: 10,
    marginTop: 10,
    padding: '5px 5px 5px 10px',
});

const InfoWrapper = styled(Box)({
    display: 'flex',
    alignItems: 'center',
});

type Props = {
    fileName: String;
    onRemove?: () => void;
};

function PDFPreview({ fileName, onRemove }: Props) {
    return (
        <Wrapper>
            <InfoWrapper>
                <PictureAsPdfIcon
                    sx={{
                        color: 'red',
                    }}
                />
                {fileName}
            </InfoWrapper>
            <div>
                <IconButton aria-label="delete" onClick={onRemove}>
                    <DeleteIcon />
                </IconButton>
            </div>
        </Wrapper>
    );
}
export default React.memo(PDFPreview);
