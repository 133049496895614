import React, { useContext, useState } from "react";
import {
	List,
	Datagrid,
	DatagridProps,
	useListContext,
	TextField,
	FunctionField,
	useTranslate,
	FilterLiveSearch,
} from "react-admin";
import {
	Box,
	Chip,
	IconButton,
	Typography,
	Autocomplete,
	TextField as InputText,
	Button,
	Tooltip,
} from "@mui/material";
import { styled } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Form } from "react-final-form";
import { commonFunction } from "../component/Common";
import { PermissionAdd } from "../permission/PermissionAdd";
import ProjectDelete from "./dialogs/ProjectDelete";
import ProjectEdit from "./dialogs/ProjectEdit";
import CreateButtonMUI from "../component/CreateButtonMUI";
import ProjectCreate from "./dialogs/ProjectCreate";
import ProjectGood from "./dialogs/ProjectGood";

import * as departmentService from "../Services/departmentService";
import * as projectTypeService from "../Services/projectTypeService";
import classNames from "classnames/bind";
import styles from "./Project.module.scss";
import PaginationCustom from "../component/PaginationCustom";

const cx = classNames.bind(styles);

const HeaderActionWrapper = styled(Box)({
	width: "100%",
	backgroundColor: "white",
});

const HeaderButtonWrapper = styled(Box)({
	display: "flex",
	justifyContent: "flex-end",
	gap: 20,
	flexWrap: "wrap",
	padding: 20,
});

const Item = styled(Box)({});

const FillterWrapper = styled(Box)({
	display: "flex",
	gap: 20,
	padding: "0 20px",
});

const FillterItem = styled(Box)({
	width: 250,
});

// @ts-ignore
const ProjectPolicyContext = React.createContext();

export const ProjectList = (props: any) => {
	const PostPagination = (props: any) => <PaginationCustom {...props} />;
	const [nameContext, setNameContext] = useState<string>();
	const [departmentContext, setDepartmentContext] = useState<any>({
		id: 0,
		name: "Tất cả",
	});
	const [projectTypeContext, setProjectTypeContext] = useState<any>({
		id: 0,
		name: "Tất cả",
	});

	//LOADING
	const [loadingContext, setLoadingContext] = useState<boolean>(false);

	const translate = useTranslate();
	return (
		<Box className="header-channel-sales">
			<Box className={"box_header"}>
				<Typography className={"title_header"}>Danh sách dự án</Typography>
			</Box>
			<div className={`header-top-detail header-top-tdn`}>
				<ProjectPolicyContext.Provider
					value={{
						nameContext: [nameContext, setNameContext],
						departmentContext: [departmentContext, setDepartmentContext],
						projectTypeContext: [projectTypeContext, setProjectTypeContext],
						loadingContext: [loadingContext, setLoadingContext],
					}}
				>
					<List
						{...props}
						actions={false}
						empty={false}
						exporter={false}
						filters={<FilterAndActions />}
						filter={{
							name: nameContext,
							type: projectTypeContext?.id ? projectTypeContext?.id : null,
							department: departmentContext?.id ? departmentContext?.id : null,
						}}
						filterDefaultValues={{
							name: "",
							type: null,
							depament: null,
						}}
						sort={{ field: "", order: "" }}
						pagination={<PostPagination />}
					>
						<ProjectDatagrid
							{...props}
							hasBulkActions={true}
						/>
					</List>
				</ProjectPolicyContext.Provider>
			</div>
		</Box>
	);
};

interface ButtonProps {
	basePath?: string;
	label: string;
	record?: any;
}

const DetailButton = (props: any) => {
	const [openDialog, setOpenDialog] = useState(false);
	return (
		<>
			<Tooltip
				title="Xem bảng hàng"
				arrow
			>
				<IconButton onClick={() => setOpenDialog(true)}>
					<VisibilityIcon />
				</IconButton>
			</Tooltip>
			<ProjectGood
				isOpen={openDialog}
				onClose={() => setOpenDialog(false)}
			/>
		</>
	);
};

const EditButton = (props: any) => {
	const [openEditDialog, setOpenEditDialog] = useState(false);
	return (
		<>
			<Tooltip
				title="Sửa"
				arrow
			>
				<IconButton onClick={() => setOpenEditDialog(true)}>
					<EditIcon className={"btn_edit"} />
				</IconButton>
			</Tooltip>
			<ProjectEdit
				isOpen={openEditDialog}
				onClose={() => setOpenEditDialog(false)}
			/>
		</>
	);
};

const DeleteButton = (props: any) => {
	const [openEditDialog, setOpenEditDialog] = useState(false);
	return (
		<>
			<Tooltip
				title="Xóa"
				arrow
			>
				<IconButton onClick={() => setOpenEditDialog(true)}>
					<DeleteIcon className={"btn_del"} />
				</IconButton>
			</Tooltip>
			<ProjectDelete
				isOpen={openEditDialog}
				onClose={() => setOpenEditDialog(false)}
			></ProjectDelete>
		</>
	);
};

const RowActions = ({ record }: ButtonProps) => {
	return (
		<Box className={"care_plan_rowActions"}>
			<EditButton />
			<DeleteButton />
			<DetailButton />
		</Box>
	);
};

const FilterAndActions = (props: any) => {
	// @ts-ignore
	const { filterValues } = useListContext();
	console.log(filterValues);
	const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);

	//STATE
	const [fillterOpen, setFillterOpen] = useState<boolean>(false);

	//API STATE
	const [departmentList, setDepartmentList] = useState<any[]>([{ id: 0, name: "Tất cả" }]);
	const [projecTypeList, setProjectTypeList] = useState<any[]>([{ id: 0, name: "Tất cả" }]);

	// @ts-ignore
	const { nameContext, departmentContext, projectTypeContext } = useContext(ProjectPolicyContext);
	const [name, setName] = nameContext;
	const [department, setDepartment] = departmentContext;
	const [projectType, setProjectType] = projectTypeContext;

	//Loading
	const [loading, setLoading] = useState<boolean>(false);

	React.useEffect(() => {
		setLoading(true);

		const fetchApi = async () => {
			const departmentListData = await departmentService.getListDepartment();
			const projectTypeListData = await projectTypeService.getListProjectType();
			setDepartmentList([...departmentList, ...departmentListData]);
			setProjectTypeList([...projecTypeList, ...projectTypeListData]);
			setLoading(false);
		};
		fetchApi();
	}, []);

	/**
	 * handle change name
	 * @param e
	 */
	const handleNameChange = (e: any) => {
		setName(e == "" ? "" : e.target.value);
	};

	const handleOpenFillter = () => {
		setFillterOpen((prev) => !prev);
	};
	const styleWrapper = {
		overflow: "hidden",
		height: fillterOpen ? 64 : 0,
		transition: "500",
	};

	return (
		<HeaderActionWrapper>
			<HeaderButtonWrapper>
				<Item>
					<Button
						disabled={loading}
						variant={fillterOpen ? "outlined" : "contained"}
						color={fillterOpen ? "error" : "primary"}
						startIcon={fillterOpen ? <CloseIcon /> : <SearchIcon />}
						onClick={handleOpenFillter}
					>
						{fillterOpen ? "Đóng" : "Lọc "}
					</Button>
				</Item>
				<Item>
					{/*{ commonFunction.checkPermission('PERMISSION_ADD') && (*/}
					<CreateButtonMUI
						title="Thêm dự án"
						onClick={() => setOpenCreateDialog(true)}
					/>
					<ProjectCreate
						isOpen={openCreateDialog}
						onClose={() => setOpenCreateDialog(false)}
					/>
				</Item>
			</HeaderButtonWrapper>
			<Box>
				<Form
					onSubmit={() => undefined}
					initialValues={filterValues}
				>
					{() => (
						<FillterWrapper
							style={styleWrapper}
							className={cx("fillter-wrapper")}
						>
							<FillterItem>
								<FilterLiveSearch
									sx={{
										mt: 1.4,
									}}
									onChange={handleNameChange}
									value={name}
									variant="outlined"
									source="name"
									label="resources.common.fields.search"
								/>
							</FillterItem>
							<FillterItem>
								<Autocomplete
									fullWidth
									id="combo-box-demo"
									options={departmentList}
									renderInput={(params) => (
										<InputText
											{...params}
											variant="outlined"
											label="Phòng ban"
										/>
									)}
									isOptionEqualToValue={(option, value) => option.id === value.id}
									value={department}
									getOptionLabel={(option) => option.name}
									onChange={(e, value) => setDepartment(value == null ? departmentList[0] : value)}
								/>
							</FillterItem>
							<FillterItem>
								<Autocomplete
									fullWidth
									id="combo-box-demo"
									options={projecTypeList}
									renderInput={(params) => (
										<InputText
											{...params}
											variant="outlined"
											label="Loại dự án"
										/>
									)}
									isOptionEqualToValue={(option, value) => option.id === value.id}
									value={projectType}
									getOptionLabel={(option) => option.name}
									onChange={(e, value) => setProjectType(value == null ? projecTypeList[0] : value)}
								/>
							</FillterItem>
						</FillterWrapper>
					)}
				</Form>
			</Box>
		</HeaderActionWrapper>
	);
};

const ProjectDatagrid = (props: DatagridProps) => {
	return (
		<>
			<div>
				<Datagrid
					optimized
					bulkActionButtons={false}
				>
					<TextField
						source={"name"}
						label={"Tên dự án"}
					/>
					<TextField
						source={"department_name"}
						label={"Phòng ban"}
					/>
					<TextField
						source={"project_name"}
						label={"Loại dự án"}
					/>
					<FunctionField
						label={"TRẠNG THÁI"}
						render={(record: any) => {
							if (record.status === 1) {
								return (
									<Chip
										label="Đang hoạt động"
										color="success"
										variant="outlined"
									/>
								);
							} else {
								return (
									<Chip
										label="Khóa"
										color="error"
										variant="outlined"
									/>
								);
							}
						}}
					/>
					<RowActions label={"THAO TÁC"} />
				</Datagrid>
			</div>
		</>
	);
};
