/** @jsxRuntime classic */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'proxy-polyfill';
// IE11 needs "jsxRuntime classic" for this initial file which means that "React" needs to be in scope
// https://github.com/facebook/create-react-app/issues/9906

import * as React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './App';
import GlobalStyles from './component/GlobalStyles';
//
// ReactDOM.render(
//     <React.StrictMode>
//         <Provider>
//             <App />
//         </Provider>
//     </React.StrictMode>,
//     document.getElementById('root'));

ReactDOM.render(
    <GlobalStyles>
        <App />
    </GlobalStyles>,
    document.getElementById('root'),
);
