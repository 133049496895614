import {
    DefaultDataProvider,
    httpClient,
    serialize,
} from '../default-data-provider';
import {constants} from "../../shared/constants";

export const baseApiUrl = 'wage';

class WageService extends DefaultDataProvider {

}

export const wageService = new WageService(
    constants.RESOURCE.WAGE,
    constants.BASE_MODULE_URL,
    baseApiUrl
);