import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import React, { useState, useEffect } from "react";
import {
  FilterLiveSearch,
  useRecordContext,
  useTranslate,
  List,
  Datagrid,
  DatagridProps,
  TextField,
} from "react-admin";
import PaginationCustom from "../component/PaginationCustom";
import { Box, IconButton, Typography, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Form } from "react-final-form";
import HttpDataService from "../dataProvider/http.service";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { BackupDelete } from "./BackupDelete";
import { commonFunction } from '../component/Common';


const Item = styled(Paper)(({ theme }) => ({
  textAlign: "right",
}));
export const BackupList = (props: any) => {
  const PostPagination = (props: any) => <PaginationCustom {...props} />;
  const translate = useTranslate();
  return (
    <Box className="header-channel-sales">
      <Box className={"box_header"}>
        <Typography className={"title_header"}>
          <span>Danh sách Backup</span>
        </Typography>
      </Box>
      <div className={`header-top-detail header-top-tdn`}>
        <List
          {...props}
          actions={false}
          empty={false}
          exporter={false}
          filters={<FilterAndActions />}
          sort={{ field: "", order: "" }}
          pagination={<PostPagination />}
          perPage={50}
        >
          <DepartmentDatagrid {...props} />
        </List>
      </div>
    </Box>
  );
};

const DeleteButton = (props: any) => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  return (
    <>
      <Tooltip title="Xoá" arrow>
        <IconButton
          style={{ padding: "2px" }}
          onClick={() => setOpenDeleteDialog(true)}
        >
          <DeleteIcon className={"btn_del "} />
        </IconButton>
      </Tooltip>
      <BackupDelete
        file_name={props?.file_name}
        openDialog={openDeleteDialog}
        onCloseDialog={() => setOpenDeleteDialog(false)}
      />
    </>
  );
};

const DownLoadButton = (props: any) => {
  const filename = props?.file_name;
  const DownloadFile = () => {
    HttpDataService.findById("backup/downloadFile", filename as string)
      .then(async (response: any) => {
        if (response.data.status) {
          // @ts-ignore

          notify(response.data.message, { type: "success" });
        } else {
          // @ts-ignore
          notify(response.data.message, { type: "error" });
        }
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };
  return (
    <>
      <Tooltip title="Tải xuống" arrow>
        <IconButton onClick={() => DownloadFile()} style={{ padding: "2px" }}>
          <FileDownloadIcon className={"btn_edit "} />
        </IconButton>
      </Tooltip>
    </>
  );
};

const RowActions = (props: any) => {
  const list = useRecordContext();
  const per_delete = commonFunction.checkPermission("BACKUP_DELETE");
  const per_download = commonFunction.checkPermission("BACKUP_DOWNLOAD");
  return (
    <Box className={"care_plan_rowActions"}>
      {!per_download ? <></> : <DownLoadButton file_name={list?.file_name} />}
      {!per_delete ? <></> : <DeleteButton file_name={list?.file_name} />}
    </Box>
  );
};
const FilterAndActions = (props: any) => {
  const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);
  // const classes = styles()
  return (
    <Box
      sx={{
        p: 1,
        m: 1,
        bgcolor: "background.paper",
        borderRadius: 1,
      }}
      className={"box_search"}
    >
      <Item className="shadow-none">
        <Form onSubmit={() => undefined}>
          {() => (
            <FilterLiveSearch
              variant="outlined"
              source="name"
              label="resources.common.fields.search"
            />
          )}
        </Form>
      </Item>
    </Box>
  );
};

const DepartmentDatagrid = (props: DatagridProps) => {
  return (
    <>
      <div className={`list-data-tdn`}>
        <Datagrid
          bulkActionButtons={false}
          className={`table-default criteria_type_datagrid table_department`}
        >
          <TextField source={"name"} label={"NAME"} />
          <TextField source={"description"} label={"Mô tả"} />
          <TextField source={"file_name"} label={"Tên file"} />
          <TextField source={"size"} label={"Size"} />
          <RowActions label={"resources.common.fields.action"} />
        </Datagrid>
      </div>
    </>
  );
};
