import {
    DefaultDataProvider,
} from '../default-data-provider';
import {constants} from "../../shared/constants";

export const baseApiUrl = 'partner';

class PartnerService extends DefaultDataProvider {

}

export const partnerService = new PartnerService(
    constants.RESOURCE.PARTNER,
    constants.BASE_MODULE_URL,
    baseApiUrl
);