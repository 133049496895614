import React from "react";
import {
  Dialog,
  DialogTitle,
  Button,
  Stack,
  Box,
  Typography,
} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { styled } from "@mui/system";
import { useNotify, useRecordContext, useRefresh } from "react-admin";
import DialogSubmitLoading from "../../../../component/DialogSubmitLoading";
import HttpDataService from "../../../../dataProvider/http.service";
import request from "../../../../utils/httpRequest";

const Wrapper = styled(Box)({
  width: 500,
});

const HeaderWrapper = styled(Box)({
  backgroundColor: "whitesmoke",
});

const ContentWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "30px 0",
});

const WarningText = styled(Typography)({
  color: "#ed6c02",
  fontSize: "1.5rem",
});

const ButtonWrapper = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  backgroundColor: "whitesmoke",
  padding: 20,
});

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

function DeleteTransaction({ isOpen, onClose }: Props) {
  //LOADING STATE
  const [submitLoading, setSubmitLoading] = React.useState<boolean>(false);

  //HOOKS
  const record = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();

  const handleDelete = async () => {
    setSubmitLoading(true);
    const response = await request
      .delete(`/transaction/delete/${record.id as string}`)
      .then((response: any) => {
        console.log(response);
        if (response.data.status) {
          // @ts-ignore
          notify(response.data.message, { type: "success" });
        } else {
          // @ts-ignore
          notify(response.data.message, { type: "error" });
        }
      })
      .catch((e: Error) => {
        // @ts-ignore
        notify("Xoá không thành công", { type: "error" });
      });
    refresh();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Wrapper>
        {submitLoading ? <DialogSubmitLoading /> : <></>}
        <HeaderWrapper>
          <DialogTitle>Xóa giao dịch</DialogTitle>
        </HeaderWrapper>
        <ContentWrapper>
          <ErrorIcon color="warning" fontSize="large" />
          <WarningText>Bạn chắc chắn muốn xóa giao dịch? </WarningText>
        </ContentWrapper>
        <ButtonWrapper>
          <Stack direction="row" spacing={2}>
            <Button variant="contained" onClick={handleDelete}>
              Xác nhận
            </Button>
            <Button variant="outlined" color="error" onClick={onClose}>
              Hủy
            </Button>
          </Stack>
        </ButtonWrapper>
      </Wrapper>
    </Dialog>
  );
}

export default React.memo(DeleteTransaction);
