import { constants } from "../shared/constants";
import HttpDataService from "../dataProvider/http.service";

class Common {
	checkPermission(permission: string) {
		const permissionApiList = localStorage.getItem(constants.LOCAL_STORAGE.PERMISSION_API_LIST);
		if (!permissionApiList) return null;
		const permissions = JSON.parse(permissionApiList as string);

		return permissions.find((item: string) => item == permission);
	}

	getDollarRate() {
		const dollarLocal = localStorage.getItem(constants.LOCAL_STORAGE.DOLLAR_RATE);
		if (!dollarLocal) return null;

		const rollar = JSON.parse(dollarLocal);
		return rollar;
	}

	getCurrentUserProfile() {
		const url = `${constants.BASE_MODULE_URL}/getCurrentUserProfile`;
		HttpDataService.getAll(url)
			.then((response: any) => {
				if (response.data.status) {
					const data = response.data.data;
					localStorage.setItem(constants.LOCAL_STORAGE.USER_IDENTITY, JSON.stringify(data.user));
					localStorage.setItem(constants.LOCAL_STORAGE.PERMISSION_API_LIST, JSON.stringify(data.permission));
				}
			})
			.catch((e: Error) => {
				console.log(e);
			});
	}

	checkUserIdentity() {
		const UserIdentityJSON = localStorage.getItem(constants.LOCAL_STORAGE.USER_IDENTITY);
		if (!UserIdentityJSON) return null;
		const permissions = JSON.parse(UserIdentityJSON as string);

		return permissions;
	}
}

export const commonFunction = new Common();
