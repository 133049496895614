import React from 'react';
import { Button } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

type Props = {
    title: String;
    onClick?: () => void;
    type?: 'button' | 'submit' | 'reset' | undefined;
    varian?: 'text' | 'outlined' | 'contained' | undefined;
    color?:
        | 'inherit'
        | 'primary'
        | 'secondary'
        | 'success'
        | 'error'
        | 'info'
        | 'warning'
        | undefined;
};

function SaveButton({
    title,
    onClick,
    varian = 'contained',
    color = 'primary',
    type = 'button',
}: Props) {
    return (
        <Button
            variant={varian}
            color={color}
            startIcon={<SaveIcon />}
            onClick={onClick}
            type={type}
        >
            {title}
        </Button>
    );
}
export default React.memo(SaveButton);
