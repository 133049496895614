import React from 'react';
import classnames from 'classnames/bind';
import styles from './ImagePreview.module.scss';

import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import Image from '../Image';

type Props = {
    src: any;
    onRemove?: () => void;
    showRemoveButton?: boolean;
};

const cx = classnames.bind(styles);

function ImagePreview({ src, onRemove, showRemoveButton = true }: Props) {
    return (
        <div className={cx('wrapper')}>
            <Image src={src} alt="" className='img-style' />
            {showRemoveButton && <IconButton
                className={cx('remove-btn')}
                aria-label="delete"
                onClick={onRemove}
                color="error"
            >
                <CancelIcon />
            </IconButton>}
        </div>
    );
}
export default React.memo(ImagePreview);
