export interface Criteria {
    [key: string]: any;
}

export class ApiCriteriaUtils {
    in(field: string, value: any[]): Criteria {
        return {
            [`${field}.in`]: value,
        };
    }
}

export const apiCriteriaUtils = new ApiCriteriaUtils();
