import {
  useNotify,
  useRefresh,
  useTranslate,
  useRecordContext,
} from "react-admin";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tabs,
  Tab,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import ReplyIcon from "@mui/icons-material/Reply";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import HttpDataService from "../dataProvider/http.service";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import PriceInput from "../component/PriceInput";
import request from "../utils/httpRequest";

//Reformat Style
const styles = makeStyles((theme: any) =>
  createStyles({
    root: {
      "& .css-k4qjio-MuiFormHelperText-root.Mui-error": {
        position: "absolute",
        paddingTop: "41px",
      },
      "& .css-1z10yd4-MuiFormControl-root-MuiTextField-root": {
        marginTop: 20,
        marginBottom: 20,
      },
    },
  })
);

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 2 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const KpiEdit = ({ onCloseDialog, openDialog }: any) => {
  const classes = styles();
  const translate = useTranslate();
  const [id, setId] = useState();
  const [type, setType] = useState(1);

  // CHI TIEU
  const [kpiSales, setKpiSales] = useState("");
  const [kpiSalesError, setKpiSalesError] = useState(false);
  const [textKpiSalesError, setTextKpiSalesError] = useState("");

  const [kpiSource, setKPISource] = useState("");
  const [kpiSourceError, setKpiSourceError] = useState(false);
  const [textKpiSourceError, setTextKpiSourceError] = useState("");

  const [kpiSpam, setKPISpam] = useState("");
  const [kpiSpamError, setKpiSpamError] = useState(false);
  const [textKpiSpamError, setTextKpiSpamError] = useState("");

  const [kpiGoSee, setKPIGoSee] = useState("");
  const [kpiGoSeeError, setKpiGoSeeError] = useState(false);
  const [textKpiGoSeeError, setTextKpiGoSeeError] = useState("");

  const [kpiVideo, setKPIVideo] = useState("");
  const [kpiVideoError, setKpiVideoError] = useState(false);
  const [textKpiVideoError, setTextKpiVideoError] = useState("");

  // TI TRONG
  const [kpiSalesRate, setKpiSalesRate] = useState("");
  const [kpiSalesRateError, setKpiSalesRateError] = useState(false);
  const [textKpiSalesRateError, setTextKpiSalesRateError] = useState("");

  const [kpiSourceRate, setKPISourceRate] = useState("");
  const [kpiSourceRateError, setKpiSourceRateError] = useState(false);
  const [textKpiSourceRateError, setTextKpiSourceRateError] = useState("");

  const [kpiSpamRate, setKPISpamRate] = useState("");
  const [kpiSpamRateError, setKpiSpamRateError] = useState(false);
  const [textKpiSpamRateError, setTextKpiSpamRateError] = useState("");

  const [kpiGoSeeRate, setKPIGoSeeRate] = useState("");
  const [kpiGoSeeRateError, setKpiGoSeeRateError] = useState(false);
  const [textKpiGoSeeRateError, setTextKpiGoSeeRateError] = useState("");

  const [kpiVideoRate, setKPIVideoRate] = useState("");
  const [kpiVideoRateError, setKpiVideoRateError] = useState(false);
  const [textKpiVideoRateError, setTextKpiVideoRateError] = useState("");

  const refresh = useRefresh();
  const notify = useNotify();
  const record = useRecordContext();

  useEffect(() => {
    (() => {
      request
        .get(`kpi/getKPI/${record.id}`)
        .then((res: any) => {
          const hasStatus = String(res?.status).startsWith("2");

          if (hasStatus) {
            const kpiData = res.data.data;
            setId(kpiData.id);
            setKpiSalesRate(kpiData.density_sales);
            setKPISourceRate(kpiData.density_source);
            setKPISpamRate(kpiData.density_spam);
            setKPIGoSeeRate(kpiData.density_go_see);
            setKPIVideoRate(kpiData.density_video);

            setKpiSales(kpiData.kpi_sales);
            setKPISource(kpiData.kpi_source);
            setKPISpam(kpiData.kpi_spam);
            setKPIGoSee(kpiData.kpi_go_see);
            setKPIVideo(kpiData.kpi_video);
          }
        })
        .catch((err: any) => {
          console.log("err", err);
        });
    })();
  }, []);

  const handleTypeChange = (e: any, value: any) => {
    setType(value);
  };

  /**
   * handle change sales
   * @param e
   */
  const handleSalesChange = (e: any) => {
    setKpiSalesError(false);
    setTextKpiSalesError("");
    setKpiSales(e);
  };

  /**
   * handle change kpi source
   * @param e
   */
  const handleKpiSourceChange = (e: any) => {
    setKpiSourceError(false);
    setTextKpiSourceError("");
    setKPISource(e);
  };

  /**
   * handle change kpi source
   * @param e
   */
  const handleKpiSpamChange = (e: any) => {
    setKpiSpamError(false);
    setTextKpiSpamError("");
    setKPISpam(e);
  };

  /**
   * handle change kpi go see
   * @param e
   */
  const handleKpiGoSeeChange = (e: any) => {
    setKpiGoSeeError(false);
    setTextKpiGoSeeError("");
    setKPIGoSee(e);
  };

  /**
   * handle change kpi video
   * @param e
   */
  const handleKpiVideoChange = (e: any) => {
    setKpiVideoError(false);
    setTextKpiVideoError("");
    setKPIVideo(e);
  };

  /**
   * handle change sales
   * @param e
   */
  const handleSalesRateChange = (e: any) => {
    setKpiSalesRateError(false);
    setTextKpiSalesRateError("");
    setKpiSalesRate(e);
  };

  /**
   * handle change kpi source
   * @param e
   */
  const handleKpiSourceRateChange = (e: any) => {
    setKpiSourceRateError(false);
    setTextKpiSourceRateError("");
    setKPISourceRate(e);
  };

  /**
   * handle change kpi source
   * @param e
   */
  const handleKpiSpamRateChange = (e: any) => {
    setKpiSpamRateError(false);
    setTextKpiSpamRateError("");
    setKPISpamRate(e);
  };

  /**
   * handle change kpi go see
   * @param e
   */
  const handleKpiGoSeeRateChange = (e: any) => {
    setKpiGoSeeRateError(false);
    setTextKpiGoSeeRateError("");
    setKPIGoSeeRate(e);
  };

  /**
   * handle change kpi video
   * @param e
   */
  const handleKpiVideoRateChange = (e: any) => {
    setKpiVideoRateError(false);
    setTextKpiVideoRateError("");
    setKPIVideoRate(e);
  };

  /**
   * handle save
   */

  const handleSave = async () => {
    try {
      if (!fnValidation()) {
        const data = {
          density_sales: +kpiSalesRate,
          density_source: +kpiSourceRate,
          density_spam: +kpiSpamRate,
          density_go_see: +kpiGoSeeRate,
          density_video: +kpiVideoRate,

          kpi_sales: +kpiSales,
          kpi_source: +kpiSource,
          kpi_spam: +kpiSpam,
          kpi_go_see: +kpiGoSee,
          kpi_video: +kpiVideo,
        };

        await HttpDataService.update(`kpi/updateKPI/${id}`, data);

        notify("Cập nhật KPI thành công", { type: "success" });
        fnResetFrm();
        onCloseDialog();
        refresh();
      }
    } catch (error) {
      console.log("error", error);
      notify("Lỗi", { type: "error" });
    }
  };

  const handleCloseDialog = () => {
    fnResetFrm();
    onCloseDialog();
  };

  /**
   * fn validation form
   */
  function fnValidation() {
    let error = false;
    let text = "Không đc để trống";

    // CHI TIEU
    if (!kpiSales) {
      error = true;
      setKpiSalesError(true);
      setTextKpiSalesError(text);
    }

    if (!kpiSource) {
      error = true;
      setKpiSourceError(true);
      setTextKpiSourceError(text);
    }

    if (!kpiSpam) {
      error = true;
      setKpiSpamError(true);
      setTextKpiSpamError(text);
    }

    if (!kpiGoSee) {
      error = true;
      setKpiGoSeeError(true);
      setTextKpiGoSeeError(text);
    }

    if (!kpiVideo) {
      error = true;
      setKpiVideoError(true);
      setTextKpiVideoError(text);
    }

    // TI TRONG
    if (!kpiSalesRate) {
      error = true;
      setKpiSalesRateError(true);
      setTextKpiSalesRateError(text);
    }

    if (!kpiSourceRate) {
      error = true;
      setKpiSourceRateError(true);
      setTextKpiSourceRateError(text);
    }

    if (!kpiSpamRate) {
      error = true;
      setKpiSpamRateError(true);
      setTextKpiSpamRateError(text);
    }

    if (!kpiGoSeeRate) {
      error = true;
      setKpiGoSeeRateError(true);
      setTextKpiGoSeeRateError(text);
    }

    if (!kpiVideoRate) {
      error = true;
      setKpiVideoRateError(true);
      setTextKpiVideoRateError(text);
    }

    return error;
  }

  /**
   * fn reset form
   */

  function fnResetFrm() {
    // CHI TIEU
    setKpiSales("");
    setKpiSalesError(false);
    setTextKpiSalesError("");

    setKPISource("");
    setKpiSourceError(false);
    setTextKpiSourceError("");

    setKPISpam("");
    setKpiSpamError(false);
    setTextKpiSpamError("");

    setKPIGoSee("");
    setKpiGoSeeError(false);
    setTextKpiGoSeeError("");

    setKPIVideo("");
    setKpiVideoError(false);
    setTextKpiVideoError("");

    // TY TRONG
    setKpiSalesRate("");
    setKpiSalesRateError(false);
    setTextKpiSalesRateError("");

    setKPISourceRate("");
    setKpiSourceRateError(false);
    setTextKpiSourceRateError("");

    setKPISpamRate("");
    setKpiSpamRateError(false);
    setTextKpiSpamRateError("");

    setKPIGoSeeRate("");
    setKpiGoSeeRateError(false);
    setTextKpiGoSeeRateError("");

    setKPIVideoRate("");
    setKpiVideoRateError(false);
    setTextKpiVideoRateError("");

    //
    setType(1);
  }

  return (
    <>
      <Dialog
        open={openDialog}
        fullWidth
        disableEnforceFocus
        PaperProps={{
          style: {
            borderRadius: 2,
            minWidth: "35%",
          },
        }}
      >
        <div className={"common__dialogTitle_area"}>
          <DialogTitle className={"member__dialogTitle"}>
            <div>Thay đổi chỉ tiêu/tháng</div>
          </DialogTitle>
          <div className={"common__dialogTitle_actions"}>
            <IconButton
              aria-label="exists"
              color={"error"}
              onClick={() => {
                onCloseDialog(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <Box className={"dialog_box"}>
          <DialogContent className={"dialog_content"}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs value={type} onChange={handleTypeChange}>
                <Tab value={1} label="Chỉ tiêu/tháng" {...a11yProps(1)} />
                <Tab value={2} label="Tỉ trọng/tháng" {...a11yProps(2)} />
              </Tabs>
            </Box>

            <CustomTabPanel value={type} index={1}>
              <PriceInput
                title="Doanh thu"
                val={kpiSales}
                required={true}
                onChange={handleSalesChange}
                statusError={{
                  isError: kpiSalesError,
                  message: textKpiSalesError,
                }}
              />
              <PriceInput
                title="Nguồn dự án"
                val={kpiSource}
                required={true}
                onChange={handleKpiSourceChange}
                statusError={{
                  isError: kpiSourceError,
                  message: textKpiSourceError,
                }}
              />
              <PriceInput
                title="Bài viết"
                val={kpiSpam}
                required={true}
                onChange={handleKpiSpamChange}
                statusError={{
                  isError: kpiSpamError,
                  message: textKpiSpamError,
                }}
              />
              <PriceInput
                title="Dẫn khách đi xem"
                val={kpiGoSee}
                required={true}
                onChange={handleKpiGoSeeChange}
                statusError={{
                  isError: kpiGoSeeError,
                  message: textKpiGoSeeError,
                }}
              />
              <PriceInput
                title="Video ngắn"
                val={kpiVideo}
                required={true}
                onChange={handleKpiVideoChange}
                statusError={{
                  isError: kpiVideoError,
                  message: textKpiVideoError,
                }}
              />
            </CustomTabPanel>

            <CustomTabPanel value={type} index={2}>
              <PriceInput
                title="Doanh thu"
                val={kpiSalesRate}
                required={true}
                onChange={handleSalesRateChange}
                statusError={{
                  isError: kpiSalesRateError,
                  message: textKpiSalesRateError,
                }}
              />
              <PriceInput
                title="Nguồn dự án"
                val={kpiSourceRate}
                required={true}
                onChange={handleKpiSourceRateChange}
                statusError={{
                  isError: kpiSourceRateError,
                  message: textKpiSourceRateError,
                }}
              />
              <PriceInput
                title="Bài viết"
                val={kpiSpamRate}
                required={true}
                onChange={handleKpiSpamRateChange}
                statusError={{
                  isError: kpiSpamRateError,
                  message: textKpiSpamRateError,
                }}
              />
              <PriceInput
                title="Dẫn khách đi xem"
                val={kpiGoSeeRate}
                required={true}
                onChange={handleKpiGoSeeRateChange}
                statusError={{
                  isError: kpiGoSeeRateError,
                  message: textKpiGoSeeRateError,
                }}
              />
              <PriceInput
                title="Video ngắn"
                val={kpiVideoRate}
                required={true}
                onChange={handleKpiVideoRateChange}
                statusError={{
                  isError: kpiVideoRateError,
                  message: textKpiVideoRateError,
                }}
              />
            </CustomTabPanel>
          </DialogContent>
        </Box>
        <Toolbar>
          <Box display="flex" justifyContent="center" width={"100%"}>
            <Button
              variant="contained"
              startIcon={<SaveIcon />}
              onClick={handleSave}
            >
              {translate("resources.common.fields.update")}
            </Button>
            &nbsp;&nbsp;
            <Button
              startIcon={<ReplyIcon />}
              className={"button-close"}
              variant="contained"
              onClick={handleCloseDialog}
            >
              {translate("resources.common.fields.cancel")}
            </Button>
          </Box>
        </Toolbar>
      </Dialog>
    </>
  );
};
