import React from 'react';
import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/system';

const Wrapper = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 500,
});

function PageLoadingAPI() {
    return (
        <Wrapper>
            <CircularProgress />
        </Wrapper>
    );
}

export default React.memo(PageLoadingAPI);
