import React, { useEffect, useState } from 'react';
import { Tree } from 'antd';
import 'antd/dist/antd.min.css';
import HttpDataService from "../dataProvider/http.service";
// import { menuService } from '../dataProvider/menu.service';

export const MenuTreeList = ({ setMenu, data }) => {
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [checkedKeys, setCheckedKeys] = useState(data);
    const [autoExpandParent, setAutoExpandParent] = useState(true);
    const [allMenus, setAllMenus] = useState([]);
    const [menuIdsHasParentIdIsNull, setMenuIdsHasParentIdIsNull] = useState(
        []
    );

    useEffect(() => {
        setCheckedKeys(data);
        getAllMenus();
    }, [data]);

    function getAllMenus() {

        HttpDataService.getAll('permission/dataAll')
            .then((response) =>
            {
                if(response.data.status) {
                    const menuListData = response.data.data;
                    const entryList = Object.values(menuListData).map(menu => {
                        return {
                            parentId: menu.parent_id,
                            title: menu.name,
                            key: menu.id,
                        };
                    });
                    entryList.forEach(item => {
                        if (item.parentId === null) {
                            menuIdsHasParentIdIsNull.push(item.key);
                        }
                    });
                    setMenuIdsHasParentIdIsNull(menuIdsHasParentIdIsNull);
                    setAllMenus([
                        {
                            parentId: 0,
                            title: 'Tất cả',
                            key: 'all',
                        },
                        ...entryList.map(item => {
                            if (item.parentId === 0) {
                                item.parentId = 'all';
                            }
                            return item;
                        }),

                    ]);
                    console.log(allMenus)
                } else {
                    // @ts-ignore
                    notify(response.data.message,{type: 'error'});
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    const populateMenuTree = (list, ownPages) => {
        let map = {},
            node,
            roots = [],
            i;

        for (i = 0; i < list.length; i += 1) {
            map[list[i].key] = i; // initialize the map
            list[i].children = []; // initialize the children
        }

        for (i = 0; i < list.length; i += 1) {
            node = list[i];
            if (
                (!node.parentId && !node.isRouting) ||
                ownPages.findIndex(ownPage => ownPage.key === node.key) !== -1
            ) {
                // if (node.parentId !== null) {
                //     console.log(map)
                //     console.log(node.parentId)
                //     list[map[node.parentId]]?.children.push(node);
                // } else {
                //     roots.push(node);
                // }
                if(node.parentId==0) {
                    roots.push(node);
                } else {
                    list[map[node.parentId]]?.children.push(node);
                }
            }
        }

        return populateMenuTree_(roots);
    };

    const populateMenuTree_ = list => {
        var map = {},
            node,
            roots = [],
            i;

        for (i = 0; i < list.length; i += 1) {
            map[list[i].key] = i; // initialize the map
            list[i].children = [...(list[i].children || [])]; // initialize the children
        }

        for (i = 0; i < list.length; i += 1) {
            node = list[i];
            // if (node.parentId !== null) {
            //     list[map[node.parentId]].children.push(node);
            // } else {
            //     roots.push(node);
            // }
            if(node.parentId==0) {
                roots.push(node);
            } else {
                list[map[node.parentId]]?.children.push(node);
            }
        }
        roots.sort((a, b) => a.menuIndex - b.menuIndex);
        return roots;
    };

    const onExpand = expandedKeysValue => {
        setExpandedKeys(expandedKeysValue);
        setAutoExpandParent(false);
    };

    const onCheck = checkedKeysValue => {
        const lst_check = checkedKeysValue.filter(item => item !== 'all')
        setCheckedKeys(lst_check);
        setMenu(lst_check);
    };

    return (
        <Tree
            checkable
            onExpand={onExpand}
            expandedKeys={expandedKeys}
            autoExpandParent={autoExpandParent}
            onCheck={onCheck}
            checkedKeys={checkedKeys}
            treeData={populateMenuTree(allMenus, allMenus)}
        />
    );
};
