import {AuthProvider, useRedirect} from 'react-admin';
import { authService } from './dataProvider/auth-service';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import {constants} from "./shared/constants";
import axios from 'axios';

const authProvider: AuthProvider = {
    login: ({ username, password, type = null, token }) => {
        if (token) {
           return authService.loginGoogle(token).catch((error) => {
            console.log(error)
        })
        }else{
        return authService.login({ username, password }).catch(({ body }) => {
            const json = JSON.stringify(body);
            if (!json) {
                throw new Error('Đăng nhập không thành công');
            }
            else if (json.includes('invalid_password'))
                throw new Error('Mật khẩu không đúng. Vui lòng nhập lại');
            else if (json.includes('not_found'))
                throw new Error('Không có thông tin tài khoản');
            else
                throw new Error('Đăng nhập không thành công');
        });
       }
    },
    logout: () => {
        // localStorage.removeItem('username');
        //@ts-ignore
        authService.logout();
        return Promise.resolve();
    },
    checkError: error => {
        const status = error.status;
        if (status === 401 || status === 403) {
            // localStorage.removeItem('token');
            return Promise.reject({ redirectTo: '/authoride', logoutUser: false });
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    checkAuth: () => {
        const jwtToken = authService.isAuthenticated();
        if (!jwtToken) {
            return Promise.reject({ message: false });
        }
        const decodedJwtToken: JwtPayload = jwt_decode(jwtToken);
        if (decodedJwtToken?.exp && Date.now() >= decodedJwtToken?.exp * 10000) {
            return Promise.reject();
        }
        return Promise.resolve();
    },

    getPermissions: () => {
        const permissionApiList = localStorage.getItem(
            constants.LOCAL_STORAGE.PERMISSION_API_LIST
        );
        
        if(permissionApiList) {
            return Promise.resolve(JSON.parse(permissionApiList));
        }
        return Promise.resolve({});
    },
    getIdentity: async () => {
        const objectIdentity = JSON.parse(await authService.getUserIdentity());
        const modifiedResult = {
            id: objectIdentity.id,
            fullName: objectIdentity.name,
            avatar: objectIdentity.image
        };
        return modifiedResult;

        // return authService.getUserIdentity().then((result: any) => {
            
        //     return result;
        // });
        // return authService.getUserIdentity()
    }
};

export default authProvider;
