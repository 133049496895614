import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  Button,
  Stack,
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  Checkbox,
} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { styled } from "@mui/system";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  FunctionField,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import HttpDataService from "../../../dataProvider/http.service";
import Image from "../../../component/Image";

// call api
import * as request from "../../../utils/httpRequest";

import * as projectService from "../../../Services/projectService";
import DialogSubmitLoading from "../../../component/DialogSubmitLoading";

const Wrapper = styled(Box)({});

const HeaderWrapper = styled(Box)({
  backgroundColor: "whitesmoke",
});

import classnames from "classnames/bind";
import styleImage from "../../Project.module.scss";

const cx = classnames.bind(styleImage);

const ContentWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "30px 0",
});

const WarningText = styled(Typography)({
  color: "#ed6c02",
  fontSize: "1.5rem",
});

const ButtonWrapper = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  backgroundColor: "whitesmoke",
  padding: 20,
});

type Props = {
  isOpen: boolean;
  lstProjectID: Array<number>;
  onClose: () => void;
};

function ExportPDF({ isOpen, lstProjectID, onClose }: Props) {
  const [submitLoading, setSubmitLoading] = React.useState<boolean>(false);
  const [dataFloor, setDataFloor] = useState<any>([]);
  const [lstCheckedFloor, setLstCheckedFloor] = useState<Array<number>>([]);
  const [finalSelectedImgIds, setFinalSelectedImgIds] = useState<number[]>([]);
  const [lstCheckedImg, setLstCheckedImg] = useState<{
    [key: number]: number[];
  }>({});

  useEffect(() => {
    const fetchAPI = async () => {
      await HttpDataService.create("project_estate/get-floor-by-project", {
        id: lstProjectID,
      })
        .then((response: any) => {
          if (response.data.data) {
            setDataFloor(response.data.data);
          }
        })
        .catch((e: Error) => {
          console.log(e);
        });
    };

    if (isOpen) fetchAPI();
  }, [isOpen]);

  const handleCheckboxChange = (event: any, record: any) => {
    const { checked } = event.target;

    if (checked) {
      setLstCheckedFloor((prev: any) => {
        const lstNew: any = [...prev, record.id];
        return lstNew;
      });
    } else {
      setLstCheckedFloor((prev: any) => {
        const lstNew: any = prev.filter((x: any) => x != record.id);
        return lstNew;
      });
    }
  };

  const handleCheckboxImgChange = (
    event: any,
    imgId: number,
    proId: number
  ) => {
    const { checked } = event.target;

    setLstCheckedImg((prev) => {
      const selectedImages = prev[proId] || [];

      if (checked) {
        if (selectedImages.length >= 2) {
          alert("Chỉ được chọn tối đa 2 ảnh.");
          return prev;
        }

        const updatedList = {
          ...prev,
          [proId]: [...selectedImages, imgId],
        };
        setFinalSelectedImgIds(Object.values(updatedList).flat());
        return updatedList;
      } else {
        const updatedList = {
          ...prev,
          [proId]: selectedImages.filter((id) => id !== imgId),
        };
        setFinalSelectedImgIds(Object.values(updatedList).flat());
        return updatedList;
      }
    });
  };

  const handleExportPDF = () => {
    const check = confirm("Bạn có chắc chắn muốn xuất pdf không ?");
    if (!check) {
      return;
    }
    setSubmitLoading(true);
    request
      .post("/project_estate/exportPDF", {
        ids: lstProjectID,
        floor_id: lstCheckedFloor,
        image_ids: finalSelectedImgIds,
      })
      .then((res: any) => {
        const str = String(res.data?.data.code).startsWith("2");

        if (str) {
          const link = document.createElement("a");
          link.href = `${process.env.REACT_APP_BASE_END_POINT_URL}${res?.data.data.file}`;
          link.target = "_blank";
          link.download = `${process.env.REACT_APP_BASE_END_POINT_URL}${res?.data.data.file}`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          onClose();
          setFinalSelectedImgIds([]);
          setLstCheckedFloor([]);
          setLstCheckedImg({});
          setSubmitLoading(false);

          // request
          // 	.get(`${process.env.REACT_APP_BASE_END_POINT_URL}${res?.data.data.file}`, {
          // 		headers: {
          // 			"Content-Type": "application/pdf",
          // 		},
          // 	})
          // 	.then((res) => res.blob())
          // 	.then((blob) => {
          // 		// Create blob link to download
          // 		const url = window.URL.createObjectURL(new Blob([blob]));
          // 		const link: any = document.createElement("a");
          // 		link.href = url;
          // 		link.setAttribute("download", res?.data.data.file_name);

          // 		// Append to html link element page
          // 		document.body.appendChild(link);

          // 		// Start download
          // 		link.click();

          // 		// Clean up and remove the link
          // 		link.parentNode.removeChild(link);
          // 	});
        }
      })
      .catch((err: any) => {
        console.log("err", err);
        setSubmitLoading(false);
      });
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth={"md"}>
      <Wrapper>
        {submitLoading ? (
          <DialogSubmitLoading />
        ) : (
          <>
            <HeaderWrapper>
              <DialogTitle>Chọn tầng</DialogTitle>
            </HeaderWrapper>
            <ContentWrapper mx={3}>
              {dataFloor.length > 0 &&
                dataFloor.map((_pro: any, index: number) => {
                  return (
                    <Box key={index} sx={{ mt: 2 }}>
                      <Typography
                        sx={{ marginBottom: 1, fontSize: 17, fontWeight: 500 }}
                      >
                        {`${index + 1}. ${_pro.name_project}`}
                      </Typography>
                      <span>Chọn ảnh:</span>
                      <div className={cx("lst-img-floor")}>
                        {_pro.image_project.map((_img: any, K: number) => {
                          return (
                            <div key={K} className={cx("item-floor")}>
                              <Image
                                src={_img?.image}
                                alt={""}
                                className={cx("item-img-floor")}
                              />
                              <Checkbox
                                size="small"
                                className={cx("item-checkbox-floor")}
                                onChange={(e) =>
                                  handleCheckboxImgChange(
                                    e,
                                    _img.id,
                                    _pro.project_estate_id
                                  )
                                }
                                checked={
                                  lstCheckedImg[
                                    _pro.project_estate_id
                                  ]?.includes(_img.id) || false
                                }
                              />
                            </div>
                          );
                        })}
                      </div>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ textAlign: "center" }}>
                              #
                            </TableCell>
                            <TableCell>{"Tên tầng"}</TableCell>
                            <TableCell>{"Diện tích"}</TableCell>
                            <TableCell>{"Giá"}</TableCell>
                            <TableCell>{"Phí dịch vụ"}</TableCell>
                            <TableCell>{"Trạng thái"}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {_pro.floors.length > 0 &&
                            _pro.floors.map((_floor: any, k: number) => {
                              return (
                                <TableRow key={k}>
                                  <TableCell>
                                    <Checkbox
                                      size="small"
                                      onChange={(e) =>
                                        handleCheckboxChange(e, _floor)
                                      }
                                    />
                                  </TableCell>
                                  <TableCell>{_floor.name}</TableCell>
                                  <TableCell>{_floor.area}</TableCell>
                                  <TableCell>{_floor.price}</TableCell>
                                  <TableCell>{_floor.service}</TableCell>
                                  <TableCell>
                                    {_floor.status == 1 ? (
                                      <Chip
                                        label="Chưa cho thuê"
                                        color="success"
                                        variant="outlined"
                                      />
                                    ) : (
                                      <Chip
                                        label="Đã cho thuê"
                                        color="error"
                                        variant="outlined"
                                      />
                                    )}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </Box>
                  );
                })}
            </ContentWrapper>
            <ButtonWrapper>
              <Stack direction="row" spacing={2}>
                <Button variant="outlined" color="error" onClick={onClose}>
                  Đóng
                </Button>

                <Button
                  color="error"
                  variant="contained"
                  disabled={lstCheckedFloor.length <= 0}
                  startIcon={<FileDownloadIcon />}
                  onClick={handleExportPDF}
                >
                  Xuất PDF
                </Button>
              </Stack>
            </ButtonWrapper>
          </>
        )}
      </Wrapper>
    </Dialog>
  );
}

export default React.memo(ExportPDF);
