import AddIcon from "@mui/icons-material/Add";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import React, { useState } from "react";
import { FilterLiveSearch, useTranslate } from "react-admin";
import {
  List,
  Datagrid,
  DatagridProps,
  useListContext,
  TextField,
  FunctionField,
  useRefresh,
  useNotify,
} from "react-admin";
import {
  Box,
  Button,
  Chip,
  IconButton,
  Typography,
  FormControl,
  FormControlLabel,
  Select,
  MenuItem,
} from "@mui/material";
import HttpDataService from "../dataProvider/http.service";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Form } from "react-final-form";
import { VideoDelete } from "./VideoDelete";
import { VideoAdd } from "./VideoAdd";
import { VideoEdit } from "./VideoEdit";
import { commonFunction } from "../component/Common";
import PaginationCustom from "../component/PaginationCustom";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import moment from "moment";

const Item = styled(Paper)(({ theme }) => ({
  textAlign: "right",
}));

export const VideoList = (props: any) => {
  
  const refresh = useRefresh();
  const PostPagination = (props: any) => <PaginationCustom {...props} />;
  const translate = useTranslate();
  return (
    <Box className="header-channel-sales">
      <Box className={"box_header"}>
        <Typography className={"title_header"}>{"Video"}</Typography>
      </Box>
      <div className={`header-top-detail header-top-tdn`}>
        <List
          {...props}
          actions={false}
          empty={false}
          exporter={false}
          filters={<FilterAndActions />}
          sort={{ field: "", order: "" }}
          pagination={<PostPagination />}
          perPage={50}
        >
          <VideoDatagrid {...props} />
        </List>
      </div>
    </Box>
  );
};

interface ButtonProps {
  basePath?: string;
  label: string;
  record?: any;
}

const EditButton = (props: any) => {
  const [onCloseDialog, setOpenEditDialog] = useState(false);
  return (
    <>
      <IconButton
        style={{ padding: "0px" }}
        onClick={() => setOpenEditDialog(true)}
      >
        <EditIcon className={"btn_edit"} />
      </IconButton>
      {onCloseDialog && (
        <VideoEdit
          {...props}
          openDialog={onCloseDialog}
          onCloseDialog={() => setOpenEditDialog(false)}
        />
      )}
    </>
  );
};

const DeleteButton = (props: any) => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  return (
    <>
      <IconButton
        style={{ padding: "0px" }}
        onClick={() => setOpenDeleteDialog(true)}
      >
        <DeleteIcon className={"btn_del"} />
      </IconButton>
      <VideoDelete
        openDialog={openDeleteDialog}
        onCloseDialog={() => setOpenDeleteDialog(false)}
      />
    </>
  );
};

const RowActions = ({ record }: ButtonProps) => {
  const per_edit = commonFunction.checkPermission("VIDEO_UPDATE");
  const per_delete = commonFunction.checkPermission("VIDEO_DELETE");
  return (
    <Box>
      {per_edit && <EditButton />}
      {per_delete && <DeleteButton />}
    </Box>
  );
};

const FilterAndActions = (props: any) => {
  const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);
  const translate = useTranslate();
  return (
    <Box
      sx={{
        p: 1,
        m: 1,
        bgcolor: "background.paper",
        borderRadius: 1,
      }}
      className={"box_search"}
    >
      <Item className="shadow-none">
        <Form onSubmit={() => undefined}>
          {() => (
            <FilterLiveSearch
              variant="outlined"
              source="name"
              label="resources.common.fields.search"
            />
          )}
        </Form>
      </Item>
      <Item className="shadow-none">
        {commonFunction.checkPermission("VIDEO_ADD") && (
          <>
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              color="primary"
              onClick={() => setOpenCreateDialog(true)}
            >
              {translate("resources.common.fields.add")}
            </Button>
            {openCreateDialog && (
              <VideoAdd
                openDialog={openCreateDialog}
                onCloseDialog={() => setOpenCreateDialog(false)}
              />
            )}
          </>
        )}
      </Item>
    </Box>
  );
};

const VideoDatagrid = (props: DatagridProps) => {
  // @ts-ignore
  const refresh = useRefresh();
  const notify = useNotify();

  const handleChangeProjectCustomStatus = async (
    event: any,
    idRecord: Number
  ) => {
    let data = {
      status: event.target.value,
    };
    console.log(idRecord, data);
    await HttpDataService.update(`video/changeStatusVideo/${idRecord}`, data)
      .then((response: any) => {
        if (response.data.status) {
          // @ts-ignore
          notify(response.data.message, { type: "success" });
        } else {
          // @ts-ignore
          notify(response.data.message, { type: "error" });
        }
      })

      .catch((e: Error) => {
        console.log(e);
      });
    refresh();
  };
  const { page, perPage } = useListContext();
  const translate = useTranslate();
  return (
    <>
      <div className={`list-data-tdn`}>
        <Datagrid optimized bulkActionButtons={false}>
          <TextField source={"name"} label={"Tiêu đề"} />
          <FunctionField
            label={"Video"}
            render={(record: any) => {
              return (
                <a href={record.link} target="_blank">
                  <PlayArrowIcon />
                </a>
              );
            }}
          />
          <TextField source={"created_name"} label={"Người đăng"} />
          <TextField source={"updated_name"} label={"Người cập nhật"} />

          <FunctionField
            label={"Thời gian cập nhật"}
            render={(record: any) => {
              return moment(record?.updated_at).format("DD/MM/YYYY");
            }}
          />

          {!commonFunction.checkPermission("VIDEO_CHANGE_STATUS") ? (
            <FunctionField
              label={"Tình trạng"}
              render={(record: any) => {
                if (record.status === 1) {
                  return (
                    <Chip
                      label={"Đã duyệt"}
                      color="success"
                      variant="outlined"
                    />
                  );
                } else if (record.status === 2) {
                  return (
                    <Chip
                      label={"Không duyệt"}
                      color="error"
                      variant="outlined"
                    />
                  );
                } else {
                  return (
                    <Chip
                      label={"Chưa duyệt"}
                      color="warning"
                      variant="outlined"
                    />
                  );
                }
              }}
            />
          ) : (
            <FunctionField
              label={"Tình trạng"}
              render={(record: any) => {
                return (
                  <FormControlLabel
                    control={
                      <FormControl variant="standard">
                        <Select
                          value={record?.status}
                          onChange={(e) =>
                            handleChangeProjectCustomStatus(e, record.id)
                          }
                          sx={{
                            ...(record?.status === 1
                              ? { color: "green" }
                              : record?.status === 2
                              ? { color: "red" }
                              : { color: "blue" }),
                          }}
                        >
                          <MenuItem style={{ color: "blue" }} value={0}>
                            Chưa duyệt
                          </MenuItem>
                          <MenuItem style={{ color: "green" }} value={1}>
                            Đã duyệt
                          </MenuItem>
                          <MenuItem style={{ color: "red" }} value={2}>
                            Không duyệt
                          </MenuItem>
                        </Select>
                      </FormControl>
                    }
                    label=""
                    labelPlacement="end"
                  />
                );
              }}
            />
          )}
          <RowActions label={translate("resources.common.fields.action")} />
        </Datagrid>
      </div>
    </>
  );
};
