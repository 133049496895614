import React, { useEffect, useState } from "react";

type Props = {
	name: string;
	onChangeData: (content: string) => void;
};

declare global {
	interface Window {
		CKEDITOR: any;
		CKFinder: any;
	}
}

const CkeditorServer = ({ name, onChangeData }: Props) => {
	let CKEDITOR: any;

	useEffect(() => {
		CKEDITOR = window.CKEDITOR.replace(name, {});

		if (CKEDITOR) {
			CKEDITOR.on("change", (evt: any) => {
				onChangeData(evt.editor.getData());
			});

			// config
			CKEDITOR.config.uploadUrl = `https://cms.tektrahomes.vn/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Files&responseType=json`;

			CKEDITOR.config.filebrowserBrowseUrl = `https://cms.tektrahomes.vn/ckfinder/ckfinder.html`;

			CKEDITOR.config.filebrowserImageBrowseUrl = `https://cms.tektrahomes.vn/ckfinder/ckfinder.html?type=Images`;

			CKEDITOR.config.filebrowserFlashBrowseUrl = `https://cms.tektrahomes.vn/ckfinder/ckfinder.html?type=Flash`;

			CKEDITOR.config.filebrowserFlashBrowseUrl = `https://cms.tektrahomes.vn/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Files`;

			CKEDITOR.config.filebrowserImageUploadUrl = `https://cms.tektrahomes.vn/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Images`;

			CKEDITOR.config.filebrowserFlashUploadUrl = `https://cms.tektrahomes.vn/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Flash`;
		}
	}, []);

	return (
		<textarea
			id={name}
			name={name}
		></textarea>
	);
};

export default CkeditorServer;
