import {Autocomplete, Dialog, DialogContent, DialogTitle, IconButton, TextField} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Box from "@mui/material/Box";
import React, {useState, useEffect} from "react";
import Button from "@mui/material/Button";
import SaveIcon from '@mui/icons-material/Save';
import ReplyIcon from '@mui/icons-material/Reply';
import Toolbar from "@mui/material/Toolbar";
import {useNotify, useRefresh, useRecordContext, EditProps, useTranslate} from "react-admin";
import HttpDataService from "../dataProvider/http.service";
import DeleteIcon from '@mui/icons-material/Delete';
import axios from "axios";
import { constants } from "../shared/constants";


interface Add extends EditProps {
    openDialog: boolean;
    onCloseDialog: (openDialog: boolean) => void;
}

export const MenuDelete = ({
    id,
    openDialog,
    onCloseDialog,
    ...rest
}: Add) => {
    const refresh = useRefresh();
    const notify = useNotify();
    const translate =useTranslate();
    /**
     * handle save
     */
    const context  = useRecordContext()
    const handleSave = () => {
        
            HttpDataService.delete('menu_admin/delete', id as string).then( async(response: any) =>
            {
                if(response.data.status) {
                    // @ts-ignore
                    const authorizationToken = localStorage.getItem(constants.LOCAL_STORAGE.TOKEN)
                    const { data } = await axios.get(`${process.env.REACT_APP_BASE_MODULE_URL}/menu_admin/getAll`,
                    {
                        headers: {
                            Authorization: `Bearer ${authorizationToken}`,   
                        }
                    })
                    const { items } = data?.data;
                    localStorage.setItem(
                        constants.LOCAL_STORAGE.LIST_MENU,
                        JSON.stringify(items)
                    )
                    window.location.reload();
                    notify(response.data.message,{type: 'success'});
                } else {
                    // @ts-ignore
                    notify(response.data.message,{type: 'error'});
                }
            })
            .catch((e: Error) => {
                console.log(e);
            });
        onCloseDialog(false);
        refresh();
    }

    const handleCloseDialog = () => {
        onCloseDialog(false);
    }

    return (
        <>
            <Dialog
                open={openDialog}
                fullWidth
                PaperProps={{
                    style: {
                        borderRadius: 2,
                        // minWidth: '85%',
                    },
                }}
            >
                <div className={'common__dialogTitle_area'}>
                    <DialogTitle className={'member__dialogTitle'}>
                        <div>
                            {translate('resources.menu.del_menu')}
                        </div>
                    </DialogTitle>
                    <div className={'common__dialogTitle_actions'}>
                        <IconButton
                            aria-label="exists"
                            color={'error'}
                            onClick={() => {
                                onCloseDialog(false);
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
                <Box className={'dialog_box'}>
                    <DialogContent className={'dialog_content'}>
                        <div className={'content-delete'} >
                            <p className={'alert-delete'}>{translate('resources.common.fields.question_delete')}</p>
                            <p className={'main-delete'}>{translate('resources.common.fields.main_delete')}</p>
                        </div>
                    </DialogContent>
                </Box>
                <Toolbar>
                    <Box
                        display="flex"
                        justifyContent="center"
                        width={'100%'}
                    >
                        <Button variant="contained"
                                startIcon={<DeleteIcon/>} onClick={handleSave}>{translate('resources.common.fields.agree')}</Button>
                        &nbsp;&nbsp;
                        <Button
                            startIcon={<ReplyIcon/>}
                            className={'button-close'}
                            variant="contained"
                            onClick={handleCloseDialog}
                        >
                            {translate('resources.common.fields.cancel')}
                        </Button>
                    </Box>
                </Toolbar>
            </Dialog>
        </>
    );
}