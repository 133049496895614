import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/system';
import Paper from '@mui/material/Paper';
import React, { useEffect, useState } from 'react';
import {
    EditProps,
    FilterLiveSearch,
    useNotify,
    useRecordContext,
    useRefresh,
    useTranslate,
} from 'react-admin';
import HttpDataService from '../dataProvider/http.service';
import {
    Pagination,
    List,
    Datagrid,
    DatagridProps,
    useListContext,
    FunctionField,
} from 'react-admin';
import {
    Box,
    Button,
    Chip,
    Grid,
    IconButton,
    Typography,
    TextField,
    Autocomplete,
} from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import { Avatar } from '@material-ui/core';
import avatarDefault from '../assets/img/avatar_default.png';
import SaveIcon from '@mui/icons-material/Save';
import { constants } from '../shared/constants';
import { commonFunction } from '../component/Common';
import { Dayjs } from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ChangePassword from './ChangePassword';

import Image from '../component/Image';
import classNames from 'classnames/bind';
import styles from './Profile.module.scss';

const Item = styled(Paper)(({ theme }) => ({
    textAlign: 'right',
}));

const dataSex = [
    { label: '--Lựa chọn--', id: '' },
    { label: 'Nam', id: 1 },
    { label: 'Nữ', id: 2 },
];


const ChangePassWrapper = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
});

const cx = classNames.bind(styles);

export const Profile = () => {
    const translate = useTranslate();
    // const PostPagination = (props: any) => <Pagination rowsPerPageOptions={[]} {...props} />;
    // image
    const [image, setImage] = useState<string>('');
    const [first_name, setFirst_name] = useState<string>('');
    const [first_nameError, setFirst_nameError] = useState(false);
    const [textFirst_nameError, setTextFirst_nameError] = useState('');
    //last_name
    const [last_name, setLast_name] = useState<string>('');
    const [last_nameError, setLast_nameError] = useState(false);
    const [textLast_nameError, setTextLast_nameError] = useState('');
    // Phone
    const [phone, setPhone] = useState<string>('');
    const [phoneError, setPhoneError] = useState(false);
    const [textPhoneError, setTextPhoneError] = useState('');
    // email
    const [email, setEmail] = useState<string>('');
    const [emailError, setEmailError] = useState(false);
    const [textEmailError, setTextEmailError] = useState('');
    //username
    const [username, setUsername] = useState<string>('');
    const [usernameError, setUsernameError] = useState(false);
    const [textUsernameError, setTextUsernameError] = useState('');
    //birthday
    const [birthday, setBirthday] = React.useState<Dayjs | null>(null);

    const [temporary_address, setTemporary_address] = useState<string>('');
    // Địa chỉ thường trú
    const [permanent_address, setPermanent_address] = useState<string>('');
    // Tài khoản ngân hàng
    const [bank, setBank] = useState<string>('');
    // Bảo hiểm xã hội
    const [bhxh, setBhxh] = useState<string>('');
    // Căn cước công dân
    const [cccd, setCccd] = useState<string>('');
    // Nơi cấp
    const [noicap, setNoicap] = useState<string>('');
    // Ngày cấp
    const [ngaycap, setNgaycap] = React.useState<Dayjs | null>(null);
    // Dân tộc
    const [ethnic, setEthnic] = useState<string>('');
    // Email cá nhân
    const [email_ca_nhan, setEmail_ca_nhan] = useState<string>('');
    // Giới tính
    const [sex, setSex] = useState<any>(dataSex[0]);

    //CHANGE PASS DIALOG
    const [changePassOpen, setChangePassOpen] = useState<boolean>(false);

    /**
     * handle change name
     * @param e
     */
    // first_name
    const handleFirst_nameChange = (e: any) => {
        setFirst_nameError(false);
        setTextFirst_nameError('');
        setFirst_name(e.target.value);
    };
    // last_name
    const handleLast_nameChange = (e: any) => {
        setLast_nameError(false);
        setTextLast_nameError('');
        setLast_name(e.target.value);
    };
    // email
    const handleEmailChange = (e: any) => {
        setUsernameError(false);
        setTextEmailError('');
        setEmail(e.target.value);
    };

    // username
    const handleUsernameChange = (e: any) => {
        setEmailError(false);
        setTextUsernameError('');
        setUsername(e.target.value);
    };
    // phone
    const handlePhoneChange = (e: any) => {
        setPhone(e.target.value);
    };

    // Email cá nhân
    const handleEmail_ca_nhan = (e: any) => {
        setEmail_ca_nhan(e.target.value);
    };
    // birthday
    const handleBirthdayChange = (e: any) => {
        setBirthday(e.target.value);
    };
    // Dân tộc
    const handleEthnicChange = (e: any) => {
        setEthnic(e.target.value);
    };
    // Địa chỉ tạm trú
    const handleTemporary_addressChange = (e: any) => {
        setTemporary_address(e.target.value);
    };
    // Địa chỉ thường trú
    const handlePermanent_addressChange = (e: any) => {
        setPermanent_address(e.target.value);
    };
    // Tài khoản ngân hàng
    const handleBankChange = (e: any) => {
        setBank(e.target.value);
    };
    // Bảo hiểm xã hội
    const handleBhxh = (e: any) => {
        setBhxh(e.target.value);
    };
    // Căn cước công dân
    const handleCccd = (e: any) => {
        setCccd(e.target.value);
    };
    // Nơi cấp
    const handleNoicap = (e: any) => {
        setNoicap(e.target.value);
    };
    // Ngày cấp
    const handleNgaycap = (e: any) => {
        setNgaycap(e.target.value);
    };
    // Giới tính
    const handleSex = (e: any) => {
        setSex(e);
    };

    const notify = useNotify();

    // ########### Get By Id ###############
    useEffect(() => {
        const res_record = JSON.parse(
            localStorage.getItem(constants.LOCAL_STORAGE.USER_IDENTITY) as string,
        );
        // const res_record = response.data.data;
        setFirst_name(res_record.first_name);
        setLast_name(res_record.last_name);
        setEmail(res_record.email);
        setPhone(res_record.phone);
        setUsername(res_record.username);
        setTemporary_address(res_record.temporary_address);
        setPermanent_address(res_record.permanent_address);
        setEmail_ca_nhan(res_record.email_ca_nhan);
        setEthnic(res_record.ethnic);
        setCccd(res_record.cccd);
        setNoicap(res_record.noicap);
        setBhxh(res_record.bhxh);
        setBank(res_record.bank);
        setBirthday(res_record.birthday);
        setNgaycap(res_record.ngaycap);
        const find_sex = dataSex.find((s) => s.id == res_record.sex);
        setSex(find_sex);
        setImage(res_record.image ? res_record.image : avatarDefault);
    }, []);

    /**
     * handle save
     */
    const handleSave = () => {
        if (!fnValidation()) {
            let data = {
                // id: record?.id,
                first_name: first_name.trim(),
                last_name: last_name.trim(),
                username: username.trim(),
                email: email.trim(),
                phone: phone,
                ethnic: ethnic,
                temporary_address: temporary_address,
                bank: bank,
                bhxh: bhxh,
                noicap: noicap,
                ngaycap: ngaycap,
                cccd: cccd,
                permanent_address: permanent_address,
                email_ca_nhan: email_ca_nhan,
                birthday: birthday,
                sex: sex.id,
            };

            HttpDataService.update('profile', data)
                .then((response: any) => {
                    console.log(response);
                    if (response.data.status) {
                        // @ts-ignore
                        notify(response.data.message, { type: 'success' });

                        commonFunction.getCurrentUserProfile();
                    } else {
                        // @ts-ignore
                        notify(response.data.message, { type: 'error' });
                    }
                })
                .catch((e: Error) => {
                    console.log(e);
                });
            //
            // fnResetFrm();
            // onCloseDialog(false);
            // refresh();
        }
    };

    /**
     * fn validation form
     */
    function fnValidation() {
        let error = false;
        if (first_name.trim().length === 0) {
            error = true;
            setFirst_nameError(true);
            setTextFirst_nameError(translate('resources.user.fields.val_first_name'));
        }
        if (last_name.trim().length === 0) {
            error = true;
            setLast_nameError(true);
            setTextLast_nameError(translate('resources.user.fields.val_last_name'));
        }
        if (email.trim().length === 0) {
            error = true;
            setEmailError(true);
            setTextEmailError(translate('resources.user.fields.val_email'));
        }
        // if (username.trim().length === 0) {
        //     error = true;
        //     setUsernameError(true);
        //     setTextUsernameError('Tài khoản đăng nhập không được để trống')
        // }

        return error;
    }

    return (
        <Box className="header-channel-sales">
            <Box className={'box_header'}>
                <Typography className={'title_header'}>
                    {translate('resources.user.info_user')}
                </Typography>
            </Box>
            <Box className={'box_profile'}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={3}>
                        <div className={`avatar-profile`}>
                            {/* <img src={image} width="219px" height="214px" /> */}
                            <Image src={image} alt="" className={cx('avatar')} />
                        </div>
                        <ChangePassWrapper>
                            <Button
                                variant="contained"
                                onClick={() => setChangePassOpen(true)}
                            >
                                Đổi mật khẩu
                            </Button>
                            <ChangePassword
                                isOpen={changePassOpen}
                                onClose={() => setChangePassOpen(false)}
                            />
                        </ChangePassWrapper>
                    </Grid>
                    <Grid item xs={9}>
                        <Grid
                            container
                            rowSpacing={1}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        >
                            <Grid item xs={6}>
                                <Item style={{border:"none"}}>
                                    <TextField
                                        fullWidth
                                        label={
                                            <span className={'span_style'}>
                                                {translate(
                                                    'resources.user.fields.first_name_user',
                                                )}
                                                <span style={{ color: 'red' }}>(*)</span>
                                            </span>
                                        }
                                        value={first_name}
                                        error={first_nameError}
                                        helperText={textFirst_nameError}
                                        onChange={handleFirst_nameChange}
                                        variant="outlined"
                                    />
                                </Item>
                                <Item style={{border:"none"}}>
                                    <TextField
                                        fullWidth
                                        label={
                                            <span className={'span_style'}>
                                                {translate(
                                                    'resources.user.fields.last_name_user',
                                                )}
                                                <span style={{ color: 'red' }}>(*)</span>
                                            </span>
                                        }
                                        value={last_name}
                                        error={last_nameError}
                                        helperText={textLast_nameError}
                                        onChange={handleLast_nameChange}
                                        variant="outlined"
                                    />
                                </Item>
                                <Item style={{border:"none"}}>
                                    <TextField
                                        fullWidth
                                        label={
                                            <span className={'span_style'}>
                                                {translate('resources.user.fields.phone')}
                                            </span>
                                        }
                                        value={phone}
                                        error={phoneError}
                                        helperText={textPhoneError}
                                        onChange={handlePhoneChange}
                                        variant="outlined"
                                        type="number"
                                    />
                                </Item>
                                <Item style={{border:"none"}}>
                                    <TextField
                                        fullWidth
                                        label={
                                            <span className={'span_style'}>
                                                {translate(
                                                    'resources.user.fields.email_user',
                                                )}
                                                <span style={{ color: 'red' }}>(*)</span>
                                            </span>
                                        }
                                        value={email}
                                        error={emailError}
                                        helperText={textEmailError}
                                        onChange={handleEmailChange}
                                        variant="outlined"
                                    />
                                </Item>
                                <Item style={{border:"none"}}>
                                    <TextField
                                        fullWidth
                                        label={
                                            <span className={'span_style'}>
                                                {translate(
                                                    'resources.user.fields.personal_email_user',
                                                )}
                                            </span>
                                        }
                                        value={email_ca_nhan}
                                        onChange={handleEmail_ca_nhan}
                                        variant="outlined"
                                    />
                                </Item>
                                <Item style={{border:"none"}}>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                        adapterLocale={'vi'}
                                    >
                                        <DatePicker
                                            label={
                                                <span className={'span_style'}>
                                                    {translate(
                                                        'resources.user.fields.birthday_user',
                                                    )}
                                                </span>
                                            }
                                            value={birthday}
                                            inputFormat="DD/MM/YYYY"
                                            onChange={(newValue) => {
                                                setBirthday(newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    variant="outlined"
                                                />
                                            )}
                                            className={'aaaa'}
                                        />
                                    </LocalizationProvider>
                                </Item>
                                <Item style={{border:"none"}}>
                                    <TextField
                                        fullWidth
                                        label={
                                            <span className={'span_style'}>
                                                {translate(
                                                    'resources.user.fields.ethnic_user',
                                                )}
                                            </span>
                                        }
                                        value={ethnic}
                                        onChange={handleEthnicChange}
                                        variant="outlined"
                                    />
                                </Item>
                                <Item style={{border:"none"}}>
                                    <Autocomplete
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label={
                                                    <span className={'span_style'}>
                                                        {translate(
                                                            'resources.user.fields.sex_user',
                                                        )}
                                                    </span>
                                                }
                                            />
                                        )}
                                        sx={{ width: '100%' }}
                                        value={sex}
                                        onChange={(event, value) => handleSex(value)}
                                        options={dataSex}
                                    />
                                </Item>
                            </Grid>
                            <Grid item xs={6}>
                                <Item style={{border:"none"}}>
                                    <TextField
                                        fullWidth
                                        label={
                                            <span className={'span_style'}>
                                                {translate(
                                                    'resources.user.fields.dctt_user',
                                                )}
                                            </span>
                                        }
                                        value={temporary_address}
                                        onChange={handleTemporary_addressChange}
                                        variant="outlined"
                                    />
                                </Item>
                                <Item style={{border:"none"}}>
                                    <TextField
                                        fullWidth
                                        label={
                                            <span className={'span_style'}>
                                                {translate(
                                                    'resources.user.fields.dcttru_user',
                                                )}
                                            </span>
                                        }
                                        value={permanent_address}
                                        onChange={handlePermanent_addressChange}
                                        variant="outlined"
                                    />
                                </Item>

                                <Item style={{border:"none"}}>
                                    <TextField
                                        fullWidth
                                        label={
                                            <span className={'span_style'}>
                                                {translate('resources.user.fields.bank')}
                                            </span>
                                        }
                                        value={bank}
                                        onChange={handleBankChange}
                                        variant="outlined"
                                        type="number"
                                    />
                                </Item>
                                <Item style={{border:"none"}}>
                                    <TextField
                                        fullWidth
                                        label={
                                            <span className={'span_style'}>
                                                {translate('resources.user.fields.bhxh')}
                                            </span>
                                        }
                                        value={bhxh}
                                        onChange={handleBhxh}
                                        variant="outlined"
                                    />
                                </Item>
                                <Item style={{border:"none"}}>
                                    <TextField
                                        fullWidth
                                        label={
                                            <span className={'span_style'}>
                                                {translate(
                                                    'resources.user.fields.id_user',
                                                )}
                                            </span>
                                        }
                                        value={cccd}
                                        onChange={handleCccd}
                                        variant="outlined"
                                        type="number"
                                    />
                                </Item>
                                <Item style={{border:"none"}}>
                                    <TextField
                                        fullWidth
                                        label={
                                            <span className={'span_style'}>
                                                {translate(
                                                    'resources.user.fields.noicap',
                                                )}
                                            </span>
                                        }
                                        value={noicap}
                                        onChange={handleNoicap}
                                        variant="outlined"
                                    />
                                </Item>
                                <Item style={{border:"none"}}>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                        adapterLocale={'vi'}
                                    >
                                        <DatePicker
                                            label={
                                                <span className={'span_style'}>
                                                    {translate(
                                                        'resources.user.fields.ngaycap',
                                                    )}
                                                </span>
                                            }
                                            value={ngaycap}
                                            inputFormat="DD/MM/YYYY"
                                            onChange={(newValue) => {
                                                setNgaycap(newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    variant="outlined"
                                                />
                                            )}
                                            className={'ngaycap'}
                                        />
                                    </LocalizationProvider>
                                </Item>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Toolbar>
                    <Box display="flex" justifyContent="center" width={'100%'}>
                        <Button
                            variant="contained"
                            startIcon={<SaveIcon />}
                            onClick={handleSave}
                        >
                            {translate('resources.common.fields.update')}
                        </Button>
                    </Box>
                </Toolbar>
            </Box>
        </Box>
    );
};

interface ButtonProps {
    basePath?: string;
    label: string;
    record?: any;
}
