import {
  Avatar,
  Box,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import CustomerIcon from "@mui/icons-material/PersonAdd";
import { Link } from "react-router-dom";
import CardWithIcon from "./CardWithIcon";
import { Customer } from "../types";
const NewCustomers = (props: any) => {
  const listCustomer = props;
  return (
    <CardWithIcon
      to="/customer"
      icon={CustomerIcon}
      title={"Số lượng khách hàng"}
      subtitle={listCustomer?.data.length}
    >
      <List>
        {listCustomer?.data.map((record: Customer) => (
          <ListItem key={record.id}>
            <ListItemAvatar>
              <Avatar src={record.image} />
            </ListItemAvatar>
            <ListItemText primary={` ${record.name}`} />
          </ListItem>
        ))}
      </List>
    </CardWithIcon>
  );
};

export default NewCustomers;
