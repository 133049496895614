import React from 'react';
import { InputRowWrapper, ItemWrapper, MultilineWrapper } from '../styledMui';

import { Autocomplete, TextField } from '@mui/material';

import { MUC_DICH_SD, TRANG_THAI } from '../../../shared/status';
import InputLabel from '../../../component/InputLabel';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import DialogForm from '../../../component/DialogForm';
import { createStyles, makeStyles } from '@material-ui/core/styles';

//Reformat Style
const styles = makeStyles((theme: any) =>
    createStyles({
        root: {
            '& .css-k4qjio-MuiFormHelperText-root.Mui-error':{
                position: 'absolute',
                paddingTop: '41px',
            },
            '& .css-1z10yd4-MuiFormControl-root-MuiTextField-root':{
                marginTop: 20,
                marginBottom: 20,
            }
        }
    }));


type Props = {
    isOpen: boolean;
    onClose: () => void;
    submitData: any;
};

type InitialType = {
    ma_lo: string;
    chieu_sau: string;
    mat_tien: string;
    dien_tich: string;
    mat_duong: string;
    gia_tri: string;
    muc_dich: {
        id: number;
        label: string;
    };
    trang_thai: {
        id: number;
        label: string;
    };
    ghi_nhan: string;
};

function ProjectDetail({ isOpen, onClose, submitData }: Props) {
    const classes = styles() 
    //HOOKS
    const {
        register,
        handleSubmit,
        reset,
        control,
        formState: { errors },
    } = useForm<InitialType>();

    React.useEffect(() => {
        if (!isOpen) {
            reset();
        }
    }, [isOpen]);

    const onSubmit: SubmitHandler<InitialType> = (data) => {
        submitData(data);
        reset();
        onClose();
    };

    return (
        <DialogForm
            size="md"
            title="Thêm bản ghi"
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={handleSubmit(onSubmit)}
        >
            <React.Fragment>
                <InputRowWrapper>
                    <ItemWrapper>
                        <TextField
                            className={classes.root}
                            fullWidth
                            variant="outlined"
                            label={<InputLabel title="Mã lô" required />}
                            error={Boolean(errors?.ma_lo)}
                            helperText={errors?.ma_lo && 'Mã lô không được bỏ trống!'}
                            {...register('ma_lo', {
                                required: true,
                            })}
                        />
                    </ItemWrapper>
                    <ItemWrapper>
                        <TextField
                            className={classes.root}
                            fullWidth
                            variant="outlined"
                            label={<InputLabel title="Chiều sâu" required />}
                            type="number"
                            error={Boolean(errors?.chieu_sau)}
                            helperText={
                                errors?.chieu_sau?.type === 'required'
                                    ? 'Chiều sâu không được bỏ trống!'
                                    : errors?.chieu_sau?.type === 'min' &&
                                      'Chiều sâu phải lớn hơn hoặc bằng 0'
                            }
                            {...register('chieu_sau', {
                                required: true,
                                min: 0,
                            })}
                        />
                    </ItemWrapper>
                </InputRowWrapper>
                <InputRowWrapper>
                    <ItemWrapper>
                        <TextField
                            className={classes.root}
                            fullWidth
                            variant="outlined"
                            label={<InputLabel title="Mặt tiền" required />}
                            type="number"
                            error={Boolean(errors?.mat_tien)}
                            helperText={
                                errors?.mat_tien?.type === 'required'
                                    ? 'Mặt tiền không được bỏ trống!'
                                    : errors?.mat_tien?.type === 'min' &&
                                      'Mặt tiền phải lớn hơn hoặc bằng 0'
                            }
                            {...register('mat_tien', {
                                required: true,
                                min: 0,
                            })}
                        />
                    </ItemWrapper>
                    <ItemWrapper>
                        <TextField
                            className={classes.root}
                            fullWidth
                            variant="outlined"
                            label={<InputLabel title="Diện tích" required />}
                            type="number"
                            error={Boolean(errors?.dien_tich)}
                            helperText={
                                errors?.dien_tich?.type === 'required'
                                    ? 'Diện tích không được bỏ trống!'
                                    : errors?.dien_tich?.type === 'min' &&
                                      'Diện tích phải lớn hơn hoặc bằng 0'
                            }
                            {...register('dien_tich', {
                                required: true,
                                min: 0,
                            })}
                        />
                    </ItemWrapper>
                </InputRowWrapper>
                <InputRowWrapper>
                    <ItemWrapper>
                        <TextField
                            className={classes.root}
                            fullWidth
                            variant="outlined"
                            label={<InputLabel title="Đường" required />}
                            type="number"
                            error={Boolean(errors?.mat_duong)}
                            helperText={
                                errors?.mat_duong?.type === 'required'
                                    ? 'Mặt đường không được bỏ trống!'
                                    : errors?.mat_duong?.type === 'min' &&
                                      'Mặt đường phải lớn hơn hoặc bằng 0'
                            }
                            {...register('mat_duong', {
                                required: true,
                                min: 0,
                            })}
                        />
                    </ItemWrapper>
                    <ItemWrapper>
                        <TextField
                            className={classes.root}
                            fullWidth
                            variant="outlined"
                            label={
                                <InputLabel title="Giá trị nhượng quyền(VND)" required />
                            }
                            type="number"
                            error={Boolean(errors?.gia_tri)}
                            helperText={
                                errors?.gia_tri?.type === 'required'
                                    ? 'Giá trị nhượng quyền không được bỏ trống!'
                                    : errors?.gia_tri?.type === 'min' &&
                                      'Giá trị nhượng quyền phải lớn hơn hoặc bằng 0'
                            }
                            {...register('gia_tri', {
                                required: true,
                                min: 0,
                            })}
                        />
                    </ItemWrapper>
                </InputRowWrapper>
                <InputRowWrapper>
                    <ItemWrapper>
                        <Controller
                            
                            name="muc_dich"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                                <Autocomplete
                                    fullWidth
                                    id="combo-box-demo"
                                    options={MUC_DICH_SD}
                                    getOptionLabel={(option) => option.label}
                                    renderInput={(params) => (
                                        <TextField
                                            className={classes.root}
                                            margin="dense"
                                            {...params}
                                            label={
                                                <InputLabel
                                                    title="Mục đích sử dụng"
                                                    required
                                                />
                                            }
                                            variant="outlined"
                                            error={Boolean(errors?.muc_dich)}
                                            helperText={
                                                errors?.muc_dich?.type === 'required' &&
                                                'Mục đích sử dụng không được bỏ trống!'
                                            }
                                        />
                                    )}
                                    onChange={(_, data) => {
                                        onChange(data);
                                        return data;
                                    }}
                                />
                            )}
                        />
                    </ItemWrapper>
                    <ItemWrapper>
                        <Controller
                            
                            name="trang_thai"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                                <Autocomplete
                                    fullWidth
                                    id="combo-box-demo"
                                    options={TRANG_THAI}
                                    getOptionLabel={(option) => option.label}
                                    renderInput={(params) => (
                                        <TextField
                                            className={classes.root}
                                            margin="dense"
                                            {...params}
                                            label={
                                                <InputLabel title="Trạng thái" required />
                                            }
                                            variant="outlined"
                                            error={Boolean(errors?.trang_thai)}
                                            helperText={
                                                errors?.trang_thai?.type === 'required' &&
                                                'Trạng thái không được bỏ trống!'
                                            }
                                        />
                                    )}
                                    onChange={(_, data) => {
                                        onChange(data);
                                        return data;
                                    }}
                                />
                            )}
                        />
                    </ItemWrapper>
                </InputRowWrapper>
                <InputRowWrapper>
                    <MultilineWrapper>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Ghi nhận"
                            multiline
                            rows={6}
                            {...register('ghi_nhan')}
                        />
                    </MultilineWrapper>
                </InputRowWrapper>
            </React.Fragment>
        </DialogForm>
    );
}

export default React.memo(ProjectDetail);
