import {
    DefaultDataProvider,
} from '../default-data-provider';
import {constants} from "../../shared/constants";

export const baseApiUrl = 'general';

export default class GeneralService extends DefaultDataProvider {

}

export const generalService = new GeneralService(
    constants.RESOURCE.GENERAL,
    constants.BASE_MODULE_URL,
    baseApiUrl
);