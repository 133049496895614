export const TRANG_THAI = [
	{
		id: 1,
		label: "Mở bán",
	},
	{
		id: 2,
		label: "Cọc cứng",
	},
	{
		id: 3,
		label: "Locked",
	},
	{
		id: 4,
		label: "Đã ký HĐCN",
	},
	{
		id: 5,
		label: "Locked tạm thời",
	},
	{
		id: 6,
		label: "Khóa",
	},
];
export const MUC_DICH_SD = [
	{
		id: 1,
		label: "ONT",
	},
	{
		id: 2,
		label: "CLN",
	},
	{
		id: 3,
		label: "ONT CLN",
	},
];

export const STATUS = [
	{ id: 0, label: "Khóa" },
	{ id: 1, label: "Đang hoạt động" },
	{ id: 2, label: "Tất cả" },
];

export const STATUS_PROJECT = [
	{ id: 0, label: "Khóa" },
	{ id: 1, label: "Đang hoạt động" },
	{ id: 2, label: "Đang cập nhật" },
	{ id: 3, label: "Tất cả" },
];

export const STATUS_PROJECT_OPTION = [
	{ id: 0, label: "Khóa" },
	{ id: 1, label: "Đang hoạt động" },
	{ id: 2, label: "Đang cập nhật" },
];

export const ISHOT = [
	{ id: 0, label: "Không nổi bật" },
	{ id: 1, label: "Nổi bật" },
	{ id: 2, label: "Tất cả" },
];

export const STATUS_CURRENT = [
	{ id: 0, label: "Chưa cho thuê" },
	{ id: 1, label: "Đã cho thuê" },
	{ id: 2, label: "Tất cả" },
];

export const STATUS_LEASE = [
	{ id: 1, label: "Chưa cho thuê" },
	{ id: 2, label: "Đã cho thuê" },
];

export const TYPE_PRICE_ALL = [
	{ id: 99, label: "Tất cả" },
	{ id: 0, label: "VNĐ" },
	{ id: 1, label: "VNĐ/m2" },
	{ id: 2, label: "$/m2" },
];

export const TYPE_PRICE = [
	{ id: 0, label: "VNĐ" },
	{ id: 1, label: "VNĐ/m2" },
	{ id: 2, label: "$/m2" },
];

export const WORKING = [
	{ label: "Fulltime", id: 1 },
	{ label: "Partime", id: 2 },
];
export const SEX = [
	{ label: "Nam", id: 1 },
	{ label: "Nữ", id: 2 },
];
export const PERMISSION = [
	{ label: "Tất cả", id: 1 },
	{ label: "Giám đốc", id: 2 },
	{ label: "Nhân viên", id: 3 },
];
