import {
  Autocomplete,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import ReplyIcon from "@mui/icons-material/Reply";
import Toolbar from "@mui/material/Toolbar";
import ImagePreview from "../component/ImgPreview";
import Image from "../component/Image";
import {
  useNotify,
  useRefresh,
  useRecordContext,
  EditProps,
  useTranslate,
} from "react-admin";

import {
  Wrapper,
  InputRowWrapper,
  ItemWrapper,
  EditorWrapper,
} from "./styledMui";
// Switch  component
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Editor from "../component/Editor";
import HttpDataService from "../dataProvider/http.service";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import CkeditorServer from "../component/CkeditorServer";
import { fnSetDataCkeditor } from "../utils/fc";

//Reformat Style
const styles = makeStyles((theme: any) =>
  createStyles({
    root: {
      "& .css-k4qjio-MuiFormHelperText-root.Mui-error": {
        position: "absolute",
        paddingTop: "41px",
      },
      "& .css-1z10yd4-MuiFormControl-root-MuiTextField-root": {
        marginTop: 20,
        marginBottom: 20,
      },
    },
  })
);

interface Add extends EditProps {
  openDialog: boolean;
  onCloseDialog: (openDialog: boolean) => void;
}

export const NewsEdit = ({ openDialog, onCloseDialog, ...rest }: Add) => {
  const classes = styles();
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [content, setContent] = useState<string>("");
  const [meta_title, setMetaTitle] = useState<string>("");
  const [meta_description, setMetaDescription] = useState<string>("");
  const [meta_keyword, setMetaKeyword] = useState<string>("");
  const [slug, setSlug] = useState<string>("");

  const [image, setImage] = React.useState<any[]>([]);
  const removeImageUtilities = (imgIndex: number) => {
    const newListImage = image.filter((_, index) => index !== imgIndex);
    if (image.length === 1) {
      setImage([]);
    }
    setImage(newListImage);
  };

  const [titleError, setTitleError] = useState(false);
  const [textTitleError, setTextTitleError] = useState("");
  const translate = useTranslate();
  const dataStatus = [
    { label: translate("resources.common.fields.active"), id: 1 },
    { label: translate("resources.common.fields.block"), id: 0 },
  ];
  const [status, setStatus] = useState<any>(dataStatus[0]);
  const refresh = useRefresh();
  const notify = useNotify();
  const record = useRecordContext();
  const [checked, setChecked] = React.useState(true);

  useEffect(() => {
    HttpDataService.findById("news/get-news", record.id as string)
      .then((response: any) => {
        if (response.data.status) {
          console.log(response);
          const res_record = response.data.data;
          setTitle(res_record.title);
          setDescription(res_record.description);
          // setContent(res_record.content);
					fnSetDataCkeditor("news_edit", res_record.content || "");

          setMetaTitle(res_record.meta_title);
          setMetaDescription(res_record.meta_description);
          setMetaKeyword(res_record.meta_keyword);
          setSlug(res_record.slug);
          const find_status = dataStatus.find((s) => s.id == res_record.status);
          setStatus(find_status);
          setImage([res_record.image]);
          setChecked(res_record.is_hot == 1 ? true : false);
        } else {
          // @ts-ignore
          notify("Lỗi", { type: "error" });
        }
      })
      .catch((e: Error) => {
        console.log(e);
      });
  }, [record.id]);

  /**
   * handle change name
   * @param e
   */
  const handleNameChange = (e: any) => {
    setTitleError(false);
    setTextTitleError("");
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (e: any) => {
    setDescription(e.target.value);
  };
  const handleMetaTitleChange = (e: any) => {
    setMetaTitle(e.target.value);
  };
  const handleMetaDescriptionChange = (e: any) => {
    setMetaDescription(e.target.value);
  };
  const handleMetaKeywordChange = (e: any) => {
    setMetaKeyword(e.target.value);
  };
  const handleSlugChange = (e: any) => {
    setSlug(e.target.value);
  };

  const handleContentChange = (e: any) => {
    setContent(e.target.value);
  };
  // checker
  const handleChange = (event: any) => {
    console.log(event, "event");
    setChecked(event.target.checked);
  };
  const handleChangeStatus = (e: any) => {
    setStatus(e);
  };

  /**
   * handle save
   */
  const handleSave = async () => {
    if (!fnValidation()) {
      let data = {
        id: record.id,
        title: title,
        description: description,
        content: content,
        meta_title: meta_title,
        meta_description: meta_description,
        meta_keyword: meta_keyword,
        slug: slug,
        is_hot: checked ? 1 : 0,
        status: status.id,
        image: image,
      };
      const submitObj = new FormData();
      //image
      if (image) {
        image.map((item: any) => {
          submitObj.append("image", item);
        });
        console.log(image, "image");
      }
      console.log(submitObj, "submitObj");

      Object.keys(data).map(
        (key) =>
          // @ts-ignore
          submitObj.append(key, data[key]),
        console.log(submitObj, "submitObj")
      );

      console.log(submitObj);
      await HttpDataService.create("news/edit-news", submitObj)
        .then((response: any) => {
          if (response.data.status) {
            // @ts-ignore
            notify(response.data.message, { type: "success" });
          } else {
            // @ts-ignore
            notify(response.data.message, { type: "error" });
          }
        })
        .catch((e: Error) => {
          console.log(e);
        });

      fnResetFrm();
      onCloseDialog(false);
      refresh();
    }
  };

  const handleCloseDialog = () => {
    fnResetFrm();
    onCloseDialog(false);
  };

  /**
   * fn validation form
   */
  function fnValidation() {
    let error = false;
    if (title.trim().length === 0) {
      error = true;
      setTitleError(true);
      setTextTitleError(translate("resources.title.fields.val_name"));
    }
    return error;
  }

  function fnResetFrm() {
    setTitle("");
    setTitleError(false);
    setTextTitleError("");
    setStatus(null);
    fnSetDataCkeditor("news_edit", "");

  }

  return (
    <>
      <Dialog
        open={openDialog}
        fullWidth
        disableEnforceFocus
        PaperProps={{
          style: {
            borderRadius: 2,
            minWidth: "65%",
          },
        }}
      >
        <div className={"common__dialogTitle_area"}>
          <DialogTitle className={"member__dialogTitle"}>
            <div>{translate("resources.news.edit_news")}</div>
          </DialogTitle>
          <div className={"common__dialogTitle_actions"}>
            <IconButton
              aria-label="exists"
              color={"error"}
              onClick={() => {
                onCloseDialog(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <Box className={"dialog_box"}>
          <DialogContent className={"dialog_content"}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={8}>
                <TextField
                  className={`${classes.root} mb-23`}
                  fullWidth
                  label={
                    <span className={"span_style"}>
                      {translate("resources.news.fields.title")}
                      <span style={{ color: "red" }}>(*)</span>
                    </span>
                  }
                  value={title}
                  error={titleError}
                  helperText={textTitleError}
                  onChange={handleNameChange}
                  variant="outlined"
                />

                <TextField
                  className={`${classes.root} mb-23`}
                  fullWidth
                  multiline
                  maxRows={3}
                  label={
                    <span className={"span_style"}>
                      {translate("resources.news.fields.description")}
                    </span>
                  }
                  value={description}
                  onChange={handleDescriptionChange}
                  variant="outlined"
                />
                <EditorWrapper className={"editor-news"}>
                  {/* <Editor
                    data={content}
                    onChange={(event: any, editor: any) => {
                      const data = editor.getData();
                      setContent(data);
                    }}
                  /> */}
                  <CkeditorServer
                    name="news_edit"
                    onChangeData={(data) => {
                      setContent(data);
                    }}
                  />
                </EditorWrapper>
                <TextField
                  className={`${classes.root} mb-23`}
                  fullWidth
                  multiline
                  maxRows={3}
                  label={
                    <span className={"span_style"}>
                      {translate("resources.news.fields.meta_title")}
                    </span>
                  }
                  value={meta_title}
                  onChange={handleMetaTitleChange}
                  variant="outlined"
                />
                <TextField
                  className={`${classes.root} mb-23`}
                  fullWidth
                  multiline
                  maxRows={3}
                  label={
                    <span className={"span_style"}>
                      {translate("resources.news.fields.meta_description")}
                    </span>
                  }
                  value={meta_description}
                  onChange={handleMetaDescriptionChange}
                  variant="outlined"
                />
                <TextField
                  className={`${classes.root} mb-23`}
                  fullWidth
                  multiline
                  maxRows={3}
                  label={
                    <span className={"span_style"}>
                      {translate("resources.news.fields.meta_keyword")}
                    </span>
                  }
                  value={meta_keyword}
                  onChange={handleMetaKeywordChange}
                  variant="outlined"
                />
                <TextField
                  className={`${classes.root} mb-23`}
                  fullWidth
                  multiline
                  maxRows={3}
                  label={
                    <span className={"span_style"}>
                      {translate("resources.news.fields.slug")}
                    </span>
                  }
                  value={slug}
                  onChange={handleSlugChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={
                        <span className={"span_style"}>
                          {translate("resources.common.fields.status")}
                        </span>
                      }
                    />
                  )}
                  fullWidth
                  value={status || null}
                  onChange={(event, value) => handleChangeStatus(value)}
                  options={dataStatus}
                />
                <Grid item>
                  <div className={"main-image-news"}>
                    <div className={"upload-image-wrapper"}>
                      <div className={"title-image"}>Hình ảnh</div>
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        onChange={(e: any) => {
                          setImage([...e.target.files]);
                          e.target.value = null;
                        }}
                      ></input>
                      {image?.length > 0 && (
                        <div className={"image-show-wrapper"}>
                          {image?.map((image: any, index: number) => {
                            return (
                              <ImagePreview
                                key={index}
                                src={
                                  typeof image == "string"
                                    ? image
                                    : URL.createObjectURL(image)
                                }
                                onRemove={() => removeImageUtilities(index)}
                              />
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                  <ItemWrapper className={"checked-news"}>
                    <FormControlLabel
                      control={
                        <Switch checked={checked} onChange={handleChange} />
                      }
                      label="Dự án nổi bật"
                      labelPlacement="start"
                    />
                  </ItemWrapper>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </Box>
        <Toolbar>
          <Box display="flex" justifyContent="center" width={"100%"}>
            <Button
              variant="contained"
              startIcon={<SaveIcon />}
              onClick={handleSave}
            >
              {translate("resources.common.fields.update")}
            </Button>
            &nbsp;&nbsp;
            <Button
              startIcon={<ReplyIcon />}
              className={"button-close"}
              variant="contained"
              onClick={handleCloseDialog}
            >
              {translate("resources.common.fields.cancel")}
            </Button>
          </Box>
        </Toolbar>
      </Dialog>
    </>
  );
};
