import * as request from '../utils/httpRequest';

export const getListProjectType = async () => {
    try {
        const response = await request.get('project_type/getAllProjectType');
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const getProjectTypeDetail = async (id: Number) => {
    try {
        const response = await request.get(`/project_type/getProjectType/${id}`);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};