import {
    DefaultDataProvider,
} from '../default-data-provider';
import {constants} from "../../shared/constants";

export const baseApiUrl = 'spam';

class SpamService extends DefaultDataProvider {

}

export const spamService = new SpamService(
    constants.RESOURCE.SPAM,
    constants.BASE_MODULE_URL,
    baseApiUrl
);