import { Autocomplete, Dialog, DialogContent, DialogTitle, IconButton, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import ReplyIcon from "@mui/icons-material/Reply";
import Toolbar from "@mui/material/Toolbar";
import { useNotify, useRefresh, useRecordContext, EditProps, useTranslate } from "react-admin";
import HttpDataService from "../dataProvider/http.service";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import * as projectTypeService from "../Services/projectTypeService";
import * as projectTypeEstateService from "../Services/projectTypeEstateService";
import { EditorWrapper } from "../projectEstate/dialogs/styledMui";
import Editor from "../component/Editor";
import CkeditorServer from "../component/CkeditorServer";
import { fnSetDataCkeditor } from "../utils/fc";

const styles = makeStyles((theme: any) =>
	createStyles({
		root: {
			"& .css-k4qjio-MuiFormHelperText-root.Mui-error": {
				position: "absolute",
				paddingTop: "41px",
			},
		},
	})
);

export const InputRowWrapper = styled(Box)({
	display: "flex",
	gap: 10,
	widows: "100%",
});

export const ItemWrapper = styled("div")({
	padding: "0",
	width: "50%",
});

export const ItemWrapper30 = styled("div")({
	padding: "0",
	width: "33.33%",
});

interface Add extends EditProps {
	openDialog: boolean;
	onCloseDialog: (openDialog: boolean) => void;
}

export const DistrictEdit = ({ openDialog, onCloseDialog, ...rest }: Add) => {
	const classes = styles();
	const [name, setName] = useState<string>("");
	const [content, setContent] = useState<string>("");
	const translate = useTranslate();

	const [nameError, setNameError] = useState(false);
	const [textNameError, setTextNameError] = useState("");
	const [meta_title, setMetaTitle] = useState<string>("");
	const [meta_description, setMetaDescription] = useState<string>("");
	const [meta_keyword, setMetaKeyword] = useState<string>("");

	const refresh = useRefresh();
	const notify = useNotify();
	const record = useRecordContext();

	useEffect(() => {
		HttpDataService.findById("districts/getDistricts", record.id as string)
			.then((response: any) => {
				if (response.data.status) {
					console.log(response);
					const res_record = response.data.data;
					setName(res_record.name);
					setMetaTitle(res_record.meta_title);
					setMetaDescription(res_record.meta_description);
					setMetaKeyword(res_record.meta_keyword);
					fnSetDataCkeditor("district_edit", res_record?.content);
				} else {
					// @ts-ignore
					notify("Lỗi", { type: "error" });
				}
			})
			.catch((e: Error) => {
				console.log(e);
			});
	}, [record.id]);

	/**
	 * handle change name
	 * @param e
	 */
	const handleNameChange = (e: any) => {
		setNameError(false);
		setTextNameError("");
		setName(e.target.value);
	};

	const handleMetaTitleChange = (e: any) => {
		setMetaTitle(e.target.value);
	};
	const handleMetaDescriptionChange = (e: any) => {
		setMetaDescription(e.target.value);
	};
	const handleMetaKeywordChange = (e: any) => {
		setMetaKeyword(e.target.value);
	};

	/**
	 * handle save
	 */
	const handleSave = async () => {
		if (!fnValidation()) {
			let data = {
				id: record.id,
				content,
				meta_title,
				meta_keyword,
				meta_description,
			};
			await HttpDataService.update("districts/edit-districts", data)
				.then((response: any) => {
					if (response.data.status) {
						// @ts-ignore
						notify(response.data.message, { type: "success" });
					} else {
						// @ts-ignore
						notify(response.data.message, { type: "error" });
					}
				})
				.catch((e: Error) => {
					console.log(e);
				});

			fnResetFrm();
			onCloseDialog(false);
			refresh();
		}
	};

	const handleCloseDialog = () => {
		fnResetFrm();
		onCloseDialog(false);
	};

	/**
	 * fn validation form
	 */
	function fnValidation() {
		let error = false;
		if (name.trim().length === 0) {
			error = true;
			setNameError(true);
			setTextNameError("Tên quận không được để trống!");
		}

		return error;
	}

	function fnResetFrm() {
		setName("");
		setNameError(false);
		setTextNameError("");
		setContent("");
		setMetaTitle("");
		setMetaKeyword("");
		setMetaDescription("");
	}

	return (
		<>
			<Dialog
				disableEnforceFocus
				open={openDialog}
				maxWidth={"md"}
				PaperProps={{
					style: {
						borderRadius: 2,
						// minWidth: '85%',
					},
				}}
			>
				<div className={"common__dialogTitle_area"}>
					<DialogTitle className={"member__dialogTitle"}>
						<div>{"Cập nhật quận/huyện"}</div>
					</DialogTitle>
					<div className={"common__dialogTitle_actions"}>
						<IconButton
							aria-label="exists"
							color={"error"}
							onClick={() => {
								onCloseDialog(false);
							}}
						>
							<CloseIcon />
						</IconButton>
					</div>
				</div>
				<Box className={"dialog_box"}>
					<DialogContent className={"dialog_content"}>
						<InputRowWrapper>
							<TextField
								className={`${classes.root} mb-23`}
								fullWidth
								label={
									<span className={"span_style"}>
										{"Tên quận/huyện"}
										<span style={{ color: "red" }}>(*)</span>
									</span>
								}
								value={name}
								error={nameError}
								helperText={textNameError}
								onChange={handleNameChange}
								variant="outlined"
								InputProps={{
									readOnly: true,
								}}
							/>
						</InputRowWrapper>

						<InputRowWrapper>
							<ItemWrapper30>
								<TextField
									className={`${classes.root} mb-23`}
									fullWidth
									label={
										<span className={"span_style"}>
											{translate("resources.general.fields.meta_title")}
										</span>
									}
									value={meta_title}
									onChange={handleMetaTitleChange}
									variant="outlined"
								/>
							</ItemWrapper30>
							<ItemWrapper30>
								<TextField
									className={`${classes.root} mb-23`}
									fullWidth
									label={
										<span className={"span_style"}>
											{translate("resources.general.fields.meta_description")}
										</span>
									}
									value={meta_description}
									onChange={handleMetaDescriptionChange}
									variant="outlined"
								/>
							</ItemWrapper30>
							<ItemWrapper30>
								<TextField
									className={`${classes.root} mb-23`}
									fullWidth
									label={
										<span className={"span_style"}>
											{translate("resources.general.fields.meta_keyword")}
										</span>
									}
									value={meta_keyword}
									onChange={handleMetaKeywordChange}
									variant="outlined"
								/>
							</ItemWrapper30>
						</InputRowWrapper>

						<CkeditorServer
							name="district_edit"
							onChangeData={(data) => {
								if (data) setContent(data);
							}}
						/>
					</DialogContent>
				</Box>
				<Toolbar>
					<Box
						display="flex"
						justifyContent="center"
						width={"100%"}
					>
						<Button
							variant="contained"
							startIcon={<SaveIcon />}
							onClick={handleSave}
						>
							{translate("resources.common.fields.update")}
						</Button>
						&nbsp;&nbsp;
						<Button
							startIcon={<ReplyIcon />}
							className={"button-close"}
							variant="contained"
							onClick={handleCloseDialog}
						>
							{translate("resources.common.fields.cancel")}
						</Button>
					</Box>
				</Toolbar>
			</Dialog>
		</>
	);
};
