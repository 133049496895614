import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  Button,
  Stack,
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { styled } from "@mui/system";
import { useNotify, useRecordContext, useRefresh } from "react-admin";
import HttpDataService from "../../../../dataProvider/http.service";
import { constants } from "../../../../shared/constants";
import moment from "moment";
// call api
import * as request from "../../../../utils/httpRequest";

import DialogSubmitLoading from "../../../../component/DialogSubmitLoading";

const Wrapper = styled(Box)({
  // width: "500px",
});

const HeaderWrapper = styled(Box)({
  backgroundColor: "whitesmoke",
});

const ContentWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "30px 0",
});

const ButtonWrapper = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  backgroundColor: "whitesmoke",
  padding: 20,
});

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

function HistoryCare({ isOpen, onClose }: Props) {
  //LOADING STATE
  const [submitLoading, setSubmitLoading] = React.useState<boolean>(false);
  const [dataCare, setDataCare] = useState<any>([]);

  //HOOKS
  const record = useRecordContext();
  React.useEffect(() => {
    if (isOpen) {
      HttpDataService.findById(
        "customer/showHistoryCustormer",
        record.id as string
      )
        .then((response: any) => {
          if (response.data.status) {
            setDataCare(response.data.data);
          } else {
            // @ts-ignore
            notify("Lỗi", { type: "error" });
          }
        })
        .catch((e: Error) => {
          console.log(e);
        });
    }
  }, [isOpen]);

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth={"lg"}>
      <Wrapper>
        {submitLoading ? <DialogSubmitLoading /> : <></>}
        <HeaderWrapper>
          <DialogTitle>Lịch sử chăm sóc</DialogTitle>
        </HeaderWrapper>
         <ContentWrapper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell >{"Thời gian"}</TableCell>
                <TableCell >{"Người tạo"}</TableCell>
                <TableCell style={{ minWidth: "280px" }}>{"Nội dung"}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataCare?.map((item: any) => (
                <TableRow>
                  <TableCell style={{ width: "140px" }}>
                    {moment(item?.created_at).format("DD/MM/YYYY")}
                  </TableCell>
                  <TableCell>{item?.created_name}</TableCell>
                  <TableCell
                    style={{
                      whiteSpace: "normal",
                      wordBreak: "break-word",
                    }}
                  >
                    {item?.content_his}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ContentWrapper>
        <ButtonWrapper>
          <Stack direction="row" spacing={2}>
            <Button variant="outlined" color="error" onClick={onClose}>
              Đóng
            </Button>
          </Stack>
        </ButtonWrapper>
      </Wrapper>
    </Dialog>
  );
}

export default React.memo(HistoryCare);
