import React from 'react';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

type Props = {
    title: String;
    onClick?: () => void;
};

export default function CreateButtonMUI({ title, onClick }: Props) {
    return (
        <Button variant="outlined" startIcon={<AddIcon />} onClick={onClick} style={{ whiteSpace: 'nowrap'}}>
            {title}
        </Button>
    );
}
