import * as React from 'react';
import { Layout, LayoutProps, useLogout, useTranslate } from 'react-admin';
import cx from 'clsx';
import Menu from './Menu';
import { lightTheme } from './themes';

import LoadingSpinner from '../component/LoadingSpinner';
import { createTheme, makeStyles } from '@material-ui/core/styles';
import { RefreshIconButton, useGetIdentity } from 'react-admin';
import { useRef, useState } from 'react';
import BGSideMenuIcon from '../assets/icon/side-menu.svg';
import { constants } from '../shared/constants';
import {
    getCollapseBtn,
    getContent,
    getDrawerSidebar,
    getSidebarContent,
    getStandardScheme,
    Root,
} from '@mui-treasury/layout';
import { Toolbar, Typography, Avatar, Popover, Button } from '@material-ui/core';
import Logo from './Logo';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import LockResetIcon from '@mui/icons-material/LockReset';
import LogoutIcon from '@mui/icons-material/Logout';
import AppBar from './AppBar';

const defaultLoadingSpinner = 0;
const CollapseBtn = getCollapseBtn(styled);
const DrawerSidebar = getDrawerSidebar(styled);
const SidebarContent = getSidebarContent(styled);
const Content = getContent(styled);
const fixedScheme = getStandardScheme();
import { Link, To } from 'react-router-dom';
import Notification from './components/Notification/Notification';
import { Theme, useMediaQuery } from '@mui/material';
const initLoadingSpinner = {
    loadingSpinner: defaultLoadingSpinner,
    setLoadingSpinner: (defaultLoadingSpinner: any) => {
        return;
    },
};

const useStyles = makeStyles((theme) => ({
    collapseBtn: {
        width: 40,
        background: `url(${BGSideMenuIcon})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },
    sidebar: {
        backgroundColor: '#fff',
        boxShadow: '0px 3px 6px rgb(0 0 0 / 14%)',
        border: 'none',
        maxWidth: '250px !important',
    },
    content: {
        backgroundColor: '#E1E1E1',
        paddingTop: 66,
        height: 'calc(100vh)',
        overflow: 'auto',
    },
    subContent: {
        backgroundColor: '#fff',
        borderRadius: theme.spacing(2),
        border: '1px solid #eee',
        padding: theme.spacing(4),
        paddingTop: 0,
        height: '100%',
        overflow: 'auto',
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
    appbar: {
        backgroundColor: '#fff',
        boxShadow: '0px 3px 6px #0000000D',
    },
    version: {
        textAlign: 'center',
        fontFamily: 'Noto, Helvetica, Arial, sans-serif',
        wordWrap: 'break-word',
        fontSize: '1.2rem',
        color: '#999',
        marginTop: 15,
    },
}));

export const LoadingSpinnerContext = React.createContext(initLoadingSpinner);

export default (props: LayoutProps) => {
    const translate = useTranslate();
    const logout = useLogout();

    const { children, ...rest } = props;
    const styles = useStyles();
    const [loadingSpinner, setLoadingSpinner] = useState(0);

    const contextLoadingSpinnerState = {
        loadingSpinner: loadingSpinner,
        setLoadingSpinner: setLoadingSpinner,
    };

    fixedScheme.configureEdgeSidebar((builder) => {
        builder
            .create('primarySidebar', { anchor: 'left' })
            .registerTemporaryConfig('xs', {
                width: 'auto', // 'auto' is only valid for temporary variant
            })
            .registerPermanentConfig('md', {
                width: 250, // px, (%, rem, em is compatible)
                collapsible: true,
                collapsedWidth: 64,
            });
    });
    const [collapseMenu, setCollapseMenu] = useState(false);

    const showHideCollapse = () => {
        setCollapseMenu(!collapseMenu);
        var body = document.body;
        if (!collapseMenu) {
            body.classList.add('collapse-hide');
        } else {
            body.classList.remove('collapse-hide');
        }
    };

    const anchorRef = useRef(null);
    const [openSelectConfig, setOpenSelectConfig] = useState(false);
    const handleOpenSelectConfig = () => {
        setOpenSelectConfig(true);
    };
    const handleCloseSelectConfig = () => {
        setOpenSelectConfig(false);
    };

    const user = JSON.parse(
        localStorage.getItem(constants.LOCAL_STORAGE.USER_IDENTITY) as string,
    );

    const isLargeEnough = useMediaQuery<Theme>(theme =>
        theme.breakpoints.up('sm')
    );

    // @ts-ignore
    return (
        // // @ts-ignore
        // <Root scheme={fixedScheme} theme={createTheme(lightTheme)}>
        //     {({ state: {} }) => (
        //         <LoadingSpinnerContext.Provider value={contextLoadingSpinnerState}>
        //             {/*<Notification />*/}
        //             <LoadingSpinner loadingSpinner={loadingSpinner} desc="" />
        //             <DrawerSidebar
        //                 sidebarId={'primarySidebar'}
        //                 PaperProps={{ className: styles.sidebar }}
        //                 className={`side-bar-left-default`}
        //             >
        //                 {/* <AppBar color="inherit" elevation={0}>
        //                     <Toolbar className={`toolbar-header`}>
        //                         <Box
        //                             sx={{
        //                                 p: 1,
        //                                 m: 1,
        //                                 bgcolor: 'background.paper',
        //                                 borderRadius: 1,
        //                             }}
        //                             className={'box_bar'}
        //                         >
        //                             <div className={'d-flex item-center'}>
        //                                 <CollapseBtn
        //                                     className={`${cx(
        //                                         styles.collapseBtn,
        //                                     )} btn-collapse-menu`}
        //                                     onClick={() => showHideCollapse()}
        //                                 />
        //                                 {isLargeEnough && <Link to={'/'}>
        //                                     <Logo />
        //                                 </Link>
        //                             </div>
        //                             <Box className={`header-right d-flex item-center`}>
        //                                 <Box>
        //                                     <Notification />
        //                                 </Box>
        //                                 <RefreshIconButton />
        //                                 <div
        //                                     className={
        //                                         'd-flex flex-col jst-center item-center'
        //                                     }
        //                                     ref={anchorRef}
        //                                     onClick={handleOpenSelectConfig}
        //                                 >
        //                                     <Typography
        //                                         className={'dashboard-text-filter'}
        //                                     >
        //                                         {user?.name}
        //                                     </Typography>
        //                                     <div
        //                                         className={
        //                                             'd-flex jst-center item-center cursor-pointer'
        //                                         }
        //                                     >
        //                                         <Typography
        //                                             className={
        //                                                 'text12 jst-center item-center text-nowrap'
        //                                             }
        //                                         >
        //                                             {user?.department_name}
        //                                         </Typography>
        //                                     </div>
        //                                 </div>
        //                                 <div
        //                                     className={`avatar avatar-default`}
        //                                     ref={anchorRef}
        //                                     onClick={handleOpenSelectConfig}
        //                                 >
        //                                     <Avatar src={user?.image} />
        //                                 </div>
        //                             </Box>
        //                         </Box>
        //                     </Toolbar>
        //                 </AppBar> */}

        //                 <SidebarContent className={`sidebar-content`}>
        //                     <Menu />
        //                 </SidebarContent>
        //             </DrawerSidebar>
        //             <Content className={styles.content}>{children}</Content>
        //             <Popover
        //                 open={openSelectConfig}
        //                 onClose={handleCloseSelectConfig}
        //                 anchorEl={anchorRef.current}
        //                 className={'popup-select-role'}
        //             >
        //                 <div className={`popover-role-view`}>
        //                     <div className={'popver-name'}>
        //                         <p className={'lb_account'}>Tài khoản</p>
        //                         <Link to={'/profile'}>
        //                             <p className={'lb_username'}>{user?.username}</p>
        //                         </Link>
        //                     </div>
        //                     <div className={'list-item mb10'} onClick={logout}>
        //                         <div className="list-item__icon">
        //                             <LogoutIcon />
        //                         </div>
        //                         <div className="list-item__title">Đăng xuất</div>
        //                     </div>
        //                 </div>
        //             </Popover>
        //         </LoadingSpinnerContext.Provider>
        //     )}
        // </Root>
        <Layout {...props} appBar={AppBar} menu={Menu} />
    );
};
