import {
    DefaultDataProvider,
} from '../default-data-provider';
import {constants} from "../../shared/constants";

export const baseApiUrl = 'feedback';

export default class FeedbackService extends DefaultDataProvider {

}

export const feedbackService = new FeedbackService(
    constants.RESOURCE.FEEDBACK,
    constants.BASE_MODULE_URL,
    baseApiUrl
);