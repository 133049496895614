import {CreateButton, CreateProps, useNotify, useRefresh, useTranslate} from "react-admin";
import {Dialog, DialogContent, DialogTitle, Grid, IconButton} from "@mui/material";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import Box from "@mui/material/Box";
import React, {useState} from "react";
import SaveIcon from '@mui/icons-material/Save';
import ReplyIcon from '@mui/icons-material/Reply';
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import HttpDataService from "../dataProvider/http.service";
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ImagePreview from "../component/ImgPreview";

//Reformat Style
const styles = makeStyles((theme: any) =>
    createStyles({
        root: {
            '& .css-k4qjio-MuiFormHelperText-root.Mui-error':{
                position: 'absolute',
                paddingTop: '41px',
            },
            '& .css-1z10yd4-MuiFormControl-root-MuiTextField-root':{
                marginTop: 20,
                marginBottom: 20,
            }
        },

        box_image: {
            '& ':{
                border: '1px solid #ccc',
                padding: '10px',
                borderRadius: '10px',
                marginBottom: '10px',
            },
        }
    }));

export const PartnerAdd = (props: any) => {
    const classes = styles()
    const { onCloseDialog, openDialog } = props;

    const translate =useTranslate();

    const dataStatus = [
        { label: translate('resources.common.fields.active'), id: 1 },
        { label: translate('resources.common.fields.block'), id: 0 },
    ]

    const [name, setName] = useState<string>("")
    const [nameError, setNameError] = useState(false)
    const [textNameError, setTextNameError] = useState("")
    const [status, setStatus] = useState<any>(dataStatus[0])
    const [image, setImage] = useState<any>()

    const refresh = useRefresh();
    const notify = useNotify();


    /**
     * handle change name
     * @param e
     */
    const handleNameChange = (e: any) => {
        setNameError(false)
        setTextNameError("")
        setName(e.target.value)
    }

    const handleChangeStatus = (e: any) => {
        setStatus(e)
    }

    /**
     * handle save
     */
    const handleSave = async () => {
        if(!fnValidation()) {
            const submitObj = new FormData();
            submitObj.append('name', name);
            submitObj.append('status', status?.id);
            submitObj.append('image', image);

            await HttpDataService.create('partner/add', submitObj)
                .then((response: any) =>
                {
                    if(response.data.status) {
                        // @ts-ignore
                        notify(response.data.message,{type: 'success'});
                    } else {
                        // @ts-ignore
                        notify(response.data.message,{type: 'error'});
                    }
                })
                .catch((e: Error) => {
                    console.log(e);
                });

            fnResetFrm();
            onCloseDialog();
            refresh();

        }
    }

    const handleCloseDialog = () => {
        fnResetFrm()
        onCloseDialog();
    }

    /**
     * fn validation form
     */
    function fnValidation() {
        let error = false
        if (name.trim().length === 0) {
            error = true
            setNameError(true)
            setTextNameError('Tên đối tác bắt buộc nhập')
        }
        return error;
    }

    function fnResetFrm() {
        setName("");
        setNameError(false);
        setTextNameError("");
        setStatus(dataStatus[0]);
        setImage(null);
    }

    return (
        <>
            <Dialog
                open={openDialog}
                fullWidth
                PaperProps={{
                    style: {
                        borderRadius: 2,
                        // minWidth: '85%',
                    },
                }}
            >
                <div className={'common__dialogTitle_area'}>
                    <DialogTitle className={'member__dialogTitle'}>
                        <div>
                            Thêm mới đối tác
                        </div>
                    </DialogTitle>
                    <div className={'common__dialogTitle_actions'}>
                        <IconButton
                            aria-label="exists"
                            color={'error'}
                            onClick={() => {
                                onCloseDialog(false);
                                fnResetFrm();
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
                <Box className={'dialog_box'}>
                    <DialogContent className={'dialog_content'}>
                        <TextField
                                className={`${classes.root} mb-23`}
                            fullWidth
                            label={
                                <span className={'span_style'}>
                                    Tên đối tác
                                    <span style={{ color: 'red' }}>(*)</span>
                                </span>
                            }
                            value={name}
                            error={nameError}
                            helperText={textNameError}
                            onChange={handleNameChange}
                            variant="outlined"
                        />

                        <div className={`${classes.box_image}`}>
                            <div className={"title-image"}>Hình ảnh</div>
                            <input
                                type="file"
                                accept=".png, .jpg, .jpeg"
                                onChange={(e: any) => {
                                setImage(e.target.files[0]);
                                e.target.value = null;
                                }}
                            ></input>
                            {image && (
                                <div>
                                    <ImagePreview
                                    src={URL.createObjectURL(image)}
                                    onRemove={() => setImage(null)}
                                    />
                                </div>
                            )}
                        </div>

                        <Autocomplete
                            renderInput={(params) => <TextField
                            
                                {...params}
                                variant="outlined"
                                label={
                                    <span className={'span_style'}>{translate('resources.common.fields.status')}</span>
                                }
                            />}
                            sx={{width: '100%'}}
                            value={status}
                            onChange={(event, value) => handleChangeStatus(value)}
                            options={dataStatus}
                        />

                    </DialogContent>
                </Box>
                <Toolbar>
                    <Box
                        display="flex"
                        justifyContent="center"
                        width={'100%'}
                    >
                        <Button variant="contained"
                                startIcon={<SaveIcon/>} onClick={handleSave}>{translate('resources.common.fields.add')}</Button>
                        &nbsp;&nbsp;
                        <Button
                            startIcon={<ReplyIcon/>}
                            className={'button-close'}
                            variant="contained"
                            onClick={handleCloseDialog}
                        >
                            {translate('resources.common.fields.cancel')}
                        </Button>
                    </Box>
                </Toolbar>
            </Dialog>
        </>
    );
}
