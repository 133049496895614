import AddIcon from "@mui/icons-material/Add";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import React, { useEffect, useState } from "react";
import { FilterLiveSearch, useTranslate } from "react-admin";
import {
  Pagination,
  List,
  Datagrid,
  DatagridProps,
  useListContext,
  useRecordContext,
  TextField,
  FunctionField,
} from "react-admin";
import { Box, Button, Chip, IconButton, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Form } from "react-final-form";
import PaginationCustom from "../component/PaginationCustom";
import { CustomerSourcesAdd } from "./CustomerSourcesAdd";
import { CustomerSourcesEdit } from "./CustomerSourcesEdit";
import { CustomerSourcesDelete } from "./CustomerSourcesDelete";
import * as sourceTypeService from "../Services/customerSourcesService";
import { commonFunction } from "../component/Common";

const Item = styled(Paper)(({ theme }) => ({
  textAlign: "right",
}));

export const CustomerSourcesList = (props: any) => {
  const PostPagination = (props: any) => <PaginationCustom {...props} />;
  const translate = useTranslate();
  const [formLoading, setFormLoading] = useState<boolean>(false);

  const [listSourceType, setListSourceType] = useState<any>([]);
  React.useEffect(() => {
    setFormLoading(true);
    const fetchAPI = async () => {
      const listSourceTypeResult =
        await sourceTypeService.getAllCustomerSourcesType();
      if (listSourceTypeResult.length > 0) {
        setListSourceType(listSourceTypeResult);
        setFormLoading(false);
      }
    };
    fetchAPI();
  }, []);

  return (
    <>
      {formLoading ? (
        <></>
      ) : (
        <Box className="header-channel-sales">
          <Box className={"box_header"}>
            <Typography className={"title_header"}>
              {"Danh sách nguồn khách hàng"}
            </Typography>
          </Box>
          <div className={`header-top-detail header-top-tdn`}>
            <List
              {...props}
              actions={false}
              empty={false}
              exporter={false}
              filters={<FilterAndActions />}
              sort={{ field: "", order: "" }}
              pagination={<PostPagination />}
              perPage={50}
            >
              <DepartmentDatagrid
                {...props}
                listSourceTypeProps={listSourceType}
              />
            </List>
          </div>
        </Box>
      )}
    </>
  );
};

interface ButtonProps {
  basePath?: string;
  label: string;
  record?: any;
}

const EditButton = (props: any) => {
  const [onCloseDialog, setOpenEditDialog] = useState(false);
  return (
    <>
      <IconButton onClick={() => setOpenEditDialog(true)}>
        <EditIcon className={"btn_edit"} />
      </IconButton>
      {onCloseDialog && (
        <CustomerSourcesEdit
          {...props}
          openDialog={onCloseDialog}
          onCloseDialog={() => setOpenEditDialog(false)}
        />
      )}
    </>
  );
};

const DeleteButton = (props: any) => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  return (
    <>
      <IconButton onClick={() => setOpenDeleteDialog(true)}>
        <DeleteIcon className={"btn_del"} />
      </IconButton>
      <CustomerSourcesDelete
          {...props}
        openDialog={openDeleteDialog}
        onCloseDialog={() => setOpenDeleteDialog(false)}
      />
    </>
  );
};

const RowActions = ({ record }: ButtonProps) => {
  return (
    <Box className={"care_plan_rowActions"}>
      {commonFunction.checkPermission("CUSTOMER_SOURCE_EDIT") && <EditButton />}
      {commonFunction.checkPermission("CUSTOMER_SOURCE_DELETE") && (
        <DeleteButton />
      )}
    </Box>
  );
};
const FilterAndActions = (props: any) => {
  const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);

  return (
    <Box
      sx={{
        p: 1,
        m: 1,
        bgcolor: "background.paper",
        borderRadius: 1,
      }}
      className={"box_search"}
    >
      <Item className="shadow-none">
        <Form onSubmit={() => undefined}>
          {() => (
            <FilterLiveSearch
              variant="outlined"
              source="name"
              label="resources.common.fields.search"
            />
          )}
        </Form>
      </Item>
      {commonFunction.checkPermission("CUSTOMER_SOURCE_ADD") && (
        <Item className="shadow-none">
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            color="primary"
            onClick={() => setOpenCreateDialog(true)}
          >
            Thêm mới
          </Button>
          <CustomerSourcesAdd
            openDialog={openCreateDialog}
            onCloseDialog={() => setOpenCreateDialog(false)}
          />
        </Item>
      )}
    </Box>
  );
};


const menuShow = (props: any) => {
  const listSourceType = props;
  const { id, children } = useRecordContext();
  return (
    <>
      {children?.map((item: any, index: any) => (
        <div key={index} className={"h-1_source_customer row"}>
          <td className="grid-center">{index + 1}</td>
          <td className="grid-center">{item.name}</td>
          <td className="grid-center">
            {" "}
            <FunctionField
              label={"Loại nguồn"}
              source={"source_type_id"}
              render={(record: any) => {
                const sourceType = listSourceType?.find(
                  (s: any) => s.id == record.source_type_id
                );
                return sourceType?.name;
              }}
            />
          </td>
          <td className="grid-center">{item.created_name}</td>
          <td className="grid-center">
            <FunctionField
              label={"resources.common.fields.status"}
              render={(record: any) => {
                if (record.status === 1) {
                  return (
                    <Chip
                      label="Đang hoạt động"
                      color="success"
                      variant="outlined"
                    />
                  );
                } else {
                  return <Chip label="Khóa" color="error" variant="outlined" />;
                }
              }}
            />
          </td>
          <td>
            <EditButton id={item.id} />
            <DeleteButton id={item.id} />
          </td>
        </div>
      ))}
    </>
  );
};

const DepartmentDatagrid = (props: any) => {
  const listSourceType = props?.listSourceTypeProps;
  return (
    <>
      <div className={`list-data-tdn`}>
        <Datagrid
          expand={() => menuShow(listSourceType)}
          bulkActionButtons={false}
          isRowExpandable={(row: any) => row.children.length > 0}
          className={`table-default criteria_type_datagrid table_department`}
        >
          <TextField source={"name"} label={"Tên nguồn"} />
          <FunctionField
            label={"Loại nguồn"}
            source={"source_type_id"}
            render={(record: any) => {
              const sourceType = listSourceType?.find(
                (s: any) => s.id == record.source_type_id
              );
              return sourceType?.name;
            }}
          />
          <TextField
            source={"created_name"}
            label={"resources.common.fields.created_by"}
          />
          <FunctionField
            label={"resources.common.fields.status"}
            render={(record: any) => {
              if (record.status == 1) {
                return (
                  <Chip
                    label="Đang hoạt động"
                    color="success"
                    variant="outlined"
                  />
                );
              } else {
                return <Chip label="Khóa" color="error" variant="outlined" />;
              }
            }}
          />

          <RowActions label={"resources.common.fields.action"} />
        </Datagrid>
      </div>
    </>
  );
};
