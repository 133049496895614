import {
    DefaultDataProvider,
    httpClient,
    serialize,
} from '../default-data-provider';
import {constants} from "../../shared/constants";

export const baseApiUrl = 'menu_admin';

class MenuService extends DefaultDataProvider {

}

export const menuService = new MenuService(
    constants.RESOURCE.MENU,
    constants.BASE_MODULE_URL,
    baseApiUrl
);