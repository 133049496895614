import {
    DefaultDataProvider,
} from '../default-data-provider';
import {constants} from "../../shared/constants";

export const baseApiUrl = 'districts';

class DistrictService extends DefaultDataProvider {

}

export const districtService = new DistrictService(
    constants.RESOURCE.DISTRICT,
    constants.BASE_MODULE_URL,
    baseApiUrl
);