import React from 'react';
import { Dialog, DialogTitle, Button, Stack, Box, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { styled } from '@mui/system';
import { useNotify, useRecordContext, useRefresh } from 'react-admin';

import * as projectService from '../../../Services/projectService';
import DialogSubmitLoading from '../../../component/DialogSubmitLoading';

const Wrapper = styled(Box)({
    width: 500,
});

const HeaderWrapper = styled(Box)({
    backgroundColor: 'whitesmoke',
});

const ContentWrapper = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '30px 0',
});

const WarningText = styled(Typography)({
    color: '#ed6c02',
    fontSize: '1.5rem',
});

const ButtonWrapper = styled(Box)({
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: 'whitesmoke',
    padding: 20,
});

type Props = {
    isOpen: boolean;
    onClose: () => void;
};

function ProjectDelete({ isOpen, onClose }: Props) {
    //LOADING STATE
    const [submitLoading, setSubmitLoading] = React.useState<boolean>(false);

    //HOOKS
    const record = useRecordContext();
    const notify = useNotify();
    const refresh = useRefresh();

    const handleDelete = () => {
        setSubmitLoading(true);

        const submit = async () => {
            const result = await projectService.deleteProject(Number(record.id));
            if (result === 200) {
                notify(`Xóa dự án thành công`, {
                    type: 'success',
                    undoable: true,
                });
                setTimeout(() => {
                    onClose();
                    refresh();
                    setSubmitLoading(false);
                }, 1000);
            } else {
                notify(`Xóa dự án thất bại`, {
                    type: 'error',
                    undoable: true,
                });
                setSubmitLoading(false);
            }
        };

        if (!submitLoading) {
            submit();
        }
    };

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <Wrapper>
                {submitLoading ? <DialogSubmitLoading /> : <></>}
                <HeaderWrapper>
                    <DialogTitle>Xóa dự án</DialogTitle>
                </HeaderWrapper>
                <ContentWrapper>
                    <ErrorIcon color="warning" fontSize="large" />
                    <WarningText>Bạn chắc chắn muốn xóa dự án này?</WarningText>
                </ContentWrapper>
                <ButtonWrapper>
                    <Stack direction="row" spacing={2}>
                        <Button variant="contained" onClick={handleDelete}>
                            Xác nhận
                        </Button>
                        <Button variant="outlined" color="error" onClick={onClose}>
                            Hủy
                        </Button>
                    </Stack>
                </ButtonWrapper>
            </Wrapper>
        </Dialog>
    );
}

export default React.memo(ProjectDelete);
