import React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/system';

const Wrapper = styled(Box)({
    position: 'fixed',
    width: '100%',
    zIndex: 999,
});

function PageSubmitLoading() {
    return (
        <Wrapper>
            <LinearProgress color="info" />
        </Wrapper>
    );
}

export default React.memo(PageSubmitLoading);
