import {
  CreateButton,
  CreateProps,
  useNotify,
  useRefresh,
  useDataProvider,
  useTranslate,
} from "react-admin";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import ReplyIcon from "@mui/icons-material/Reply";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import HttpDataService from "../dataProvider/http.service";
import Item from "antd/es/list/Item";
import { constants } from "../shared/constants";
// import {officeIdService} from "../dataProvider/service/officeID.service";
import axios from "axios";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import ImagePreview from "../component/ImgPreview";
import classnames from "classnames/bind";
import styles from "./User.module.scss";
// import * as request from "../utils/httpRequest";
import * as projectService from "../Services/projectService";
import { object } from "prop-types";
const cx = classnames.bind(styles);

import { createStyles, makeStyles } from "@material-ui/core/styles";

const styled = makeStyles((theme: any) =>
  createStyles({
    root: {
      "& .css-k4qjio-MuiFormHelperText-root.Mui-error": {
        position: "absolute",
        paddingTop: "41px",
      },
    },
  })
);

const locales = ["vi"] as const;

const dataStatus = [
  { label: "--Lựa chọn--", id: "" },
  { label: "Đang hoạt động", id: 1 },
  { label: "Khóa", id: 0 },
];
const dataShowHome = [
  { label: "Không hiện", id: 0 },
  { label: "Hiện trang chủ", id: 1 },
];
const dataWorking_form = [
  { label: "--Lựa chọn--", id: "" },
  { label: "Fulltime", id: 1 },
  { label: "Partime", id: 2 },
];
const dataSex = [
  { label: "--Lựa chọn--", id: "" },
  { label: "Nam", id: 1 },
  { label: "Nữ", id: 2 },
];
const dataPermission_data = [
  { label: "--Lựa chọn--", id: "" },
  { label: "Tất cả", id: 1 },
  { label: "Quản lý", id: 2 },
  { label: "Nhân viên", id: 3 },
];
let dataDepartment_id: any[] = [];
let dataOffice: any[] = [];
let dataParts: any[] = [];
let dataRoles: any[] = [{ label: "--Lựa chọn--", id: "" }];
let dataListManager: any[] = [{ label: "--Lựa chọn--", id: "" }];

export interface doingTemplate {
  id: number;
  label: string;
}

export const UserAdd = (props: any) => {
  const classes = styled();
  const { onCloseDialog, openDialog } = props;
  const translate = useTranslate();
  // image
  const [image, setImage] = React.useState<any[]>([]);
  //first_name
  const [first_name, setFirst_name] = useState<string>("");
  const [first_nameError, setFirst_nameError] = useState(false);
  const [textFirst_nameError, setTextFirst_nameError] = useState("");
  //last_name
  const [last_name, setLast_name] = useState<string>("");
  const [last_nameError, setLast_nameError] = useState(false);
  const [textLast_nameError, setTextLast_nameError] = useState("");
  //birthday
  const [birthday, setBirthday] = React.useState<Dayjs | null>(null);
  // Phone
  const [phone, setPhone] = useState<string>("");
  // Địa chỉ tạm trú
  const [temporary_address, setTemporary_address] = useState<string>("");
  // Địa chỉ thường trú
  const [permanent_address, setPermanent_address] = useState<string>("");
  // Tài khoản ngân hàng
  const [bank, setBank] = useState<string>("");
  // Bảo hiểm xã hội
  const [bhxh, setBhxh] = useState<string>("");
  // Căn cước công dân
  const [cccd, setCccd] = useState<string>("");
  // Nơi cấp
  const [noicap, setNoicap] = useState<string>("");
  // Ngày cấp
  const [ngaycap, setNgaycap] = React.useState<Dayjs | null>(null);
  // Dân tộc
  const [ethnic, setEthnic] = useState<string>("");
  // email
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState(false);
  const [textEmailError, setTextEmailError] = useState("");
  // Email cá nhân
  const [email_ca_nhan, setEmail_ca_nhan] = useState<string>("");
  //username
  const [username, setUsername] = useState<string>("");
  const [usernameError, setUsernameError] = useState(false);
  const [textUsernameError, setTextUsernameError] = useState("");
  // password
  const [password, setPassword] = useState<string>("");
  const [passwordError, setPasswordError] = useState(false);
  const [textPasswordError, setTextPasswordError] = useState("");
  // password-confirm
  const [password_confirm, setPassword_confirm] = useState<string>("");
  const [password_confirmError, setPassword_confirmError] = useState(false);
  const [textPassword_confirmError, setTextPassword_confirmError] =
    useState("");

  //status
  const [status, setStatus] = useState<any>(dataStatus[0]);
  //show home
  const [showHome, setShowHome] = useState<any>(dataShowHome[0]);
  // permission_data
  const [permission_data, setPermission_data] = useState<any>(
    dataPermission_data[0]
  );
  // hình thức làm việc
  const [working_form, setWorking_form] = useState<any>(dataWorking_form[0]);
  // Giới tính
  const [sex, setSex] = useState<any>(dataSex[0]);
  // phòng ban
  const [department, setDepartment] = useState<any>(null);
  // Chức vụ
  const [office, setOffice] = useState<any>(null);
  // Bộ phận
  const [parts, setParts] = useState<any>(null);
  // Nhóm quyền
  const [roles, setRoles] = useState<any>(dataRoles[0]);
  //danh sách quản lý
  const [manage, setManage] = useState<any>(dataListManager[0]);


  const refresh = useRefresh();
  const notify = useNotify();

  /**
   * handle change name
   * @param e
   */
  // first_name
  const handleFirst_nameChange = (e: any) => {
    setFirst_nameError(false);
    setTextFirst_nameError("");
    setFirst_name(e.target.value);
  };
  // last_name
  const handleLast_nameChange = (e: any) => {
    setLast_nameError(false);
    setTextLast_nameError("");
    setLast_name(e.target.value);
  };
  // email
  const handleEmailAddChange = (e: any) => {
    setEmailError(false);
    setTextEmailError("");
    setEmail(e.target.value);
  };
  // Email cá nhân
  const handleEmail_ca_nhan = (e: any) => {
    setEmail_ca_nhan(e.target.value);
  };
  // username
  const handleUsernameChange = (e: any) => {
    setUsernameError(false);
    setTextUsernameError("");
    setUsername(e.target.value);
  };
  // passwword
  const handlePasswordChange = (e: any) => {
    setPasswordError(false);
    setTextPasswordError("");
    setPassword(e.target.value);
  };
  // passwword-confirm
  const handlePassword_confirmChange = (e: any) => {
    setPassword_confirmError(false);
    setTextPassword_confirmError("");
    setPassword_confirm(e.target.value);
  };
  // birthday
  const handleBirthdayChange = (e: any) => {
    setBirthday(e.target.value);
  };
  // phone
  const handlePhoneChange = (e: any) => {
    setPhone(e.target.value);
  };
  // Dân tộc
  const handleEthnicChange = (e: any) => {
    setEthnic(e.target.value);
  };
  // Địa chỉ tạm trú
  const handleTemporary_addressChange = (e: any) => {
    setTemporary_address(e.target.value);
  };
  // Địa chỉ thường trú
  const handlePermanent_addressChange = (e: any) => {
    setPermanent_address(e.target.value);
  };
  // Tài khoản ngân hàng
  const handleBankChange = (e: any) => {
    setBank(e.target.value);
  };
  // Bảo hiểm xã hội
  const handleBhxh = (e: any) => {
    setBhxh(e.target.value);
  };
  // Căn cước công dân
  const handleCccd = (e: any) => {
    setCccd(e.target.value);
  };
  // Nơi cấp
  const handleNoicap = (e: any) => {
    setNoicap(e.target.value);
  };
  // Ngày cấp
  const handleNgaycap = (e: any) => {
    setNgaycap(e.target.value);
  };
  // status
  const handleChangeStatus = (e: any) => {
    setStatus(e);
  };
  // setPermission_data
  const handleChangePermission_data = (e: any) => {
    setManage(dataListManager[0])
    setPermission_data(e);
  };
  // hình thức làm việc
  const handleChangeWorking_from = (e: any) => {
    setWorking_form(e);
  };
  // Giới tính
  const handleSex = (e: any) => {
    setSex(e);
  };
  // phòng ban
  const handleDepartment_idChange = (e: any) => {
    setDepartment(e);
  };
  // Chức vụ
  const handleOfficeChange = (e: any) => {
    setOffice(e);
  };
  // Bộ phận
  const handlePartsChange = (e: any) => {
    setParts(e);
  };
  // Nhóm quyền
  const handleChangeRoles = (e: any) => {
    setRoles(e);
  };

  const dataProvider = useDataProvider();

  // ################## -API- ##############
  // api phòng ban
  useEffect(() => {
    if(openDialog) {
      HttpDataService.getAll("department/getAllDepartment").then((res) => {
        let data = res.data.data;
        dataDepartment_id = [];
        data.map((item: any) => {
          dataDepartment_id.push({
            id: item.id,
            label: item.name,
          });
        });
      });
    }
    
  }, [openDialog]);

  // api chức vụ
  useEffect(() => {
    if(openDialog) {
      HttpDataService.getAll("office/getAllOffice").then((res) => {
        let data = res.data.data;
        dataOffice = [];
        data.map((item: any) => {
          dataOffice.push({
            id: item.id,
            label: item.name,
          });
        });
      });
    }
  }, [openDialog]);

  // api Bộ phận
  useEffect(() => {
    if(openDialog) {
      HttpDataService.getAll("parts/getAllParts").then((res) => {
        let data = res.data.data;
        dataParts = [];
        data.map((item: any) => {
          dataParts.push({
            id: item.id,
            label: item.name,
          });
        });
      });
    }
  }, [openDialog]);

  // api Nhóm quyền
  useEffect(() => {
    if(openDialog) {
      HttpDataService.getAll("roles/getAllRoles").then((res) => {
        dataRoles = [{ label: "--Lựa chọn--", id: "" }];
        let data = res.data.data;
        data.map((item: any) => {
          dataRoles.push({
            id: item.id,
            label: item.name,
          });
        });
      });
    }
  }, [openDialog]);

  // api danh sách quản lý
  useEffect(() => {
    if(openDialog) {
      HttpDataService.getAll("users/selectUserByType?type=2").then((res) => {
        dataListManager = [{ label: "--Lựa chọn--", id: "" }];
        let data = res.data.data;
        data.map((item: any) => {
          dataListManager.push({
            id: item.id,
            label: item.name,
          });
        });
      });
    }
  }, [openDialog]);

  const [locale, setLocale] = React.useState<(typeof locales)[number]>("vi");
  const removeImageUtilities = (imgIndex: number) => {
    const newListImage = image.filter((_, index) => index !== imgIndex);
    if (image.length === 1) {
      setImage([]);
    }
    setImage(newListImage);
  };

  const createUser = async (data: any) => {
    const submitObj = new FormData();
    if (image) {
      image.map((item: any) => {
        submitObj.append("image", item);
      });
    }
    Object.keys(data).map((key) =>
      // console.log(data[key])
      submitObj.append(key, data[key])
    );
    try {
      const response = await HttpDataService.create(
        "users/addUsers",
        submitObj
      );
      return response.status;
    } catch (error) {
      console.log(error);
    }
  };
  /**
   * handle save
   */
  const handleSave = () => {
    if (!fnValidation()) {
      let data = {
        first_name: first_name.trim(),
        last_name: last_name.trim(),
        username: username.trim(),
        email: email.trim(),
        password: password.trim(),
        password_confirm: password_confirm.trim(),
        status: status?.id || 0,
        show_home: showHome?.id || 0,
        phone: phone,
        working_form: working_form?.id || 0,
        department_id: department?.id || 0,
        ethnic: ethnic,
        temporary_address: temporary_address,
        bank: bank,
        bhxh: bhxh,
        noicap: noicap,
        ngaycap: ngaycap ? ngaycap : "",
        cccd: cccd,
        permanent_address: permanent_address,
        email_ca_nhan: email_ca_nhan,
        sex: sex?.id || 0,
        parts_id: parts?.id || 0,
        office_id: office?.id || 0,
        permission_data: permission_data?.id || 0,
        manage_id: manage?.id || 0,
        birthday: birthday ? birthday : "",
        roles_id: roles?.id || 0,
        image: image,
      };
      const submit = async (data: any) => {
        const result = await createUser(data);
        console.log(data);
        if (result === 200) {
          notify(`Thêm mới thành công`, {
            type: "success",
            undoable: true,
          });
          refresh();
        } else {
          notify(`Thêm mới thất bại`, {
            type: "error",
            undoable: true,
          });
        }
      };
      submit(data);
      fnResetFrm();
      onCloseDialog();
    }
  };

  const handleCloseDialog = () => {
    fnResetFrm();
    onCloseDialog();
  };

  /**
   * fn validation form
   */
  function fnValidation() {
    let error = false;
    if (first_name.trim().length === 0) {
      error = true;
      setFirst_nameError(true);
      setTextFirst_nameError(translate("resources.user.fields.val_first_name"));
    }
    if (last_name.trim().length === 0) {
      error = true;
      setLast_nameError(true);
      setTextLast_nameError(translate("resources.user.fields.val_last_name"));
    }
    if (email.trim().length === 0) {
      error = true;
      setEmailError(true);
      setTextEmailError(translate("resources.user.fields.val_email"));
    }
    if (username.trim().length === 0) {
      error = true;
      setUsernameError(true);
      setTextUsernameError(translate("resources.user.fields.val_account"));
    }
    if (password.trim().length === 0) {
      error = true;
      setPasswordError(true);
      setTextPasswordError(translate("resources.user.fields.val_password"));
    }
    if (password_confirm.trim().length === 0) {
      error = true;
      setPassword_confirmError(true);
      setTextPassword_confirmError(
        translate("resources.user.fields.val_retype_password")
      );
    }
    return error;
  }

  function fnResetFrm() {
    setEmail("");
    setEmailError(false);
    setTextEmailError("");
    setFirst_name("");
    setFirst_nameError(false);
    setTextFirst_nameError("");
    setLast_name("");
    setLast_nameError(false);
    setTextLast_nameError("");
    setStatus(null);
    setBirthday(null);
    setPhone("");
    setTemporary_address("");
    setPermanent_address("");
    setBank("");
    setBhxh("");
    setCccd("");
    setNoicap("");
    setNgaycap(null);
    setEthnic("");
    setEmail_ca_nhan("");
    setUsername("");
    setUsernameError(false);
    setTextUsernameError("");
    setPassword("");
    setPasswordError(false);
    setTextPasswordError("");
    setPassword_confirm("");
    setPassword_confirmError(false);
    setTextPassword_confirmError("");
    setPermission_data(null);
    setWorking_form(null);
    setSex(null);
    setDepartment(null);
    setOffice(null);
    setParts(null);
    setRoles(dataRoles[0]);
    setManage(dataListManager[0]);
    setShowHome(dataShowHome[0]);

  }

  return (
    <>
      <Dialog
        open={openDialog}
        fullWidth
        PaperProps={{
          style: {
            borderRadius: 2,
            minWidth: "65%",
          },
        }}
      >
        <div className={"common__dialogTitle_area"}>
          <DialogTitle className={"member__dialogTitle"}>
            <div>{translate("resources.user.add_user")}</div>
          </DialogTitle>
          <div className={"common__dialogTitle_actions"}>
            <IconButton
              aria-label="exists"
              color={"error"}
              onClick={handleCloseDialog}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <Box className={"dialog_box"}>
          <DialogContent className={"dialog_content"}>
            <p className={"title-add-user"}>
              {translate("resources.user.info_contact_user")}
            </p>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={6}>
                <Item className={"item_li"}>
                  <TextField
                    className={`${classes.root} mb-23`}
                    fullWidth
                    label={
                      <span className={"span_style"}>
                        {translate("resources.user.fields.first_name_user")}
                        <span style={{ color: "red" }}>(*)</span>
                      </span>
                    }
                    value={first_name}
                    error={first_nameError}
                    helperText={textFirst_nameError}
                    onChange={handleFirst_nameChange}
                    variant="outlined"
                  />
                </Item>
                <Item className={"item_li"}>
                  <TextField
                    className={`${classes.root} mb-23`}
                    fullWidth
                    label={
                      <span className={"span_style"}>
                        {translate("resources.user.fields.last_name_user")}
                        <span style={{ color: "red" }}>(*)</span>
                      </span>
                    }
                    value={last_name}
                    error={last_nameError}
                    helperText={textLast_nameError}
                    onChange={handleLast_nameChange}
                    variant="outlined"
                  />
                </Item>
                <Item className={"item_li"}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={locale}
                  >
                    <DatePicker
                      label={
                        <span className={"span_style"}>
                          {translate("resources.user.fields.birthday_user")}
                        </span>
                      }
                      value={birthday}
                      inputFormat="DD/MM/YYYY"
                      onChange={(newValue) => {
                        setBirthday(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          className=" mb-23"
                          {...params}
                          fullWidth
                          variant="outlined"
                        />
                      )}
                      className={"aaaa"}
                    />
                  </LocalizationProvider>
                </Item>
                <Item className={"item_li"}>
                  <TextField
                    className="mb-23"
                    fullWidth
                    label={
                      <span className={"span_style"}>
                        {translate("resources.user.fields.phone")}
                      </span>
                    }
                    value={phone}
                    onChange={handlePhoneChange}
                    variant="outlined"
                    type="number"
                  />
                </Item>
                <Item className={"item_li"}>
                  <TextField
                    className="mb-23"
                    fullWidth
                    label={
                      <span className={"span_style"}>
                        {translate("resources.user.fields.ethnic_user")}
                      </span>
                    }
                    value={ethnic}
                    onChange={handleEthnicChange}
                    variant="outlined"
                  />
                </Item>
                <Item className={"item_li"}>
                  <TextField
                    className="mb-23"
                    fullWidth
                    label={
                      <span className={"span_style"}>
                        {translate("resources.user.fields.dctt_user")}
                      </span>
                    }
                    value={temporary_address}
                    onChange={handleTemporary_addressChange}
                    variant="outlined"
                  />
                </Item>
                <Item className={"item_li"}>
                  <TextField
                    className="mb-23"
                    fullWidth
                    label={
                      <span className={"span_style"}>
                        {translate("resources.user.fields.dcttru_user")}
                      </span>
                    }
                    value={permanent_address}
                    onChange={handlePermanent_addressChange}
                    variant="outlined"
                  />
                </Item>

                <Item className={"item_li"}>
                  <TextField
                    className="mb-23"
                    fullWidth
                    label={
                      <span className={"span_style"}>
                        {translate("resources.user.fields.personal_email_user")}
                      </span>
                    }
                    value={email_ca_nhan}
                    onChange={handleEmail_ca_nhan}
                    variant="outlined"
                  />
                </Item>
                <Item className={"item_li"}>
                  <TextField
                    className="mb-23"
                    fullWidth
                    label={
                      <span className={"span_style"}>
                        {translate("resources.user.fields.bank")}
                      </span>
                    }
                    value={bank}
                    onChange={handleBankChange}
                    variant="outlined"
                    type="number"
                  />
                </Item>
                <Item className={"item_li"}>
                  <TextField
                    className="mb-23"
                    fullWidth
                    label={
                      <span className={"span_style"}>
                        {translate("resources.user.fields.bhxh")}
                      </span>
                    }
                    value={bhxh}
                    onChange={handleBhxh}
                    variant="outlined"
                  />
                </Item>
              </Grid>
              <Grid item xs={6}>
                <Item className={"item_li"}>
                  <TextField
                    className="mb-23"
                    fullWidth
                    label={
                      <span className={"span_style"}>
                        {translate("resources.user.fields.id_user")}
                      </span>
                    }
                    value={cccd}
                    onChange={handleCccd}
                    variant="outlined"
                    type="number"
                  />
                </Item>

                <Item className={"item_li"}>
                  <TextField
                    className=" mb-23"
                    fullWidth
                    label={
                      <span className={"span_style"}>
                        {translate("resources.user.fields.noicap")}
                      </span>
                    }
                    value={noicap}
                    onChange={handleNoicap}
                    variant="outlined"
                  />
                </Item>
                <Item className={"item_li"}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={locale}
                  >
                    <DatePicker
                      label={
                        <span className={"span_style"}>
                          {translate("resources.user.fields.ngaycap")}
                        </span>
                      }
                      value={ngaycap}
                      inputFormat="DD/MM/YYYY"
                      onChange={(newValue) => {
                        setNgaycap(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          className="mb-23"
                          {...params}
                          fullWidth
                          variant="outlined"
                        />
                      )}
                      className={"ngaycap"}
                    />
                  </LocalizationProvider>
                </Item>
                <Item className={"item_li"}>
                  <Autocomplete
                    renderInput={(params) => (
                      <TextField
                        className="mb-23"
                        {...params}
                        variant="outlined"
                        label={
                          <span className={"span_style"}>
                            {translate("resources.user.fields.sex_user")}
                          </span>
                        }
                      />
                    )}
                    sx={{ width: "100%" }}
                    value={sex}
                    onChange={(event, value) => handleSex(value)}
                    options={dataSex}
                  />
                </Item>
                <Item className={"item_li"}>
                  <Autocomplete
                    renderInput={(params) => (
                      <TextField
                        className="mb-23"
                        {...params}
                        variant="outlined"
                        label={
                          <span className={"span_style"}>
                            {translate("resources.user.fields.working_form")}
                          </span>
                        }
                      />
                    )}
                    sx={{ width: "100%" }}
                    value={working_form}
                    onChange={(event, value) => handleChangeWorking_from(value)}
                    options={dataWorking_form}
                  />
                </Item>
                <Item className={"item_li"}>
                  <Autocomplete
                    renderInput={(params) => (
                      <TextField
                        className="mb-23"
                        {...params}
                        variant="outlined"
                        label={
                          <span className={"span_style"}>
                            {translate("resources.user.fields.department")}
                          </span>
                        }
                      />
                    )}
                    sx={{ width: "100%" }}
                    value={department}
                    onChange={(event, value) =>
                      handleDepartment_idChange(value)
                    }
                    options={dataDepartment_id}
                    getOptionLabel={(option) => `${option.label}`}
                  />
                </Item>
                <Item className={"item_li"}>
                  <Autocomplete
                    renderInput={(params) => (
                      <TextField
                        className="mb-23"
                        {...params}
                        variant="outlined"
                        label={
                          <span className={"span_style"}>
                            {translate("resources.user.fields.office")}
                          </span>
                        }
                      />
                    )}
                    sx={{ width: "100%" }}
                    value={office}
                    onChange={(event, value) => handleOfficeChange(value)}
                    options={dataOffice}
                    getOptionLabel={(option) => `${option.label}`}
                  />
                </Item>
                <Item className={"item_li"}>
                  <Autocomplete
                    renderInput={(params) => (
                      <TextField
                        className="mb-23"
                        {...params}
                        variant="outlined"
                        label={
                          <span className={"span_style"}>
                            {translate("resources.user.fields.parts")}
                          </span>
                        }
                      />
                    )}
                    sx={{ width: "100%" }}
                    value={parts}
                    onChange={(event, value) => handlePartsChange(value)}
                    options={dataParts}
                    getOptionLabel={(option) => `${option.label}`}
                  />
                </Item>

                <Item className={"item_li"}>
                  <Autocomplete
                    renderInput={(params) => (
                      <TextField
                        className="mb-23"
                        {...params}
                        variant="outlined"
                        label={
                          <span className={"span_style"}>
                            {translate("resources.common.fields.status")}
                          </span>
                        }
                      />
                    )}
                    sx={{ width: "100%" }}
                    value={status}
                    onChange={(event, value) => handleChangeStatus(value)}
                    options={dataStatus}
                  />
                </Item>
                <Item className={"item_li"}>
                  <Autocomplete
                    renderInput={(params) => (
                      <TextField
                        className="mb-23"
                        {...params}
                        variant="outlined"
                        label={
                          <span className={"span_style"}>
                            {`Hiện trang chủ`}
                          </span>
                        }
                      />
                    )}
                    sx={{ width: "100%" }}
                    value={showHome}
                    onChange={(event, value) => setShowHome(value)}
                    options={dataShowHome}
                  />
                </Item>
              </Grid>
            </Grid>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <div className={"main-image"}>
                <div className={cx("upload-image-wrapper")}>
                  <div className={"title-image"}>Hình ảnh</div>
                  <input
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    onChange={(e: any) => {
                      setImage([...e.target.files]);
                      e.target.value = null;
                    }}
                  ></input>
                  {image.length > 0 && (
                    <div className={cx("image-show-wrapper")}>
                      {image.map((image: any, index: number) => (
                        <ImagePreview
                          key={index}
                          src={URL.createObjectURL(image)}
                          onRemove={() => removeImageUtilities(index)}
                        />
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </Grid>
            <p className={"Section_addUser title-add-user"}>
              {translate("resources.user.info_log")}
            </p>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              {/*Thông tin đăng nhập*/}
              <Grid item xs={6}>
                <Item className={"item_li"}>
                  <TextField
                    className={`${classes.root} mb-23`}
                    fullWidth
                    label={
                      <span className={"span_style"}>
                        {translate("resources.user.fields.account_user")}
                        <span style={{ color: "red" }}>(*)</span>
                      </span>
                    }
                    value={username}
                    error={usernameError}
                    helperText={textUsernameError}
                    onChange={handleUsernameChange}
                    variant="outlined"
                  />
                </Item>
                <Item className={"item_li"}>
                  <TextField
                    className={`${classes.root} mb-23`}
                    fullWidth
                    label={
                      <span className={"span_style"}>
                        {translate("resources.user.fields.email_user")}
                        <span style={{ color: "red" }}>(*)</span>
                      </span>
                    }
                    value={email}
                    error={emailError}
                    helperText={textEmailError}
                    onChange={handleEmailAddChange}
                    variant="outlined"
                  />
                </Item>
                <Item className={"item_li"}>
                  <Autocomplete
                    renderInput={(params) => (
                      <TextField
                        className="mb-23"
                        {...params}
                        variant="outlined"
                        label={
                          <span className={"span_style"}>
                            {translate(
                              "resources.user.fields.group_permission"
                            )}
                          </span>
                        }
                      />
                    )}
                    sx={{ width: "100%" }}
                    value={roles}
                    onChange={(event, value) => handleChangeRoles(value)}
                    options={dataRoles}
                  />
                </Item>
                <Item className={"item_li"}>
                </Item>
              </Grid>
              <Grid item xs={6} className={"abc"}>
                <Item className={"item_li"}>
                  <TextField
                    className={`${classes.root} mb-23`}
                    fullWidth
                    label={
                      <span className={"span_style"}>
                        {translate("resources.user.fields.password_user")}
                        <span style={{ color: "red" }}>(*)</span>
                      </span>
                    }
                    value={password}
                    error={passwordError}
                    helperText={textPasswordError}
                    onChange={handlePasswordChange}
                    variant="outlined"
                    type="password"
                  />
                </Item>
                <Item className={"item_li"}>
                  <TextField
                    className={`${classes.root} mb-23`}
                    fullWidth
                    label={
                      <span className={"span_style"}>
                        {translate(
                          "resources.user.fields.retype_password_user"
                        )}
                        <span style={{ color: "red" }}>(*)</span>
                      </span>
                    }
                    value={password_confirm}
                    error={password_confirmError}
                    helperText={textPassword_confirmError}
                    onChange={handlePassword_confirmChange}
                    variant="outlined"
                    type="password"
                  />
                </Item>
                <Item className={"item_li"}>
                  <Autocomplete
                    renderInput={(params) => (
                      <TextField
                        className="mb-23"
                        {...params}
                        variant="outlined"
                        label={
                          <span className={"span_style"}>
                            {translate("resources.user.fields.permission")} 
                          </span>
                        }
                      />
                    )}
                    sx={{ width: "100%" }}
                    value={permission_data}
                    onChange={(event, value) =>
                      handleChangePermission_data(value)
                    }
                    options={dataPermission_data}
                  />
                </Item>
                {permission_data?.id == 3 && (
                  <Item className={"item_li"}>
                  <Autocomplete
                    renderInput={(params) => (
                      <TextField
                        className="mb-23"
                        {...params}
                        variant="outlined"
                        label={
                          <span className={"span_style"}>
                            Quản lý
                          </span>
                        }
                      />
                    )}
                    sx={{ width: "100%" }}
                    value={manage}
                    onChange={(event, value) =>
                      setManage(value)
                    }
                    options={dataListManager}
                  />
                </Item>

                )}
              </Grid>
            </Grid>
          </DialogContent>
        </Box>
        <Toolbar>
          <Box display="flex" justifyContent="end" width={"100%"}>
            <Button
              variant="contained"
              startIcon={<SaveIcon />}
              onClick={handleSave}
            >
              {translate("resources.common.fields.save")}
            </Button>
            &nbsp;&nbsp;
            <Button
              startIcon={<ReplyIcon />}
              className={"button-close"}
              variant="contained"
              onClick={handleCloseDialog}
            >
              {translate("resources.common.fields.cancel")}
            </Button>
          </Box>
        </Toolbar>
      </Dialog>
    </>
  );
};
