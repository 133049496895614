import {Autocomplete, Dialog, DialogContent, DialogTitle, IconButton, TextField} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Box from "@mui/material/Box";
import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import SaveIcon from '@mui/icons-material/Save';
import ReplyIcon from '@mui/icons-material/Reply';
import Toolbar from "@mui/material/Toolbar";
import {useDataProvider, useNotify, useRefresh, useTranslate, required} from "react-admin";
import HttpDataService from "../dataProvider/http.service";
import { createStyles, makeStyles } from '@material-ui/core/styles';
import axios from "axios";
import { constants } from "../shared/constants";
import { Menu } from "../layout";
import { useNavigate } from "react-router";



const dataParentPer: any[]  = []

const styles = makeStyles((theme: any) =>
    createStyles({
        root: {
            '& .css-k4qjio-MuiFormHelperText-root.Mui-error':{
                position: 'absolute',
                paddingTop: '41px',
            },
            '& .css-1z10yd4-MuiFormControl-root-MuiTextField-root':{
                marginTop: 20,
                marginBottom: 20,
            }
        }
    }));

export const MenuAdd = (props: any) => {
    const classes = styles()
    const { onCloseDialog, openDialog } = props;
    const [name, setName] = useState<string>("")
    const [nameError, setNameError] = useState(false)
    const [textNameError, setTextNameError] = useState("")
    const [code, setCode] = useState<string>("")
    const [icon, setIcon] = useState<string>("")
    const [codeError, setCodeError] = useState(false)
    const [textCodeError, setTextCodeError] = useState("")
    const [parent, setParent] = useState<any>("")
    const [des, setDes] = useState<string>("")
    const [order, setOrder] = useState<string>("")
    const translate = useTranslate();
    const dataStatus = [
        { label: translate('resources.common.fields.active'), id: 1 },
        { label: translate('resources.common.fields.block'), id: 0 },
    ]
    const [status, setStatus] = useState<any>(dataStatus[0])
    const [slug, setSlug] = useState<string>("")
    const [textSlugError, setTextSlugError] = useState("")
    const navigate = useNavigate()

    useEffect(() => {
        HttpDataService.getAll('menu_admin/getParent')
            .then((response: any) =>
            {
                if(response.data.status) {
                    const res_record = response.data.data;
                    res_record.map((item: any) => {
                        dataParentPer.push({
                            id: item.id,
                            label: `${item.name}`,
                        });
                    });

                } else {
                    // @ts-ignore
                    notify(response.data.message,{type: 'error'});
                }
            })
            .catch((e: Error) => {
                console.log(e);
            });
    }, []);

    const refresh = useRefresh();
    const notify = useNotify();

    /**
     * handle change name
     * @param e
     */
    const handleNameChange = (e: any) => {
        setNameError(false)
        setTextNameError("")
        setName(e.target.value)
    }

    /**
     * handle change code
     * @param e
     */
    const handleCodeChange = (e: any) => {
        setCodeError(false)
        setTextCodeError("")
        setCode(e.target.value)
    }
    const handleIconChange = (e: any) => {
        setIcon(e.target.value)
    }
    const handleDesChange = (e: any) => {
        setDes(e.target.value)
    }

    const handleSlugChange = (e: any) => {
        setSlug(e.target.value)
    }

    const handleOrderChange = (e: any) => {
           setOrder(e.target.value)
    }

    /**
     *
     * @param e
     */
    const handleChangeParent = (e: any) => {
        const parent_choose = dataParentPer.find(i => i.id==e.id);
        setParent(parent_choose)
    }
    /**
     *
     * @param e
     */
    const handleChangeStatus = (e: any) => {
        setStatus(e)
    }

    /**
     * handle save
     */
    const handleSave = () => {
        if(!fnValidation()) {
         
            let data = {
                name: name.trim(),
                code: code.trim(),
                icon: icon,
                description: des,
                order: parseInt(order),
                status: status?.id,
                parent_id: parent?.id,
                slug: slug,
            }
            HttpDataService.create('menu_admin/add', data)
                .then( async(response: any) =>
                {
                    if(response.data.status) {
                        // @ts-ignore
                        const authorizationToken = localStorage.getItem(constants.LOCAL_STORAGE.TOKEN)
                        const { data } = await axios.get(`${process.env.REACT_APP_BASE_MODULE_URL}/menu_admin/getAll`,
                        {
                            headers: {
                                Authorization: `Bearer ${authorizationToken}`,   
                            }
                        })
                        const { items } = data?.data;
                        localStorage.setItem(
                            constants.LOCAL_STORAGE.LIST_MENU,
                            JSON.stringify(items)
                        )
                        window.location.reload();
                        notify(response.data.message,{type: 'success'});
                    } else {
                        // @ts-ignore
                        notify(response.data.message,{type: 'error'});
                    }
                })
                .catch((e: Error) => {
                    console.log(e);
                });

            fnResetFrm();
            onCloseDialog();
            refresh();

        }
    }

    const handleCloseDialog = () => {
        fnResetFrm()
        onCloseDialog();
    }

    /**
     * fn validation form
     */
    function fnValidation() {
        let error = false
        if (name.trim().length === 0) {
            error = true
            setNameError(true)
            setTextNameError(translate('resources.menu.fields.val_name'))
        }

        if (code.trim().length === 0) {
            error = true
            setCodeError(true)
            setTextCodeError(translate('resources.menu.fields.val_code'))
        }

        return error;
    }

    function fnResetFrm() {
        setName("");
        setNameError(false);
        setTextNameError("");
        setCode("");
        setCodeError(false);
        setTextCodeError("");
        setStatus(null);
        setParent(null)
        setSlug("");
        setDes("");
        setDes("")
    }

    return (
        <>
            <Dialog
                open={openDialog}
                fullWidth
                PaperProps={{
                    style: {
                        borderRadius: 2,
                        // minWidth: '85%',
                    },
                }}
            >
                <div className={'common__dialogTitle_area'}>
                    <DialogTitle className={'member__dialogTitle'}>
                        <div>
                            {translate('resources.menu.add_menu')}
                        </div>
                    </DialogTitle>
                    <div className={'common__dialogTitle_actions'}>
                        <IconButton
                            aria-label="exists"
                            color={'error'}
                            onClick={() => {
                                onCloseDialog(false);
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
                <Box className={'dialog_box'}>
                    <DialogContent className={'dialog_content'}>
                        <TextField
                            className={`${classes.root} mb-23`}
                            fullWidth
                            label={
                                <span className={'span_style'}>
                                     {translate('resources.menu.fields.name_menu')}
                                    <span style={{ color: 'red' }}>(*)</span>
                                </span>
                            }
                            value={name}
                            error={nameError}
                            helperText={textNameError}
                            onChange={handleNameChange}
                            variant="outlined"
                        />
                        <TextField
                           className={`${classes.root} mb-23`}
                            fullWidth
                            label={
                                <span className={'span_style'}>
                                     {translate('resources.menu.fields.code_menu')}
                                    <span style={{ color: 'red' }}>(*)</span>
                                </span>
                            }
                            value={code}
                            error={codeError}
                            helperText={textCodeError}
                            onChange={handleCodeChange}
                            variant="outlined"
                        />
                        <TextField
                           className={`${classes.root} mb-23`}
                            fullWidth
                            label={
                                <span className={'span_style'}>
                                     <span>Icon</span>
                                </span>
                            }
                            value={icon}
                            onChange={handleIconChange}
                            variant="outlined"
                        />

                            <TextField
                                type="number"
                            className="mb-23"
                            fullWidth
                            label={
                                <span className={'span_style'}>
                                    {translate('resources.menu.fields.order')}
                                    <span style={{ color: 'red' }}></span>
                                </span>
                            }
                            value={order}
                            onChange={handleOrderChange}
                            variant="outlined"
                        />
         



                        <TextField
                            className="mb-23"
                            fullWidth
                            label={
                                <span className={'span_style'}>
                                     {translate('resources.menu.fields.des_menu')}
                                    <span style={{ color: 'red' }}></span>
                                </span>
                            }
                            value={des}
                            onChange={handleDesChange}
                            variant="outlined"
                        />

                        <TextField
                            className="mb-23"
                            fullWidth
                            label={
                                <span className={'span_style'}>
                                    {translate('resources.menu.fields.slug_menu')}
                                    <span style={{ color: 'red' }}></span>
                                </span>
                            }
                            value={slug}
                            onChange={handleSlugChange}
                            variant="outlined"
                        />

                        <Autocomplete
                            className="mb-23"
                            renderInput={(params) => <TextField
                                {...params}
                                variant="outlined"
                                label={
                                    <span className={'span_style'}>{translate('resources.menu.fields.father_menu')}</span>
                                }
                            />}
                            sx={{width: '100%'}}
                            value={parent}
                            onChange={(event, value) => handleChangeParent(value)}
                            options={dataParentPer}
                        />

                        <Autocomplete
                            renderInput={(params) => <TextField
                                {...params}
                                variant="outlined"
                                label={
                                    <span className={'span_style'}>{translate('resources.common.fields.status')}</span>
                                }
                            />}
                            sx={{width: '100%'}}
                            value={status}
                            onChange={(event, value) => handleChangeStatus(value)}
                            options={dataStatus}
                        />

                    </DialogContent>
                </Box>
                <Toolbar>
                    <Box
                        display="flex"
                        justifyContent="center"
                        width={'100%'}
                    >
                        <Button variant="contained"
                                startIcon={<SaveIcon/>} onClick={handleSave}>{translate('resources.common.fields.save')}</Button>
                        &nbsp;&nbsp;
                        <Button
                            startIcon={<ReplyIcon/>}
                            className={'button-close'}
                            variant="contained"
                            onClick={handleCloseDialog}
                        >
                            {translate('resources.common.fields.cancel')}
                        </Button>
                    </Box>
                </Toolbar>
            </Dialog>
        </>
    );
}