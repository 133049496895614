import { CreateButton, CreateProps, useNotify, useRefresh, useTranslate } from "react-admin";
import { Dialog, DialogContent, DialogTitle, Grid, IconButton } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import ReplyIcon from "@mui/icons-material/Reply";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import HttpDataService from "../dataProvider/http.service";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import InputLabel from "../component/InputLabel";
import ImagePreview from "../component/ImgPreview";

import { InputRowWrapper, ItemWrapper, ItemWrapper30, MultilineWrapper } from "../projectEstate/dialogs/styledMui";
import Editor from "../component/Editor";
import CkeditorServer from "../component/CkeditorServer";

const styles = makeStyles((theme: any) =>
	createStyles({
		root: {
			"& .css-k4qjio-MuiFormHelperText-root.Mui-error": {
				position: "absolute",
				paddingTop: "41px",
			},
		},
		box_image: {
			"& ": {
				border: "1px solid #ccc",
				padding: "10px",
				borderRadius: "10px",
				marginBottom: "10px",
				marginTop: "15px",
			},
		},
	})
);

let dataProjectTypeEstate_id: any[] = [];

type ErrorTypes = {
	isError: boolean;
	message: String;
};

export const ProjectTypeAdd = (props: any) => {
	const classes = styles();
	const { onCloseDialog, openDialog } = props;

	const translate = useTranslate();

	const dataStatus = [
		{ label: translate("resources.common.fields.active"), id: 1 },
		{ label: translate("resources.common.fields.block"), id: 0 },
	];

	const [listTypeParent, setListTypeParent] = useState<any[]>([]);
	const [name, setName] = useState<string>("");
	const [parent, setParent] = useState<any>(null);
	const [nameError, setNameError] = useState(false);
	const [depth, setDepth] = useState<string>("");
	const [meta_title, setMetaTitle] = useState<string>("");
	const [meta_description, setMetaDescription] = useState<string>("");
	const [meta_keyword, setMetaKeyword] = useState<string>("");
	const [depthError, setDepthError] = useState(false);
	const [image, setImage] = useState<any>();
	const [content, setContent] = useState<string>("");
	const [slug, setSlug] = useState<string>("");

	const [slugError, setslugError] = useState(false);
	const [typeEstateErr, setTypeEstateErr] = React.useState<ErrorTypes | null>();

	const [textNameError, setTextNameError] = useState("");
	const [textDepthError, setTextDepthError] = useState("");
	const [textSlugError, setTextSlugError] = useState("");
	const [status, setStatus] = useState<any>(dataStatus[0]);

	const [projectTypeEstate, setProjecTypeEstate] = useState<any>(null);

	const refresh = useRefresh();
	const notify = useNotify();

	/**
	 * handle change name
	 * @param e
	 */
	const handleNameChange = (e: any) => {
		setNameError(false);
		setTextNameError("");
		setName(e.target.value);
	};

	const handleDepthChange = (e: any) => {
		setDepthError(false);
		setTextDepthError("");
		setDepth(e.target.value);
	};

	const handleSlugChange = (e: any) => {
		setslugError(false);
		setTextSlugError("");
		setSlug(e.target.value);
	};

	const handleMetaTitleChange = (e: any) => {
		setMetaTitle(e.target.value);
	};
	const handleMetaDescriptionChange = (e: any) => {
		setMetaDescription(e.target.value);
	};
	const handleMetaKeywordChange = (e: any) => {
		setMetaKeyword(e.target.value);
	};

	const handleChangeParent = (e: any) => {
		console.log(e, "handleChangeParent");
	};

	const handleChangeStatus = (e: any) => {
		setStatus(e);
		console.log(e);
	};

	const handleChangeProjectTypeEstate = (e: any) => {
		setProjecTypeEstate(e);
	};

	// ################## -API- ##############
	// api loại hình
	useEffect(() => {
		HttpDataService.getAll("project_type_estate/getAll").then((res) => {
			let data = res.data.data;
			dataProjectTypeEstate_id = [];
			data.map((item: any) => {
				dataProjectTypeEstate_id.push({
					id: item.id,
					label: item.name,
					slug: item.slug,
				});
			});
		});

		HttpDataService.getAll("project_type/getAllProjectType").then((res) => {
			let data = res.data.data;
			setListTypeParent(data);
		});
	}, []);

	/**
	 * handle save
	 */
	const handleSave = async () => {
		if (!fnValidation()) {
			// let data = {
			//     name: name.trim(),
			//     depth: depth,
			//     slug: slug.trim(),
			//     status: status.id,
			//     meta_title: meta_title.trim(),
			//     meta_description: meta_description.trim(),
			//     meta_keyword: meta_keyword.trim(),
			//     project_type_estate: projectTypeEstate?.id || 0,
			//     parent_id: parent?.id || 0
			// }
			const submitObj = new FormData();
			submitObj.append("name", name);
			submitObj.append("depth", depth);
			submitObj.append("slug", slug);
			submitObj.append("status", status?.id);
			submitObj.append("meta_title", meta_title);
			submitObj.append("meta_description", meta_description);
			submitObj.append("meta_keyword", meta_keyword);
			submitObj.append("project_type_estate", projectTypeEstate?.id || 0);
			submitObj.append("parent_id", parent?.id || 0);
			submitObj.append("image", image);
			submitObj.append("content", content);
			await HttpDataService.create("project_type/addProjectType", submitObj)
				.then((response: any) => {
					if (response.data.status) {
						// @ts-ignore
						notify(response.data.message, { type: "success" });
					} else {
						// @ts-ignore
						notify(response.data.message, { type: "error" });
					}
				})
				.catch((e: Error) => {
					console.log(e);
				});

			fnResetFrm();
			onCloseDialog();
			refresh();
		}
	};

	const handleCloseDialog = () => {
		fnResetFrm();
		onCloseDialog();
	};

	/**
	 * fn validation form
	 */
	function fnValidation() {
		let error = false;
		if (name.trim().length === 0) {
			error = true;
			setNameError(true);
			setTextNameError(translate("resources.project_type.fields.val_name"));
		}
		if (slug.trim().length === 0) {
			error = true;
			setslugError(true);
			setTextSlugError(translate("resources.project_type.fields.val_slug"));
		}
		console.log(projectTypeEstate);
		if (!projectTypeEstate) {
			error = true;
			setTypeEstateErr({
				isError: true,
				message: "Loại hình  không được để trống!",
			});
		}

		return error;
	}

	function fnResetFrm() {
		setName("");
		setParent(null);
		setDepth("");
		setSlug("");
		setDepthError(false);
		setNameError(false);
		setslugError(false);
		setTextNameError("");
		setTextDepthError("");
		setTextSlugError("");
		setStatus(null);
		setProjecTypeEstate(null);
		setMetaKeyword("");
		setMetaDescription("");
		setMetaTitle("");
		setTypeEstateErr(null);
		setContent("");
		setImage(null);
	}

	return (
		<>
			<Dialog
				disableEnforceFocus
				open={openDialog}
				maxWidth={"md"}
				PaperProps={{
					style: {
						borderRadius: 2,
					},
				}}
			>
				<div className={"common__dialogTitle_area"}>
					<DialogTitle className={"member__dialogTitle"}>
						<div>{translate("resources.project_type.add_project_type")}</div>
					</DialogTitle>
					<div className={"common__dialogTitle_actions"}>
						<IconButton
							aria-label="exists"
							color={"error"}
							onClick={() => {
								fnResetFrm();
								onCloseDialog(false);
							}}
						>
							<CloseIcon />
						</IconButton>
					</div>
				</div>
				<Box className={"dialog_box"}>
					<DialogContent className={"dialog_content"}>
						<InputRowWrapper>
							<ItemWrapper>
								<TextField
									className={`${classes.root} mb-23`}
									fullWidth
									label={
										<span className={"span_style"}>
											{translate("resources.project_type.fields.name_project_type")}
											<span style={{ color: "red" }}>*</span>
										</span>
									}
									value={name}
									error={nameError}
									helperText={textNameError}
									onChange={handleNameChange}
									variant="outlined"
								/>
							</ItemWrapper>
							<ItemWrapper>
								<Autocomplete
									getOptionLabel={(option) => option.name}
									renderInput={(params) => (
										<TextField
											{...params}
											variant="outlined"
											label={<span className={"span_style"}>Cấp cha</span>}
										/>
									)}
									sx={{ width: "100%" }}
									value={parent}
									onChange={(event, value) => setParent(value)}
									options={listTypeParent}
								/>
							</ItemWrapper>
						</InputRowWrapper>
						<InputRowWrapper>
							<ItemWrapper>
								<TextField
									className="mb-23"
									fullWidth
									label={
										<span className={"span_style"}>
											{translate("resources.project_type.fields.depth_project_type")}
											<span style={{ color: "red" }}></span>
										</span>
									}
									value={depth}
									onChange={handleDepthChange}
									variant="outlined"
								/>
							</ItemWrapper>
							<ItemWrapper>
								<Autocomplete
									renderInput={(params) => (
										<TextField
											{...params}
											variant="outlined"
											label={
												<InputLabel
													title={translate("resources.project_type.fields.type")}
													required
												/>
											}
											error={typeEstateErr?.isError}
											helperText={typeEstateErr?.message}
										/>
									)}
									sx={{ width: "100%" }}
									value={projectTypeEstate}
									onChange={(event, value) => {
										handleChangeProjectTypeEstate(value);
										setTypeEstateErr(null);
									}}
									options={dataProjectTypeEstate_id}
								/>
							</ItemWrapper>
						</InputRowWrapper>
						<InputRowWrapper>
							<ItemWrapper30>
								<TextField
									className="mb-23"
									fullWidth
									label={
										<span className={"span_style"}>
											{translate("resources.general.fields.meta_title")}
										</span>
									}
									value={meta_title}
									onChange={handleMetaTitleChange}
									variant="outlined"
								/>
							</ItemWrapper30>
							<ItemWrapper30>
								<TextField
									className="mb-23"
									fullWidth
									label={
										<span className={"span_style"}>
											{translate("resources.general.fields.meta_description")}
										</span>
									}
									value={meta_description}
									onChange={handleMetaDescriptionChange}
									variant="outlined"
								/>
							</ItemWrapper30>
							<ItemWrapper30>
								<TextField
									className="mb-23"
									fullWidth
									label={
										<span className={"span_style"}>
											{translate("resources.general.fields.meta_keyword")}
										</span>
									}
									value={meta_keyword}
									onChange={handleMetaKeywordChange}
									variant="outlined"
								/>
							</ItemWrapper30>
						</InputRowWrapper>
						<InputRowWrapper>
							<ItemWrapper>
								<TextField
									className="mb-23"
									fullWidth
									label={
										<span className={"span_style"}>
											{translate("resources.project_type.fields.slug")}
											<span style={{ color: "red" }}>*</span>
										</span>
									}
									value={slug}
									error={slugError}
									helperText={textSlugError}
									onChange={handleSlugChange}
									variant="outlined"
								/>
							</ItemWrapper>
							<ItemWrapper>
								<Autocomplete
									renderInput={(params) => (
										<TextField
											{...params}
											variant="outlined"
											label={
												<span className={"span_style"}>
													{translate("resources.common.fields.status")}
												</span>
											}
										/>
									)}
									sx={{ width: "100%" }}
									value={status}
									onChange={(event, value) => handleChangeStatus(value)}
									options={dataStatus}
								/>
							</ItemWrapper>
						</InputRowWrapper>
						<InputRowWrapper>
							<MultilineWrapper>
								<div className={`${classes.box_image}`}>
									<div className={"title-image"}>Hình ảnh</div>
									<input
										type="file"
										accept=".png, .jpg, .jpeg"
										onChange={(e: any) => {
											setImage(e.target.files[0]);
											e.target.value = null;
										}}
									></input>
									{image && (
										<div>
											<ImagePreview
												src={URL.createObjectURL(image)}
												onRemove={() => setImage(null)}
											/>
										</div>
									)}
								</div>
							</MultilineWrapper>
						</InputRowWrapper>
						<InputRowWrapper>
							<MultilineWrapper>
								<CkeditorServer
									name="project_type_add"
									onChangeData={(data) => {
										if (data) setContent(data);
									}}
								/>
							</MultilineWrapper>
						</InputRowWrapper>
					</DialogContent>
				</Box>
				<Toolbar>
					<Box
						display="flex"
						justifyContent="center"
						width={"100%"}
					>
						<Button
							variant="contained"
							startIcon={<SaveIcon />}
							onClick={handleSave}
						>
							{translate("resources.common.fields.add")}
						</Button>
						&nbsp;&nbsp;
						<Button
							startIcon={<ReplyIcon />}
							className={"button-close"}
							variant="contained"
							onClick={handleCloseDialog}
						>
							{translate("resources.common.fields.cancel")}
						</Button>
					</Box>
				</Toolbar>
			</Dialog>
		</>
	);
};
