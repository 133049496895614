import { TranslationMessages } from 'react-admin';
import englishMessages from 'ra-language-english';

const customEnglishMessages: TranslationMessages = {
    ...englishMessages,
    ra: {
        message: {
            about: 'Về',
            are_you_sure: 'Bạn có chắc chắn không?',
            bulk_delete_content:
                'Bạn có chắc chắn muốn xóa %{name} này? |||| Bạn có chắc chắn muốn xóa %{smart_count} bản ghi này?',
            bulk_delete_title:
                'Xóa %{name} |||| Xóa %{smart_count} bản ghi %{name}',
            delete_content: 'Bạn có chắc chắn muốn xóa bản ghi này?',
            delete_title: 'Xóa %{name}',
            details: 'Chi tiết',
            error:
                'Đã xảy ra lỗi ở phía người dùng và yêu cầu của bạn không thể hoàn thành.',
            invalid_form: 'Dữ liệu nhập không đúng. Vui lòng kiểm tra lại',
            loading: 'Trang web đang tải, vui lòng đợi trong giây lát',
            no: 'Không',
            not_found:
                'Bạn đã nhập một URL sai hoặc bạn đã làm theo một liên kết không đúng.',
            yes: 'Có',
            unsaved_changes:
                'Một vài thay đổi của bạn chưa được lưu. Bạn có chắc là muốn bỏ qua chúng?',
            bulk_update_content: 'Nội dung cập nhật',
            bulk_update_title: 'Tiêu đề cập nhật',
        },
        // @ts-ignore
        notification: {
            updated:
                'Cập nhật thành công |||| Cập nhật thành công %{smart_count} thành phần',
            created: 'Đã tạo thành công',
            deleted:
                'Đã xóa thành công |||| Xóa thành công %{smart_count} thành phần',
            bad_item: 'Thành phần không đúng',
            item_doesnt_exist: 'Thành phần không tồn tại',
            http_error: 'Lỗi giao tiếp với máy chủ',
            data_provider_error:
                'Lỗi dataProvider. Kiểm tra console để biết chi tiết.',
        },
        // @ts-ignore
        configurable: {
            customize: '12345667'
        },
        // @ts-ignore
        action: {
            create_item: 'Create %{item}',
            add_filter: 'Thêm filters',
            add: 'Thêm',
            back: 'Trở về',
            bulk_actions: '%{smart_count} đã chọn',
            cancel: 'Bỏ qua',
            accept: 'Đồng ý',
            clear_input_value: 'Xóa giá trị',
            clone: 'Sao chép',
            confirm: 'Xác nhận',
            create: 'Tạo mới',
            delete: 'Xóa',
            edit: 'Sửa',
            export: 'Xuất Báo Cáo',
            list: 'Danh sách',
            refresh: 'Làm mới',
            remove_filter: 'Bỏ filters',
            remove: 'Xóa bỏ',
            save: 'Lưu',
            search: 'Tìm kiếm',
            show: 'Hiển thị',
            sort: 'Sắp xếp',
            undo: 'Hoàn tác',
            unselect: 'Huỷ chọn',
            expand: 'Mở rộng',
            close: 'Đóng',
            open_menu: 'Mở menu',
            close_menu: 'Đóng menu',
            update: 'Cập nhật',
            move_up: 'Lên',
            move_down: 'Xuống',
            select_all: 'Chọn tất cả',
            select_row: 'Chọn hàng',
            deleteFilters: 'Xóa bộ lọc',
            cancel_form: 'Hủy',
            cancelled: 'Hủy',
            refuse: 'Từ chối',
            chuyenKenh: 'Chuyển kênh',
        },
        // @ts-ignore
        navigation: {
            no_results: 'Không tìm thấy kết quả nào',
            no_more_results:
                'Trang số %{page} đã vượt giới hạn. Vui lòng trở lại trang trước.',
            page_out_of_boundaries: 'Trang số %{page} đã vượt giới hạn',
            page_out_from_end: 'Không thể đi tiếp sau trang cuối',
            page_out_from_begin: 'Không thể trở lại trước trang 1',
            page_range_info: '%{offsetBegin}-%{offsetEnd} của %{total}',
            page_rows_per_page: 'Số bản ghi/trang:',
            next: 'Tiếp',
            prev: 'Trước',
            skip_nav: 'Bỏ qua',
        },
        // @ts-ignore
        page: {
            create: 'Tạo %{name}',
            dashboard: 'Trang chủ',
            edit: '%{name} #%{id}',
            error: 'Đã xảy ra lỗi',
            list: 'Danh sách %{name}',
            loading: 'Đang tải',
            not_found: 'Không tìm thấy',
            show: '%{name} #%{id}',
            empty: 'Chưa có %{name} nào.',
            invite: 'Bạn có muốn tạo mới không?',
        },
        auth: {
            auth_check_error: 'Vui lòng đăng nhập để tiếp tục',
            user_menu: 'Hồ sơ',
            username: 'Tên đăng nhập',
            password: 'Mật khẩu',
            sign_in: 'ĐĂNG NHẬP',
            sign_in_sso: 'Đăng nhập SSO',
            sign_in_error: 'Đăng nhập không thành công, vui lòng thử lại',
            logout: 'Đăng xuất',
            forgotPassword: 'Quên mật khẩu?',
            textHeader: 'ĐĂNG NHẬP HỆ THỐNG',
            year: '©2022',
        },
    },
    pos: {
        search: 'Search',
        configuration: 'Configuration',
        language: 'Language',
        theme: {
            name: 'Theme',
            light: 'Light',
            dark: 'Dark',
        },
        dashboard: {
            monthly_revenue: 'Monthly Revenue',
            month_history: '30 Day Revenue Project',
            new_orders: 'New Orders',
            pending_reviews: 'Pending Reviews',
            all_reviews: 'See all reviews',
            new_customers: 'New Customers',
            all_customers: 'See all customers',
            pending_orders: 'Pending Orders',
            order: {
                items:
                    'by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items',
            },
            welcome: {
                title: 'Welcome to the react-admin e-commerce demo',
                subtitle:
                    "This is the admin of an imaginary poster shop. Feel free to explore and modify the data - it's local to your computer, and will reset each time you reload.",
                ra_button: 'react-admin site',
                demo_button: 'Source for this demo',
            },
        },
        menu: {
            sales: 'Sales',
            catalog: 'Catalog',
            dashboard: 'Dashboard',
            users: 'Users',
            project:"Quản lý dự án",
            department:"Quản lý phòng ban",
            system: 'Hệ thống',
            parts: 'Quản lý bộ phận',
            office: 'Quản lý chức vụ',
        },
    },
    resources: {
        customers: {
            name: 'Customer |||| Customers',
            fields: {
                commands: 'Orders',
                first_seen: 'First seen',
                groups: 'Segments',
                last_seen: 'Last seen',
                last_seen_gte: 'Visited Since',
                name: 'Name',
                total_spent: 'Total spent',
                password: 'Password',
                confirm_password: 'Confirm password',
                stateAbbr: 'State',
            },
            filters: {
                last_visited: 'Last visited',
                today: 'Today',
                this_week: 'This week',
                last_week: 'Last week',
                this_month: 'This month',
                last_month: 'Last month',
                earlier: 'Earlier',
                has_ordered: 'Has ordered',
                has_newsletter: 'Has newsletter',
                group: 'Segment',
            },
            fieldGroups: {
                identity: 'Identity',
                address: 'Address',
                stats: 'Stats',
                history: 'History',
                password: 'Password',
                change_password: 'Change Password',
            },
            page: {
                delete: 'Delete Customer',
            },
            errors: {
                password_mismatch:
                    'The password confirmation is not the same as the password.',
            },
        },
        commands: {
            name: 'Order |||| Orders',
            amount: '1 order |||| %{smart_count} orders',
            title: 'Order %{reference}',
            fields: {
                basket: {
                    delivery: 'Delivery',
                    reference: 'Reference',
                    quantity: 'Quantity',
                    sum: 'Sum',
                    tax_rate: 'Tax Rate',
                    taxes: 'Tax',
                    total: 'Total',
                    unit_price: 'Unit Price',
                },
                address: 'Address',
                customer_id: 'Customer',
                date_gte: 'Passed Since',
                date_lte: 'Passed Before',
                nb_items: 'Nb Items',
                total_gte: 'Min amount',
                status: 'Status',
                returned: 'Returned',
            },
            section: {
                order: 'Order',
                customer: 'Customer',
                shipping_address: 'Shipping Address',
                items: 'Items',
                total: 'Totals',
            },
        },
        invoices: {
            name: 'Invoice |||| Invoices',
            fields: {
                date: 'Invoice date',
                customer_id: 'Customer',
                command_id: 'Order',
                date_gte: 'Passed Since',
                date_lte: 'Passed Before',
                total_gte: 'Min amount',
                address: 'Address',
            },
        },
        products: {
            name: 'Poster |||| Posters',
            fields: {
                category_id: 'Category',
                height_gte: 'Min height',
                height_lte: 'Max height',
                height: 'Height',
                image: 'Image',
                price: 'Price',
                reference: 'Reference',
                sales: 'Sales',
                stock_lte: 'Low Stock',
                stock: 'Stock',
                thumbnail: 'Thumbnail',
                width_gte: 'Min width',
                width_lte: 'Max width',
                width: 'Width',
            },
            tabs: {
                image: 'Image',
                details: 'Details',
                description: 'Description',
                reviews: 'Reviews',
            },
            filters: {
                categories: 'Categories',
                stock: 'Stock',
                no_stock: 'Out of stock',
                low_stock: '1 - 9 items',
                average_stock: '10 - 49 items',
                enough_stock: '50 items & more',
                sales: 'Sales',
                best_sellers: 'Best sellers',
                average_sellers: 'Average',
                low_sellers: 'Low',
                never_sold: 'Never sold',
            },
        },
        categories: {
            name: 'Category |||| Categories',
            fields: {
                products: 'Products',
            },
        },
        reviews: {
            name: 'Review |||| Reviews',
            amount: '1 review |||| %{smart_count} reviews',
            relative_to_poster: 'Review on poster',
            detail: 'Review detail',
            fields: {
                customer_id: 'Customer',
                command_id: 'Order',
                product_id: 'Product',
                date_gte: 'Posted since',
                date_lte: 'Posted before',
                date: 'Date',
                comment: 'Comment',
                rating: 'Rating',
            },
            action: {
                accept: 'Accept',
                reject: 'Reject',
            },
            notification: {
                approved_success: 'Review approved',
                approved_error: 'Error: Review not approved',
                rejected_success: 'Review rejected',
                rejected_error: 'Error: Review not rejected',
            },
        },
        segments: {
            name: 'Segment |||| Segments',
            fields: {
                customers: 'Customers',
                name: 'Name',
            },
            data: {
                compulsive: 'Compulsive',
                collector: 'Collector',
                ordered_once: 'Ordered once',
                regular: 'Regular',
                returns: 'Returns',
                reviewer: 'Reviewer',
            },
        },
        dashboard: {
            name: 'Dashboard biểu đồ',
        },
        users: {
            name: 'Quản lý người dùng',
            fields: {
                name: 'Name',
            },
        },
        project: {
            name: 'Danh sách dự án',
        },
        department: {
            name: 'Danh sách phòng ban',
        },
        permission: {
            name: 'Quản lý quyền'
        },
        parts: {
            name: 'Danh sách bộ phận'
        },
        roles: {
            name: 'Quản lý nhóm quyền'
        },
        office: {
            name: 'Danh sách chức vụ'
        }
    },
};

export default customEnglishMessages;
