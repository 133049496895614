import { Autocomplete, Dialog, DialogContent, DialogTitle, IconButton, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import ReplyIcon from "@mui/icons-material/Reply";
import Toolbar from "@mui/material/Toolbar";
import { useNotify, useRefresh, useRecordContext, EditProps, useTranslate } from "react-admin";
import HttpDataService from "../dataProvider/http.service";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import * as projectTypeService from "../Services/projectTypeService";
import * as projectTypeEstateService from "../Services/projectTypeEstateService";
import InputLabel from "../component/InputLabel";
import ImagePreview from "../component/ImgPreview";

import { InputRowWrapper, ItemWrapper, ItemWrapper30, MultilineWrapper } from "../projectEstate/dialogs/styledMui";
import Editor from "../component/Editor";
import CkeditorServer from "../component/CkeditorServer";
import { fnSetDataCkeditor } from "../utils/fc";

const styles = makeStyles((theme: any) =>
	createStyles({
		root: {
			"& .css-k4qjio-MuiFormHelperText-root.Mui-error": {
				position: "absolute",
				paddingTop: "41px",
			},
		},
		box_image: {
			"& ": {
				border: "1px solid #ccc",
				padding: "10px",
				borderRadius: "10px",
				marginBottom: "10px",
				marginTop: "15px",
			},
		},
	})
);

interface Add extends EditProps {
	openDialog: boolean;
	onCloseDialog: (openDialog: boolean) => void;
}

let dataProjectTypeEstate_id: any[] = [];

type ErrorTypes = {
	isError: boolean;
	message: String;
};

export const ProjectTypeEdit = ({ openDialog, onCloseDialog, id, ...rest }: Add) => {
	const classes = styles();
	const [name, setName] = useState<string>("");
	const [depth, setDepth] = useState<string>("");
	const [slug, setSlug] = useState<string>("");
	const [meta_title, setMetaTitle] = useState<string>("");
	const [meta_description, setMetaDescription] = useState<string>("");
	const [meta_keyword, setMetaKeyword] = useState<string>("");
	const translate = useTranslate();
	const dataStatus = [
		{ label: translate("resources.common.fields.active"), id: 1 },
		{ label: translate("resources.common.fields.block"), id: 0 },
	];
	const dataType = [
		{ label: translate("resources.project_type.fields.sell"), id: 1 },
		{ label: translate("resources.project_type.fields.rent"), id: 0 },
	];

	const [nameError, setNameError] = useState(false);
	const [depthError, setDepthError] = useState(false);
	const [slugError, setSlugError] = useState(false);
	const [textNameError, setTextNameError] = useState("");
	const [textDepthError, setTextDepthError] = useState("");
	const [textSlugError, setTextSlugError] = useState("");
	const [status, setStatus] = useState<any>(dataStatus[0]);
	const [projectTypeEstate, setProjectTypeEstate] = useState<any>(dataType[0]);
	const [typeEstateErr, setTypeEstateErr] = React.useState<ErrorTypes | null>();
	const [imageExist, setImageExist] = useState<any>();
	const [imageNew, setImageNew] = useState<any>();
	const [content, setContent] = useState<string>("");
	const [listTypeParent, setListTypeParent] = useState<any[]>([]);
	const [parent, setParent] = useState<any>(null);

	const refresh = useRefresh();
	const notify = useNotify();
	const record = useRecordContext();

	const [loading, setLoading] = useState<boolean>(true);
	const [listTypeEstate, setListTypeEstate] = useState<any[]>([]);
	const [initialState, setInitialState] = React.useState<any>();
	const [listType, setListType] = useState<any>(null);

	useEffect(() => {
		const fetchApi = async () => {
			const data = await projectTypeService.getProjectTypeDetail(Number(id));
			setInitialState(data);
			setName(data.name);
			setDepth(data.depth);
			setProjectTypeEstate(data.project_type_estate.name);
			setMetaTitle(data.meta_title);
			setMetaDescription(data.meta_description);
			setMetaKeyword(data.meta_keyword);
			setSlug(data.slug);
			const find_status = dataStatus.find((s) => s.id == data.status);
			setStatus(find_status);
			setContent(data?.content || "");
			setImageExist(data.image);

			fnSetDataCkeditor("project_type_edit", data?.content);

			const listTypeResult = await projectTypeEstateService.getListTypeEstate();
			const listTypeEstateObj = await listTypeResult.find((item: any) => item.id === data.project_type_estate);

			HttpDataService.getAll("project_type/getAllProjectType").then((res) => {
				let dataParent = res.data.data;
				setListTypeParent(dataParent);

				const parentObj = dataParent.find((item: any) => item.id === data.parent_id);
				console.log(parentObj);
				setParent(parentObj);
			});

			setListType(listTypeEstateObj);
			setListTypeEstate(listTypeResult);

			setLoading(false);
		};
		fetchApi();
	}, []);

	/**
	 * handle change name
	 * @param e
	 */
	const handleNameChange = (e: any) => {
		setNameError(false);
		setTextNameError("");
		setName(e.target.value);
	};

	const handleDepthChange = (e: any) => {
		setDepthError(false);
		setTextDepthError("");
		setDepth(e.target.value);
	};

	const handleSlugChange = (e: any) => {
		setSlugError(false);
		setTextSlugError("");
		setSlug(e.target.value);
	};

	const handleMetaTitleChange = (e: any) => {
		setMetaTitle(e.target.value);
	};
	const handleMetaDescriptionChange = (e: any) => {
		setMetaDescription(e.target.value);
	};
	const handleMetaKeywordChange = (e: any) => {
		setMetaKeyword(e.target.value);
	};

	const handleChangeStatus = (e: any) => {
		setStatus(e);
	};
	const handleChangeType = (e: any) => {
		setProjectTypeEstate(e);
	};

	/**
	 * handle save
	 */
	const handleSave = async () => {
		if (!fnValidation()) {
			// let data = {
			//     id,
			//     parent_id: parent?.id,
			//     name: name.trim(),
			//     depth: depth,
			//     slug: slug.trim(),
			//     meta_title: meta_title.trim(),
			//     meta_description: meta_description.trim(),
			//     meta_keyword: meta_keyword.trim(),
			//     status: status.id,
			//     project_type_estate: listType?.id || 0,
			// };
			const submitObj = new FormData();
			submitObj.append("id", id?.toString() || "");
			submitObj.append("name", name);
			submitObj.append("depth", depth);
			submitObj.append("slug", slug);
			submitObj.append("status", status?.id);
			submitObj.append("meta_title", meta_title);
			submitObj.append("meta_description", meta_description);
			submitObj.append("meta_keyword", meta_keyword);
			submitObj.append("project_type_estate", listType?.id || 0);
			submitObj.append("parent_id", parent?.id || 0);
			submitObj.append("content", content);
			if (imageNew) submitObj.append("image", imageNew);

			await HttpDataService.create("project_type/editProjectType?_method=put", submitObj)
				.then((response: any) => {
					if (response.data.status) {
						// @ts-ignore
						notify(response.data.message, { type: "success" });
					} else {
						// @ts-ignore
						notify(response.data.message, { type: "error" });
					}
				})
				.catch((e: Error) => {
					console.log(e);
				});

			fnResetFrm();
			onCloseDialog(false);
			refresh();
		}
	};

	const handleCloseDialog = () => {
		fnResetFrm();
		onCloseDialog(false);
	};

	/**
	 * fn validation form
	 */
	function fnValidation() {
		let error = false;
		if (name.trim().length === 0) {
			error = true;
			setNameError(true);
			setTextNameError(translate("resources.office.fields.val_name"));
		}

		if (slug.trim().length === 0) {
			error = true;
			setSlugError(true);
			setTextSlugError(translate("resources.office.fields.val_slug"));
		}
		return error;
	}

	function fnResetFrm() {
		setName("");
		setNameError(false);
		setTextNameError("");
		setStatus(null);
		setListType(null);
		setProjectTypeEstate(null);
		setContent("");
		setImageExist(null);
		setImageNew(null);
	}

	const handleChangeProjectTypeEstate = (e: any) => {
		setProjectTypeEstate(e);
	};

	return (
		<>
			<Dialog
				disableEnforceFocus
				open={openDialog}
				maxWidth={"md"}
				PaperProps={{
					style: {
						borderRadius: 2,
						// minWidth: '85%',
					},
				}}
			>
				<div className={"common__dialogTitle_area"}>
					<DialogTitle className={"member__dialogTitle"}>
						<div>{translate("resources.project_type.edit_project_type")}</div>
					</DialogTitle>
					<div className={"common__dialogTitle_actions"}>
						<IconButton
							aria-label="exists"
							color={"error"}
							onClick={() => {
								onCloseDialog(false);
							}}
						>
							<CloseIcon />
						</IconButton>
					</div>
				</div>
				<Box className={"dialog_box"}>
					<DialogContent className={"dialog_content"}>
						<InputRowWrapper>
							<ItemWrapper>
								<TextField
									className={`${classes.root} mb-23`}
									fullWidth
									label={
										<span className={"span_style"}>
											{translate("resources.project_type.fields.name_project_type")}
											<span style={{ color: "red" }}>*</span>
										</span>
									}
									value={name}
									error={nameError}
									helperText={textNameError}
									onChange={handleNameChange}
									variant="outlined"
								/>
							</ItemWrapper>
							<ItemWrapper>
								<Autocomplete
									renderInput={(params) => (
										<TextField
											{...params}
											variant="outlined"
											label={<InputLabel title="Cấp cha" />}
										/>
									)}
									sx={{ width: "100%" }}
									value={parent}
									onChange={(event, value) => {
										setParent(value);
									}}
									options={listTypeParent}
									getOptionLabel={(option) => option.name}
								/>
							</ItemWrapper>
						</InputRowWrapper>
						<InputRowWrapper>
							<ItemWrapper>
								<TextField
									className="mb-23"
									fullWidth
									label={
										<span className={"span_style"}>
											{translate("resources.project_type.fields.depth_project_type")}
											<span style={{ color: "red" }}></span>
										</span>
									}
									value={depth}
									onChange={handleDepthChange}
									variant="outlined"
								/>
							</ItemWrapper>
							<ItemWrapper>
								<Autocomplete
									options={listTypeEstate}
									renderInput={(params) => (
										<TextField
											{...params}
											variant="outlined"
											label={
												<span className={"span_style"}>
													{" "}
													{translate("resources.project_type.fields.type")}
												</span>
											}
										/>
									)}
									fullWidth
									value={listType}
									onChange={(e, value) => {
										setListType(value);
									}}
									getOptionLabel={(option) => option.name}
								/>
							</ItemWrapper>
						</InputRowWrapper>
						<InputRowWrapper>
							<ItemWrapper30>
								<TextField
									className={`${classes.root} mb-23`}
									fullWidth
									label={
										<span className={"span_style"}>
											{translate("resources.general.fields.meta_title")}
										</span>
									}
									value={meta_title}
									onChange={handleMetaTitleChange}
									variant="outlined"
								/>
							</ItemWrapper30>
							<ItemWrapper30>
								<TextField
									className={`${classes.root} mb-23`}
									fullWidth
									label={
										<span className={"span_style"}>
											{translate("resources.general.fields.meta_description")}
										</span>
									}
									value={meta_description}
									onChange={handleMetaDescriptionChange}
									variant="outlined"
								/>
							</ItemWrapper30>
							<ItemWrapper30>
								<TextField
									className={`${classes.root} mb-23`}
									fullWidth
									label={
										<span className={"span_style"}>
											{translate("resources.general.fields.meta_keyword")}
										</span>
									}
									value={meta_keyword}
									onChange={handleMetaKeywordChange}
									variant="outlined"
								/>
							</ItemWrapper30>
						</InputRowWrapper>
						<InputRowWrapper>
							<ItemWrapper>
								<TextField
									className={`${classes.root} mb-23`}
									fullWidth
									label={
										<span className={"span_style"}>
											{translate("resources.project_type.fields.slug")}
											<span style={{ color: "red" }}>(*)</span>
										</span>
									}
									value={slug}
									error={slugError}
									helperText={textSlugError}
									onChange={handleSlugChange}
									variant="outlined"
								/>
							</ItemWrapper>
							<ItemWrapper>
								<Autocomplete
									renderInput={(params) => (
										<TextField
											{...params}
											variant="outlined"
											label={
												<span className={"span_style"}>
													{" "}
													{translate("resources.common.fields.status")}
												</span>
											}
										/>
									)}
									fullWidth
									value={status || null}
									onChange={(event, value) => handleChangeStatus(value)}
									options={dataStatus}
								/>
							</ItemWrapper>
						</InputRowWrapper>
						<InputRowWrapper>
							<MultilineWrapper>
								<div className={`${classes.box_image}`}>
									<div className={"title-image"}>Hình ảnh</div>
									<input
										type="file"
										accept=".png, .jpg, .jpeg"
										onChange={(e: any) => {
											setImageNew(e.target.files[0]);
											setImageExist(null);
											e.target.value = null;
										}}
									></input>
									{imageExist && (
										<div>
											<ImagePreview
												src={`${process.env.REACT_APP_BASE_END_POINT_URL}${imageExist}`}
												onRemove={() => setImageExist(null)}
											/>
										</div>
									)}
									{imageNew && (
										<div>
											<ImagePreview
												src={URL.createObjectURL(imageNew)}
												onRemove={() => setImageNew(null)}
											/>
										</div>
									)}
								</div>
							</MultilineWrapper>
						</InputRowWrapper>
						<InputRowWrapper>
							<MultilineWrapper>
								<CkeditorServer
									name="project_type_edit"
									onChangeData={(data) => {
										if (data) setContent(data);
									}}
								/>
							</MultilineWrapper>
						</InputRowWrapper>
					</DialogContent>
				</Box>
				<Toolbar>
					<Box
						display="flex"
						justifyContent="center"
						width={"100%"}
					>
						<Button
							variant="contained"
							startIcon={<SaveIcon />}
							onClick={handleSave}
						>
							{translate("resources.common.fields.update")}
						</Button>
						&nbsp;&nbsp;
						<Button
							startIcon={<ReplyIcon />}
							className={"button-close"}
							variant="contained"
							onClick={handleCloseDialog}
						>
							{translate("resources.common.fields.cancel")}
						</Button>
					</Box>
				</Toolbar>
			</Dialog>
		</>
	);
};
