import {
    DefaultDataProvider,
    httpClient,
    serialize,
} from '../default-data-provider';
import {constants} from "../../shared/constants";

export const baseApiUrl = 'permission';

class PermissionService extends DefaultDataProvider {

}

export const permissionService = new PermissionService (
    constants.RESOURCE.PERMISSION,
    constants.BASE_MODULE_URL,
    baseApiUrl
);