import React, { useContext, useRef, useState } from "react";
import {
  List,
  Datagrid,
  DatagridProps,
  useListContext,
  TextField,
  FunctionField,
  useTranslate,
  FilterLiveSearch,
  useRefresh,
} from "react-admin";
import {
  Box,
  Chip,
  IconButton,
  Typography,
  Autocomplete,
  TextField as InputText,
  Button,
  Tooltip,
  SelectChangeEvent,
  FormControlLabel,
  MenuItem,
  Select,
  FormControl,
  tooltipClasses,
} from "@mui/material";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { ItemWrapper, dataStatus } from "./dialogs/customercreate";
import { styled } from "@mui/system";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { Form } from "react-final-form";
import classNames from "classnames/bind";
import action from "../../projectEstate/Project.module.scss";
import PaginationCustom from "../../component/PaginationCustom";
import CreateButtonMUI from "../../component/CreateButtonMUI";
import CreateCustomer from "./dialogs/customercreate";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import EditCustomer from "./dialogs/customeredit";
import CustomerDelete from "./dialogs/customerdelete";
import { STATUS } from "../../shared/status";
import AddIcon from "@mui/icons-material/Add";
import { useForm } from "react-hook-form";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import NotesIcon from "@mui/icons-material/Notes";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import HistoryCare from "./dialogs/customercare";
import CustomerProject from "./dialogs/customerproject";
import { CreateHistoryCare } from "./dialogs/customercreatecare";
const cx = classNames.bind(action);
import { commonFunction } from "../../component/Common";
import {
  uploadImportCustomer,
  updateCustomer,
} from "../../Services/customerService";
import { useNotify } from "react-admin";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import HttpDataService from "../../dataProvider/http.service";

import moment from "moment";
import "./index.scss";
import request from "../../utils/httpRequest";
import CreateTransaction from "./dialogs/CreateTransaction";
import SizeContext from "antd/lib/config-provider/SizeContext";

const FillterWrapper = styled(Box)({
  display: "flex",
  flexWrap: "wrap",
  gap: "0 15px",
  margin: "7px 15px",
});

const FillterItem = styled(Box)({
  width: 200,
});

const HeaderButtonWrapper = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  gap: 10,
  padding: "15px 10px",
});
export const ResponsiveHeaderButtonWrapper = styled(HeaderButtonWrapper)({
  "@media (max-width: 600px)": {
    justifyContent: "flex-start",
  },
});
// @ts-ignore
const ProjectPolicyContext = React.createContext<any>();
export const STATUS_CUSTOMER = [
  { id: 0, label: "Đang chăm sóc" },
  { id: 1, label: "Đã chốt" },
  { id: 2, label: "Thuê chỗ khác" },
  { id: 3, label: "Không phản hồi" },
  { id: 4, label: "Đang chờ" },
  { id: 5, label: "Chưa liên hệ" },
  { label: "Tất cả" },
];
// tooltip hover display otherrequest
const CustomTooltip = styled(({ className, ...props }: any) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
    fontSize: "1rem",
    fontWeight: "400",
  },
});

export const CustomerList = (props: any) => {
  const PostPagination = (props: any) => <PaginationCustom {...props} />;
  const [nameContext, setNameContext] = useState<string>();
  const [finance_fromContext, setFinance_fromContext] = useState<string>();
  const [finance_toContext, setFinance_toContext] = useState<string>();
  const [acreage_fromContext, setAcreage_fromContext] = useState<string>();
  const [acreage_toContext, setAcreage_toContext] = useState<string>();
  const [sourceContext, setSourceContext] = useState<any>({
    id: 0,
    name: "Tất cả",
  });
  const [saleContext, setSaleContext] = useState<any>({
    id: 0,
    name: "Tất cả",
  });
  const [statusContext, setStatusContext] = useState<any>(STATUS_CUSTOMER[6]);
  const [dateStartContext, setDateStartContext] = useState<Dayjs | null>(null);
  const today = dayjs();
  const [dateEndContext, setDateEndContext] = useState<Dayjs | null>(today);
  const [loadingContext, setLoadingContext] = useState<boolean>(false);
  const translate = useTranslate();
  //
  return (
    <Box className="header-channel-sales">
      <Box className={"box_header"}>
        <Typography className={"title_header"}>Danh sách khách hàng</Typography>
      </Box>
      <div className={`header-top-detail header-top-tdn`}>
        <ProjectPolicyContext.Provider
          value={{
            nameContext: [nameContext, setNameContext],
            finance_fromContext: [finance_fromContext, setFinance_fromContext],
            finance_toContext: [finance_toContext, setFinance_toContext],
            acreage_fromContext: [acreage_fromContext, setAcreage_fromContext],
            acreage_toContext: [acreage_toContext, setAcreage_toContext],
            sourceContext: [sourceContext, setSourceContext],
            saleContext: [saleContext, setSaleContext],
            statusContext: [statusContext, setStatusContext],
            dateStartContext: [dateStartContext, setDateStartContext],
            dateEndContext: [dateEndContext, setDateEndContext],
          }}
        >
          <List
            {...props}
            actions={false}
            empty={false}
            exporter={false}
            filters={<FilterAndActions />}
            filter={{
              name: nameContext,
              source: sourceContext?.id ? sourceContext?.id : null,
              sale: saleContext?.id ? saleContext?.id : null,
              status:
                statusContext?.id === 4 ? statusContext?.id : statusContext?.id,
              finance_from: finance_fromContext,
              finance_to: finance_toContext,
              acreage_from: acreage_fromContext,
              acreage_to: acreage_toContext,
              date_from: dateStartContext
                ? dateStartContext.format("YYYY-MM-DD")
                : undefined,
              date_to: dateEndContext
                ? dateEndContext.format("YYYY-MM-DD")
                : undefined,
            }}
            filterDefaultValues={{
              name: "",
              code: "",
              finance_from: "",
              finance_to: "",
              acreage_from: "",
              acreage_to: "",
            }}
            sort={{ field: "", order: "" }}
            pagination={<PostPagination />}
          >
            <ProjectDatagrid {...props} hasBulkActions={true} />
          </List>
        </ProjectPolicyContext.Provider>
      </div>
    </Box>
  );
};

interface ButtonProps {
  basePath?: string;
  label: string;
  record?: any;
}

const EditButton = (props: any) => {
  const [openEditDialog, setOpenEditDialog] = useState(false);
  return (
    <>
      <Tooltip title="Sửa" arrow>
        <IconButton onClick={() => setOpenEditDialog(true)}>
          <EditIcon className={"btn_edit"} />
        </IconButton>
      </Tooltip>
      <EditCustomer
        isOpen={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
      />
    </>
  );
};

const DeleteButton = (props: any) => {
  const [openEditDialog, setOpenEditDialog] = useState(false);
  return (
    <>
      <Tooltip title="Xóa" arrow>
        <IconButton onClick={() => setOpenEditDialog(true)}>
          <DeleteIcon className={"btn_del"} />
        </IconButton>
      </Tooltip>
      <CustomerDelete
        isOpen={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
      ></CustomerDelete>
    </>
  );
};

const ViewOtherButton = ({ record }: any) => {
  return (
    <CustomTooltip
      title={record.other_request ? record.other_request : ""}
      arrow
    >
      <NotesIcon className={"btn_floor"} />
    </CustomTooltip>
  );
};

const TransactionButton = () => {
  const [openTransactionDialog, setOpenTransactionDialog] = useState(false);

  return (
    <>
      <Tooltip title="Tạo giao dịch" arrow>
        <IconButton onClick={() => setOpenTransactionDialog(true)}>
          <ReceiptLongIcon className={"btn_floor"} />
        </IconButton>
      </Tooltip>
      <CreateTransaction
        isOpen={openTransactionDialog}
        onClose={() => setOpenTransactionDialog(false)}
      />
    </>
  );
};

const ViewTransactionButton = () => {
  const [openViewTransactionDialog, setOpenViewTransactionDialog] =
    useState(false);

  return (
    <>
      <Tooltip title="Đã giao dịch" arrow>
        <IconButton>
          <CheckCircleIcon className={"btn_change"} />
        </IconButton>
      </Tooltip>
      {/* <CustomerDelete
        isOpen={openViewTransactionDialog}
        onClose={() => setOpenViewTransactionDialog(false)}
      ></CustomerDelete> */}
    </>
  );
};

const RowActions = ({ record }: ButtonProps) => {
  return (
    <Box className={cx("planRowAction")}>
      {/* đã chốt show btn tạo giao dịch */}
      {/* {record.transaction_status == 1 ? (
        <ViewTransactionButton />
      ) : (
        record.status == 1 && <TransactionButton />
      )} */}
      <ViewOtherButton record={record} />
      {record.status == 1 && record.transaction && <ViewTransactionButton />}
      {record.status == 1 && !record.transaction && <TransactionButton />}
      {commonFunction.checkPermission("CUSTOMER_EDIT") && <EditButton />}
      {commonFunction.checkPermission("CUSTOMER_DELETE") && <DeleteButton />}
    </Box>
  );
};

const RowTakeCare = ({ record }: ButtonProps) => {
  return (
    <Box className={cx("care_plan_rowActions")}>
      <CreateButton />
      <ViewButton />
    </Box>
  );
};

const RowProject = (project: any) => {
  return (
    <Box className="care_plan_rowActions">
      <ViewProjectButton props={project} />
    </Box>
  );
};

const ViewProjectButton = (props: any) => {
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const tooltipContent = (
    <span>
      {props.props.project.project_count} sản phẩm đã xem <br />
      {props.props.project.project_pass} sản phẩm đã duyệt
    </span>
  );
  return (
    <>
      <Tooltip title={tooltipContent}>
        <IconButton onClick={() => setOpenEditDialog(true)}>
          {props.props.project.project_pass ? (
            <RemoveRedEyeOutlinedIcon className={"btn_floor"} />
          ) : props.props.project.project_count ? (
            <RemoveRedEyeOutlinedIcon className={"btn_edit"} />
          ) : (
            <VisibilityOffOutlinedIcon className={"btn_edit"} />
          )}
        </IconButton>
      </Tooltip>
      <CustomerProject
        isOpen={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
      ></CustomerProject>
    </>
  );
};

//xem lịch sử chăm sóc
const ViewButton = (props: any) => {
  const [openEditDialog, setOpenEditDialog] = useState(false);
  return (
    <>
      <Tooltip title="Xem" arrow>
        <IconButton onClick={() => setOpenEditDialog(true)}>
          <HistoryOutlinedIcon className={"btn_edit"} />
        </IconButton>
      </Tooltip>
      <HistoryCare
        isOpen={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
      ></HistoryCare>
    </>
  );
};
const CreateButton = (props: any) => {
  const [openEditDialog, setOpenEditDialog] = useState(false);
  return (
    <>
      <Tooltip title="Thêm" arrow>
        <IconButton onClick={() => setOpenEditDialog(true)}>
          <AddCircleOutlineIcon className={"btn_floor"} />
        </IconButton>
      </Tooltip>
      {openEditDialog && (
        <CreateHistoryCare
          openDialog={openEditDialog}
          onCloseDialog={() => setOpenEditDialog(false)}
        ></CreateHistoryCare>
      )}
    </>
  );
};

const FilterAndActions = (props: any) => {
  // const notify = useNotify();
  // const refresh = useRefresh();
  // @ts-ignore
  // const { register, handleSubmit } = useForm();
  const { filterValues } = useListContext();
  const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);
  const [openCreateFiled, setOpenCreateFiled] = useState<boolean>(false);
  const [sourceList, setSourceList] = useState<any[]>([
    { id: 0, name: "Tất cả" },
  ]);
  const [saleList, setSaleList] = useState<any[]>([{ id: 0, name: "Tất cả" }]);

  //STATE
  const [fillterOpen, setFillterOpen] = useState<boolean>(false);
  // @ts-ignore
  const {
    nameContext,
    finance_fromContext,
    finance_toContext,
    acreage_fromContext,
    acreage_toContext,
    sourceContext,
    saleContext,
    dateStartContext,
    dateEndContext,
    statusContext,
  } = useContext(ProjectPolicyContext);
  const [name, setName] = nameContext;
  const [source, setSource] = sourceContext;
  const [sale, setSale] = saleContext;
  const [finance_from, setFinance_from] = finance_fromContext;
  const [finance_to, setFinance_to] = finance_toContext;
  const [acreage_from, setAcreage_from] = acreage_fromContext;
  const [acreage_to, setAcreage_to] = acreage_toContext;
  const [dateStart, setDateStart] = dateStartContext;
  const [dateEnd, setDateEnd] = dateEndContext;
  const [status, setStatus] = statusContext;

  //Loading
  const [loading, setLoading] = useState<boolean>(false);

  React.useEffect(() => {
    setLoading(true);
    const fetchApi = async () => {
      const [res1, res2] = await Promise.all([
        request.get("customer_source/selectCustomerSource"),
        request.get("users/selectUserByType"),
      ]);
      setSourceList([...sourceList, ...res1.data.data]);
      setSaleList([...saleList, ...res2.data.data]);
      setLoading(false);
    };
    fetchApi();
  }, []);

  // const onSubmit = async () => {
  //   if (picture.length > 0) {
  //     const formdata = new FormData();
  //     picture.map((item: any) => {
  //       formdata.append("file[]", item);
  //     });
  //     uploadImportCustomer(formdata)
  //       .then(() => {
  //         notify(`Thêm file thành công`, {
  //           type: "success",
  //           undoable: true,
  //         });
  //         setOpenCreateFiled(false);
  //         refresh();
  //         setPicture([]);
  //       })
  //       .catch((error) => {
  //         notify(error.message, {
  //           type: "error",
  //           undoable: true,
  //         });
  //         refresh();
  //         setPicture([]);
  //       });
  //   } else {
  //     notify(`Bạn chưa tạo file nào, vui lòng thử lại sau !`, {
  //       type: "error",
  //       undoable: true,
  //     });
  //   }
  // };
  /**
   * handle change name
   * @param e
   */
  const handleNameChange = (e: any) => {
    setName(e == "" ? "" : e.target.value);
  };
  const handleFromPriceChange = (e: any) => {
    setFinance_from(e == "" ? "" : e.target.value);
  };
  const handleToPriceChange = (e: any) => {
    setFinance_to(e == "" ? "" : e.target.value);
  };
  const handleFromAcreageChange = (e: any) => {
    setAcreage_from(e == "" ? "" : e.target.value);
  };
  const handleToAcreageChange = (e: any) => {
    setAcreage_to(e == "" ? "" : e.target.value);
  };
  const handleOpenFillter = () => {
    setFillterOpen((prev) => !prev);
    setOpenCreateFiled(false);
  };
  const handlerOpenFiled = () => {
    setOpenCreateFiled((prev) => !prev);
    // setPicture([]);
    setFillterOpen(false);
  };
  // const onChangePicture = async (e: any) => {
  //   console.log(e.target.files[0]);
  //   const file = e.target.files;
  //   Array.from(file).map((item: any) => {
  //     setPicture((priviewImage: any) => priviewImage.concat(item));
  //   });
  // };
  // const handleDrop = async (e: any) => {
  //   await e.preventDefault();
  //   await e.stopPropagation();
  //   Array.from(e.dataTransfer.files).map((item: any) => {
  //     if (item.type != "text/csv") {
  //       notify("Vui lòng chọn đúng định dạng file excel", { type: "error" });
  //     } else {
  //       setPicture((priviewImage: any) => priviewImage.concat(item));
  //     }
  //   });
  // };
  // const handleDragOver = async (e: any) => {
  //   await e.preventDefault();
  //   await e.stopPropagation();
  // };
  const styleWrapper = {
    overflow: "hidden",
    height: fillterOpen ? 150 : 0,
    transition: "200",
  };

  return (
    <Box className="HeaderActionWrapper">
      <ResponsiveHeaderButtonWrapper>
        <Box>
          <Button
            disabled={loading}
            variant={fillterOpen ? "outlined" : "contained"}
            color={fillterOpen ? "error" : "primary"}
            startIcon={fillterOpen ? <CloseIcon /> : <SearchIcon />}
            onClick={handleOpenFillter}
          >
            {fillterOpen ? "Đóng" : "Lọc "}
          </Button>
        </Box>
        {commonFunction.checkPermission("CUSTOMER_ADD") && (
          <Box>
            <CreateButtonMUI
              title="Thêm khách hàng"
              onClick={() => setOpenCreateDialog(true)}
            />
            <CreateCustomer
              isOpen={openCreateDialog}
              onClose={() => setOpenCreateDialog(false)}
            />
          </Box>
        )}
      </ResponsiveHeaderButtonWrapper>

      <Box sx={{ width: "100%" }}>
        <Form onSubmit={() => undefined} initialValues={filterValues}>
          {() => (
            <FillterWrapper
              style={styleWrapper}
              className={cx("fillter-wrapper")}
            >
              <FillterItem>
                <FilterLiveSearch
                  onChange={handleNameChange}
                  value={name}
                  variant="outlined"
                  source="name"
                  label="resources.common.fields.search"
                />
              </FillterItem>
              <FillterItem>
                <Autocomplete
                  fullWidth
                  id="combo-box-demo"
                  options={STATUS_CUSTOMER}
                  renderInput={(params) => (
                    <InputText
                      {...params}
                      variant="outlined"
                      label="Trạng thái"
                    />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={status}
                  getOptionLabel={(option) => option?.label}
                  onChange={(e, value) =>
                    setStatus(value == null ? value : value)
                  }
                />
              </FillterItem>
              <FillterItem>
                <Autocomplete
                  fullWidth
                  id="combo-box-demo"
                  options={sourceList}
                  renderInput={(params) => (
                    <InputText {...params} variant="outlined" label="Nguồn" />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={source}
                  getOptionLabel={(option) => option?.name}
                  onChange={(e, value) =>
                    setSource(value == null ? sourceList[0] : value)
                  }
                />
              </FillterItem>
              <FillterItem>
                <Autocomplete
                  fullWidth
                  id="combo-box-demo"
                  options={saleList}
                  renderInput={(params) => (
                    <InputText {...params} variant="outlined" label="Sales" />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={sale}
                  getOptionLabel={(option) => option?.name}
                  onChange={(e, value) =>
                    setSale(value == null ? saleList[0] : value)
                  }
                />
              </FillterItem>
              <FillterItem>
                <FilterLiveSearch
                  sx={{
                    mt: 1.4,
                  }}
                  onChange={handleFromPriceChange}
                  value={finance_from}
                  variant="outlined"
                  source="from_price"
                  label="Tài chính từ"
                />
              </FillterItem>
              <FillterItem>
                <FilterLiveSearch
                  sx={{
                    mt: 1.4,
                  }}
                  onChange={handleToPriceChange}
                  value={finance_to}
                  variant="outlined"
                  source="to_price"
                  label="Tài chính đến"
                />
              </FillterItem>
              <FillterItem>
                <FilterLiveSearch
                  sx={{
                    mt: 1.4,
                  }}
                  onChange={handleFromAcreageChange}
                  value={acreage_from}
                  variant="outlined"
                  source="from_price"
                  label="Diện tích từ"
                />
              </FillterItem>
              <FillterItem>
                <FilterLiveSearch
                  sx={{
                    mt: 1.4,
                  }}
                  onChange={handleToAcreageChange}
                  value={acreage_to}
                  variant="outlined"
                  source="to_price"
                  label="Diện tích đến"
                />
              </FillterItem>
              <FillterItem>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="vi"
                >
                  <DatePicker
                    label="Ngày bắt đầu"
                    inputFormat="DD/MM/YYYY"
                    value={dateStart}
                    onChange={(newValue) => setDateStart(newValue)}
                    renderInput={(params) => (
                      <InputText
                        className="mb-23"
                        {...params}
                        fullWidth
                        variant="outlined"
                      />
                    )}
                  />
                </LocalizationProvider>
              </FillterItem>
              <FillterItem>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="vi"
                >
                  <DatePicker
                    label="Ngày kết thúc"
                    inputFormat="DD/MM/YYYY"
                    disableFuture
                    value={dateEnd}
                    onChange={(newValue) => setDateEnd(newValue)}
                    renderInput={(params) => (
                      <InputText
                        className="mb-23"
                        {...params}
                        fullWidth
                        variant="outlined"
                      />
                    )}
                  />
                </LocalizationProvider>
              </FillterItem>
            </FillterWrapper>
          )}
        </Form>
      </Box>
    </Box>
  );
};
const ProjectDatagrid = (props: DatagridProps) => {
  const refresh = useRefresh();
  const notify = useNotify();

  const handleChangeCustomStatus = async (
    event: SelectChangeEvent,
    idRecord: Number
  ) => {
    let data = {
      id: idRecord,
      status: event.target.value,
    };

    await HttpDataService.update(`customer/updateStatus?id=${data.id}`, data)
      .then((response: any) => {
        if (response.data.status) {
          // @ts-ignore
          notify(response.data.message, { type: "success" });
        } else {
          // @ts-ignore
          notify(response.data.message, { type: "error" });
        }
      })
      .catch((e: Error) => {
        // @ts-ignore
        notify("Cập nhật không thành công", { type: "error" });
      });
    refresh();
  };

  const userPermission = Number(
    commonFunction.checkUserIdentity().permission_data
  );
  var showAssigner = false;
  var showPerson_charge = false;
  if (userPermission === 2) {
    showPerson_charge = true;
  } else if (userPermission === 1) {
    showAssigner = true;
    showPerson_charge = true;
  } else if (userPermission === 0) {
    showAssigner = true;
    showPerson_charge = true;
  }
  return (
    <>
      <div>
        <Datagrid optimized bulkActionButtons={false}>
          <TextField source={"code"} label={"Mã khách hàng"} />
          <FunctionField
            label={"Thời gian"}
            source={"created_at"}
            render={(record: any) => {
              return moment(record.created_at).format("DD/MM/YYYY");
            }}
          />
          {showAssigner ? (
            <TextField source={"assigner_name"} label={"Quản lý"} />
          ) : (
            <></>
          )}
          {showPerson_charge ? (
            <TextField source={"sale_name"} label={"Sale"} />
          ) : (
            <></>
          )}
          <TextField source={"source_name"} label={"Nguồn"} />
          <TextField source={"name"} label={"Tên khách hàng"} />
          <TextField source={"contact"} label={"Liên hệ"} />
          <TextField source={"district_names"} label={"Khu vực"} />
          <FunctionField
            label={"Tài chính"}
            render={(record: any) => {
              return record?.finance
                ? record?.finance.toLocaleString("vi", {
                    style: "currency",
                    currency: "VND",
                  })
                : "";
            }}
          />
          <TextField source={"acreage"} label={"Diện tích"} />
          {/* <TextField source={"project_type_name"} label={"Loại dự án"} /> */}
          {/* <TextField source={"other_request"} label={"Yêu cầu khác"} /> */}
          <FunctionField
            label={"Sản phẩm"}
            render={(record: any) => {
              return <RowProject project={record} />;
            }}
          />
          <RowTakeCare label={"Chăm sóc"} />
          <FunctionField
            className={cx("status")}
            label={"Trạng thái"}
            render={(record: any) => {
              const isStatus6 = record.status === 5;
              const isStatus1 = record.status === 1;
              return (
                <FormControlLabel
                  control={
                    <FormControl variant="standard">
                      <Select
                        // labelId="demo-select-small-label"
                        // id="demo-select-small"
                        value={record.status}
                        onChange={(e) => handleChangeCustomStatus(e, record.id)}
                        sx={{
                          ...(isStatus6 && { color: "red" }),
                          ...(isStatus1 && { color: "green" }),
                        }}
                        readOnly={isStatus1}
                      >
                        <MenuItem value={0}>Đang chăm sóc</MenuItem>
                        <MenuItem style={{ color: "green" }} value={1}>
                          Đã chốt
                        </MenuItem>
                        <MenuItem value={2}>Thuê chỗ khác</MenuItem>
                        <MenuItem value={3}>Không phản hồi</MenuItem>
                        <MenuItem value={4}>Đang chờ</MenuItem>
                        <MenuItem style={{ color: "red" }} value={5}>
                          Chưa liên hệ
                        </MenuItem>
                      </Select>
                    </FormControl>
                  }
                  label=""
                  labelPlacement="end"
                />
              );
            }}
          />

          <FunctionField
            label={"THAO TÁC"}
            render={(record: any) => {
              return <RowActions label={"THAO TÁC"} record={record} />;
            }}
          />
        </Datagrid>
      </div>
    </>
  );
};
