import {
  CreateButton,
  CreateProps,
  useNotify,
  useRefresh,
  useTranslate,
} from "react-admin";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
} from "@mui/material";
import {
  Wrapper,
  InputRowWrapper,
  ItemWrapper,
  EditorWrapper,
} from "./styledMui";
import Editor from "../component/Editor";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import React, { useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import ReplyIcon from "@mui/icons-material/Reply";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import HttpDataService from "../dataProvider/http.service";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import ImagePreview from "../component/ImgPreview";
import CkeditorServer from "../component/CkeditorServer";

//Reformat Style
const styles = makeStyles((theme: any) =>
  createStyles({
    root: {
      "& .css-k4qjio-MuiFormHelperText-root.Mui-error": {
        position: "absolute",
        paddingTop: "41px",
      },
      "& .css-1z10yd4-MuiFormControl-root-MuiTextField-root": {
        marginTop: 20,
        marginBottom: 20,
      },
    },
  })
);

export const NewsCreate = (props: any) => {
  const classes = styles();
  const { onCloseDialog, openDialog } = props;
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [meta_title, setMetaTitle] = useState<string>("");
  const [meta_description, setMetaDescription] = useState<string>("");
  const [meta_keyword, setMetaKeyword] = useState<string>("");
  const [content, setContent] = useState<string>("");
  const [slug, setSlug] = useState<string>("");

  //avatar
  const [image, setImage] = React.useState<any[]>([]);
  const removeImageUtilities = (imgIndex: number) => {
    const newListImage = image.filter((_, index) => index !== imgIndex);
    if (image.length === 1) {
      setImage([]);
    }
    setImage(newListImage);
  };
  // checker
  const [checked, setChecked] = React.useState(false);
  const handleChange = (event: any) => {
    console.log(event, "event");
    setChecked(event.target.checked);
  };

  const [titleError, setTitleError] = useState(false);
  const [textTitleError, setTextTitleError] = useState("");
  const translate = useTranslate();
  const dataStatus = [
    { label: translate("resources.common.fields.active"), id: 1 },
    { label: translate("resources.common.fields.block"), id: 0 },
  ];
  const [status, setStatus] = useState<any>(dataStatus[0]);
  const refresh = useRefresh();
  const notify = useNotify();

  /**
   * handle change name
   * @param e
   */
  const handleTitleChange = (e: any) => {
    setTitleError(false);
    setTextTitleError("");
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (e: any) => {
    setDescription(e.target.value);
  };

  const handleMetaTitleChange = (e: any) => {
    setMetaTitle(e.target.value);
  };
  const handleMetaDescriptionChange = (e: any) => {
    setMetaDescription(e.target.value);
  };
  const handleMetaKeywordChange = (e: any) => {
    setMetaKeyword(e.target.value);
  };
  const handleSlugChange = (e: any) => {
    setSlug(e.target.value);
  };
  const handleChangeParent = (e: any) => {
    console.log(e, "handleChangeParent");
  };

  const handleChangeStatus = (e: any) => {
    setStatus(e);
  };

  /**
   * handle save
   */

  const handleSave = async () => {
    // if (!fnValidation()) {
    let data = {
      title: title.trim(),
      description: description.trim(),
      meta_title: meta_title.trim(),
      meta_description: meta_description.trim(),
      meta_keyword: meta_keyword.trim(),
      content: content,
      slug: slug,
      status: status.id === 1 ? 1 : 0,
      is_hot: checked ? 1 : 0,
    };

    const submitObj = new FormData();
    //avatar
    if (image) {
      image.map((item: any) => {
        submitObj.append("image", item);
      });
    }

    Object.keys(data).map(
      (key) =>
        // @ts-ignore
        submitObj.append(key, data[key]),
    );

    await HttpDataService.create("news/add-news", submitObj)
      .then((response: any) => {
        if (response.data.status) {
          console.log(response.data, "response");
          // @ts-ignore
          notify(response.data.message, { type: "success" });
        } else {
          // @ts-ignore
          notify(response.data.message, { type: "error" });
        }
      })
      .catch((e: Error) => {
        console.log(e);
      });

    fnResetFrm();
    onCloseDialog();
    refresh();
    // }
  };

  const handleCloseDialog = () => {
    fnResetFrm();
    onCloseDialog();
  };

  /**
   * fn validation form
   */
  function fnValidation() {
    let error = false;
    if (title.trim().length === 0) {
      error = true;
      setTitleError(true);
      setTextTitleError(translate("resources.news.fields.val_name"));
    }
    return error;
  }

  function fnResetFrm() {
    setTitle("");
    setTitleError(false);
    setTextTitleError("");
    setDescription("");
    setContent("");
    setMetaTitle("");
    setMetaDescription("");
    setMetaKeyword("");
    setStatus(null);
  }

  return (
    <>
      <Dialog
        open={openDialog}
        fullWidth
        disableEnforceFocus
        PaperProps={{
          style: {
            borderRadius: 2,
            minWidth: "65%",
          },
        }}
      >
        <div className={"common__dialogTitle_area"}>
          <DialogTitle className={"member__dialogTitle"}>
            <div>{translate("resources.news.add_news")}</div>
          </DialogTitle>
          <div className={"common__dialogTitle_actions"}>
            <IconButton
              aria-label="exists"
              color={"error"}
              onClick={() => {
                onCloseDialog(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <Box className={"dialog_box"}>
          <DialogContent className={"dialog_content"}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={8}>
                <TextField
                  className={`${classes.root} mb-23`}
                  fullWidth
                  label={
                    <span className={"span_style"}>
                      {translate("resources.news.fields.title")}
                      <span style={{ color: "red" }}>(*)</span>
                    </span>
                  }
                  value={title}
                  error={titleError}
                  helperText={textTitleError}
                  onChange={handleTitleChange}
                  variant="outlined"
                />
                <TextField
                  className={classes.root}
                  fullWidth
                  multiline
                  maxRows={3}
                  label={
                    <span className={"span_style"}>
                      {translate("resources.news.fields.description")}
                    </span>
                  }
                  value={description}
                  onChange={handleDescriptionChange}
                  variant="outlined"
                />
                <EditorWrapper className={"editor-news"}>
                  {/* <Editor
                    data={content}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setContent(data);
                    }}
                  /> */}
                  <CkeditorServer
                    name="news_add"
                    onChangeData={(data) => {
                      setContent(data);
                    }}
                  />
                </EditorWrapper>
                <TextField
                  className={`${classes.root} mb-23`}
                  fullWidth
                  multiline
                  maxRows={3}
                  label={
                    <span className={"span_style"}>
                      {translate("resources.news.fields.meta_title")}
                    </span>
                  }
                  value={meta_title}
                  onChange={handleMetaTitleChange}
                  variant="outlined"
                />
                <TextField
                  className={`${classes.root} mb-23`}
                  fullWidth
                  multiline
                  maxRows={3}
                  label={
                    <span className={"span_style"}>
                      {translate("resources.news.fields.meta_description")}
                    </span>
                  }
                  value={meta_description}
                  onChange={handleMetaDescriptionChange}
                  variant="outlined"
                />
                <TextField
                  className={`${classes.root} mb-23`}
                  fullWidth
                  multiline
                  maxRows={3}
                  label={
                    <span className={"span_style"}>
                      {translate("resources.news.fields.meta_keyword")}
                    </span>
                  }
                  value={meta_keyword}
                  onChange={handleMetaKeywordChange}
                  variant="outlined"
                />
                <TextField
                  className={`${classes.root} mb-23`}
                  fullWidth
                  label={
                    <span className={"span_style"}>
                      {translate("resources.news.fields.slug")}
                    </span>
                  }
                  value={slug}
                  onChange={handleSlugChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={
                        <span className={"span_style"}>
                          {translate("resources.common.fields.status")}
                        </span>
                      }
                    />
                  )}
                  sx={{ width: "100%" }}
                  value={status}
                  onChange={(event, value) => handleChangeStatus(value)}
                  options={dataStatus}
                />
                <Grid item>
                  <div className={"main-image-news"}>
                    <div className={"upload-image-wrapper"}>
                      <div className={"title-image"}>Hình ảnh</div>
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        onChange={(e: any) => {
                          setImage([...e.target.files]);
                          e.target.value = null;
                        }}
                      ></input>
                      {image.length > 0 && (
                        <div className={"image-show-wrapper"}>
                          {image.map((image: any, index: number) => (
                            <ImagePreview
                              key={index}
                              src={URL.createObjectURL(image)}
                              onRemove={() => removeImageUtilities(index)}
                            />
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </Grid>
                <ItemWrapper className={"checked-news"}>
                  <FormControlLabel
                    control={
                      <Switch checked={checked} onChange={handleChange} />
                    }
                    label="Tin tức nổi bật"
                    labelPlacement="start"
                  />
                </ItemWrapper>
              </Grid>
            </Grid>
          </DialogContent>
        </Box>
        <Toolbar>
          <Box display="flex" justifyContent="center" width={"100%"}>
            <Button
              variant="contained"
              startIcon={<SaveIcon />}
              onClick={handleSave}
            >
              {translate("resources.common.fields.add")}
            </Button>
            &nbsp;&nbsp;
            <Button
              startIcon={<ReplyIcon />}
              className={"button-close"}
              variant="contained"
              onClick={handleCloseDialog}
            >
              {translate("resources.common.fields.cancel")}
            </Button>
          </Box>
        </Toolbar>
      </Dialog>
    </>
  );
};
