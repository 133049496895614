import {
    DefaultDataProvider,
    httpClient,
    serialize,
} from '../default-data-provider';
import {constants} from "../../shared/constants";

export const baseApiUrl = 'department';

export default class DepartmentIdService extends DefaultDataProvider {

}

export const departmentIdService = new DepartmentIdService(
    constants.RESOURCE.DEPARTMENT_ID,
    constants.BASE_MODULE_URL,
    baseApiUrl
);