import request from '../utils/httpRequest';

export const getDistricts = async () => {
    try {
        const response = await request.get('districts/getAll');
        
        return response.data.data.items;
    } catch (error) {
        console.log(error);
    }
};