export const constants = {
	BASE_MODULE_URL: `${process.env.REACT_APP_BASE_MODULE_URL}`,
	LOCAL_STORAGE: {
		TOKEN: "token",
		USER_IDENTITY: "userIdentity",
		PERMISSION_API_LIST: "permissionApiList",
		LIST_MENU: "listMenu",
		DOLLAR_RATE: "dollar",
	},
	RESOURCE: {
		USERS: "users",
		PROJECT: "project",
		DEPARTMENT: "department",
		PERMISSION: "permission",
		PARTS: "parts",
		ROLES: "roles",
		OFFICE: "office",
		DEPARTMENT_ID: "getAllDepartment",
		OFFICE_ID: "getAllDepartment",
		ROLES_ID: "getAllRoles",
		PROFILE: "profile",
		PROJECT_TYPE: "project_type",
		MENU: "menu_admin",
		CUSTOMER: "customer",
		TRANSACTION: "transaction",
		VIDEO: "video",
		SPAM: "spam",
		KPI: "kpi",
		CUSTOMER_SOURCES: "customerSources",
		PROJECT_ESTATE: "project_estate",
		FEEDBACK: "feedback",
		GENERAL: "general",
		NEWS: "news",
		WAGE: "wage",
		SALARY: "salary",
		KEYWORD: "keyword",
		DIRECTION: "direction",
		SOURCE: "source",
		PARTNER: "partner",
		STREET: "streets",
		DISTRICT: "district",
		BACKUP: "backup",
	},
	// PARENT: {
	//     GETPARENT: "getParent"
	// }
};
