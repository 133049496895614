import * as React from 'react';
import { ReactElement, ReactNode } from 'react';
import {
    List,
    MenuItem,
    ListItemIcon,
    Typography,
    Collapse,
    Tooltip,
} from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useTranslate, useSidebarState } from 'react-admin';
import ListItemText from '@mui/material/ListItemText';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { createStyles, makeStyles } from '@material-ui/core/styles';

interface Props {
    dense: boolean;
    handleToggle: () => void;
    icon: ReactElement;
    isOpen: boolean;
    name: string;
    children: ReactNode;
}

const styles = makeStyles((theme: any) =>
    createStyles({
        root: {
            '.css-10hburv-MuiTypography-root':{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                width: '100%',
                display: 'inlineBlock',
            }
        }
    }));


const SubMenu = (props: Props) => {
    const classes = styles()
    const { handleToggle, isOpen, name, icon, children, dense } = props;
    // console.log(isOpen)
    const translate = useTranslate();

    const [sidebarIsOpen] = useSidebarState();

    const header = (
        <MenuItem dense={dense} onClick={handleToggle} className={'menu_cus pl25'}>
            <ListItemIcon sx={{ minWidth: 5 }}>
                {/*{isOpen ? <ExpandMore /> : icon}*/}
                { icon }
            </ListItemIcon>
            <ListItemText sx={{
                '.css-10hburv-MuiTypography-root':{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    width: '100%',
                    display: 'inlineBlock',
                }
            }}>{translate(name)}</ListItemText>
            <Typography variant="inherit" color="textSecondary">
                {isOpen ? <KeyboardArrowUpIcon className={'icon_right_menu'}/> : <KeyboardArrowDownIcon className={'icon_right_menu'}/>}
            </Typography>
        </MenuItem>
    );

    return (
        <div>
            {sidebarIsOpen || isOpen ? (
                header
            ) : (
                <Tooltip title={translate(name)} placement="right">
                    {header}
                </Tooltip>
            )}
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <List
                    dense={dense}
                    component="div"
                    disablePadding
                    sx={{
                        '& a': {
                            transition:
                                'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
                            paddingLeft: sidebarIsOpen ? 4 : 2,
                        },
                    }}
                >
                    {children}
                </List>
            </Collapse>
        </div>
    );
};

export default SubMenu;
