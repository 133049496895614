import {TranslationMessages} from 'react-admin';
import englishMessages from 'ra-language-english';

const vietnameseMessages: TranslationMessages = {
    ...englishMessages,
    ra: {
        message: {
            about: 'Về',
            are_you_sure: 'Bạn có chắc chắn không?',
            bulk_delete_content:
                'Bạn có chắc chắn muốn xóa %{name} này? |||| Bạn có chắc chắn muốn xóa %{smart_count} bản ghi này?',
            bulk_delete_title:
                'Xóa %{name} |||| Xóa %{smart_count} bản ghi %{name}',
            delete_content: 'Bạn có chắc chắn muốn xóa bản ghi này?',
            delete_title: 'Xóa %{name}',
            details: 'Chi tiết',
            error:
                'Đã xảy ra lỗi ở phía người dùng và yêu cầu của bạn không thể hoàn thành.',
            invalid_form: 'Dữ liệu nhập không đúng. Vui lòng kiểm tra lại',
            loading: 'Trang web đang tải, vui lòng đợi trong giây lát',
            no: 'Không',
            not_found:
                'Bạn đã nhập một URL sai hoặc bạn đã làm theo một liên kết không đúng.',
            yes: 'Có',
            unsaved_changes:
                'Một vài thay đổi của bạn chưa được lưu. Bạn có chắc là muốn bỏ qua chúng?',
            bulk_update_content: 'Nội dung cập nhật',
            bulk_update_title: 'Tiêu đề cập nhật',
        },
        // @ts-ignore
        notification: {
            updated:
                'Cập nhật thành công |||| Cập nhật thành công %{smart_count} thành phần',
            created: 'Đã tạo thành công',
            deleted:
                'Đã xóa thành công |||| Xóa thành công %{smart_count} thành phần',
            bad_item: 'Thành phần không đúng',
            item_doesnt_exist: 'Thành phần không tồn tại',
            http_error: 'Lỗi giao tiếp với máy chủ',
            data_provider_error:
                'Lỗi dataProvider. Kiểm tra console để biết chi tiết.',
        },
        // @ts-ignore
        configurable: {
            customize: '12345667'
        },
        // @ts-ignore
        action: {
            create_item: 'Create %{item}',
            add_filter: 'Thêm filters',
            add: 'Thêm',
            back: 'Trở về',
            bulk_actions: '%{smart_count} đã chọn',
            cancel: 'Bỏ qua',
            accept: 'Đồng ý',
            clear_input_value: 'Xóa giá trị',
            clone: 'Sao chép',
            confirm: 'Xác nhận',
            create: 'Tạo mới',
            delete: 'Xóa',
            edit: 'Sửa',
            export: 'Xuất Báo Cáo',
            list: 'Danh sách',
            refresh: 'Làm mới',
            remove_filter: 'Bỏ filters',
            remove: 'Xóa bỏ',
            save: 'Lưu',
            search: 'Tìm kiếm',
            show: 'Hiển thị',
            sort: 'Sắp xếp',
            undo: 'Hoàn tác',
            unselect: 'Huỷ chọn',
            expand: 'Mở rộng',
            close: 'Đóng',
            open_menu: 'Mở menu',
            close_menu: 'Đóng menu',
            update: 'Cập nhật',
            move_up: 'Lên',
            move_down: 'Xuống',
            select_all: 'Chọn tất cả',
            select_row: 'Chọn hàng',
            deleteFilters: 'Xóa bộ lọc',
            cancel_form: 'Hủy',
            cancelled: 'Hủy',
            refuse: 'Từ chối',
            chuyenKenh: 'Chuyển kênh',
        },
        // @ts-ignore
        navigation: {
            no_results: 'Không tìm thấy kết quả nào',
            no_more_results:
                'Trang số %{page} đã vượt giới hạn. Vui lòng trở lại trang trước.',
            page_out_of_boundaries: 'Trang số %{page} đã vượt giới hạn',
            page_out_from_end: 'Không thể đi tiếp sau trang cuối',
            page_out_from_begin: 'Không thể trở lại trước trang 1',
            page_range_info: '%{offsetBegin}-%{offsetEnd} của %{total}',
            page_rows_per_page: 'Số bản ghi/trang:',
            next: 'Tiếp',
            prev: 'Trước',
            skip_nav: 'Bỏ qua',
        },
        // @ts-ignore
        page: {
            create: 'Tạo %{name}',
            dashboard: 'Trang chủ',
            edit: '%{name} #%{id}',
            error: 'Đã xảy ra lỗi',
            list: 'Danh sách %{name}',
            loading: 'Đang tải',
            not_found: 'Không tìm thấy',
            show: '%{name} #%{id}',
            empty: 'Chưa có %{name} nào.',
            invite: 'Bạn có muốn tạo mới không?',
        },
        auth: {
            auth_check_error: 'Vui lòng đăng nhập để tiếp tục',
            user_menu: 'Hồ sơ',
            username: 'Tên đăng nhập',
            password: 'Mật khẩu',
            sign_in: 'ĐĂNG NHẬP',
            sign_in_sso: 'Đăng nhập SSO',
            sign_in_error: 'Đăng nhập không thành công, vui lòng thử lại',
            logout: 'Đăng xuất',
            forgotPassword: 'Quên mật khẩu?',
            textHeader: 'ĐĂNG NHẬP HỆ THỐNG',
            year: '©2022',
        },
    },
    pos: {
        search: 'Search',
        configuration: 'Cấu hình',
        language: 'Language',
        theme: {
            name: 'Theme',
            light: 'Light',
            dark: 'Dark',
        },
        dashboard: {
            monthly_revenue: 'Monthly Revenue',
            month_history: '30 Day Revenue Project',
            new_orders: 'New Orders',
            pending_reviews: 'Pending Reviews',
            all_reviews: 'See all reviews',
            new_customers: 'New Customers',
            all_customers: 'See all customers',
            pending_orders: 'Pending Orders',
            order: {
                items:
                    'by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items',
            },
            welcome: {
                title: 'Welcome to the react-admin e-commerce demo',
                subtitle:
                    "This is the admin of an imaginary poster shop. Feel free to explore and modify the data - it's local to your computer, and will reset each time you reload.",
                ra_button: 'react-admin site',
                demo_button: 'Source for this demo',
            },
        },
        menu: {
            sales: 'Sales',
            dashboard: 'Trang trủ',
            users: 'Users',
            project: "Quản lý dự án",
            department: "Quản lý phòng ban",
            system: 'Hệ thống',
            parts: 'Quản lý bộ phận',
            catalog: 'Danh mục',
            office: 'Chức vụ'
        },
    },
    resources: {
        customers: {
            name: 'Customer |||| Customers',
            fields: {
                commands: 'Orders',
                first_seen: 'First seen',
                groups: 'Segments',
                last_seen: 'Last seen',
                last_seen_gte: 'Visited Since',
                name: 'Name',
                total_spent: 'Total spent',
                password: 'Password',
                confirm_password: 'Confirm password',
                stateAbbr: 'State',
            },
            filters: {
                last_visited: 'Last visited',
                today: 'Today',
                this_week: 'This week',
                last_week: 'Last week',
                this_month: 'This month',
                last_month: 'Last month',
                earlier: 'Earlier',
                has_ordered: 'Has ordered',
                has_newsletter: 'Has newsletter',
                group: 'Segment',
            },
            fieldGroups: {
                identity: 'Identity',
                address: 'Address',
                stats: 'Stats',
                history: 'History',
                password: 'Password',
                change_password: 'Change Password',
            },
            page: {
                delete: 'Delete Customer',
            },
            errors: {
                password_mismatch:
                    'The password confirmation is not the same as the password.',
            },
        },
        commands: {
            name: 'Order |||| Orders',
            amount: '1 order |||| %{smart_count} orders',
            title: 'Order %{reference}',
            fields: {
                basket: {
                    delivery: 'Delivery',
                    reference: 'Reference',
                    quantity: 'Quantity',
                    sum: 'Sum',
                    tax_rate: 'Tax Rate',
                    taxes: 'Tax',
                    total: 'Total',
                    unit_price: 'Unit Price',
                },
                address: 'Address',
                customer_id: 'Customer',
                date_gte: 'Passed Since',
                date_lte: 'Passed Before',
                nb_items: 'Nb Items',
                total_gte: 'Min amount',
                status: 'Status',
                returned: 'Returned',
            },
            section: {
                order: 'Order',
                customer: 'Customer',
                shipping_address: 'Shipping Address',
                items: 'Items',
                total: 'Totals',
            },
        },
        invoices: {
            name: 'Invoice |||| Invoices',
            fields: {
                date: 'Invoice date',
                customer_id: 'Customer',
                command_id: 'Order',
                date_gte: 'Passed Since',
                date_lte: 'Passed Before',
                total_gte: 'Min amount',
                address: 'Address',
            },
        },
        products: {
            name: 'Poster |||| Posters',
            fields: {
                category_id: 'Category',
                height_gte: 'Min height',
                height_lte: 'Max height',
                height: 'Height',
                image: 'Image',
                price: 'Price',
                reference: 'Reference',
                sales: 'Sales',
                stock_lte: 'Low Stock',
                stock: 'Stock',
                thumbnail: 'Thumbnail',
                width_gte: 'Min width',
                width_lte: 'Max width',
                width: 'Width',
            },
            tabs: {
                image: 'Image',
                details: 'Details',
                description: 'Description',
                reviews: 'Reviews',
            },
            filters: {
                categories: 'Categories',
                stock: 'Stock',
                no_stock: 'Out of stock',
                low_stock: '1 - 9 items',
                average_stock: '10 - 49 items',
                enough_stock: '50 items & more',
                sales: 'Sales',
                best_sellers: 'Best sellers',
                average_sellers: 'Average',
                low_sellers: 'Low',
                never_sold: 'Never sold',
            },
        },
        categories: {
            name: 'Category |||| Categories',
            fields: {
                products: 'Products',

            },
        },
        common: {
            fields: {
                address: 'Địa chỉ',
                phone: 'Số điện thoại',
                email: 'Email',
                option: '--Lựa chọn--',
                add: 'Thêm mới',
                created_by: 'Người tạo',
                updated_by: 'Người cập nhật',
                status: 'Trạng thái',
                active: 'Đang hoạt động',
                block: 'Khóa',
                update: 'Cập nhật',
                save: 'Lưu',
                agree: 'Đồng ý',
                cancel: 'Bỏ qua',
                action: 'Thao tác',
                question_delete: 'Bạn có xác nhận xóa bản ghi ?',
                main_delete: 'Hành động xóa bản ghi này là không thế hoàn tác !',
                fulltime: 'Fulltime',
                partime: 'Partime',
                male: 'Nam',
                female: 'Nữ',
                search: 'Tìm kiếm',
                reset: 'Thiết lập lại',
                all:'Tất cả',

            }
        },

        user: {
            list_user: ' Danh sách người dùng',
            info_user: 'Thông tin người dùng',
            info_log: 'Thông tin đăng nhập',
            add_user: 'Thêm mới người dùng',
            edit_user: 'Cập nhật thông tin người dùng',
            del_user: 'Xóa người dùng',
            info_contact_user: 'Thông tin & Liên hệ',

            fields: {
                name_user: ' Tên người dùng',
                email_user: ' Email',
                account_user: ' Tài khoản đăng nhập',
                sex_user: ' Giới tính',
                department: 'Phòng ban',
                first_name_user: 'Họ',
                last_name_user: 'Tên',
                birthday_user: 'Ngày sinh',
                dctt_user: 'Địa chỉ tạm trú',
                dcttru_user: 'Địa chỉ thường trú',
                personal_email_user: 'Email cá nhân',
                phone: 'Số điện thoại',
                ethnic_user: 'Dân tộc',
                id_user: 'Căn cước công dân',
                permission: 'Quyền dữ liệu',
                group_permission: 'Nhóm quyền',
                office: 'Chức vụ',
                parts: 'Bộ phận',
                bhxh: 'Bảo hiểm xã hội',
                noicap: 'Nơi cấp',
                ngaycap: 'Ngày cấp',
                bank: 'Tài khoản ngân hàng',
                working_form: 'Hình thức làm việc',
                password_user: 'Mật khẩu',
                retype_password_user: 'Nhập lại mật khẩu',
                val_first_name: 'Họ không được để trống',
                val_last_name: 'Tên không được để trống',
                val_email: 'Email không được để trống',
                val_account: 'Tài khoản đăng nhập không được để trống',
                val_password: 'Mật khẩu không được để trống',
                val_retype_password: 'Nhập lại mật khẩu không được để trống',
                name_search: 'Nhập tên tìm kiếm',
                email_search: 'Nhập email tìm kiếm',
                user_name_search: 'Nhập tài khoản tìm kiếm',

            }
        },
        feeback:{
            list_feeback: ' Danh sách feeback',
        },

        reviews: {
            name: 'Review |||| Reviews',
            amount: '1 review |||| %{smart_count} reviews',
            relative_to_poster: 'Review on poster',
            detail: 'Review detail',
            fields: {
                customer_id: 'Customer',
                command_id: 'Order',
                product_id: 'Product',
                date_gte: 'Posted since',
                date_lte: 'Posted before',
                date: 'Date',
                comment: 'Comment',
                rating: 'Rating',
            },
            action: {
                accept: 'Accept',
                reject: 'Reject',
            },
            notification: {
                approved_success: 'Review approved',
                approved_error: 'Error: Review not approved',
                rejected_success: 'Review rejected',
                rejected_error: 'Error: Review not rejected',
            },
        },
        segments: {
            name: 'Segment |||| Segments',
            fields: {
                customers: 'Customers',
                name: 'Name',
            },
            data: {
                compulsive: 'Compulsive',
                collector: 'Collector',
                ordered_once: 'Ordered once',
                regular: 'Regular',
                returns: 'Returns',
                reviewer: 'Reviewer',
            },
        },
        dashboard: {
            name: 'Dashboard biểu đồ',
        },
        users: {
            name: 'Quản lý người dùng',
            fields: {
                name: 'Name',
            },
        },
        project: {
            name: 'Danh sách dự án',
        },
        department: {
            name: 'Quản lý phòng ban',
            list_department: 'Danh sách phòng ban',
            add_department: 'Thêm mới phòng ban',
            edit_department: 'Chỉnh sửa phòng ban',
            del_department: 'Xóa phòng ban',
            fields: {
                name_department: 'Tên phòng ban',
                val_name: "Tên phòng ban bắt buộc nhập",
            },

        },
        permission: {
            name: 'Quản lý quyền',
            list_permission: 'Danh sách quyền',
            add_permission: 'Thêm mới quyền',
            edit_permission: 'Chỉnh sửa quyền',
            del_permission: 'Xóa quyền',
            fields: {
                add_permission: 'Thêm quyền',
                name_permission: 'Tên quyền',
                code_permission: 'Mã quyền',
                slug_permission: 'Slug',
                father_permission: 'Cấp cha',
                val_name: "Tên quyền bắt buộc nhập",
                val_code: "Mã quyền bắt buộc nhập",
                val_slug: "Slug bắt buộc nhập",
            },
        },
        parts: {
            name: 'Quản lý bộ phận',
            list_parts: 'Danh sách bộ phận',
            edit_parts: 'Chỉnh sửa bộ phận',
            add_parts: 'Thêm mới bộ phận',
            del_parts: 'Xóa bộ phận',
            fields: {
                name_parts: 'Tên bộ phận',
                val_name: "Tên bộ phận bắt buộc nhập",

            }
        },
        news: {
            list_news: 'Danh sách tin tức',
            edit_news: 'Chỉnh sửa tin tức',
            add_news: 'Thêm mới tin tức',
            del_news: 'Xóa tin tức',
            fields: {
                title: 'Tiêu đề',
                description: 'Mô tả',
                content: 'Nội dung',
                hot: "Tin nổi bật",
                image: "Hình ảnh",
                is_hot: "Nổi bật",
                not_hot: "Không nổi bật",
                meta_title: "Meta title",
                meta_description: "Meta description",
                meta_keyword: "Meta keywords",
                slug: 'Đường dẫn',
                val_name: "Tên bài viết bắt buộc nhập",
            }
        },
        roles: {
            name: 'Quản lý nhóm quyền',
            list_roles: 'Danh sách nhóm quyền',
            add_roles: 'Thêm mới nhóm quyền',
            del_roles: 'Xóa nhóm quyền',
            edit_roles: 'Cập nhật nhóm quyền',
            fields:{
                add_roles: 'Thêm nhóm quyền',
                name_roles: 'Tên nhóm quyền',
                tree_roles: 'Cây danh sách quyền',
                gua_name: 'Guard Name',
                val_name: "Tên quyền bắt buộc nhập",

            },
        },
        home: {
            name: 'Trang chủ'
        },
        office: {
            name: 'Quản lý chức vụ',
            list_office: 'Danh sách chức vụ',
            add_office: "Thêm mới chức vụ",
            del_office: "Xóa chức vụ",
            edit_office: "Chỉnh sửa chức vụ",
            fields: {
                name_office: "Tên chức vụ",
                val_name: "Tên chức vụ bắt buộc nhập",
            }
        },
        project_type: {
            name: "Loại dự án",
            list_project_type: "Danh sách loại dự án",
            add_project_type: "Thêm mới loại dự án",
            edit_project_type: "Sửa loại dự án",
            delete_project_type: "Xóa loại dự án",
            fields: {
                name: "Tên dự án",
                name_project_type: "Tên loại dự án",
                val_name: "Tên loại dự án bắt buộc nhập",
                depth_project_type: "Số thứ tự",
                val_depth: "Depth bắt buộc nhập",
                slug: "Đường dẫn",
                type: "Loại hình",
                val_slug: "Đường dẫn bắt buộc nhập",
                sell: 'Nhà đất bán',
                rent: 'Nhà đất cho thuê',
            }
        },

        keyword: {
            list_keyword: "Danh sách từ khóa",
            add_keyword: "Thêm mới từ khóa",
            edit_keyword: 'Cập nhật từ khóa',
            fields: {
                name: 'Tên từ khóa',
                val_name: "Tên từ khóa không được để trống !",
            }
        },

        direction: {
            list_direction: 'Danh sách hướng',
            add_direction: "Thêm mới hướng",
            edit_direction: 'Cập nhật hướng',

            fields: {
                name: 'Tên hướng',
                val_name: "Tên hướng không được để trống !",
            }
        },

        general: {
            fields: {
                name: "Tên công ty",
                email: "Email",
                address: "Địa chỉ",
                phone: "Số điện thoại",
                title: "Tiêu đề",
                title_about: "Tiêu đề giới thiệu",
                maps: "Google maps",
                meta_title: "Tiêu đề Meta",
                meta_description: "Mô tả Meta",
                meta_keyword: "Từ khóa Meta",
                val_name: "Tên công ty không được để trống !",
                val_address: "Địa chỉ không được để trống !",
                val_phone: "Số điện thoại không được để trống !",
            }
        },

        menu: {
            name: 'Quản lý menu',
            list_menu: 'Danh sách menu',
            edit_menu: 'Chỉnh sửa menu',
            add_menu: 'Thêm mới menu',
            del_menu: 'Xóa menu',
            fields: {
                name_menu: "Tên Menu",
                val_name: "Tên Menu bắt buộc nhập",
                code_menu: "Mã Menu",
                val_code: "Mã Menu bắt buộc nhập",
                des_menu: "Mô tả",
                val_des: "Mô tả bắt buộc nhập",
                father_menu: 'Cấp cha',
                slug_menu: "Link điều hướng",
                order: "Số thứ tự",
                val_slug: "Link điều hướng bắt buộc nhập",
                depth_menu: "Số thứ tự",
            }
        },
        feedback: {
            name: 'Quản lý Feedback',
            list_feedback: 'Danh sách Feedback',
            del_feedback: "Xóa Feedback",
            edit_feedback: "Chỉnh sửa Feedback",
            fields: {
                name: "Tên ",
                phone: "Số điện thoại",
                email: "Email",
                content: "Nội dung",
                note: "Ghi chú",
                val_name: "Tên chức vụ bắt buộc nhập",
                active: "Đã liên hệ",
                block: "Chưa liên hệ",
                created_at: "Ngày liên hệ",
            }
        },
        changePassword: {
            name: 'Đổi mật khẩu tài khoản',
            fields: {
                old_password: 'Mật khẩu cũ',
                new_password: 'Mật khẩu mới',
                re_enter_password: 'Nhập lại mật khẩu mới'
            }
        },
        profile: {
            name: 'Profile'
        }
    },
};

export default vietnameseMessages;
