import * as request from '../utils/httpRequest';

export const getListTypeEstate = async () => {
    try {
        const response = await request.get('project_type_estate/getAll');
        return response.data;
    } catch (error) {
        console.log(error);
    }
};
