import TextField from "@mui/material/TextField";
import React from "react";
import {InputProps as StandardInputProps} from "@mui/material/Input/Input";

interface Props {
    placeholder?: string;
    value?: string;
    onChange?: StandardInputProps['onChange'];
}

const InputNoLabel = ({ placeholder, value, onChange }: Props) => {
    const handleChange = (e: any) => {
        if (onChange) {
            onChange(e);
        }
    };

    return (
        <TextField
            className="c_input"
            label={false}
            variant="outlined"
            placeholder={placeholder}
            value={value}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
        />
    )
}

export default InputNoLabel;