import React, { useContext, useState } from "react";
import {
  List,
  Datagrid,
  DatagridProps,
  useListContext,
  TextField,
  FunctionField,
  useTranslate,
  FilterLiveSearch,
  useNotify,
  SelectField,
  AutocompleteInput,
  useRefresh,
  CheckboxGroupInput,
} from "react-admin";
import {
  Box,
  Chip,
  IconButton,
  Typography,
  Autocomplete,
  TextField as InputText,
  Button,
  Tooltip,
  FormControlLabel,
  Switch,
  MenuItem,
  Select,
  FormControl,
  SelectChangeEvent,
  Checkbox,
  InputLabel,
} from "@mui/material";
import { styled } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import DifferenceIcon from "@mui/icons-material/Difference";
import EditIcon from "@mui/icons-material/Edit";
import ApartmentIcon from "@mui/icons-material/Apartment";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ApartmentRoundedIcon from "@mui/icons-material/ApartmentRounded";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";

import { Form } from "react-final-form";
import ProjectDelete from "./dialogs/ProjectDelete";
import ProjectEdit from "./dialogs/ProjectEdit";
import CreateButtonMUI from "../component/CreateButtonMUI";
import ProjectCreate from "./dialogs/ProjectCreate";
import ExportPDF from "./dialogs/ExportPDF";
import * as projectTypeService from "../Services/projectTypeService";
import * as projectTypeEstateService from "../Services/projectTypeEstateService";
import classNames from "classnames/bind";
import styles from "./Project.module.scss";
import PaginationCustom from "../component/PaginationCustom";
import * as userService from "../Services/userService";
import * as sourceService from "../Services/sourceService";
import * as districtService from "../Services/districtsService";
// call api
import * as request from "../utils/httpRequest";
import HttpDataService from "../dataProvider/http.service";
import {
  ISHOT,
  STATUS_CURRENT,
  STATUS_PROJECT,
  STATUS_PROJECT_OPTION,
  TYPE_PRICE_ALL,
} from "../shared/status";
import ProjectFloor from "./dialogs/ProjectFloor";
import ProjectDetail from "./dialogs/ProjectDetail";
import { commonFunction } from "../component/Common";
import ProjectChangeUser from "./dialogs/ProjectChangeUser";
import ProjectCopy from "./dialogs/ProjectCopy";
const cx = classNames.bind(styles);

const HeaderActionWrapper = styled(Box)({
  width: "100%",
  backgroundColor: "white",
  display: "flex",
});

const HeaderButtonWrapper = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  gap: 10,
  padding: "15px 10px",
});

const mediaQuery = "@media (max-width: 600px)";
export const ResponsiveHeaderWrapper = styled(HeaderActionWrapper)({
  [mediaQuery]: {
    flexDirection: "column-reverse",
  },
});
export const ResponsiveHeaderButtonWrapper = styled(HeaderButtonWrapper)({
  [mediaQuery]: {
    justifyContent: "flex-start",
  },
});

const Item = styled(Box)({});

const FillterWrapper = styled(Box)({
  display: "flex",
  flexWrap: "wrap",
  gap: "0 15px",
  margin: "7px 15px",
});

const FillterItem = styled(Box)({
  width: 250,
});

// @ts-ignore
const ProjectPolicyContext = React.createContext<any>();

export const ProjectEstateList = (props: any) => {
  const PostPagination = (props: any) => <PaginationCustom {...props} />;
  const [nameContext, setNameContext] = useState<string>();
  const [addressContext, setAddressContext] = useState<string>();
  const [codeContext, setCodeContext] = useState<string>();
  const [projectTypeContext, setProjectTypeContext] = useState<any>({
    id: 0,
    name: "Tất cả",
  });

  const [typeContext, setTypeContext] = useState<any>({
    id: 0,
    name: "Tất cả",
  });

  const [createdNameContext, setCreatedNameContext] = useState<any>({
    id: 0,
    name: "Tất cả",
  });

  const [sourceContext, setSourceContext] = useState<any>({
    id: 0,
    name: "Tất cả",
  });

  const [districtIdsContext, setDistrictIdsContext] = useState<any>([
    {
      id: 0,
      name: "Tất cả",
    },
  ]);

  const [streetContext, setStreetContext] = useState<any>({
    id: 0,
    name: "Tất cả",
  });

  const [typePriceContext, setTypePriceContext] = useState<any>(
    TYPE_PRICE_ALL[0]
  );
  const [isHotContext, setIsHotContext] = useState<any>(ISHOT[2]);
  const [statusCurrentContext, setStatusCurrentContext] = useState<any>(
    STATUS_CURRENT[2]
  );
  const [statusContext, setStatusContext] = useState<any>(STATUS_PROJECT[3]);

  const today = dayjs();
  const [dateStartContext, setDateStartContext] = useState<Dayjs | null>(null);
  const [dateEndContext, setDateEndContext] = useState<Dayjs | null>(today);

  const [fromPriceContext, setFromPriceContext] = useState<string>();
  const [toPriceContext, setToPriceContext] = useState<string>();

  const [fromAreaContext, setFromAreaContext] = useState<string>();
  const [toAreaContext, setToAreaContext] = useState<string>();

  const [lstCheckedContext, setLstCheckedContext] = useState<any>([]);

  //LOADING
  const [loadingContext, setLoadingContext] = useState<boolean>(false);
  const translate = useTranslate();

  return (
    <Box className="header-channel-sales">
      <Box className={"box_header"}>
        <Typography className={"title_header"}>Danh sách dự án</Typography>
      </Box>

      <div className={`header-top-detail header-top-tdn`}>
        <ProjectPolicyContext.Provider
          value={{
            nameContext: [nameContext, setNameContext],
            addressContext: [addressContext, setAddressContext],
            codeContext: [codeContext, setCodeContext],
            projectTypeContext: [projectTypeContext, setProjectTypeContext],
            typeContext: [typeContext, setTypeContext],
            createdNameContext: [createdNameContext, setCreatedNameContext],
            sourceContext: [sourceContext, setSourceContext],
            typePriceContext: [typePriceContext, setTypePriceContext],
            loadingContext: [loadingContext, setLoadingContext],
            isHotContext: [isHotContext, setIsHotContext],
            statusCurrentContext: [
              statusCurrentContext,
              setStatusCurrentContext,
            ],
            statusContext: [statusContext, setStatusContext],
            dateStartContext: [dateStartContext, setDateStartContext],
            dateEndContext: [dateEndContext, setDateEndContext],
            fromPriceContext: [fromPriceContext, setFromPriceContext],
            toPriceContext: [toPriceContext, setToPriceContext],
            fromAreaContext: [fromAreaContext, setFromAreaContext],
            toAreaContext: [toAreaContext, setToAreaContext],
            lstCheckedContext: [lstCheckedContext, setLstCheckedContext],
            districtIdsContext: [districtIdsContext, setDistrictIdsContext],
            streetContext: [streetContext, setStreetContext],
          }}
        >
          <List
            {...props}
            actions={false}
            empty={false}
            exporter={false}
            filters={<FilterAndActions />}
            filter={{
              name: nameContext,
              code: codeContext,
              address: addressContext,
              type: projectTypeContext?.id ? projectTypeContext?.id : null,
              project_type_estate: typeContext?.id ? typeContext?.id : null,
              created_by: createdNameContext?.id
                ? createdNameContext?.id
                : null,
              source_id: sourceContext?.id ? sourceContext?.id : null,
              street: streetContext?.id ? streetContext?.id : null,
              districts:
                districtIdsContext.length > 0
                  ? districtIdsContext.map((x: any) => x.id).join(",")
                  : null,
              type_price: typePriceContext?.id,
              is_hot: isHotContext?.id === 2 ? isHotContext : isHotContext?.id,
              status_current:
                statusCurrentContext?.id === 2
                  ? statusCurrentContext
                  : statusCurrentContext?.id,
              status:
                statusContext?.id === 3 ? statusContext : statusContext?.id,
              start_date: dateStartContext
                ? dateStartContext.format("YYYY-MM-DD")
                : undefined,
              end_date: dateEndContext
                ? dateEndContext.format("YYYY-MM-DD")
                : undefined,
              from_price: fromPriceContext,
              to_price: toPriceContext,
              from_area: fromAreaContext,
              to_area: toAreaContext,
            }}
            filterDefaultValues={{
              name: "",
              address: "",
              code: "",
              type: null,
              project_type_estate: null,
              created_by: null,
              created_at: null,
              source_id: null,
              type_price: null,
              districts: null,
              street: null,
              // isHot: null,
            }}
            sort={{ field: "", order: "" }}
            pagination={<PostPagination />}
          >
            <ProjectDatagrid
              {...props}
              hasBulkActions={true}
              onHandleChecked={(value: any) => {
                setLstCheckedContext(value);
              }}
            />
          </List>
        </ProjectPolicyContext.Provider>
      </div>
    </Box>
  );
};

interface ButtonProps {
  basePath?: string;
  label: string;
  record?: any;
}

const EditButton = (props: any) => {
  const [openEditDialog, setOpenEditDialog] = useState(false);
  return (
    <>
      <Tooltip title="Sửa" arrow>
        <IconButton onClick={() => setOpenEditDialog(true)}>
          <EditIcon className={"btn_edit"} />
        </IconButton>
      </Tooltip>
      {openEditDialog && (
        <ProjectEdit
          isOpen={openEditDialog}
          onClose={() => setOpenEditDialog(false)}
        />
      )}
    </>
  );
};

const DetailButton = (props: any) => {
  const [openDetailDialog, setOpenDetailDialog] = useState(false);
  return (
    <>
      <Tooltip title="Chi tiết" arrow>
        <IconButton onClick={() => setOpenDetailDialog(true)}>
          <VisibilityIcon className={"btn_edit"} />
        </IconButton>
      </Tooltip>
      {openDetailDialog && (
        <ProjectDetail
          isOpen={openDetailDialog}
          onClose={() => setOpenDetailDialog(false)}
        />
      )}
    </>
  );
};

const ChangeUserButton = (props: any) => {
  const [openChangeUserDialog, setOpenChangUserDialog] = useState(false);
  return (
    <>
      <Tooltip title="Thay đổi người tạo" arrow>
        <IconButton onClick={() => setOpenChangUserDialog(true)}>
          <ChangeCircleIcon className={"btn_change"} />
        </IconButton>
      </Tooltip>
      {openChangeUserDialog && (
        <ProjectChangeUser
          isOpen={openChangeUserDialog}
          onClose={() => setOpenChangUserDialog(false)}
        />
      )}
    </>
  );
};

const DeleteButton = (props: any) => {
  const [openEditDialog, setOpenEditDialog] = useState(false);
  return (
    <>
      <Tooltip title="Xóa" arrow>
        <IconButton onClick={() => setOpenEditDialog(true)}>
          <DeleteIcon className={"btn_del"} />
        </IconButton>
      </Tooltip>
      <ProjectDelete
        isOpen={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
      ></ProjectDelete>
    </>
  );
};

const CopyButton = (props: any) => {
  const [openEditDialog, setOpenEditDialog] = useState(false);
  return (
    <>
      <Tooltip title="Tạo bản sao" arrow>
        <IconButton onClick={() => setOpenEditDialog(true)}>
          <DifferenceIcon className={"btn_floor"} />
        </IconButton>
      </Tooltip>
      <ProjectCopy
        isOpen={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
      ></ProjectCopy>
    </>
  );
};

const FloorButton = (props: any) => {
  const [openEditDialog, setOpenEditDialog] = useState(false);
  return (
    <>
      <Tooltip title="Danh sách tầng" arrow>
        <IconButton onClick={() => setOpenEditDialog(true)}>
          <ApartmentIcon className={"btn_floor"} />
        </IconButton>
      </Tooltip>
      {openEditDialog && (
        <ProjectFloor
          isOpen={openEditDialog}
          onClose={() => setOpenEditDialog(false)}
        ></ProjectFloor>
      )}
    </>
  );
};

const RowActions = ({ record }: ButtonProps) => {
  let per_edit = commonFunction.checkPermission("PROJECT_ESTATE_EDIT");
  let per_copy = commonFunction.checkPermission("PROJECT_ESTATE_COPY");
  let per_delete = commonFunction.checkPermission("PROJECT_ESTATE_DELETE");
  const per_change = commonFunction.checkPermission(
    "PROJECT_ESTATE_CREATED_BY"
  );
  const per_detail = commonFunction.checkPermission("PROJECT_ESTATE_DETAIL");

  const userIdentityJSON: any = localStorage.getItem("userIdentity");
  const userIdentity: any = JSON.parse(userIdentityJSON);
  if (
    userIdentity.permission_data == 3 &&
    userIdentity.id != record.created_by
  ) {
    per_edit = false;
    per_delete = false;
  }

  return (
    <Box className={cx("care_plan_rowActions")}>
      <FloorButton />
      {per_detail && <DetailButton />}
      {per_change && <ChangeUserButton />}
      {per_edit && <EditButton />}
      {per_copy && <CopyButton />}
      {per_delete && <DeleteButton />}
    </Box>
  );
};

const FilterAndActions = (props: any) => {
  // @ts-ignore
  const { filterValues } = useListContext();
  const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);
  const [openExportPDFDialog, setOpenExportPDFDialog] =
    useState<boolean>(false);

  //STATE
  const [fillterOpen, setFillterOpen] = useState<boolean>(false);

  //API STATE
  const [projecTypeList, setProjectTypeList] = useState<any[]>([
    { id: 0, name: "Tất cả" },
  ]);

  //Người tạo
  const [createdList, setCreatedList] = useState<any[]>([
    { id: 0, name: "Tất cả" },
  ]);
  //Nguồn
  const [sourceList, setSourceList] = useState<any[]>([
    { id: 0, name: "Tất cả" },
  ]);
  // Quận
  const [listDistrict, setListDistrict] = useState<any[]>([
    { id: 0, name: "Tất cả" },
  ]);

  //Đường
  const [streetList, setStreetList] = useState<any[]>([
    { id: 0, name: "Tất cả" },
  ]);

  //API STATE
  const [typeList, setTypeList] = useState<any[]>([{ id: 0, name: "Tất cả" }]);

  // @ts-ignore
  const {
    nameContext,
    codeContext,
    addressContext,
    projectTypeContext,
    typeContext,
    createdNameContext,
    isHotContext,
    statusCurrentContext,
    statusContext,
    dateStartContext,
    dateEndContext,
    sourceContext,
    typePriceContext,
    fromPriceContext,
    toPriceContext,
    fromAreaContext,
    toAreaContext,
    lstCheckedContext,
    districtIdsContext,
    streetContext,
  } = useContext(ProjectPolicyContext);

  const [name, setName] = nameContext;
  const [address, setAddress] = addressContext;
  const [code, setCode] = codeContext;
  const [projectType, setProjectType] = projectTypeContext;
  const [type, setType] = typeContext;
  const [created_name, setCreatedName] = createdNameContext;
  const [source, setSource] = sourceContext;
  const [districtIds, setDistrictIds] = districtIdsContext;
  const [isHot, setIsHot] = isHotContext;
  const [statusCurrent, setStatusCurrent] = statusCurrentContext;
  const [status, setStatus] = statusContext;
  const [dateStart, setDateStart] = dateStartContext;
  const [dateEnd, setDateEnd] = dateEndContext;
  const [typePrice, setTypePrice] = typePriceContext;
  const [fromPrice, setFromPrice] = fromPriceContext;
  const [toPrice, setToPrice] = toPriceContext;
  const [fromArea, setFromArea] = fromAreaContext;
  const [toArea, setToArea] = toAreaContext;
  const [street, setStreet] = streetContext;

  const [lstChecked, setLstChecked] = lstCheckedContext;

  //Loading
  const [loading, setLoading] = useState<boolean>(false);
  React.useEffect(() => {}, []);
  React.useEffect(() => {
    setLoading(true);

    const fetchApi = async () => {
      const projectTypeListData = await projectTypeService.getListProjectType();
      const typeListData = await projectTypeEstateService.getListTypeEstate();
      const createdListData = await userService.getAllLeader();
      const sourceListData = await sourceService.getAllSource();
      const listDistrictResult = await districtService.getDistricts();
      const listStreetData = await request.get(`streets/get-all-street`);

      setProjectTypeList([...projecTypeList, ...projectTypeListData]);
      setTypeList([...typeList, ...typeListData]);
      setCreatedList([...createdList, ...createdListData]);
      setSourceList([...sourceList, ...sourceListData]);
      setStreetList([...streetList, ...listStreetData?.data]);
      setListDistrict([...listDistrict, ...listDistrictResult]);
      setLoading(false);
    };
    fetchApi();
  }, []);
  const [listProjectType, setListProjectType] = React.useState<any[]>([]);

  // call api project type
  const handleProjectTypeEstate = async (value: any) => {
    setListProjectType([]);
    const response = await request.get(
      `project_type/getProjectTypeId/${value.project_type_estate}`
    );

    if (response.data) {
      setListProjectType(response.data);
    }
  };

  /**
   * handle change name
   * @param e
   */
  const handleNameChange = (e: any) => {
    setName(e == "" ? "" : e.target.value);
  };

  const handleAddressChange = (e: any) => {
    setAddress(e == "" ? "" : e.target.value);
  };

  const handleCodeChange = (e: any) => {
    setCode(e == "" ? "" : e.target.value);
  };

  const handleFromPriceChange = (e: any) => {
    setFromPrice(e == "" ? "" : e.target.value);
  };

  const handleToPriceChange = (e: any) => {
    setToPrice(e == "" ? "" : e.target.value);
  };

  const handleFromAreaChange = (e: any) => {
    setFromArea(e == "" ? "" : e.target.value);
  };

  const handleToAreaChange = (e: any) => {
    setToArea(e == "" ? "" : e.target.value);
  };

  const handleOpenFillter = () => {
    setFillterOpen((prev) => !prev);
  };

  const styleWrapper = {
    overflow: "auto",
    height: fillterOpen ? 300 : 0,
    transition: "500",
  };

  return (
    <ResponsiveHeaderWrapper>
      <Box flex={1}>
        <Form onSubmit={() => undefined} initialValues={filterValues}>
          {() => (
            <FillterWrapper
              style={styleWrapper}
              className={cx("fillter-wrapper")}
            >
              <FillterItem>
                <FilterLiveSearch
                  sx={{
                    mt: 1.4,
                  }}
                  onChange={handleNameChange}
                  value={name}
                  variant="outlined"
                  source="name"
                  label="resources.common.fields.search"
                />
              </FillterItem>
              <FillterItem>
                <FilterLiveSearch
                  sx={{
                    mt: 1.4,
                  }}
                  onChange={handleAddressChange}
                  value={address}
                  variant="outlined"
                  source="address"
                  label="Địa chỉ"
                />
              </FillterItem>
              <FillterItem>
                <FilterLiveSearch
                  sx={{
                    mt: 1.4,
                  }}
                  onChange={handleCodeChange}
                  value={code}
                  variant="outlined"
                  source="code"
                  label="Mã sản phẩm"
                />
              </FillterItem>
              <FillterItem>
                <Autocomplete
                  fullWidth
                  id="combo-box-demo"
                  options={typeList}
                  renderInput={(params) => (
                    <InputText
                      {...params}
                      variant="outlined"
                      label="Loại hình"
                    />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={type}
                  getOptionLabel={(option) => option.name}
                  // onChange={(e, value) => setType(value == null ? typeList[0] : value)}
                  onChange={(event, value) => {
                    handleProjectTypeEstate(value);
                    setType(value == null ? typeList[0] : value);
                  }}
                />
              </FillterItem>
              <FillterItem>
                <Autocomplete
                  fullWidth
                  id="combo-box-demo"
                  options={listProjectType}
                  renderInput={(params) => (
                    <InputText
                      {...params}
                      variant="outlined"
                      label="Loại dự án"
                    />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={projectType}
                  getOptionLabel={(option) => option.name}
                  onChange={(e, value) =>
                    setProjectType(value == null ? projecTypeList[0] : value)
                  }
                />
              </FillterItem>
              <FillterItem>
                <Autocomplete
                  fullWidth
                  id="combo-box-demo"
                  options={createdList}
                  renderInput={(params) => (
                    <InputText
                      {...params}
                      variant="outlined"
                      label="Người tạo"
                    />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={created_name}
                  getOptionLabel={(option) => option.name}
                  onChange={(e, value) =>
                    setCreatedName(value == null ? createdList[0] : value)
                  }
                />
              </FillterItem>
              <FillterItem>
                <Autocomplete
                  fullWidth
                  id="combo-box-demo"
                  options={ISHOT}
                  renderInput={(params) => (
                    <InputText
                      {...params}
                      variant="outlined"
                      label="Dự án nổi bật"
                    />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={isHot}
                  getOptionLabel={(option) => option?.label}
                  onChange={(e, value) =>
                    setIsHot(value == null ? ISHOT[2] : value)
                  }
                />
              </FillterItem>

              <FillterItem>
                <Autocomplete
                  fullWidth
                  id="combo-box-demo"
                  options={STATUS_CURRENT}
                  renderInput={(params) => (
                    <InputText
                      {...params}
                      variant="outlined"
                      label="Đã cho thuê"
                    />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={statusCurrent}
                  getOptionLabel={(option) => option?.label}
                  onChange={(e, value) =>
                    setStatusCurrent(value == null ? STATUS_CURRENT[2] : value)
                  }
                />
              </FillterItem>

              <FillterItem>
                <Autocomplete
                  fullWidth
                  id="combo-box-demo"
                  options={STATUS_PROJECT}
                  renderInput={(params) => (
                    <InputText
                      {...params}
                      variant="outlined"
                      label="Trạng thái"
                    />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={status}
                  getOptionLabel={(option) => option?.label}
                  onChange={(e, value) =>
                    setStatus(value == null ? STATUS_PROJECT[3] : value)
                  }
                />
              </FillterItem>
              <FillterItem>
                <Autocomplete
                  fullWidth
                  id="combo-box-demo"
                  options={sourceList}
                  renderInput={(params) => (
                    <InputText {...params} variant="outlined" label="Nguồn" />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={source}
                  getOptionLabel={(option) => option?.name}
                  onChange={(e, value) =>
                    setSource(value == null ? sourceList[0] : value)
                  }
                />
              </FillterItem>

              <FillterItem>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="vi"
                >
                  <DatePicker
                    label="Ngày bắt đầu"
                    inputFormat="DD/MM/YYYY"
                    value={dateStart}
                    onChange={(newValue) => setDateStart(newValue)}
                    renderInput={(params) => (
                      <InputText
                        className="mb-23"
                        {...params}
                        fullWidth
                        variant="outlined"
                      />
                    )}
                  />
                </LocalizationProvider>
              </FillterItem>
              <FillterItem>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="vi"
                >
                  <DatePicker
                    label="Ngày kết thúc"
                    inputFormat="DD/MM/YYYY"
                    disableFuture
                    value={dateEnd}
                    onChange={(newValue) => setDateEnd(newValue)}
                    renderInput={(params) => (
                      <InputText
                        className="mb-23"
                        {...params}
                        fullWidth
                        variant="outlined"
                      />
                    )}
                  />
                </LocalizationProvider>
              </FillterItem>
              <FillterItem>
                <FilterLiveSearch
                  sx={{
                    mt: 1.4,
                  }}
                  onChange={handleFromPriceChange}
                  value={fromPrice}
                  variant="outlined"
                  source="from_price"
                  label="Giá bán từ"
                />
              </FillterItem>
              <FillterItem>
                <FilterLiveSearch
                  sx={{
                    mt: 1.4,
                  }}
                  onChange={handleToPriceChange}
                  value={toPrice}
                  variant="outlined"
                  source="to_price"
                  label="Giá bán đến"
                />
              </FillterItem>
              <FillterItem>
                <Autocomplete
                  fullWidth
                  id="combo-box-demo"
                  options={TYPE_PRICE_ALL}
                  renderInput={(params) => (
                    <InputText {...params} variant="outlined" label="Đơn vị" />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={typePrice}
                  getOptionLabel={(option) => option?.label}
                  onChange={(e, value) => {
                    console.log(value == null ? TYPE_PRICE_ALL[0] : value);
                    setTypePrice(value == null ? TYPE_PRICE_ALL[0] : value);
                  }}
                />
              </FillterItem>
              <FillterItem>
                <FilterLiveSearch
                  sx={{
                    mt: 1.4,
                  }}
                  onChange={handleFromAreaChange}
                  value={fromArea}
                  variant="outlined"
                  source="from_area"
                  label="Diện tích từ"
                />
              </FillterItem>
              <FillterItem>
                <FilterLiveSearch
                  sx={{
                    mt: 1.4,
                  }}
                  onChange={handleToAreaChange}
                  value={toArea}
                  variant="outlined"
                  source="to_area"
                  label="Diện tích đến"
                />
              </FillterItem>

              <FillterItem>
                <Autocomplete
                  fullWidth
                  id="combo-box-demo"
                  options={streetList}
                  renderInput={(params) => (
                    <InputText {...params} variant="outlined" label="Đường" />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={street}
                  getOptionLabel={(option) => option?.name}
                  onChange={(e, value) =>
                    setStreet(value == null ? streetList[0] : value)
                  }
                />
              </FillterItem>

              <FillterItem>
                <Autocomplete
                  disableCloseOnSelect
                  multiple={true}
                  limitTags={2}
                  renderInput={(params) => (
                    <InputText {...params} variant="outlined" label="Khu vực" />
                  )}
                  sx={{ width: "100%" }}
                  options={listDistrict}
                  value={districtIds}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(e, value) => {
                    setDistrictIds(value == null ? listDistrict[0] : value);
                  }}
                />
              </FillterItem>
            </FillterWrapper>
          )}
        </Form>
      </Box>
      <ResponsiveHeaderButtonWrapper>
        <Item>
          <Button
            disabled={loading}
            variant={fillterOpen ? "outlined" : "contained"}
            color={fillterOpen ? "error" : "primary"}
            startIcon={fillterOpen ? <CloseIcon /> : <SearchIcon />}
            onClick={handleOpenFillter}
          >
            {fillterOpen ? "Đóng" : "Lọc"}
          </Button>
        </Item>
        <Item>
          <Button
            color="success"
            variant="contained"
            disabled={lstChecked.length <= 0}
            startIcon={<ApartmentRoundedIcon />}
            onClick={() => setOpenExportPDFDialog(true)}
          >
            Chọn tầng
          </Button>
        </Item>
        <Item>
          {commonFunction.checkPermission("PROJECT_ESTATE_ADD") && (
            <>
              <CreateButtonMUI
                title="Thêm dự án"
                onClick={() => setOpenCreateDialog(true)}
              />
              <ProjectCreate
                isOpen={openCreateDialog}
                onClose={() => setOpenCreateDialog(false)}
              />
            </>
          )}

          <ExportPDF
            lstProjectID={lstChecked}
            isOpen={openExportPDFDialog}
            onClose={() => setOpenExportPDFDialog(false)}
          />
        </Item>
      </ResponsiveHeaderButtonWrapper>
    </ResponsiveHeaderWrapper>
  );
};

const ProjectDatagrid = (props: any) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const [lstChecked, setLstChecked] = useState<any>([]);

  const handleChange = async (
    e: any,
    record: any,
    fieldName: string,
    path: string
  ) => {
    const fieldValue = e.target.checked;

    // const submitObj = new FormData();
    // submitObj.append(fieldName, fieldValue ? "1" : "0");

    // // Include other fields from the record
    // Object.keys(record).forEach((key) => {
    //   if (key !== fieldName) {
    //     submitObj.append(key, record[key]);
    //   }
    // });
    const data = {
      id: record.id,
      [fieldName]: fieldValue,
    };

    const result = await request.put(`/project_estate/${path}`, data);

    if (result.status === 200) {
      notify(`Cập nhật dự án thành công`, {
        type: "success",
        undoable: true,
      });
    } else {
      notify(`Cập nhật dự án thất bại`, {
        type: "error",
        undoable: true,
      });
    }
  };

  const handleChangeProjectStatus = async (
    event: SelectChangeEvent,
    idRecord: Number
  ) => {
    let data = {
      id: idRecord,
      status: event.target.value,
    };
    await HttpDataService.update("project_estate/update_status", data)
      .then((response: any) => {
        if (response.data.status) {
          // @ts-ignore
          notify(response.data.message, { type: "success" });
        } else {
          // @ts-ignore
          notify(response.data.message, { type: "error" });
        }
      })
      .catch((e: Error) => {
        console.log(e);
      });

    refresh();
  };

  // Handle Checkbox change
  const handleCheckboxChange = (event: any, record: any) => {
    const { checked } = event.target;

    if (checked) {
      setLstChecked((prev: any) => {
        const lstNew: any = [...prev, record.id];
        props.onHandleChecked(lstNew);
        return lstNew;
      });
    } else {
      setLstChecked((prev: any) => {
        const lstNew: any = prev.filter((x: any) => x != record.id);
        props.onHandleChecked(lstNew);
        return lstNew;
      });
    }
  };

  return (
    <>
      <div className={cx("project_estate_list")}>
        {/* style={{tableLayout: 'fixed'}} */}
        <Datagrid
          optimized
          bulkActionButtons={false}
          sx={{ overflowX: "scroll" }}
        >
          <FunctionField
            label={""}
            render={(record: any) => {
              return (
                <Checkbox
                  size="small"
                  onChange={(e) => handleCheckboxChange(e, record)}
                />
              );
            }}
          />

          <TextField source={"code"} label={"Mã sản phẩm"} />
          <TextField source={"project_name"} label={"Loại dự án"} />
          <TextField source={"source_name"} label={"Nguồn"} />
          <TextField source={"name"} label={"Tên dự án"} />
          <TextField source={"address"} label={"Địa chỉ"} />
          <TextField source={"created_name"} label={"Người tạo"} />
          <TextField source={"created_at_formatted"} label={"Thời gian tạo"} />
          <TextField
            source={"updated_at_formatted"}
            label={"Thời gian cập nhật"}
          />
          <FunctionField
            label={"Nổi bật"}
            render={(record: any) => {
              return (
                <FormControlLabel
                  control={
                    <Switch
                      defaultChecked={record.is_hot === 1}
                      onChange={(e) =>
                        handleChange(e, record, "is_hot", "update_is_hot")
                      }
                    />
                  }
                  label=""
                  labelPlacement="start"
                />
              );
            }}
          />

          <FunctionField
            className={cx("status")}
            label={"Trạng thái"}
            render={(record: any) => {
              return (
                <FormControlLabel
                  control={
                    <FormControl
                      variant="standard"
                      sx={{ m: 1, minWidth: 100 }}
                      size="small"
                    >
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={record.status}
                        label=""
                        onChange={(e) =>
                          handleChangeProjectStatus(e, record.id)
                        }
                      >
                        <MenuItem value={0}>Khóa</MenuItem>
                        <MenuItem value={1}>Đang hoạt động</MenuItem>
                        <MenuItem value={2}>Đang cập nhật</MenuItem>
                      </Select>
                    </FormControl>
                  }
                  label=""
                  labelPlacement="start"
                />
              );
            }}
          />
          <FunctionField
            render={(record: any) => {
              return <RowActions label={"THAO TÁC"} record={record} />;
            }}
          />
        </Datagrid>
      </div>
    </>
  );
};
