export const fnSetDataCkeditor = (name: string, value: string) => {
	if (window.CKEDITOR && window.CKEDITOR.instances[name]) {
		const editor = window.CKEDITOR.instances[name];
		const timeoutID = setTimeout(() => {
			if (editor.status === "ready") {
				editor.setData(value);
			} else {
				editor.on("instanceReady", () => {
					editor.setData(value);
				});
			}
			clearTimeout(timeoutID); // Clear the timeout after setting the data
		}, 10);
	}
};

export const fnRemoveAllCkeditorInstances = () => {
	for (let instanceName in window.CKEDITOR.instances) {
		if (window.CKEDITOR.instances.hasOwnProperty(instanceName)) {
			window.CKEDITOR.instances[instanceName].destroy();
			delete window.CKEDITOR.instances[instanceName];
		}
	}
};
