import * as request from '../utils/httpRequest';



export const getUserDetail = async (id: Number) => {
    try {
        const response = await request.get(`/users/getUser/${id}`);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};
export const getAllLeader = async () => {
    try {
        const response = await request.get('users/selectUserByType');
        return response.data;
    } catch (error) {
        console.log(error);
    }
};
