import {
    DefaultDataProvider,
    httpClient,
    serialize,
} from '../default-data-provider';
import {constants} from "../../shared/constants";

export const baseApiUrl = 'users';

class UsersService extends DefaultDataProvider {

}

export const usersService = new UsersService(
    constants.RESOURCE.USERS,
    constants.BASE_MODULE_URL,
    baseApiUrl
);