import React from "react";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import DashboardIconActive from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import BadgeIcon from "@mui/icons-material/Badge";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import ApartmentIcon from "@mui/icons-material/Apartment";
import SettingsIcon from "@mui/icons-material/Settings";
import ListIcon from "@mui/icons-material/List";
import ArticleIcon from "@mui/icons-material/Article";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import BallotIcon from "@mui/icons-material/Ballot";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PersonIcon from "@mui/icons-material/Person";
import GroupsIcon from "@mui/icons-material/Groups";
import MenuIcon from "@mui/icons-material/Menu";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import ErrorIcon from "@mui/icons-material/Error";
import CommentIcon from "@mui/icons-material/Comment";
import FeedIcon from "@mui/icons-material/Feed";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import AllOutIcon from "@mui/icons-material/AllOut";
import GradeIcon from "@mui/icons-material/Grade";
import SourceIcon from "@mui/icons-material/Source";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import AddRoadIcon from "@mui/icons-material/AddRoad";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import BackupIcon from "@mui/icons-material/Backup";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CalculateIcon from "@mui/icons-material/Calculate";
import ArtTrackIcon from "@mui/icons-material/ArtTrack";
import AlignVerticalBottomIcon from "@mui/icons-material/AlignVerticalBottom";
import LegendToggleRoundedIcon from "@mui/icons-material/LegendToggleRounded";
import PaidRoundedIcon from "@mui/icons-material/PaidRounded";

export const getIcon = (name: any) => {
	return mapError.get(name) == undefined ? <ErrorIcon /> : mapError.get(name);
};

let mapError = new Map();
mapError.set("MENU_DASHBOARD", <DashboardIconActive className={"color_icon_menu"} />);
mapError.set("MENU_ORDER", <ShoppingCartIcon className={"color_icon_menu"} />);
mapError.set("TRANG_CHU", <DashboardIconActive className={"color_icon_menu"} />);
mapError.set("MENU_EMPLOYEE", <BadgeIcon className={"color_icon_menu"} />);
mapError.set("MENU_WAREHOUSE", <WarehouseIcon className={"color_icon_menu"} />);
mapError.set("MENU_SUPPLYCOMPANY", <ApartmentIcon className={"color_icon_menu"} />);
mapError.set("MENU_SETTING", <SettingsIcon className={"color_icon_menu"} />);
mapError.set("project", <ArticleIcon className={"color_icon_menu"} />);
mapError.set("listproject", <ArticleIcon className={"color_icon_menu"} />);
mapError.set("catalog", <ListIcon className={"color_icon_menu"} />);
mapError.set("department", <Diversity1Icon className={"color_icon_menu"} />);
mapError.set("office", <AssignmentIndIcon className={"color_icon_menu"} />);
mapError.set("parts", <BallotIcon className={"color_icon_menu"} />);
mapError.set("project_type", <AccountTreeIcon className={"color_icon_menu"} />);
mapError.set("system", <ManageAccountsIcon className={"color_icon_menu"} />);
mapError.set("users", <PersonIcon className={"color_icon_menu"} />);
mapError.set("roles", <GroupsIcon className={"color_icon_menu"} />);
mapError.set("permission", <AdminPanelSettingsIcon className={"color_icon_menu"} />);
mapError.set("other", <MoreVertIcon className={"color_icon_menu"} />);
mapError.set("video", <VideoLibraryIcon className={"color_icon_menu"} />);
mapError.set("spam", <ArtTrackIcon className={"color_icon_menu"} />);
mapError.set("menu_admin", <MenuIcon className={"color_icon_menu"} />);
mapError.set("feedback", <CommentIcon className={"color_icon_menu"} />);
mapError.set("project_estate", <FeedIcon className={"color_icon_menu"} />);
mapError.set("settings", <SettingsIcon className={"color_icon_menu"} />);
mapError.set("news", <NewspaperIcon className={"color_icon_menu"} />);
mapError.set("backup", <BackupIcon className={"color_icon_menu"} />);
mapError.set("Transaction", <ReceiptLongIcon className={"color_icon_menu"} />);
mapError.set("wage", <LocalAtmIcon className={"color_icon_menu"} />);
mapError.set("calculate", <CalculateIcon className={"color_icon_menu"} />);
mapError.set("kpi", <AlignVerticalBottomIcon className={"color_icon_menu"} />);
mapError.set("flow", <LegendToggleRoundedIcon className={"color_icon_menu"} />);
mapError.set("paid", <PaidRoundedIcon className={"color_icon_menu"} />);

mapError.set("AllOutIcon", <AllOutIcon className="color_icon_menu" />);
mapError.set("CustomerSources", <SourceIcon className="color_icon_menu" />);
mapError.set("GradeIcon", <GradeIcon className="color_icon_menu" />);
mapError.set("SourceIcon", <SourceIcon className="color_icon_menu" />);
mapError.set("GroupWorkIcon", <GroupWorkIcon className="color_icon_menu" />);
mapError.set("AddRoadIcon", <AddRoadIcon className="color_icon_menu" />);
mapError.set("LocationCityIcon", <LocationCityIcon className="color_icon_menu" />);
