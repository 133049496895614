import {
    Autocomplete,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField,
} from '@mui/material';
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import ReplyIcon from '@mui/icons-material/Reply';
import Toolbar from '@mui/material/Toolbar';
import {
    useNotify,
    useRefresh,
    useRecordContext,
    EditProps,
    useTranslate,
} from 'react-admin';
import HttpDataService from '../dataProvider/http.service';
import { createStyles, makeStyles } from '@material-ui/core/styles';

//Reformat Style
const styles = makeStyles((theme: any) =>
    createStyles({
        root: {
            '& .css-k4qjio-MuiFormHelperText-root.Mui-error':{
                position: 'absolute',
                paddingTop: '41px',
            }
        }
    }));
interface Add extends EditProps {
    openDialog: boolean;
    onCloseDialog: (openDialog: boolean) => void;
}

export const FeedbackEdit = ({ openDialog, onCloseDialog, ...rest }: Add) => {
    const classes = styles()
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [content, setContent] = useState<string>('');
    const [note, setNote] = useState<string>('');
    const translate = useTranslate();
    const dataStatus = [
        { label: translate('resources.feedback.fields.active'), id: 1 },
        { label: translate('resources.feedback.fields.block'), id: 0 },
    ];
    const [noteError, setNoteError] = useState(false);
    const [textNoteError, setTextNoteError] = useState('');
    const [status, setStatus] = useState<any>(dataStatus[0]);

    const refresh = useRefresh();
    const notify = useNotify();
    const record = useRecordContext();

    useEffect(() => {
        HttpDataService.findById('feedback/getFeedback', record.id as string)
            .then((response: any) => {
                if (response.data.status) {
                    console.log(response);
                    const res_record = response.data.data;
                    setName(res_record.name);
                    setEmail(res_record.email);
                    setPhone(res_record.phone);
                    setContent(res_record.content);

                    const find_status = dataStatus.find(
                        (s) => s.id == res_record.status,
                    );
                    setStatus(find_status);
                } else {
                    // @ts-ignore
                    notify('Lỗi', { type: 'error' });
                }
            })
            .catch((e: Error) => {
                console.log(e);
            });
    }, [record.id]);

    /**
     * handle change name
     * @param e
     */
    const handleNoteChange = (e: any) => {
        setNoteError(false);
        setTextNoteError('');
        setNote(e.target.value);
    };

    const handleChangeStatus = (e: any) => {
        setStatus(e);
    };

    /**
     * handle save
     */
    const handleSave = () => {
        if (!fnValidation()) {
            let data = {
                id: record.id,
                name: name.trim(),
                phone: phone.trim(),
                email: email.trim(),
                content: content.trim(),
                status: status.id,
            };
            HttpDataService.update('feedback/editFeedback', data)
                .then((response: any) => {
                    if (response.data.status) {
                        // @ts-ignore
                        notify(response.data.message, { type: 'success' });
                    } else {
                        // @ts-ignore
                        notify(response.data.message, { type: 'error' });
                    }
                })
                .catch((e: Error) => {
                    console.log(e);
                });

            fnResetFrm();
            onCloseDialog(false);
            refresh();
        }
    };

    const handleCloseDialog = () => {
        fnResetFrm();
        onCloseDialog(false);
    };

    /**
     * fn validation form
     */
    function fnValidation() {
        let error = false;
        if (name.trim().length === 0) {
            error = true;
            setNoteError(true);
        }
        return error;
    }
``
    function fnResetFrm() {
        setNote('');
        setNoteError(false);
        setStatus(null);
    }

    return (
        <>
            <Dialog
                open={openDialog}
                fullWidth
                PaperProps={{
                    style: {
                        borderRadius: 2,
                        // minWidth: '85%',
                    },
                }}
            >
                <div className={'common__dialogTitle_area'}>
                    <DialogTitle className={'member__dialogTitle'}>
                        <div>{translate('resources.feedback.edit_feedback')}</div>
                    </DialogTitle>
                    <div className={'common__dialogTitle_actions'}>
                        <IconButton
                            aria-label="exists"
                            color={'error'}
                            onClick={() => {
                                onCloseDialog(false);
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
                <Box className={'dialog_box'}>
                    <DialogContent className={'dialog_content'}>
                        <TextField
                            className={`${classes.root} mb-23`}
                            fullWidth
                            label={
                                <span className={'span_style'}>
                                    {translate(
                                        'resources.feedback.fields.name',
                                    )}
                                </span>
                            }

                            value={name}
                            variant="outlined"
                            inputProps={
                                { readOnly: true, }
                            }
                        />
                        <TextField
                            className={`${classes.root} mb-23`}
                            fullWidth
                            label={
                                <span className={'span_style'}>
                                    {translate(
                                        'resources.feedback.fields.email',
                                    )}
                                </span>
                            }

                            value={email}
                            variant="outlined"
                            inputProps={
                                { readOnly: true, }
                            }
                        />
                        <TextField
                            className={`${classes.root} mb-23`}
                            fullWidth
                            label={
                                <span className={'span_style'}>
                                    {translate(
                                        'resources.feedback.fields.phone',
                                    )}
                                </span>
                            }
                            value={phone}
                            variant="outlined"
                            inputProps={
                                { readOnly: true, }
                            }
                        />
                        <TextField
                            className={`${classes.root} mb-23`}
                            fullWidth
                            label={
                                <span className={'span_style'}>
                                    {translate(
                                        'resources.feedback.fields.content',
                                    )}
                                </span>
                            }
                            value={content}
                            variant="outlined"
                            multiline
                            maxRows={4}
                            inputProps={
                                { readOnly: true, }
                            }
                        />
                        <TextField
                            className={`${classes.root} mb-23`}
                            fullWidth
                            label={
                                <span className={'span_style'}>
                                    {translate(
                                        'resources.feedback.fields.note',
                                    )}
                                </span>
                            }
                            value={note}
                            variant="outlined"
                            multiline
                            maxRows={4}
                            onChange={handleNoteChange}

                        />

                        <Autocomplete
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label={
                                        <span className={'span_style'}>
                                            {' '}
                                            {translate(
                                                'resources.common.fields.status',
                                            )}
                                        </span>
                                    }
                                />
                            )}
                            fullWidth
                            value={status || null}
                            onChange={(event, value) =>
                                handleChangeStatus(value)
                            }
                            options={dataStatus}
                        />
                    </DialogContent>
                </Box>
                <Toolbar>
                    <Box display="flex" justifyContent="center" width={'100%'}>
                        <Button
                            variant="contained"
                            startIcon={<SaveIcon />}
                            onClick={handleSave}
                        >
                            {translate('resources.common.fields.update')}
                        </Button>
                        &nbsp;&nbsp;
                        <Button
                            startIcon={<ReplyIcon />}
                            className={'button-close'}
                            variant="contained"
                            onClick={handleCloseDialog}
                        >
                            {translate('resources.common.fields.cancel')}
                        </Button>
                    </Box>
                </Toolbar>
            </Dialog>
        </>
    );
};
