import {
    DefaultDataProvider,
} from '../default-data-provider';
import {constants} from "../../shared/constants";

export const baseApiUrl = 'source';

class SourceService extends DefaultDataProvider {

}

export const sourceService = new SourceService(
    constants.RESOURCE.SOURCE,
    constants.BASE_MODULE_URL,
    baseApiUrl
);