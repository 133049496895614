import {
  Autocomplete,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import ReplyIcon from "@mui/icons-material/Reply";
import Toolbar from "@mui/material/Toolbar";
import {
  useNotify,
  useRefresh,
  useRecordContext,
  EditProps,
  useTranslate,
} from "react-admin";
import HttpDataService from "../dataProvider/http.service";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import * as customerSourceService from "../Services/customerSourcesService";

const styles = makeStyles((theme: any) =>
  createStyles({
    root: {
      "& .css-k4qjio-MuiFormHelperText-root.Mui-error": {
        position: "absolute",
        paddingTop: "41px",
      },
    },
  })
);

interface Add extends EditProps {
  openDialog: boolean;
  onCloseDialog: (openDialog: boolean) => void;
}

export const CustomerSourcesEdit = ({
  openDialog,
  onCloseDialog,
  ...props
}: Add) => {
  const classes = styles();
  const [name, setName] = useState<string>("");
  const translate = useTranslate();
  const dataStatus = [
    { label: translate("resources.common.fields.active"), id: 1 },
    { label: translate("resources.common.fields.block"), id: 0 },
  ];

  const [nameError, setNameError] = useState(false);
  const [sourceType, setSourceType] = useState<any>([]);
  const [parentSource, setParentSource] = useState<any>([]);
  const [listSourceType, setListSourceType] = useState<any[]>([]);
  const [listParentSource, setListParentSource] = useState<any[]>([]);
  const [textNameError, setTextNameError] = useState("");
  const [status, setStatus] = useState<any>(dataStatus[0]);

  const refresh = useRefresh();
  const notify = useNotify();
  const record = useRecordContext();
  useEffect(() => {
    HttpDataService.findById("customer_source/getSource", props.id as string || record.id as string)
      .then((response: any) => {
        if (response.data.status) {
          const res_record = response.data.data;
          setName(res_record?.name);
          const find_status = dataStatus.find(
            (s: any) => s.id == res_record.status
          );
          setStatus(find_status);
          const fetchAPI = async () => {
            const listSourceTypeResult =
              await customerSourceService.getAllCustomerSourcesType();
            setListSourceType(listSourceTypeResult);
            const find_sourceType = listSourceTypeResult.find(
              (s: any) => s.id == res_record.source_type_id
            );
            setSourceType(find_sourceType);

            const listParentSourceResult =
              await customerSourceService.getParentSource();
            setListParentSource(listParentSourceResult);
            const find_parentSource = listParentSourceResult.find(
              (s: any) => s.id == res_record.parent_id
            );
            setParentSource(find_parentSource );
          };
          fetchAPI();
        } else {
          // @ts-ignore
          notify("Lỗi", { type: "error" });
        }
      })
      .catch((e: Error) => {
        console.log(e);
      });
  }, [record.id]);

  /**
   * handle change name
   * @param e
   */
  const handleNameChange = (e: any) => {
    setNameError(false);
    setTextNameError("");
    setName(e.target.value);
  };
  const handleNameParentChange = (e: any) => {
    setParentSource(e);
  };
  const handleChangeStatus = (e: any) => {
    setStatus(e);
  };
  const handleSourceTypeChange = (e: any) => {
    setSourceType(e);
  };

  /**
   * handle save
   */
  const handleSave = async () => {
    if (!fnValidation()) {
      let data = {
        id: props.id ?? record.id,
        name: name.trim(),
        status: status?.id,
        source_type_id: sourceType?.id,
        parent_id: parentSource?.id != record.id ? parentSource?.id : null,
      };
      await customerSourceService
        .updateCustomerSource(data)
        .then((response: any) => {
          if (response.status) {
            // @ts-ignore
            notify(response.message, { type: "success" });
            fnResetFrm();
            onCloseDialog(false);
            refresh();
          } else {
            // @ts-ignore
            notify(response.message, { type: "error" });
          }
        })
        .catch((e: any) => {
          console.log(e);
          setNameError(true);
          setTextNameError(e?.response.data.errors.name[0]);
        });
    }
  };

  const handleCloseDialog = () => {
    fnResetFrm();
    onCloseDialog(false);
  };

  /**
   * fn validation form
   */
  function fnValidation() {
    let error = false;
    if (name.trim().length === 0) {
      error = true;
      setNameError(true);
      setTextNameError("Tên nguồn không được để trống");
    }
    return error;
  }

  function fnResetFrm() {
    setName("");
    setNameError(false);
    setStatus(null);
  }

  return (
    <>
      <Dialog
        open={openDialog}
        fullWidth
        PaperProps={{
          style: {
            borderRadius: 2,
            // minWidth: '85%',
          },
        }}
      >
        <div className={"common__dialogTitle_area"}>
          <DialogTitle className={"member__dialogTitle"}>
            <div>{"Cập nhật nguồn"}</div>
          </DialogTitle>
          <div className={"common__dialogTitle_actions"}>
            <IconButton
              aria-label="exists"
              color={"error"}
              onClick={() => {
                onCloseDialog(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <Box className={"dialog_box"}>
          <DialogContent className={"dialog_content"}>
            <TextField
              className={`${classes.root} mb-23`}
              fullWidth
              label={
                <span className={"span_style"}>
                  {"Tên nguồn"}
                  <span style={{ color: "red" }}>(*)</span>
                </span>
              }
              value={name}
              error={nameError}
              helperText={textNameError}
              onChange={handleNameChange}
              variant="outlined"
            />
            <Autocomplete
              value={sourceType}
              fullWidth
              id="combo-box-demo"
              options={listSourceType}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  margin="dense"
                  variant="outlined"
                  {...params}
                  label={<span className={"span_style"}>{"Loại nguồn"}</span>}
                />
              )}
              onChange={(event, value) => {
                handleSourceTypeChange(value);
              }}
            />

            <Autocomplete
              value={parentSource || null}
              fullWidth
              id="combo-box-demo"
              options={listParentSource}
              getOptionLabel={(option) => option?.name}
              renderInput={(params) => (
                <TextField
                  margin="dense"
                  variant="outlined"
                  label={<span className={"span_style"}>{"Cấp cha"}</span>}
                  {...params}
                />
              )}
              onChange={(event, value) => {
                handleNameParentChange(value);
              }}
            />
            <Autocomplete
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={
                    <span className={"span_style"}>
                      {" "}
                      {translate("resources.common.fields.status")}
                    </span>
                  }
                />
              )}
              fullWidth
              value={status || null}
              onChange={(event, value) => handleChangeStatus(value)}
              options={dataStatus}
            />
          </DialogContent>
        </Box>
        <Toolbar>
          <Box display="flex" justifyContent="center" width={"100%"}>
            <Button
              variant="contained"
              startIcon={<SaveIcon />}
              onClick={handleSave}
            >
              {translate("resources.common.fields.update")}
            </Button>
            &nbsp;&nbsp;
            <Button
              startIcon={<ReplyIcon />}
              className={"button-close"}
              variant="contained"
              onClick={handleCloseDialog}
            >
              {translate("resources.common.fields.cancel")}
            </Button>
          </Box>
        </Toolbar>
      </Dialog>
    </>
  );
};
