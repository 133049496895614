import axios from 'axios';

axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem(
    'token',
)}`;

const request = axios.create({
    baseURL: process.env.REACT_APP_BASE_MODULE_URL,
});

export const get = async (path: any, req?: any,) => {
    const response = await request.get(path, req, );
    return response.data;
};

export const post = async (path: any, req?: any, headers?: any) => {
    const response = await request.post(path, req, headers);
    return response;
};

export const put = async (path: any, req?: any, headers?: any) => {
    const response = await request.put(path, req, headers);
    return response;
};

export const update = async (path: any, req?: any, headers?: any) => {
    const response = await request.post(path, req, headers);
    return response;
};

export const del = async (path: any) => {
    const response = await request.delete(path);
    return response;
};

export default request;
