import {
    DefaultDataProvider,
    httpClient,
    serialize,
} from '../default-data-provider';
import {constants} from "../../shared/constants";

export const baseApiUrl = 'customer';

export default class CustomerService extends DefaultDataProvider {

}

export const customerService = new CustomerService(
    constants.RESOURCE.CUSTOMER,
    constants.BASE_MODULE_URL,
    baseApiUrl
);