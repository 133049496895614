import * as request from '../utils/httpRequest';

export const getListCity = async () => {
    try {
        const response = await request.get('provinces/getAll');
        return response.data;
    } catch (error) {
        console.log(error);
    }
};
