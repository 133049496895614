import {
    DefaultDataProvider,
    httpClient,
    serialize,
} from '../default-data-provider';
import {constants} from "../../shared/constants";

export const baseApiUrl = 'transaction';

export default class TransactionService extends DefaultDataProvider {

}

export const transactionService = new TransactionService(
    constants.RESOURCE.TRANSACTION,
    constants.BASE_MODULE_URL,
    baseApiUrl
);