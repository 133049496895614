import * as React from "react";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

import CardWithIcon from "./CardWithIcon";

const NbNewOrders = (props: any) => {
  const value = props.data;
  const sum = value.reduce((x: any, y: any) => x + y.total, 0);
  return (
    <CardWithIcon
      to="/project_estate"
      icon={ShoppingCartIcon}
      title={"Số lượng dự án"}
      subtitle={sum}
    />
  );
};

export default NbNewOrders;
