import {
    DefaultDataProvider,
    httpClient,
    serialize,
} from '../default-data-provider';
import {constants} from "../../shared/constants";

export const baseApiUrl = 'project';

export default class ProjectService extends DefaultDataProvider {

}

export const projectService = new ProjectService(
    constants.RESOURCE.PROJECT,
    constants.BASE_MODULE_URL,
    baseApiUrl
);