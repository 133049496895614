import * as React from "react";
import { Admin, CustomRoutes, Resource, usePermissions } from "react-admin";
import polyglotI18nProvider from "ra-i18n-polyglot";
import { Route } from "react-router";

import authProvider from "./authProvider";
import { Login, Layout } from "./layout";
import { Dashboard } from "./dashboard";
import englishMessages from "./i18n/en";
import { lightTheme } from "./layout/themes";
import vietnameseMessages from "./i18n/vi";
import { dataProviderFactor } from "./dataProvider/data-provider-factor";

import { constants } from "./shared/constants";
import { UserList } from "./users/UserList";
import { FeedbackList } from "./feedback/feedbackList";
import { ProjectList } from "./project/ProjectList";
import { usersService } from "./dataProvider/service/users.service";
import { projectService } from "./dataProvider/service/project.service";
import { departmentService } from "./dataProvider/service/department.service";
import { departmentIdService } from "./dataProvider/service/departmentID.service";
import { officeIdService } from "./dataProvider/service/officeID.service";
import { rolesIdService } from "./dataProvider/service/rolesID.service";
import { projectEstateService } from "./dataProvider/service/projectEstate.service";
import { newsService } from "./dataProvider/service/news.service";
import { keywordService } from "./dataProvider/service/keyword.service";
import dataProviderFactory1 from "./dataProvider";
import { DepartmentList } from "./department/DepartmentList";
import { PermissionList } from "./permission/PermissionList";
import { PartsList } from "./parts/PartsList";
import { RolesList } from "./roles/RolesList";
import { permissionService } from "./dataProvider/service/permission.service";
import { partsService } from "./dataProvider/service/parts.service";
import CustomNotification from "./CustomNotification";
import { rolesService } from "./dataProvider/service/roles.service";
import { officeService } from "./dataProvider/service/office.service";
import { OfficeList } from "./office/OfficeList";
import Configuration from "./configuration/Configuration";
import { ChangePassword } from "./layout/ChangePassword";
import { Profile } from "./profile/Profile";
import { profileService } from "./dataProvider/service/profile.service";
import { Project_typeList } from "./projecttype/ProjectTypeList";
import { project_typeService } from "./dataProvider/service/project_type.service";
import { MenuList } from "./menu/MenuList";
import { menuService } from "./dataProvider/service/menu.service";
import { QueryClient } from "react-query";
import { CustomerList } from "./pages/customer/CustomerList";
import { customerService } from "./dataProvider/service/customer.service";
import { ProjectEstateList } from "./projectEstate/ProjectEstateList";
import { feedbackService } from "./dataProvider/service/feedback.service";
import { generalService } from "./dataProvider/service/general.service";
import { GeneralList } from "./general/GeneralList";
import { NewsList } from "./news/NewsList";
import { KeywordList } from "./keyword/KeywordList";
import { TransactionList } from "./pages/customer/dialogs/ViewTransaction";
import { transactionService } from "./dataProvider/service/transaction.service";
import { DirectionList } from "./direction/DirectionList";
import { directionService } from "./dataProvider/service/directionService";
import { sourceService } from "./dataProvider/service/source.service";
import { SourceList } from "./source/SourceList";
import { customerSourcesService } from "./dataProvider/service/customerSources.service";
import { CustomerSourcesList } from "./customerSources/CustomerSourcesList";
import { partnerService } from "./dataProvider/service/partner.service";
import { PartnerList } from "./partner/PartnerList";
import { StreetList } from "./streets/StreetList";
import { streetService } from "./dataProvider/service/street.service";
import { districtService } from "./dataProvider/service/district.service";
import { videoService } from "./dataProvider/service/video.service";
import { DistrictList } from "./district/DistrictList";
import { BackupList } from "./backup/BackupList";
import { VideoList } from "./video/VideoList";
import { SpamList } from "./spam/SpamList";
import { spamService } from "./dataProvider/service/spam.service";

import { backupService } from "./dataProvider/service/backup.service";
import { wageService } from "./dataProvider/service/wage.service";
import { WageList } from "./wage/WageList";
import { salaryService } from "./dataProvider/service/salary.service";
import { SalaryList } from "./salary/SalaryList";
import { kpiService } from "./dataProvider/service/kpi.service";
import { KpiList } from "./kpi/KpiList";

const i18nProvider = polyglotI18nProvider(
	(locale) => {
		if (locale === "vi") {
			return vietnameseMessages;
		}

		// Always fallback on english
		return englishMessages;
	},
	"vi",
	{
		allowMissing: true,
	}
);

const registerDataProvider = () => {
	// @ts-ignore
	dataProviderFactor.registerDataProvider({
		[constants.RESOURCE.USERS]: usersService,
		[constants.RESOURCE.PROJECT]: projectService,
		[constants.RESOURCE.DEPARTMENT]: departmentService,
		[constants.RESOURCE.PERMISSION]: permissionService,
		[constants.RESOURCE.PARTS]: partsService,
		[constants.RESOURCE.ROLES]: rolesService,
		[constants.RESOURCE.OFFICE]: officeService,
		[constants.RESOURCE.DEPARTMENT_ID]: departmentIdService,
		[constants.RESOURCE.OFFICE_ID]: officeIdService,
		[constants.RESOURCE.ROLES_ID]: rolesIdService,
		[constants.RESOURCE.PROFILE]: profileService,
		[constants.RESOURCE.PROJECT_TYPE]: project_typeService,
		[constants.RESOURCE.CUSTOMER_SOURCES]: customerSourcesService,
		[constants.RESOURCE.MENU]: menuService,
		[constants.RESOURCE.BACKUP]: backupService,
		[constants.RESOURCE.WAGE]: wageService,
		[constants.RESOURCE.SALARY]: salaryService,
		[constants.RESOURCE.CUSTOMER]: customerService,
		[constants.RESOURCE.PROJECT_ESTATE]: projectEstateService,
		[constants.RESOURCE.FEEDBACK]: feedbackService,
		[constants.RESOURCE.GENERAL]: generalService,
		[constants.RESOURCE.TRANSACTION]: transactionService,
		[constants.RESOURCE.NEWS]: newsService,
		[constants.RESOURCE.KEYWORD]: keywordService,
		[constants.RESOURCE.DIRECTION]: directionService,
		[constants.RESOURCE.SOURCE]: sourceService,
		[constants.RESOURCE.VIDEO]: videoService,
		[constants.RESOURCE.SPAM]: spamService,
		[constants.RESOURCE.KPI]: kpiService,
		[constants.RESOURCE.PARTNER]: partnerService,
		[constants.RESOURCE.STREET]: streetService,
		[constants.RESOURCE.DISTRICT]: districtService,
	});
};

const App = () => {
	registerDataProvider();

	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				staleTime: 5 * 60 * 1000, // 5 minutes
			},
		},
	});
	return (
		<Admin
			queryClient={queryClient}
			title=""
			dataProvider={dataProviderFactory1(process.env.REACT_APP_BASE_MODULE_URL || "")}
			authProvider={authProvider}
			dashboard={Dashboard}
			loginPage={Login}
			layout={Layout}
			i18nProvider={i18nProvider}
			disableTelemetry
			theme={lightTheme}
			notification={CustomNotification}
			requireAuth
		>
			<Resource
				name={constants.RESOURCE.USERS}
				list={UserList}
			/>
			<Resource
				name={constants.RESOURCE.PROJECT}
				list={ProjectList}
			/>
			<Resource
				name={constants.RESOURCE.DEPARTMENT}
				list={DepartmentList}
			/>
			<Resource
				name={constants.RESOURCE.PERMISSION}
				list={PermissionList}
			/>
			<Resource
				name={constants.RESOURCE.PROJECT_TYPE}
				list={Project_typeList}
			/>
			<Resource
				name={constants.RESOURCE.MENU}
				list={MenuList}
			/>
			<Resource
				name={constants.RESOURCE.BACKUP}
				list={BackupList}
			/>
			<Resource
				name={constants.RESOURCE.PARTS}
				list={PartsList}
			/>
			<Resource
				name={constants.RESOURCE.SPAM}
				list={SpamList}
			/>
				<Resource
				name={constants.RESOURCE.KPI}
				list={KpiList}
			/>
			<Resource
				name={constants.RESOURCE.ROLES}
				list={RolesList}
			/>
			<Resource
				name={constants.RESOURCE.OFFICE}
				list={OfficeList}
			/>
			<Resource
				name={constants.RESOURCE.VIDEO}
				list={VideoList}
			/>
			<Resource
				name={constants.RESOURCE.CUSTOMER}
				list={CustomerList}
			/>
			<Resource
				name={constants.RESOURCE.TRANSACTION}
				list={TransactionList}
			/>
			<Resource
				name={constants.RESOURCE.WAGE}
				list={WageList}
			/>
			<Resource
				name={constants.RESOURCE.SALARY}
				list={SalaryList}
			/>
			<Resource
				name={constants.RESOURCE.CUSTOMER_SOURCES}
				list={CustomerSourcesList}
			/>
			<Resource
				name={constants.RESOURCE.PROJECT_ESTATE}
				list={ProjectEstateList}
			/>
			<Resource
				name={constants.RESOURCE.FEEDBACK}
				list={FeedbackList}
			/>
			<Resource
				name={constants.RESOURCE.GENERAL}
				list={GeneralList}
			/>
			<Resource
				name={constants.RESOURCE.NEWS}
				list={NewsList}
			/>
			<Resource
				name={constants.RESOURCE.KEYWORD}
				list={KeywordList}
			/>
			<Resource
				name={constants.RESOURCE.DIRECTION}
				list={DirectionList}
			/>
			<Resource
				name={constants.RESOURCE.SOURCE}
				list={SourceList}
			/>
			<Resource
				name={constants.RESOURCE.PARTNER}
				list={PartnerList}
			/>
			<Resource
				name={constants.RESOURCE.STREET}
				list={StreetList}
			/>
			<Resource
				name={constants.RESOURCE.DISTRICT}
				list={DistrictList}
			/>
			{/*<Resource*/}
			{/*    name={constants.RESOURCE.PROFILE}*/}
			{/*    list={ProfileList}*/}
			{/*/>*/}
			<CustomRoutes>
				<Route
					path="/configuration"
					element={<Configuration />}
				/>
				<Route
					path="/profile"
					element={<Profile />}
				/>
				<Route
					path="/changePassword"
					element={<ChangePassword />}
				/>
			</CustomRoutes>
		</Admin>
	);
};

export default App;
