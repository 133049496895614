import React from 'react';
import {
    Dialog,
    DialogProps,
    DialogTitle,
    IconButton,
    Box,
    Stack,
    Button,
    FormControl,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';
import SaveCustomer from './SaveCustomer';

type Props = {
    title: String;
    size?: DialogProps['maxWidth'];
    isOpen: boolean;
    onClose: () => void;
    onSubmit?: any;
    children: JSX.Element;
    minHeight?: number | 'auto';
    loading: boolean;
};

const FormWrapper = styled('form')({
    overflowY: 'auto',
});

const InputWrapper = styled(Box)({
    padding: '20px 60px',
    "@media (max-width: 1920px)": {
        padding: '10px 20px',
      },
      "@media (max-width: 600px)": {
        padding: '0px 0px',
      },
});
const ButtonWrapper = styled(Box)({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: 20,
    backgroundColor: 'whitesmoke',
});

function DialogFormCustomer({
    isOpen,
    onClose,
    children,
    title,
    size = 'sm',
    onSubmit,
    minHeight = 500,
    loading
}: Props) {
    return (
        <Dialog fullWidth maxWidth={size} open={isOpen} onClose={onClose}>
            <DialogTitle
                sx={{
                    backgroundColor: 'whitesmoke',
                    position: 'relative',
                }}
            >
                {title}
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <FormWrapper onSubmit={onSubmit}>
                <FormControl error fullWidth>
                    <InputWrapper
                        sx={{
                            minHeight: minHeight,
                        }}
                    >
                        {children}
                    </InputWrapper>
                    <ButtonWrapper>
                        <Stack spacing={2} direction="row">
                            {onSubmit && <SaveCustomer loading={loading} title="Lưu" type="submit" />}
                            <Button variant="outlined" color="error" onClick={onClose}>
                                Đóng
                            </Button>
                        </Stack>
                    </ButtonWrapper>
                </FormControl>
            </FormWrapper>
        </Dialog>
    );
}
export default DialogFormCustomer;
