import { styled } from '@mui/system';
import { Box, Typography } from '@mui/material';
import { wrap } from 'module';

export const Wrapper = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
});

export const InputRowWrapper = styled(Box)({
    display: 'flex',
    widows: '100%',
    flexWrap: 'wrap'
});

export const ItemWrapper = styled('div')({
    padding: '10px 20px',
    width: '50%',
});

export const ItemWrapper30 = styled('div')({
    padding: '10px 20px',
    width: '33%',
});

export const MultilineWrapper = styled('div')({
    padding: '10px 20px',
    width: '100%',
});

export const EditorWrapper = styled(Box)({
    padding: 20,
    marginBottom: 20,
});

export const FileWrapper = styled(Box)({
    padding: 20,
});

export const LabelText = styled(Typography)({
    fontSize: '1rem',
    fontWeight: 600,
    marginBottom: 10,
});

export const ImagePreviewWrapper = styled(Box)({
    display: 'flex',
    flexWrap: 'wrap',
    padding: 10,
    marginTop: 10,
    border: '1px solid whitesmoke',
    borderRadius: 10,
});

export const FilesPreviewWrapper = styled(Box)({});

export const TableWrapper = styled(Box)({
    marginBottom: 20,
});

export const TableHeaderWrapper = styled(Box)({
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '10px 20px',
});

const mediaQuery = '@media (max-width: 600px)';
export const ResponsiveItemWrapper = styled(ItemWrapper)({
    [mediaQuery]: {
        width: '100%', // Cho các màn hình có độ rộng lớn hơn 600px, giữ nguyên chiều rộng 50%
    },
});
export const ResponsiveItemWrapper30 = styled(ItemWrapper30)({
    [mediaQuery]: {
        width: '100%', // Cho các màn hình có độ rộng lớn hơn 600px, giữ nguyên chiều rộng 50%
    },
});