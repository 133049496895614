import {
    DefaultDataProvider,
} from '../default-data-provider';
import {constants} from "../../shared/constants";

export const baseApiUrl = 'key_word';

class KeywordService extends DefaultDataProvider {

}

export const keywordService = new KeywordService(
    constants.RESOURCE.KEYWORD,
    constants.BASE_MODULE_URL,
    baseApiUrl
);