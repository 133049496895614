import {
    DefaultDataProvider,
    httpClient,
    serialize,
} from '../default-data-provider';
import { constants } from '../../shared/constants';

export const baseApiUrl = 'profile';

class ProfileService extends DefaultDataProvider {}

export const profileService = new ProfileService(
    constants.RESOURCE.PROFILE,
    constants.BASE_MODULE_URL,
    baseApiUrl,
);
