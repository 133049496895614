import {
    DefaultDataProvider,
} from '../default-data-provider';
import {constants} from "../../shared/constants";

export const baseApiUrl = 'kpi';

class KpiService extends DefaultDataProvider {

}

export const kpiService = new KpiService(
    constants.RESOURCE.KPI,
    constants.BASE_MODULE_URL,
    baseApiUrl
);