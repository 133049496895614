import {
  Autocomplete,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import ReplyIcon from "@mui/icons-material/Reply";
import Toolbar from "@mui/material/Toolbar";
import {
  EditProps,
  useRecordContext,
  useRefresh,
  useNotify,
} from "react-admin";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import ImagePreview from "../../../../component/ImgPreview";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { styled as styleSystem } from "@mui/system";
import { Dayjs } from "dayjs";
import { useForm, SubmitHandler } from "react-hook-form";
import * as projectCustomer from "../../../../Services/customerService";
const styles = makeStyles((theme: any) =>
  createStyles({
    root: {
      "& .css-k4qjio-MuiFormHelperText-root.Mui-error": {
        position: "absolute",
        paddingTop: "41px",
      },
    },
  })
);
export const InputRowWrapper = styleSystem(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "top",
  width: "100%",
  "@media (max-width: 1024px)": {
    display: "flex",
    flexDirection: "column",
  },
});
export const ItemWrapper = styleSystem("div")({
  padding: "10px 20px",
  width: "50%",
  "@media (max-width: 1024px)": {
    width: "100%",
  },
});

export const Wrapper = styleSystem(Box)({
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#fff",
  "@media (max-width: 1024px)": {
    width: "100%",
  },
});

export const CustomerProjectEdit = ({
  items,
  openDialog,
  onCloseDialog,
  ...props
}: any) => {
  //LOADING STATE
  const idProject = items.id;
  //HOOKS
  const notify = useNotify();
  const [viewTimeCustomer, setViewTimeCustomer] = React.useState<any | null>(
    items?.view_time || null
  );
  const { onSaveSuccess } = props;
  const [imageCustomerProject, setImageCustomerProject] = React.useState<any[]>([]);
  const removeImageUtilities = (imgIndex: number) => {
    const newListImage = imageCustomerProject.filter(
      (_, index) => index !== imgIndex
    );
    if (imageCustomerProject.length === 1) {
      setImageCustomerProject([]);
    }
    setImageCustomerProject(newListImage);
  };
  /**
   * handle change name
   * @param e
   */
  const validateForm = () => {
    let pass = true;
    if (imageCustomerProject.length < 1) {
      pass = false;
      notify("Ảnh không được để trống", { type: "error" });
    }
    return pass;
  };
  const handleUpdateCustomerProject: SubmitHandler<any> = async () => {
    if (!validateForm()) return;
    const submitObj = new FormData();
    submitObj.append("id", idProject as string);
    submitObj.append("view_time", viewTimeCustomer);

	if (imageCustomerProject) {
		imageCustomerProject.map((item: any) => {
			submitObj.append("image", item);
		});
		submitObj.append("status", 1 as any);
	}
    await projectCustomer
      .updateProjectCustomer(submitObj)
      .then((response: any) => {
        if (response.status === 500) {
          notify(response.statusText, { type: "error" });
        } else if (response.status) {
          // @ts-ignore
          notify(response.message, { type: "success" });
        } else {
          // @ts-ignore
          notify(response.message, { type: "error" });
        }
      })
      .catch((e: Error) => {
        console.log(e);
      });
    onCloseDialog(false);
    onSaveSuccess();
  };
  const handleCloseDialog = () => {
    onCloseDialog(false);
  };

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={onCloseDialog}
        fullWidth
        PaperProps={{
          style: {
            borderRadius: 2,
          },
        }}
      >
        <div className={"common__dialogTitle_area"}>
          <DialogTitle className={"member__dialogTitle"}>
            <div>{"Thông tin"}</div>
          </DialogTitle>
          <div className={"common__dialogTitle_actions"}>
            <IconButton
              aria-label="exists"
              color={"error"}
              onClick={() => {
                onCloseDialog(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <Box className={"dialog_box"}>
          <DialogContent className={"dialog_content"}>
            <InputRowWrapper>
              <ItemWrapper>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="vi"
                >
                  <DatePicker
                    label="Thời gian"
                    inputFormat="DD/MM/YYYY"
                    value={viewTimeCustomer}
                    onChange={(newValue) =>
                      setViewTimeCustomer(newValue.format("YYYY/MM/DD"))
                    }
                    renderInput={(params) => (
                      <TextField
                        className="mb-23"
                        {...params}
                        fullWidth
                        variant="outlined"
                      />
                    )}
                  />
                </LocalizationProvider>
              </ItemWrapper>
              <ItemWrapper>
                <div className={"upload-image-wrapper"}>
                  <div className={"title-image"}>Hình ảnh</div>
                  <input
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    onChange={(e: any) => {
                      setImageCustomerProject([
                        ...imageCustomerProject,
                        ...e.target.files,
                      ]);
                      // e.target.value = null;
                    }}
                  ></input>
                  {imageCustomerProject.length > 0 && (
                    <div className={"image-show-wrapper"}>
                      {imageCustomerProject.map((image: any, index: number) => (
                        <ImagePreview
                          key={index}
                          src={URL.createObjectURL(image)}
                          onRemove={() => removeImageUtilities(index)}
                        />
                      ))}
                    </div>
                  )}
                </div>
              </ItemWrapper>
            </InputRowWrapper>
          </DialogContent>
        </Box>
        <Toolbar>
          <Box display="flex" justifyContent="center" width={"100%"}>
            <Button
              variant="contained"
              startIcon={<SaveIcon />}
              onClick={handleUpdateCustomerProject}
            >
              {"Lưu"}
            </Button>
            &nbsp;&nbsp;
            <Button
              startIcon={<ReplyIcon />}
              className={"button-close"}
              variant="contained"
              onClick={() => handleCloseDialog()}
            >
              {"Thoát"}
            </Button>
          </Box>
        </Toolbar>
      </Dialog>
    </>
  );
};
