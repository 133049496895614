import AddIcon from "@mui/icons-material/Add";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import React, { createContext, useContext, useEffect, useState } from "react";
import classNames from "classnames/bind";
import styles from "./Salary.module.scss";
import {
  FilterLiveSearch,
  useRecordContext,
  useSimpleFormIteratorItem,
  useTranslate,
} from "react-admin";
import {
  List,
  Datagrid,
  DatagridProps,
  TextField,
  FunctionField,
} from "react-admin";
import {
  Box,
  Button,
  Chip,
  IconButton,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { useLocation } from "react-router-dom";

import { TextField as TextInput } from "@mui/material";

import { Form } from "react-final-form";
import { KpiUpdate } from "./KpiUpdate";
import PaginationCustom from "../component/PaginationCustom";
import moment from "moment";
import request from "../utils/httpRequest";
import { commonFunction } from "../component/Common";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { DataArray } from "@mui/icons-material";

const ctx = classNames.bind(styles);

const Item = styled(Paper)(({ theme }) => ({
  textAlign: "right",
}));

const FillterWrapper = styled(Box)({
  display: "flex",
  flexWrap: "wrap",
  gap: "0 15px",
  margin: "7px 15px",
});

const FillterItem = styled(Box)({
  width: 200,
});

const today = dayjs();

const SalaryPolicyContext = createContext<any>(null);
export const SalaryList = (props: any) => {
  const PostPagination = (props: any) => <PaginationCustom {...props} />;
  const translate = useTranslate();
  const [dateContext, setDateContext] = useState<Dayjs | null>(today);
  const [dateFilter, setDateFilter] = useState<Dayjs | null>(today);
  return (
    <Box className="header-channel-sales">
      <Box className={"box_header"}>
        <Typography className={"title_header"}>{"Bảng Lương"}</Typography>
      </Box>
      <div className={`header-top-detail header-top-tdn`}>
        <SalaryPolicyContext.Provider
          value={{
            dateContext: [dateContext, setDateContext],
          }}
        >
          <List
            {...props}
            actions={false}
            empty={false}
            exporter={false}
            filters={<FilterAndActions />}
            sort={{ field: "", order: "" }}
            pagination={<PostPagination />}
            perPage={50}
            filter={{
              date: dateContext ? dateContext.format("YYYY-MM") : undefined,
            }}
          >
            <WageDatagrid
              {...props}
              filDate={dateContext ? dateContext.format("YYYY-MM") : undefined}
            />
          </List>
        </SalaryPolicyContext.Provider>
      </div>
    </Box>
  );
};

interface ButtonProps {
  basePath?: string;
  label: string;
  record?: any;
}

// const RowActions = ({ record }: ButtonProps) => {
// 	const per_edit = commonFunction.checkPermission("WAGE_UPDATE");
// 	const per_delete = commonFunction.checkPermission("WAGE_DELETE");

// 	return <Box></Box>;
// };

const FilterAndActions = (props: any) => {
  const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);
  const translate = useTranslate();

  const { dateContext } = useContext(SalaryPolicyContext);

  const [date, setDate] = dateContext;

  const styleWrapper = {
    overflow: "hidden",
    transition: "200",
  };
  return (
    <Box
      sx={{
        p: 1,
        m: 1,
        bgcolor: "background.paper",
        borderRadius: 1,
      }}
      className={"box_search"}
    >
      <Item className="shadow-none">
        <Form onSubmit={() => undefined}>
          {() => (
            <Box
              sx={{
                display: "flex",
                gap: "16px",
                alignItems: "center",
              }}
            >
              <FilterLiveSearch
                variant="outlined"
                source="name"
                label="resources.common.fields.search"
              />
              <FillterItem>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="vi"
                >
                  <DatePicker
                    label="Tháng"
                    inputFormat="MM/YYYY"
                    value={date}
                    views={["month", "year"]}
                    onChange={(newValue) => setDate(newValue)}
                    renderInput={(params) => (
                      <TextInput
                        className="mb-23"
                        {...params}
                        fullWidth
                        variant="outlined"
                      />
                    )}
                  />
                </LocalizationProvider>
              </FillterItem>
            </Box>
          )}
        </Form>
      </Item>
    </Box>
  );
};

const SubTable = (props: any) => {
  const [kpiData, setKpiData] = useState<any>();
  const data = props.record;
  useEffect(() => {
    const fetchApi = () => {
      request
        .get(`kpi/getAll?date=${props.dateFilter}&size=1`)
        .then((res: any) => {
          const hasStatus = String(res?.status).startsWith("2");
          if (hasStatus) {
            setKpiData(res.data.data.items[0]);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    };
    fetchApi();
  }, [props.dateFilter]);

  const total =
    Number(data.sales_revenue_rate) +
    Number(data.spam_count_rate) +
    Number(data.source_project_rate) +
    Number(data.customer_viewing_rate) +
    Number(data.video_post_rate);
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell className={ctx("t_head")}></TableCell>
          <TableCell className={ctx("t_head")}>Doanh số/tháng</TableCell>
          <TableCell className={ctx("t_head")}>Bài viết</TableCell>
          <TableCell className={ctx("t_head")}>Nguồn hàng</TableCell>
          <TableCell className={ctx("t_head")}>Dẫn khách đi xem</TableCell>
          <TableCell className={ctx("t_head")}>Video ngắn</TableCell>
          <TableCell className={ctx("t_head")}>Đạt chỉ tiêu(%)</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell className={ctx("t_body")}>Tỷ trọng</TableCell>
          <TableCell className={ctx("t_body")}>
            {kpiData ? `${kpiData.density_sales}%` : "Đang cập nhật"}
          </TableCell>
          <TableCell className={ctx("t_body")}>
            {kpiData ? `${kpiData.density_spam}%` : "Đang cập nhật"}
          </TableCell>
          <TableCell className={ctx("t_body")}>
            {kpiData ? `${kpiData.density_source}%` : "Đang cập nhật"}
          </TableCell>
          <TableCell className={ctx("t_body")}>
            {kpiData ? `${kpiData.density_go_see}%` : "Đang cập nhật"}
          </TableCell>
          <TableCell className={ctx("t_body")}>
            {kpiData ? `${kpiData.density_video}%` : "Đang cập nhật"}
          </TableCell>
          <TableCell className={ctx("t_body")}>100%</TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={ctx("t_body")}>Chỉ tiêu/tháng</TableCell>
          <TableCell className={ctx("t_body")}>
            {kpiData
              ? kpiData.kpi_sales?.toLocaleString("vi", {
                  style: "decimal",
                  currency: "VND",
                }) + " đ"
              : "Đang cập nhật"}
          </TableCell>
          <TableCell className={ctx("t_body")}>
            {kpiData ? `${kpiData.kpi_spam}` : "Đang cập nhật"}
          </TableCell>
          <TableCell className={ctx("t_body")}>
            {kpiData ? `${kpiData.kpi_source}` : "Đang cập nhật"}
          </TableCell>
          <TableCell className={ctx("t_body")}>
            {kpiData ? `${kpiData.kpi_go_see}` : "Đang cập nhật"}
          </TableCell>
          <TableCell className={ctx("t_body")}>
            {kpiData ? `${kpiData.kpi_video}` : "Đang cập nhật"}
          </TableCell>
          <TableCell className={ctx("t_body")}></TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={ctx("t_body")}>Doanh số/tháng</TableCell>
          <TableCell className={ctx("t_body")}>
            {data?.sales_revenue?.toLocaleString("vi", {
              style: "decimal",
              currency: "VND",
            }) + " đ"}
          </TableCell>
          <TableCell className={ctx("t_body")}>{data?.spam_count}</TableCell>
          <TableCell className={ctx("t_body")}>
            {data?.source_project}
          </TableCell>
          <TableCell className={ctx("t_body")}>
            {data?.customer_viewing}
          </TableCell>
          <TableCell className={ctx("t_body")}>{data?.video_post}</TableCell>
          <TableCell className={ctx("t_body")}></TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={ctx("t_body")}>Tỉ trọng thực tế</TableCell>
          <TableCell className={ctx("t_body")}>
            {data?.sales_revenue_rate}%
          </TableCell>
          <TableCell className={ctx("t_body")}>
            {data?.spam_count_rate}%
          </TableCell>
          <TableCell className={ctx("t_body")}>
            {data?.source_project_rate}%
          </TableCell>
          <TableCell className={ctx("t_body")}>
            {data?.customer_viewing_rate}%
          </TableCell>
          <TableCell className={ctx("t_body")}>
            {data?.video_post_rate}%
          </TableCell>
          <TableCell className={ctx("t_body")}>{total}% </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

const WageDatagrid = (props: any) => {
  const localtion = useLocation();
  // @ts-ignore
  const translate = useTranslate();
  const userPermission = Number(
    commonFunction.checkUserIdentity().permission_data
  );
  const userId = Number(commonFunction.checkUserIdentity().id);
  const fillerData = (dataRecord: any) => {
    return dataRecord.filter((item: any) => item.employee_id === userId);
  };
  const expandRow = ({ record }: any) => {
    if (record.employee_id === userId) {
      return <SubTable record={record} dateFilter={props?.filDate} />;
    } else {
      return null;
    }
  };

  return (
    <div className={`list-data-tdn`}>
      <Datagrid
        optimized
        rowClick="expand"
        expand={
          userPermission >= 3 ? (
            expandRow
          ) : (
            <SubTable dateFilter={props?.filDate} />
          )
        }
        bulkActionButtons={false}
      >
        <FunctionField
          label={"Tên"}
          render={(record: any) => {
            if (userPermission < 3) {
              return record?.employee;
            } else {
              if (record.employee_id == userId) {
                return record?.employee;
              } else {
                return null;
              }
            }
          }}
        />
        <FunctionField
          label={"Lương cứng"}
          render={(record: any) => {
            if (userPermission < 3) {
              return record?.wage_basic_salary
                ? record?.wage_basic_salary.toLocaleString("vi", {
                    style: "decimal",
                    currency: "VND",
                  }) + " đ"
                : "0 đ";
            } else {
              if (record.employee_id == userId) {
                return record?.wage_basic_salary
                  ? record?.wage_basic_salary.toLocaleString("vi", {
                      style: "decimal",
                      currency: "VND",
                    }) + " đ"
                  : "0 đ";
              } else {
                return null;
              }
            }
          }}
        />
        <FunctionField
          label={"Mức KPI"}
          render={(record: any) => {
            if (userPermission < 3) {
              return record?.wage_name;
            } else {
              if (record.employee_id == userId) {
                return record?.wage_name;
              } else {
                return null;
              }
            }
          }}
        />
        <FunctionField
          label={"Lương KPI"}
          render={(record: any) => {
            if (userPermission < 3) {
              return record?.salary_kpi
                ? record?.salary_kpi.toLocaleString("vi", {
                    style: "decimal",
                    currency: "VND",
                  }) + " đ"
                : "0 đ";
            } else {
              if (record.employee_id == userId) {
                return record?.salary_kpi
                  ? record?.salary_kpi.toLocaleString("vi", {
                      style: "decimal",
                      currency: "VND",
                    }) + " đ"
                  : "0 đ";
              } else {
                return null;
              }
            }
          }}
        />
        <FunctionField
          label={"Tỉ lệ hoa hồng"}
          render={(record: any) => {
            if (userPermission < 3) {
              return record?.wage_rate + "%";
            } else {
              if (record.employee_id == userId) {
                return record?.wage_rate + "%";
              } else {
                return null;
              }
            }
          }}
        />
        <FunctionField
          label={"Tiền hoa hồng"}
          render={(record: any) => {
            if (userPermission < 3) {
              return record?.wage_rate + "%"
                ? record?.rose.toLocaleString("vi", {
                    style: "decimal",
                    currency: "VND",
                  }) + " đ"
                : "0 đ";
            } else {
              if (record.employee_id == userId) {
                return record?.wage_rate + "%"
                  ? record?.rose.toLocaleString("vi", {
                      style: "decimal",
                      currency: "VND",
                    }) + " đ"
                  : "0 đ";
              } else {
                return null;
              }
            }
          }}
        />
        <FunctionField
          label={"Tổng lương"}
          render={(record: any) => {
            if (userPermission < 3) {
              return record?.total_salary
                ? record?.total_salary.toLocaleString("vi", {
                    style: "decimal",
                    currency: "VND",
                  }) + " đ"
                : "0 đ";
            } else {
              if (record.employee_id == userId) {
                return record?.total_salary
                  ? record?.total_salary.toLocaleString("vi", {
                      style: "decimal",
                      currency: "VND",
                    }) + " đ"
                  : "0 đ";
              } else {
                return null;
              }
            }
          }}
        />
        <FunctionField
          label={"Tháng/Năm"}
          render={(record: any) => {
            if (userPermission < 3) {
              return moment(record?.updated_at).format("MM/YYYY");
            } else {
              if (record.employee_id == userId) {
                return moment(record?.updated_at).format("MM/YYYY");
              } else {
                return null;
              }
            }
          }}
        />
        {/* <RowActions label={translate("resources.common.fields.action")} /> */}
      </Datagrid>
    </div>
  );
};
