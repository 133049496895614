import { TextField } from '@mui/material';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import InputLabel from './InputLabel';

type ErrorTypes = {
    isError: boolean;
    message: String;
  };

type Props = {
    onChange: (value: any) => void;
    title: string;
    val?: string;
    required: boolean;
    statusError?: ErrorTypes | null;
    readonly?: boolean | false;
    submitLoading?: boolean | false;
};

const PriceInput = ({ title, val, onChange, required, statusError, readonly, submitLoading }: Props) => {
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if(val) setInputValue(formatPrice(val.toString()));
  }, [val])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const price = formatPrice(e.target.value);
    setInputValue(price);
    onChange(price.replace(/\D/g, ''));
  };

  // const formatPrice = (data: string) => {
  //   if(!data) return "0";
  //   // Remove non-digit characters from the input
  //   const numericValue = data.replace(/\D/g, '');

  //   // Format the numeric value as VND without currency symbol
  //   const formattedValue = new Intl.NumberFormat('vi-VN', {
  //     style: 'currency',
  //     currency: 'VND',
  //   }).format(Number(numericValue));

  //   // Remove the currency symbol
  //   const valueWithoutSymbol = formattedValue.replace(/[^\d.]/g, '');
  //   return valueWithoutSymbol;
  // }

  const formatPrice = useMemo(() => (data: string) => {
    if (!data) return "0";

    const numericValue = data.replace(/\D/g, '');
    const formattedValue = new Intl.NumberFormat('vi-VN', {
      style: 'currency',
      currency: 'VND',
    }).format(Number(numericValue));

    const valueWithoutSymbol = formattedValue.replace(/[^\d.]/g, '');
    return valueWithoutSymbol;
  }, []);

  return (
    <TextField
        error={statusError?.isError}
        helperText={statusError?.message}
        fullWidth
        id="outlined-basic"
        label={<InputLabel title={title} required={required} />}
        variant="outlined"
        value={inputValue}
        onChange={handleChange}
        InputProps={{
            readOnly: readonly,
        }}
        disabled={submitLoading}
    />
  );
};

export default PriceInput;
