import {
    CreateParams,
    CreateResult,
    DataProvider,
    DeleteManyParams,
    DeleteManyResult,
    DeleteParams,
    DeleteResult,
    fetchUtils,
    GetListParams,
    GetListResult,
    GetManyParams,
    GetManyReferenceParams,
    GetManyReferenceResult,
    GetManyResult,
    GetOneParams,
    GetOneResult,
    PaginationPayload,
    RaRecord,
    SortPayload,
    UpdateManyParams,
    UpdateManyResult,
    UpdateParams,
    UpdateResult,
} from 'react-admin';
import { ApiPagination } from './api-pagination';
import { apiCriteriaUtils } from './api-criteria-utils';
import { authService } from './auth-service';

type UiPagination = {
    pagination: PaginationPayload;
    sort: SortPayload;
};

export const isObject = (value: any) => {
    return typeof value === 'object';
};

export const isDateObject = (value: any) => {
    return isObject(value) && typeof value?.getMonth === 'function';
};

export const isArray = (value: any) => {
    return isObject(value) && Array.isArray(value);
};

export const serialize = (obj: any, prefix?: string): string => {
    let str: string[] = [];
    let propertyName: string;
    for (propertyName in obj) {
        if (obj.hasOwnProperty(propertyName)) {
            const key: string = prefix
                ? `${prefix}.${propertyName}`
                : propertyName;
            let propertyValue: any = obj[propertyName];
            if (isDateObject(propertyValue)) {
                str.push(
                    `${encodeURIComponent(key)}=${encodeURIComponent(
                        (propertyValue as Date).toLocaleString()
                    )}`
                );
            } else if (isArray(propertyValue)) {
                (propertyValue as any[]).forEach(value => {
                    if (isObject(value)) {
                        str.push(serialize(value, key));
                    } else {
                        str.push(
                            `${encodeURIComponent(key)}=${encodeURIComponent(
                                value
                            )}`
                        );
                    }
                });
            } else if (isObject(propertyValue)) {
                str.push(serialize(propertyValue, key));
            } else if (propertyValue !== null && propertyValue !== undefined) {
                str.push(
                    `${encodeURIComponent(key)}=${encodeURIComponent(
                        propertyValue
                    )}`
                );
            }
        }
    }
    return str.join('&');
};

export const convertToApiPagination = (params: UiPagination): ApiPagination => {
    return {
        size: params.pagination.perPage,
        sort: [`${params.sort.field},${params.sort.order.toLowerCase()}`],
        page: params.pagination.page,
    };
};

export const httpClient = (url: string, options: any) => {
    let headers: Headers = options.headers;
    if (!headers) {
        options.headers = headers = new Headers({});
    }

    if (!headers.get('Accept')) headers.set('Accept', 'application/json');

    const authorizationToken = authService.getToken();
    if (authorizationToken) {
        headers.set('Authorization', `Bearer ${authorizationToken}`);
    }

    return fetchUtils.fetchJson(url, options);
};

const getAll = 'getAll'
const addOne = 'addOne'
const getOne = 'getOne'

export class DefaultDataProvider implements DataProvider {
    resource: string;
    apiResource: string;
    apiUrl: string;

    constructor(resource: string, apiUrl: string, apiResource: string) {
        this.resource = resource;
        this.apiUrl = apiUrl;
        this.apiResource = apiResource || resource;
    }

    /**
     * ham chuyen doi kieu du lieu tren sv ve kieu du lieu tuong thich
     * default thi khong phai chuyen doi
     *
     * @protected
     * @param from
     */
    protected transform<RecordType extends RaRecord = RaRecord>(
        from: any
    ): RecordType {
        return from as RecordType;
    }

    protected transformList<RecordType extends RaRecord = RaRecord>(
        froms: any
    ): RecordType[] {
        return (froms.data.items as any[])?.map(item => this.transform<RecordType>(item));
    }

    create<RecordType extends RaRecord = RaRecord>(
        resource: string,
        params: CreateParams
    ): Promise<CreateResult<RecordType>> {
        const url = `${this.apiUrl}/${this.apiResource}/${addOne}`;
        return httpClient(url, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(response => {
            const { json } = response;
            return { data: this.transform<RecordType>(json) };
        });
    }

    delete<RecordType extends RaRecord = RaRecord>(
        resource: string,
        params: DeleteParams
    ): Promise<DeleteResult<RecordType>> {
        const url = `${this.apiUrl}/${this.apiResource}/${params.id}`;
        return httpClient(url, {
            method: 'DELETE',
        }).then(response => {
            return { data: { id: '123' } as any };
        });
    }

    deleteMany(
        resource: string,
        params: DeleteManyParams
    ): Promise<DeleteManyResult> {
        const query = {
            ...apiCriteriaUtils.in('id', params.ids),
            unpaged: true,
        };
        const url = `${this.apiUrl}/${this.apiResource}?${serialize(query)}`;
        // @ts-ignore
        return httpClient(url, {
            method: 'DELETE',
        }).then(response => {
            const { json } = response;
            return {
                data: this.transformList(json),
                total: json?.length || 0,
            };
        });
    }

    getList<RecordType extends RaRecord = RaRecord>(
        resource: string,
        params: GetListParams
    ): Promise<GetListResult<RecordType>> {
        const query = {
            ...params.filter,
            ...convertToApiPagination(params),
        };
        const url = `${this.apiUrl}/${this.apiResource}/${getAll}?${serialize(query)}`;
        const options = {};
        return httpClient(url, options).then(response => {
            return this.toGetListResult(response);
        });
    }

    toGetListResult<RecordType extends RaRecord = RaRecord>(response: {
        status: number;
        headers: Headers;
        body: string;
        json: any;
    }) {

        // const total = parseInt(response.headers.get('X-Total-Count') || '0');
        const { json } = response;
        const data = this.transformList<RecordType>(json);
        const total = json.data.total;

        return {
            data: data,
            total: total,
        };

    }


    getMany<RecordType extends RaRecord = RaRecord>(
        resource: string,
        params: GetManyParams
    ): Promise<GetManyResult<RecordType>> {
        const query = {
            ...apiCriteriaUtils.in('id', params.ids),
            unpaged: true,
            size: 1000,
        };
        const url = `${this.apiUrl}/${this.apiResource}?${serialize(query)}`;
        const options = {};
        return httpClient(url, options).then(response => {
            const { json } = response;
            return {
                data: this.transformList<RecordType>(json),
                total: json?.length || 0,
            };
        });
    }

    getManyReference<RecordType extends RaRecord = RaRecord>(
        resource: string,
        params: GetManyReferenceParams
    ): Promise<GetManyReferenceResult<RecordType>> {
        const cloneFilter = {
            ...params.filter,
            [params.target]: params.id,
        };
        const query = {
            ...cloneFilter,
            ...convertToApiPagination(params),
        };
        const url = `${this.apiUrl}/${this.apiResource}?${serialize(query)}`;
        return httpClient(url, {}).then(response => {
            const total = parseInt(
                response.headers.get('X-Total-Count') || '0'
            );
            const { json } = response;
            return { data: json, total: total || 0 };
        });
    }

    getOne<RecordType extends RaRecord = RaRecord>(
        resource: string,
        params: GetOneParams
    ): Promise<GetOneResult<RecordType>> {
        const url = `${this.apiUrl}/${this.apiResource}/${getOne}/${params.id}`;
        const options = {};
        return httpClient(url, options).then(response => {
            const { json } = response;
            return { data: this.transform<RecordType>(json) as RecordType };
        });
    }

    update<RecordType extends RaRecord = RaRecord>(
        resource: string,
        params: UpdateParams
    ): Promise<UpdateResult<RecordType>> {
        return this.put({
            url: `${this.apiUrl}/${this.apiResource}/${params.data.id}`,
            data: params.data,
        });
    }

    protected put<RecordType extends RaRecord = RaRecord>(params: {
        url: string;
        data: any;
        options?: any;
    }): Promise<UpdateResult<RecordType>> {
        const { url, data, options } = params;
        return httpClient(url, {
            ...(options || {}),
            method: 'PUT',
            body: JSON.stringify(data),
        }).then(response => {
            const { json } = response;
            return { data: this.transform<RecordType>(json) };
        });
    }

    updateMany(
        resource: string,
        params: UpdateManyParams
    ): Promise<UpdateManyResult> {
        return Promise.resolve({ data: [{ id: '123' } as any] });
    }
}
