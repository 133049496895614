import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import React, {useContext, useEffect, useState} from "react";

import {
    Pagination,
    List,
    Datagrid,
    DatagridProps,
    useListContext,
    TextField,
    FunctionField,
    FilterLiveSearch,
    useTranslate
} from "react-admin";
import {
    Box,
    Button, Chip,
    IconButton, Typography,
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {Form} from "react-final-form";
import {RolesAdd} from "./RolesAdd";
import {RolesEdit} from "./RolesEdit";
import {RolesDelete} from "./RolesDelete";
import {commonFunction} from "../component/Common";
import {PartCreate} from "../parts/PartsAdd";

const Item = styled(Paper)(({ theme }) => ({
    textAlign: 'right',
}));

// @ts-ignore
const RolesPolicyContext = React.createContext();

export const RolesList = (props: any) => {
    const PostPagination = (props: any) => (<Pagination rowsPerPageOptions={[5, 10, 25, 50]} {...props} />);
    const translate =useTranslate();
    // @ts-ignore
    const [nameContext, setNameContext] = useState<string>()
    return (
        <Box className="header-channel-sales">
            <Box className={'box_header'}>
                <Typography className={'title_header'}>
                    {translate('resources.roles.list_roles')}
                </Typography>
            </Box>
            <div className={`header-top-detail header-top-tdn`}>
                <RolesPolicyContext.Provider value={{
                    nameContext: [nameContext, setNameContext],
                }}>
                    <List
                        {...props}
                        filter={{
                            name: nameContext,
                        }}
                        actions={false}
                        empty={false}
                        exporter={false}
                        filters={<FilterAndActions />}
                        sort={{field: '', order: ''}}
                        pagination={<PostPagination/>}
                        filterDefaultValues={{
                            name: ''
                        }}
                    >
                        <PermissionDatagrid {...props} />
                    </List>
                </RolesPolicyContext.Provider>
            </div>
        </Box>
    );
}

interface ButtonProps {
    basePath?: string;
    label: string;
    record?: any;
}

const EditButton = (props: any) => {
    const [onCloseDialog, setOpenEditDialog] = useState(false);
    return (
        <>
            <IconButton onClick={() => setOpenEditDialog(true)}>
                <EditIcon className={'btn_edit'}/>
            </IconButton>
            { onCloseDialog && (
                <RolesEdit
                    openDialog={onCloseDialog}
                    onCloseDialog={() => setOpenEditDialog(false)}
                />
            )}

        </>
    )
}

const DeleteButton = (props: any) => {
    const [openDialog, setOpenEditDialog] = useState(false);
    return (
        <>
            <IconButton onClick={() => setOpenEditDialog(true)} >
                <DeleteIcon className={'btn_del'}/>
            </IconButton>
            { openDialog && (
                <RolesDelete
                    openDialog={openDialog}
                    onCloseDialog={() => setOpenEditDialog(false)}
                />
            )}
        </>
    )
}

const RowActions = ({record}: ButtonProps) => {
    const per_edit = commonFunction.checkPermission('ROLES_EDIT');
    const per_delete = commonFunction.checkPermission('ROLES_DELETE');
    return (
        <Box>
            { per_edit && (<EditButton/>) }
            { per_delete && (<DeleteButton />) }

        </Box>
    );
};

const FilterAndActions = (props: any) => {
    // @ts-ignore
    const {filterValues} = useListContext();

    const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);
    const translate =useTranslate();
    // @ts-ignore
    const {nameContext} = useContext(RolesPolicyContext);
    const [name, setName] = nameContext;

    /**
     * handle change name
     * @param e
     */
    const handleNameChange = (e: any) => {
        setName(e == '' ? '' : e.target.value)
    }

    return (
        <Box
            sx={{
                p: 1,
                m: 1,
                bgcolor: 'background.paper',
                borderRadius: 1,
            }}
            className={'box_search'}
        >

            <Item className="shadow-none">
                <Form onSubmit={() => undefined} initialValues={filterValues}>
                    {() => (
                        <FilterLiveSearch onChange={handleNameChange} value={name} variant="outlined" source="name" label="resources.common.fields.search"/>
                    )}
                </Form>
            </Item>
            <Item className="shadow-none">
                {commonFunction.checkPermission('ROLES_ADD') && (
                    <>
                        <Button variant="outlined" startIcon={<AddIcon/>} color="primary"
                                onClick={() => setOpenCreateDialog(true)}>
                            {translate('resources.roles.fields.add_roles')}
                        </Button>
                        {openCreateDialog && (
                            <RolesAdd openDialog={openCreateDialog}
                                        onCloseDialog={() => setOpenCreateDialog(false)}/>
                        )
                        }
                    </>
                )}
            </Item>
        </Box>
    );
}

const PermissionDatagrid = (props: DatagridProps) => {
    // @ts-ignore
    const {page, perPage} = useListContext();
    const translate =useTranslate();
    return (
        <>
            {/*<FilterAndActions/>*/}
            <div>
                <Datagrid
                    optimized
                    bulkActionButtons={false}
                >
                    <TextField source={'name'} label={translate('resources.roles.fields.name_roles')} />
                    <TextField source={'guard_name'}  label={translate('resources.roles.fields.gua_name')}/>
                    <FunctionField
                        label={translate('resources.common.fields.status')}
                        render={(record: any) => {
                            if (record.status === 1) {
                                return (
                                    <Chip label={translate('resources.common.fields.active')} color="success" variant="outlined" />
                                );
                            } else {
                                return (
                                    <Chip label={translate('resources.common.fields.block')} color="error" variant="outlined" />
                                );
                            }
                        }}
                    />
                    <RowActions label={translate('resources.common.fields.action')}/>
                </Datagrid>
            </div>
        </>

    );
}
