import React from 'react';
import { Pagination } from 'react-admin';
import classNames from 'classnames/bind';
import styles from './Pagination.module.scss';

type Props = {
    props: any;
};

const cx = classNames.bind(styles);

function PaginationCustom({ props }: Props) {
    return (
        <Pagination
            className={cx('pagination-custom')}
            rowsPerPageOptions={[5, 10, 25, 50]}
            {...props}
        />
    );
}
export default React.memo(PaginationCustom);
