import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import React, { useContext, useState } from 'react';
import { FilterLiveSearch, useTranslate } from 'react-admin';
import {
    Pagination,
    List,
    Datagrid,
    DatagridProps,
    useListContext,
    TextField,
    FunctionField,
} from 'react-admin';
import { Box, Button, Chip, IconButton, Typography } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { DepartmentEdit } from './DepartmentEdit';
import { DepartmentCreate } from './DepartmentAdd';
import PaginationCustom from '../component/PaginationCustom';
import { DepartmentDelete } from './DepartmentDelete';
import { Form } from 'react-final-form';
import { commonFunction } from '../component/Common';
import { PermissionAdd } from '../permission/PermissionAdd';

const Item = styled(Paper)(({ theme }) => ({
    textAlign: 'right',
}));

// @ts-ignore
const PermissionPolicyContext = React.createContext();

export const DepartmentList = (props: any) => {
    const PostPagination = (props: any) => <PaginationCustom {...props} />;
    const translate = useTranslate();

    const [nameContext, setNameContext] = useState<string>();
    return (
        <Box className="header-channel-sales">
            <Box className={'box_header'}>
                <Typography className={'title_header'}>
                    {translate('resources.department.list_department')}
                </Typography>
            </Box>
            <div className={`header-top-detail header-top-tdn`}>
                <PermissionPolicyContext.Provider
                    value={{
                        nameContext: [nameContext, setNameContext],
                    }}
                >
                    <List
                        {...props}
                        filter={{
                            name: nameContext,
                        }}
                        actions={false}
                        empty={false}
                        exporter={false}
                        filters={<FilterAndActions />}
                        sort={{ field: '', order: '' }}
                        pagination={<PostPagination />}
                        perPage={50}
                        filterDefaultValues={{
                            name: '',
                        }}
                    >
                        <DepartmentDatagrid {...props} />
                    </List>
                </PermissionPolicyContext.Provider>
            </div>
        </Box>
    );
};

interface ButtonProps {
    basePath?: string;
    label: string;
    record?: any;
}

const EditButton = (props: any) => {
    const [onCloseDialog, setOpenEditDialog] = useState(false);
    return (
        <>
            <IconButton onClick={() => setOpenEditDialog(true)}>
                <EditIcon className={'btn_edit'} />
            </IconButton>
            {onCloseDialog && (
                <DepartmentEdit
                    {...props}
                    openDialog={onCloseDialog}
                    onCloseDialog={() => setOpenEditDialog(false)}
                />
            )}
        </>
    );
};

const DeleteButton = (props: any) => {
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    return (
        <>
            <IconButton onClick={() => setOpenDeleteDialog(true)}>
                <DeleteIcon className={'btn_del'} />
            </IconButton>
            <DepartmentDelete
                openDialog={openDeleteDialog}
                onCloseDialog={() => setOpenDeleteDialog(false)}
            />
        </>
    );
};

const RowActions = ({ record }: ButtonProps) => {
    const per_edit = commonFunction.checkPermission('DEPARTMENT_EDIT');
    const per_delete = commonFunction.checkPermission('DEPARTMENT_DELETE');
    return (
        <Box>
            {per_edit && <EditButton />}
            {per_delete && <DeleteButton />}
        </Box>
    );
};
const FilterAndActions = (props: any) => {
    // @ts-ignore
    const { filterValues } = useListContext();
    const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);
    // @ts-ignore
    const { nameContext } = useContext(PermissionPolicyContext);
    const [name, setName] = nameContext;
    const translate = useTranslate();
    /**
     * handle change name
     * @param e
     */
    const handleNameChange = (e: any) => {
        setName(e == '' ? '' : e.target.value);
    };

    return (
        <Box
            sx={{
                p: 1,
                m: 1,
                bgcolor: 'background.paper',
                borderRadius: 1,
            }}
            className={'box_search'}
        >
            <Item className="shadow-none">
                <Form onSubmit={() => undefined} initialValues={filterValues}>
                    {() => (
                        <FilterLiveSearch
                            onChange={handleNameChange}
                            value={name}
                            variant="outlined"
                            source="name"
                            label="resources.common.fields.search"
                        />
                    )}
                </Form>
            </Item>
            <Item className="shadow-none">
                {commonFunction.checkPermission('DEPARTMENT_ADD') && (
                    <>
                        <Button
                            variant="outlined"
                            startIcon={<AddIcon />}
                            color="primary"
                            onClick={() => setOpenCreateDialog(true)}
                        >
                            {translate('resources.common.fields.add')}
                        </Button>
                        {openCreateDialog && (
                            <DepartmentCreate
                                openDialog={openCreateDialog}
                                onCloseDialog={() => setOpenCreateDialog(false)}
                            />
                        )}
                    </>
                )}
            </Item>
        </Box>
    );
};

const DepartmentDatagrid = (props: DatagridProps) => {
    // @ts-ignore
    const { page, perPage } = useListContext();
    const translate = useTranslate();
    return (
        <>
            <div className={`list-data-tdn`}>
                <Datagrid
                    optimized
                    bulkActionButtons={false}
                    className={`table-default criteria_type_datagrid table_department`}
                >
                    <TextField
                        source={'name'}
                        label={translate('resources.department.fields.name_department')}
                    />
                    <TextField
                        source={'created_name'}
                        label={translate('resources.common.fields.created_by')}
                    />
                    <TextField
                        source={'updated_name'}
                        label={translate('resources.common.fields.updated_by')}
                    />
                    <FunctionField
                        label={translate('resources.common.fields.status')}
                        render={(record: any) => {
                            if (record.status === 1) {
                                return (
                                    <Chip
                                        label={translate('resources.common.fields.active')}
                                        color="success"
                                        variant="outlined"
                                    />
                                );
                            } else {
                                return (
                                    <Chip
                                        label={translate('resources.common.fields.block')}
                                        color="error"
                                        variant="outlined"
                                    />
                                );
                            }
                        }}
                    />
                    <RowActions label={translate('resources.common.fields.action')} />
                </Datagrid>
            </div>
        </>
    );
};
