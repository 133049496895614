import { Autocomplete, Dialog, DialogContent, IconButton, TextField, DialogTitle } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import ReplyIcon from "@mui/icons-material/Reply";
import Toolbar from "@mui/material/Toolbar";
import { useNotify, useRefresh, useRecordContext, EditProps, useTranslate } from "react-admin";
import HttpDataService from "../dataProvider/http.service";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import PriceInput from "../component/PriceInput";

//Reformat Style
const styles = makeStyles((theme: any) =>
	createStyles({
		root: {
			"& .css-k4qjio-MuiFormHelperText-root.Mui-error": {
				position: "absolute",
				paddingTop: "41px",
			},
			"& .css-1z10yd4-MuiFormControl-root-MuiTextField-root": {
				marginTop: 20,
				marginBottom: 20,
			},
		},
	})
);

interface Add extends EditProps {
	openDialog: boolean;
	onCloseDialog: (openDialog: boolean) => void;
}

export const WageEdit = ({ openDialog, onCloseDialog, ...rest }: Add) => {
	const classes = styles();
	const [name, setName] = useState<string>("");
	const [min_sale, setMin_sale] = useState<string>();
	const [max_sale, setMax_sale] = useState<string>();
	const [basic_salary, setBasic_salary] = useState<string>();
	const [kpi, setKpi] = useState<string>();
	const [rate, setRate] = useState<string>();
	const [space, setSpace] = useState<any>();

	const [nameError, setNameError] = useState(false);
	const [basic_salaryError, setBasic_salaryError] = useState(false);
	const [textNameError, setTextNameError] = useState("");
	const [basic_salaryTextError, setBasic_salaryTextError] = useState("");
	const translate = useTranslate();
	const dataStatus = [
		{ label: translate("resources.common.fields.active"), id: 1 },
		{ label: translate("resources.common.fields.block"), id: 0 },
	];
	const [status, setStatus] = useState<any>();
	const refresh = useRefresh();
	const notify = useNotify();
	const record = useRecordContext();

	useEffect(() => {
		const fetchAPI = async () => {
			await HttpDataService.findById("wage/get-wage", record.id as string)
				.then((response: any) => {
					if (response.data.status) {
						const res_record = response.data.data;
						const findStatus = dataStatus.find((e) => {
							return e.id == res_record.status;
						});
						setName(res_record.name);
						setMin_sale(res_record.min_sale);
						setMax_sale(res_record.max_sale);
						setBasic_salary(res_record.basic_salary);
						setKpi(res_record.kpi);
						setRate(res_record.rate);
						setStatus(findStatus);
						setSpace(res_record.space);
					} else {
						// @ts-ignore
						notify("Lỗi", { type: "error" });
					}
				})
				.catch((e: Error) => {
					console.log(e);
				});
		};
		fetchAPI();
	}, [record.id]);
	/**
	 * handle change name
	 * @param e
	 */
	const handleNameChange = (e: any) => {
		setNameError(false);
		setTextNameError("");
		setName(e.target.value);
	};
	const handleMin_saleChange = (e: any) => {
		setMin_sale(e);
	};
	const handleMax_saleChange = (e: any) => {
		setMax_sale(e);
	};
	const handleSpaceChange = (e: any) => {
		setSpace(e);
	};
	const handleBasic_salaryChange = (e: any) => {
		setBasic_salary(e);
	};
	const handleKpiChange = (e: any) => {
		setKpi(e);
	};
	const handleRateChange = (e: any) => {
		setRate(e.target.value);
	};
	const handleChangeStatus = (e: any) => {
		setStatus(e);
	};

	// checker
	/**
	 * handle save
	 */
	const handleSave = async () => {
		if (!fnValidation()) {
			let data = {
				id: record.id,
				name: name.trim(),
				min_sale: min_sale || 0,
				max_sale: max_sale || null,
				basic_salary: basic_salary || 0,
				kpi: kpi || 0,
				rate: rate,
				space: space,
				status: status.id,
			};

			await HttpDataService.update("wage/edit-wage", data)
				.then((response: any) => {
					if (response.data.status) {
						// @ts-ignore
						notify(response.data.message, { type: "success" });
					} else {
						// @ts-ignore
						notify(response.data.message || "Lỗi", { type: "error" });
					}
				})
				.catch((e: Error) => {
					console.log(e);
					notify("Lỗi", { type: "error" });
				});

			fnResetFrm();
			onCloseDialog(false);
			refresh();
		}
	};

	const handleCloseDialog = () => {
		fnResetFrm();
		onCloseDialog(false);
	};

	/**
	 * fn validation form
	 */
	function fnValidation() {
		let error = false;
		if (name.trim().length < 3) {
			error = true;
			setNameError(true);
			setTextNameError(translate("Tên chứa ít nhất 3 ký tự"));
		}
		if (String(basic_salary).trim().length === 0) {
			error = true;
			setBasic_salaryError(true);
			setBasic_salaryTextError("Lương cơ bản không được để trống");
		}
		return error;
	}

	function fnResetFrm() {
		setName("");
		setNameError(false);
		setTextNameError("");
		setBasic_salaryError(false);
		setBasic_salaryTextError("");
		setStatus(null);
	}

	return (
		<>
			<Dialog
				open={openDialog}
				fullWidth
				disableEnforceFocus
				PaperProps={{
					style: {
						borderRadius: 2,
						minWidth: "35%",
					},
				}}
			>
				<div className={"common__dialogTitle_area"}>
					<DialogTitle className={"member__dialogTitle"}>
						<div>{"Chỉnh sửa video"}</div>
					</DialogTitle>
					<div className={"common__dialogTitle_actions"}>
						<IconButton
							aria-label="exists"
							color={"error"}
							onClick={() => {
								onCloseDialog(false);
							}}
						>
							<CloseIcon />
						</IconButton>
					</div>
				</div>
				<Box className={"dialog_box"}>
					<DialogContent className={"dialog_content"}>
						<TextField
							className={`${classes.root} mb-23`}
							fullWidth
							label={
								<span>
									{"Tên mức lương"}
									<span style={{ color: "red" }}>(*)</span>
								</span>
							}
							value={name}
							error={nameError}
							helperText={textNameError}
							onChange={handleNameChange}
							variant="outlined"
						/>
						<PriceInput
							title="Doanh số tối thiểu"
							val={min_sale}
							onChange={handleMin_saleChange}
							required={false}
						/>
						<PriceInput
							title="Doanh số tối đa"
							val={max_sale}
							onChange={handleMax_saleChange}
							required={false}
						/>
						<PriceInput
							title="Khoảng"
							val={space}
							onChange={handleSpaceChange}
							required={false}
						/>
						<PriceInput
							title="Lương cơ bản"
							val={basic_salary}
							onChange={handleBasic_salaryChange}
							required={false}
						/>
						<PriceInput
							title="KPI"
							val={kpi}
							onChange={handleKpiChange}
							required={false}
						/>
						<TextField
							className={`${classes.root} mb-23`}
							fullWidth
							label={<span>{"Tỉ lệ"}</span>}
							value={rate}
							onChange={handleRateChange}
							variant="outlined"
						/>
						<Autocomplete
							renderInput={(params) => (
								<TextField
									{...params}
									variant="outlined"
									label={<span> {translate("resources.common.fields.status")}</span>}
								/>
							)}
							fullWidth
							value={status || null}
							onChange={(event, value) => handleChangeStatus(value)}
							options={dataStatus}
						/>
					</DialogContent>
				</Box>
				<Toolbar>
					<Box
						display="flex"
						justifyContent="center"
						width={"100%"}
					>
						<Button
							variant="contained"
							startIcon={<SaveIcon />}
							onClick={handleSave}
						>
							{translate("resources.common.fields.update")}
						</Button>
						&nbsp;&nbsp;
						<Button
							startIcon={<ReplyIcon />}
							className={"button-close"}
							variant="contained"
							onClick={handleCloseDialog}
						>
							{translate("resources.common.fields.cancel")}
						</Button>
					</Box>
				</Toolbar>
			</Dialog>
		</>
	);
};
