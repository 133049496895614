import {
    DefaultDataProvider,
} from '../default-data-provider';
import {constants} from "../../shared/constants";

export const baseApiUrl = 'direction';

class DirectionService extends DefaultDataProvider {

}

export const directionService = new DirectionService(
    constants.RESOURCE.DIRECTION,
    constants.BASE_MODULE_URL,
    baseApiUrl
);