import {
    DefaultDataProvider,
    httpClient,
    serialize,
} from '../default-data-provider';
import {constants} from "../../shared/constants";

export const baseApiUrl = 'department';

export default class DepartmentService extends DefaultDataProvider {

}

export const departmentService = new DepartmentService(
    constants.RESOURCE.DEPARTMENT,
    constants.BASE_MODULE_URL,
    baseApiUrl
);