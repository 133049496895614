import {
    Autocomplete,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    TextField,
    Typography
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Box from "@mui/material/Box";
import React, {useState, useEffect} from "react";
import Button from "@mui/material/Button";
import SaveIcon from '@mui/icons-material/Save';
import ReplyIcon from '@mui/icons-material/Reply';
import Toolbar from "@mui/material/Toolbar";
import {useNotify, useRefresh, useRecordContext, EditProps, useTranslate, Title, FilterLiveSearch} from "react-admin";
import HttpDataService from "../dataProvider/http.service";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CardContent from "@mui/material/CardContent";
import {darkTheme, lightTheme} from "./themes";
import Card from "@mui/material/Card";
import {Form} from "react-final-form";
import AddIcon from "@mui/icons-material/Add";
import {DepartmentCreate} from "../department/DepartmentAdd";
import {styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import {constants} from "../shared/constants";


export const ChangePassword = () => {
    //old password
    const [oldPassword, setOldPassword] = useState<string>("")
    const [oldPasswordError, setOldPasswordError] = useState(false)
    const [textOldPasswordError, setTextOldPasswordError] = useState("")
    const [showOldPassword, setShowOldPassword] = useState(false)


    //new password
    const [newPassword, setNewPassword] = useState<string>("")
    const [newPasswordError, setNewPasswordError] = useState(false)
    const [textNewPasswordError, setTextNewPasswordError] = useState("")
    const [showNewPassword, setShowNewPassword] = useState(false)

    //re-new password
    const [reNewPassword, setReNewPassword] = useState<string>("")
    const [reNewPasswordError, setReNewPasswordError] = useState(false)
    const [textReNewPassError, setTextReNewPassError] = useState("")
    const [showReNewPassword, setShowReNewPassword] = useState(false)

    const refresh = useRefresh();
    const notify = useNotify();
    const record = useRecordContext();
    const translate =useTranslate();
    const user = JSON.parse(localStorage.getItem(constants.LOCAL_STORAGE.USER_IDENTITY) as string)

    /**
     * handle change old password
     * @param e
     */
    const handleOldPasswordChange = (e: any) => {
        setOldPasswordError(false)
        setTextOldPasswordError("")
        setOldPassword(e.target.value)
    }

    /**
     * handle change new password
     * @param e
     */
    const handleNewPasswordChange = (e: any) => {
        setNewPasswordError(false)
        setTextNewPasswordError("")
        setNewPassword(e.target.value)
    }

    /**
     * handle change re-new password
     * @param e
     */
    const handleReNewPasswordChange = (e: any) => {
        setReNewPasswordError(false)
        setTextReNewPassError("")
        setReNewPassword(e.target.value)
    }

    /**
     * handleClickShowOldPassword
     */
    const handleClickShowOldPassword = () => {
        setShowOldPassword(!showOldPassword)
    };

    /**
     * handleClickShowNewPassword
     */
    const handleClickShowNewPassword = () => {
        setShowNewPassword(!showNewPassword)
    }

    /**
     * handleClickShowReNewPassword
     */
    const handleClickShowReNewPassword = () => {
        setShowReNewPassword(!showReNewPassword)
    }

    /**
     * handle save
     */
    const handleSave = () => {
        if(!fnValidation()) {
            let data = {
                username: user?.username,
                oldPassword: oldPassword,
                newPassword: newPassword,
            }
            HttpDataService.update('changePassword', data).then((response: any) =>
            {
                if(response.data.status) {
                    // @ts-ignore
                    notify(response.data.message,{type: 'success'});
                } else {
                    // @ts-ignore
                    notify(response.data.message,{type: 'error'});
                }
            })
            .catch((e: Error) => {
                console.log(e);
            });
            //
            // fnResetFrm();
            // onCloseDialog(false);
            // refresh();

        }
    }

    const handleCloseDialog = () => {
        fnResetFrm()
        // onCloseDialog(false);
    }

    /**
     * fn validation form
     */
    function fnValidation() {
        let error = false
        if (oldPassword.trim().length === 0) {
            error = true
            setOldPasswordError(true)
            setTextOldPasswordError('Mật khẩu cũ bắt buộc nhập')
        }

        if (newPassword.trim().length === 0) {
            error = true
            setNewPasswordError(true)
            setTextNewPasswordError('Mật khẩu mới bắt buộc nhập')
        }

        if (reNewPassword.trim().length === 0) {
            error = true
            setReNewPasswordError(true)
            setTextReNewPassError('Nhập lại mật khẩu mới')
        }

        if(newPassword.trim().length>0 && reNewPassword.trim().length>0 &&
            newPassword!=reNewPassword
        ) {
            setReNewPasswordError(true)
            setTextReNewPassError('Mật khẩu không khớp')
        }
        return error;
    }

    function fnResetFrm() {
        // setName("");
        // setNameError(false);
        // setTextNameError("");
        // setStatus(null);
    }

    return (
        <Box className="header-channel-sales">
            <Box className={'box_header'}>
                <Typography className={'title_header'}>
                    {translate('resources.changePassword.name')}
                </Typography>
            </Box>
            <div className={`main_change_password`}>
                <div className={'box_change_password'}>
                    <TextField
                        fullWidth
                        label={
                            <span className={'span_style'}>
                                {translate('resources.changePassword.fields.old_password')}
                                <span style={{color: 'red'}}>(*)</span>
                             </span>
                        }
                        type={showOldPassword ? 'text' : 'password'}
                        value={oldPassword}
                        error={oldPasswordError}
                        helperText={textOldPasswordError}
                        onChange={handleOldPasswordChange}
                        variant="outlined"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowOldPassword}
                                    edge="end"
                                >
                                    {showOldPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>,
                        }}
                    />

                    <TextField
                        fullWidth
                        autoComplete="disabled"
                        label={
                            <span className={'span_style'}>
                                {translate('resources.changePassword.fields.new_password')}
                                <span style={{color: 'red'}}>(*)</span>
                             </span>
                        }
                        type={showNewPassword ? 'text' : 'password'}
                        value={newPassword}
                        error={newPasswordError}
                        helperText={textNewPasswordError}
                        onChange={handleNewPasswordChange}
                        variant="outlined"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowNewPassword}
                                    edge="end"
                                >
                                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>,
                        }}
                    />

                    <TextField
                        fullWidth
                        autoComplete="disabled"
                        label={
                            <span className={'span_style'}>
                                {translate('resources.changePassword.fields.re_enter_password')}
                                <span style={{color: 'red'}}>(*)</span>
                             </span>
                        }
                        type={showReNewPassword ? 'text' : 'password'}
                        value={reNewPassword}
                        error={reNewPasswordError}
                        helperText={textReNewPassError}
                        onChange={handleReNewPasswordChange}
                        variant="outlined"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowReNewPassword}
                                    edge="end"
                                >
                                    {showReNewPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>,
                        }}
                    />
                    <Button className={'btn_changePass'} variant="contained"
                            startIcon={<SaveIcon/>} onClick={handleSave}>{translate('resources.common.fields.update')}</Button>
                </div>
            </div>
        </Box>

    );
}