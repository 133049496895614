import AddIcon from "@mui/icons-material/Add";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import React, { useState } from "react";
import { FilterLiveSearch, useTranslate } from "react-admin";
import {
  Pagination,
  List,
  Datagrid,
  DatagridProps,
  useListContext,
  TextField,
  FunctionField,
} from "react-admin";
import { Box, Button, Chip, IconButton, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Form } from "react-final-form";
import PaginationCustom from "../component/PaginationCustom";
import { StreetAdd } from "./StreetAdd";
import { DeleteStreet } from "./StreetDelete";
import { StreetEdit } from "./StreeetEdit";
import { commonFunction } from "../component/Common";

const Item = styled(Paper)(({ theme }) => ({
  textAlign: "right",
}));

export const StreetList = (props: any) => {
  const PostPagination = (props: any) => <PaginationCustom {...props} />;
  const translate = useTranslate();
  return (
    <Box className="header-channel-sales">
      <Box className={"box_header"}>
        <Typography className={"title_header"}>{"Danh sách đường"}</Typography>
      </Box>
      <div className={`header-top-detail header-top-tdn`}>
        <List
          {...props}
          actions={false}
          empty={false}
          exporter={false}
          filters={<FilterAndActions />}
          sort={{ field: "", order: "" }}
          pagination={<PostPagination />}
          perPage={50}
        >
          <DepartmentDatagrid {...props} />
        </List>
      </div>
    </Box>
  );
};

interface ButtonProps {
  basePath?: string;
  label: string;
  record?: any;
}

const EditButton = (props: any) => {
  const [onCloseDialog, setOpenEditDialog] = useState(false);
  return (
    <>
      <IconButton onClick={() => setOpenEditDialog(true)}>
        <EditIcon className={"btn_edit"} />
      </IconButton>
      {onCloseDialog && (
        <StreetEdit
          {...props}
          openDialog={onCloseDialog}
          onCloseDialog={() => setOpenEditDialog(false)}
        />
      )}
    </>
  );
};

const DeleteButton = (props: any) => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  return (
    <>
      <IconButton onClick={() => setOpenDeleteDialog(true)}>
        <DeleteIcon className={"btn_del"} />
      </IconButton>
      <DeleteStreet
        openDialog={openDeleteDialog}
        onCloseDialog={() => setOpenDeleteDialog(false)}
      />
    </>
  );
};

const RowActions = ({ record }: ButtonProps) => {
  return (
    <Box className={"care_plan_rowActions"}>
      {commonFunction.checkPermission("STREETS_EDIT") && <EditButton />}
      {commonFunction.checkPermission("STREETS_DELETE") && <DeleteButton />}
    </Box>
  );
};
const FilterAndActions = (props: any) => {
  const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);

  return (
    <Box
      sx={{
        p: 1,
        m: 1,
        bgcolor: "background.paper",
        borderRadius: 1,
      }}
      className={"box_search"}
    >
      <Item className="shadow-none">
        <Form onSubmit={() => undefined}>
          {() => (
            <FilterLiveSearch
              variant="outlined"
              source="name"
              label="resources.common.fields.search"
            />
          )}
        </Form>
      </Item>
      {commonFunction.checkPermission("STREETS_ADD") && (
        <Item className="shadow-none">
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            color="primary"
            onClick={() => setOpenCreateDialog(true)}
          >
            Thêm mới
          </Button>
          <StreetAdd
            openDialog={openCreateDialog}
            onCloseDialog={() => setOpenCreateDialog(false)}
          />
        </Item>
      )}
    </Box>
  );
};

const DepartmentDatagrid = (props: DatagridProps) => {
  // @ts-ignore
  const { page, perPage } = useListContext();

  return (
    <>
      <div className={`list-data-tdn`}>
        <Datagrid
          optimized
          bulkActionButtons={false}
          className={`table-default criteria_type_datagrid table_department`}
        >
          <TextField source={"name"} label={"Tên đường"} />

          <FunctionField
            label={"resources.common.fields.status"}
            render={(record: any) => {
              if (record.status == 1) {
                return (
                  <Chip
                    label="Đang hoạt động"
                    color="success"
                    variant="outlined"
                  />
                );
              } else {
                return <Chip label="Khóa" color="error" variant="outlined" />;
              }
            }}
          />

          <RowActions label={"resources.common.fields.action"} />
        </Datagrid>
      </div>
    </>
  );
};
