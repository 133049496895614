import * as React from "react";
import { Card, CardHeader, CardContent, Box } from "@mui/material";
import { ResponsiveContainer } from "recharts";
import { BarChart, ChartContainer, BarPlot } from "@mui/x-charts";
import dayjs, { Dayjs } from "dayjs";

const ProjectBarChart = (props: any) => {
  const today = dayjs();
  const listProjectByDistrict = props?.data;

  const uData: any[] = listProjectByDistrict?.map((dt: any) => [
    dt?.total as Number,
  ]);
  const xLabels: any[] = listProjectByDistrict?.map((dt: any) => [
    dt?.name as Number,
  ]);
  return (
    <Card style={{ width: "100%" }}>
      <CardHeader title={"Thống kê dự án theo quận"}></CardHeader>
      <ResponsiveContainer>
        <CardContent
          style={{
            width: "100%",
            height: "300px",
            paddingTop: "0px",
            paddingBottom: "16px",
          }}
        >
          <BarChart
            margin={{ top: 10, right: 10, left: 30, bottom: 60 }}
            series={[{ data: uData, type: "bar", color: "#5e5ec3" }]}
            xAxis={[
              {
                interval: 0,
                scaleType: "band",
                data: xLabels,
                tickLabelStyle: {
                  angle: -45,
                  textAnchor: "end",
                  fontSize: 10,
                },
              } as Omit<any, "id">,
            ]}
          >
            <BarPlot />
          </BarChart>
        </CardContent>
      </ResponsiveContainer>
    </Card>
  );
};

export default ProjectBarChart;
