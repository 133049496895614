import { Autocomplete, Dialog, DialogContent, DialogTitle, IconButton, TextField } from "@mui/material";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import ReplyIcon from "@mui/icons-material/Reply";
import Toolbar from "@mui/material/Toolbar";
import { useNotify, useRefresh, useRecordContext, EditProps, useTranslate } from "react-admin";
import HttpDataService from "../../../../dataProvider/http.service";
import { createStyles, makeStyles } from "@material-ui/core/styles";

//Reformat Style
const styles = makeStyles((theme: any) =>
	createStyles({
		root: {
			"& .css-k4qjio-MuiFormHelperText-root.Mui-error": {
				position: "absolute",
				paddingTop: "41px",
			},
		},
	})
);
interface Add extends EditProps {
	openDialog: boolean;
	onCloseDialog: (openDialog: boolean) => void;
}

export const CreateHistoryCare = ({ openDialog, onCloseDialog, ...rest }: Add) => {
	const classes = styles();
	const [content, setContent] = useState<string>("");
	const translate = useTranslate();
	const [note, setNote] = useState<string>("");

	const handleContentChange = (e: any) => {
		setContent(e.target.value);
	};

	const refresh = useRefresh();
	const notify = useNotify();
	const record = useRecordContext();

	useEffect(() => {
		HttpDataService.findById("customer/getCustomer", record.id as string)
			.then((response: any) => {
				if (response.data.status) {
				} else {
					// @ts-ignore
					notify("Lỗi", { type: "error" });
				}
			})
			.catch((e: Error) => {
				console.log(e);
			});
	}, [record.id]);

	/**
	 * handle change name
	 */
	// const handleNoteChange = (e: any) => {
	//     setNoteError(false);
	//     setTextNoteError('');
	//     setNote(e.target.value);
	// };

	// const handleChangeStatus = (e: any) => {
	//     setStatus(e);
	// };

	/**
	 * handle save
	 */
	const handleSave = () => {
		let data = {
			customer_id: record.id,
			content_his: content.trim(),
		};
		HttpDataService.create("customer/careHistoryInsert", data)
			.then((response: any) => {
				if (response.data.status) {
					// @ts-ignore
					notify(response.data.message, { type: "success" });
					refresh();
				} else {
					// @ts-ignore
					notify(response.data.message, { type: "error" });
				}
			})
			.catch((e: Error) => {
				console.log(e);
			});
		onCloseDialog(false);
		refresh();
	};

	const handleCloseDialog = () => {
		onCloseDialog(false);
	};

	/**
   * fn validation form
//    */
	//   function fnValidation() {
	//       let error = false;
	//       if (name.trim().length === 0) {
	//           error = true;
	//           setNoteError(true);
	//       }
	//       return error;
	//   }
	// ``
	// function fnResetFrm() {
	//     setNote('');
	//     setNoteError(false);
	//     setStatus(null);
	// }

	return (
		<>
			<Dialog
				open={openDialog}
				fullWidth
				PaperProps={{
					style: {
						borderRadius: 2,
						// minWidth: '85%',
					},
				}}
			>
				<div className={"common__dialogTitle_area"}>
					<DialogTitle className={"member__dialogTitle"}>
						<div>Thêm lịch sử chăm sóc khách hàng</div>
					</DialogTitle>
					<div className={"common__dialogTitle_actions"}>
						<IconButton
							aria-label="exists"
							color={"error"}
							onClick={() => {
								onCloseDialog(false);
							}}
						>
							<CloseIcon />
						</IconButton>
					</div>
				</div>
				<Box className={"dialog_box"}>
					<DialogContent className={"dialog_content"}>
						<TextField
							className={`${classes.root} mb-23`}
							fullWidth
							label={<span className={"span_style"}>Nội dung</span>}
							value={content}
							variant="outlined"
							multiline
							maxRows={4}
							onChange={handleContentChange}
						/>
					</DialogContent>
				</Box>
				<Toolbar>
					<Box
						display="flex"
						justifyContent="center"
						width={"100%"}
					>
						<Button
							variant="contained"
							startIcon={<SaveIcon />}
							onClick={handleSave}
						>
							Lưu
						</Button>
						&nbsp;&nbsp;
						<Button
							startIcon={<ReplyIcon />}
							className={"button-close"}
							variant="contained"
							onClick={handleCloseDialog}
						>
							{translate("resources.common.fields.cancel")}
						</Button>
					</Box>
				</Toolbar>
			</Dialog>
		</>
	);
};
