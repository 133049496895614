import { CreateButton, CreateProps, useNotify, useRefresh, useTranslate } from "react-admin";
import { Dialog, DialogContent, DialogTitle, Grid, IconButton } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import ReplyIcon from "@mui/icons-material/Reply";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import HttpDataService from "../dataProvider/http.service";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { EditorWrapper } from "../projectEstate/dialogs/styledMui";
import Editor from "../component/Editor";
import ImagePreview from "../component/ImgPreview";
import CkeditorServer from "../component/CkeditorServer";
import { fnRemoveAllCkeditorInstances, fnSetDataCkeditor } from "../utils/fc";
import FileUpload from "../component/FileUpload";
import { styled } from "@mui/system";

const styles = makeStyles((theme: any) =>
	createStyles({
		root: {
			"& .css-k4qjio-MuiFormHelperText-root.Mui-error": {
				position: "absolute",
				paddingTop: "41px",
			},
		},
		box_image: {
			"& ": {
				border: "1px solid #ccc",
				padding: "10px",
				borderRadius: "10px",
				marginBottom: "10px",
				marginTop: "15px",
			},
		},
	})
);

export const InputRowWrapper = styled(Box)({
	display: "flex",
	gap: 10,
	widows: "100%",
});

export const ItemWrapper = styled("div")({
	padding: "0",
	width: "50%",
});

export const ItemWrapper30 = styled("div")({
	padding: "0",
	width: "33.33%",
});

export const StreetAdd = (props: any) => {
	const classes = styles();
	const { onCloseDialog, openDialog } = props;

	const translate = useTranslate();

	const dataStatus = [
		{ label: translate("resources.common.fields.active"), id: 1 },
		{ label: translate("resources.common.fields.block"), id: 0 },
	];

	const [name, setName] = useState<string>("");
	const [nameError, setNameError] = useState(false);

	const [textNameError, setTextNameError] = useState("");
	const [status, setStatus] = useState<any>(dataStatus[0]);
	const [content, setContent] = useState<string>("");
	const [image, setImage] = useState<any>();
	const [meta_title, setMetaTitle] = useState<string>("");
	const [meta_description, setMetaDescription] = useState<string>("");
	const [meta_keyword, setMetaKeyword] = useState<string>("");

	const refresh = useRefresh();
	const notify = useNotify();

	/**
	 * handle change name
	 * @param e
	 */
	const handleNameChange = (e: any) => {
		setNameError(false);
		setTextNameError("");
		setName(e.target.value);
	};

	const handleMetaTitleChange = (e: any) => {
		setMetaTitle(e.target.value);
	};
	const handleMetaDescriptionChange = (e: any) => {
		setMetaDescription(e.target.value);
	};
	const handleMetaKeywordChange = (e: any) => {
		setMetaKeyword(e.target.value);
	};

	const handleChangeStatus = (e: any) => {
		setStatus(e);
	};

	/**
	 * handle save
	 */
	const handleSave = async () => {
		if (!fnValidation()) {
			// let data = {
			//     name: name.trim(),
			//     status: status.id,
			//     content
			// }
			const submitObj = new FormData();
			submitObj.append("name", name);
			submitObj.append("status", status?.id);
			submitObj.append("content", content);
			submitObj.append("meta_title", meta_title);
			submitObj.append("meta_description", meta_description);
			submitObj.append("meta_keyword", meta_keyword);

			if (image) submitObj.append("image", image);

			await HttpDataService.create("streets/add-streets", submitObj)
				.then((response: any) => {
					if (response.data.status) {
						// @ts-ignore
						notify(response.data.message, { type: "success" });

						fnResetFrm();
						onCloseDialog();
						refresh();
					} else {
						if (response.data.errors) {
							setNameError(true);
							console.log(response.data.errors.name[0]);
							setTextNameError(response.data.errors.name[0]);
						}
					}
				})
				.catch((e: any) => {
					if (e.response.data.errors) {
						setNameError(true);
						console.log(e.response.data.errors.name[0]);
						setTextNameError(e.response.data.errors.name[0]);
					}
				});
		}
	};

	const handleCloseDialog = () => {
		fnResetFrm();
		onCloseDialog();
	};

	/**
	 * fn validation form
	 */
	function fnValidation() {
		let error = false;
		if (name.trim().length === 0) {
			error = true;
			setNameError(true);
			setTextNameError("Tên đường không được để trống!");
		}

		return error;
	}

	function fnResetFrm() {
		setName("");
		setNameError(false);
		setTextNameError("");
		setStatus(dataStatus[0]);
		setContent("");
		setImage(null);
		fnSetDataCkeditor("street_add", "");
		setMetaTitle("");
		setMetaKeyword("");
		setMetaDescription("");
	}

	return (
		<>
			<Dialog
				disableEnforceFocus
				maxWidth={"md"}
				open={openDialog}
				fullWidth
				PaperProps={{
					style: {
						borderRadius: 2,
					},
				}}
			>
				<div className={"common__dialogTitle_area"}>
					<DialogTitle className={"member__dialogTitle"}>
						<div>{"Thêm mới đường"}</div>
					</DialogTitle>
					<div className={"common__dialogTitle_actions"}>
						<IconButton
							aria-label="exists"
							color={"error"}
							onClick={() => {
								onCloseDialog(false);
								fnResetFrm();
							}}
						>
							<CloseIcon />
						</IconButton>
					</div>
				</div>
				<Box className={"dialog_box"}>
					<DialogContent className={"dialog_content"}>
						<InputRowWrapper>
							<TextField
								className={`${classes.root} mb-23`}
								fullWidth
								label={
									<span className={"span_style"}>
										{"Tên đường"}
										<span style={{ color: "red" }}>(*)</span>
									</span>
								}
								value={name}
								error={nameError}
								helperText={textNameError}
								onChange={handleNameChange}
								variant="outlined"
							/>
						</InputRowWrapper>

						<InputRowWrapper>
							<Autocomplete
								renderInput={(params) => (
									<TextField
										{...params}
										variant="outlined"
										label={
											<span className={"span_style"}>
												{translate("resources.common.fields.status")}
											</span>
										}
									/>
								)}
								sx={{ width: "100%" }}
								value={status}
								onChange={(event, value) => handleChangeStatus(value)}
								options={dataStatus}
							/>
						</InputRowWrapper>

						<InputRowWrapper>
							<ItemWrapper30>
								<TextField
									className={`${classes.root} mb-23`}
									fullWidth
									label={
										<span className={"span_style"}>
											{translate("resources.general.fields.meta_title")}
										</span>
									}
									value={meta_title}
									onChange={handleMetaTitleChange}
									variant="outlined"
								/>
							</ItemWrapper30>
							<ItemWrapper30>
								<TextField
									className={`${classes.root} mb-23`}
									fullWidth
									label={
										<span className={"span_style"}>
											{translate("resources.general.fields.meta_description")}
										</span>
									}
									value={meta_description}
									onChange={handleMetaDescriptionChange}
									variant="outlined"
								/>
							</ItemWrapper30>
							<ItemWrapper30>
								<TextField
									className={`${classes.root} mb-23`}
									fullWidth
									label={
										<span className={"span_style"}>
											{translate("resources.general.fields.meta_keyword")}
										</span>
									}
									value={meta_keyword}
									onChange={handleMetaKeywordChange}
									variant="outlined"
								/>
							</ItemWrapper30>
						</InputRowWrapper>

						<CkeditorServer
							name="street_add"
							onChangeData={(data) => {
								setContent(data);
							}}
						/>

						<div className={`${classes.box_image}`}>
							<div className={"title-image"}>Hình ảnh</div>
							<input
								type="file"
								accept=".png, .jpg, .jpeg"
								onChange={(e: any) => {
									setImage(e.target.files[0]);
									e.target.value = null;
								}}
							></input>
							{image && (
								<div>
									<ImagePreview
										src={URL.createObjectURL(image)}
										onRemove={() => setImage(null)}
									/>
								</div>
							)}
						</div>
					</DialogContent>
				</Box>
				<Toolbar>
					<Box
						display="flex"
						justifyContent="center"
						width={"100%"}
					>
						<Button
							variant="contained"
							startIcon={<SaveIcon />}
							onClick={handleSave}
						>
							{translate("resources.common.fields.add")}
						</Button>
						&nbsp;&nbsp;
						<Button
							startIcon={<ReplyIcon />}
							className={"button-close"}
							variant="contained"
							onClick={handleCloseDialog}
						>
							{translate("resources.common.fields.cancel")}
						</Button>
					</Box>
				</Toolbar>
			</Dialog>
		</>
	);
};
