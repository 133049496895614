import AddIcon from "@mui/icons-material/Add";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import React, { useState } from "react";
import { FilterLiveSearch, useTranslate } from "react-admin";
import { List, Datagrid, DatagridProps, TextField, FunctionField } from "react-admin";
import { Box, Button, Chip, IconButton, Typography, TextField as InputText } from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Form } from "react-final-form";
import { WageDelete } from "./WageDelete";
import { WageAdd } from "./WageAdd";
import { WageEdit } from "./WageEdit";
import { commonFunction } from "../component/Common";
import PaginationCustom from "../component/PaginationCustom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";

const WageContext = React.createContext<any>("");

const Item = styled(Paper)(({ theme }) => ({
	textAlign: "right",
}));

const FillterWrapper = styled(Box)({
	display: "flex",
	flexWrap: "wrap",
	gap: "0 15px",
	margin: "7px 15px",
});

const FillterItem = styled(Box)({
	width: 200,
});

export const WageList = (props: any) => {
	const PostPagination = (props: any) => <PaginationCustom {...props} />;
	const translate = useTranslate();
	const today = dayjs();
	const [dateContext, setDateContext] = useState<Dayjs | null>(today);

	return (
		<Box className="header-channel-sales">
			<Box className={"box_header"}>
				<Typography className={"title_header"}>{"Danh sách mức lương"}</Typography>
			</Box>
			<div className={`header-top-detail header-top-tdn`}>
				<WageContext.Provider
					value={{
						dateContext: [dateContext, setDateContext],
					}}
				>
					<List
						{...props}
						actions={false}
						empty={false}
						exporter={false}
						filters={<FilterAndActions />}
						filter={{
							date: dateContext ? dateContext.format("YYYY-MM") : undefined,
						}}
						filterDefaultValues={{
							date: "",
						}}
						sort={{ field: "", order: "" }}
						pagination={<PostPagination />}
						perPage={50}
					>
						<WageDatagrid {...props} />
					</List>
				</WageContext.Provider>
			</div>
		</Box>
	);
};

interface ButtonProps {
	basePath?: string;
	label: string;
	record?: any;
}

const EditButton = (props: any) => {
	const [onCloseDialog, setOpenEditDialog] = useState(false);
	return (
		<>
			<IconButton
				style={{ padding: "0px" }}
				onClick={() => setOpenEditDialog(true)}
			>
				<EditIcon className={"btn_edit"} />
			</IconButton>
			{onCloseDialog && (
				<WageEdit
					{...props}
					openDialog={onCloseDialog}
					onCloseDialog={() => setOpenEditDialog(false)}
				/>
			)}
		</>
	);
};

const DeleteButton = (props: any) => {
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	return (
		<>
			<IconButton
				style={{ padding: "0px" }}
				onClick={() => setOpenDeleteDialog(true)}
			>
				<DeleteIcon className={"btn_del"} />
			</IconButton>
			<WageDelete
				openDialog={openDeleteDialog}
				onCloseDialog={() => setOpenDeleteDialog(false)}
			/>
		</>
	);
};

const RowActions = ({ record }: ButtonProps) => {
	const per_edit = commonFunction.checkPermission("WAGE_UPDATE");
	const per_delete = commonFunction.checkPermission("WAGE_DELETE");
	return (
		<Box>
			{per_edit && <EditButton />}
			{per_delete && <DeleteButton />}
		</Box>
	);
};

const FilterAndActions = (props: any) => {
	const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);
	const translate = useTranslate();
	const { dateContext } = React.useContext(WageContext);
	const [date, setDate] = dateContext;

	return (
		<Box
			sx={{
				p: 1,
				m: 1,
				bgcolor: "background.paper",
				borderRadius: 1,
			}}
			className={"box_search"}
		>
			<Item className="shadow-none">
				<Form onSubmit={() => undefined}>
					{() => (
						<Box
							sx={{
								display: "flex",
								gap: "16px",
								alignItems: "center",
							}}
						>
							<FilterLiveSearch
								variant="outlined"
								source="name"
								label="resources.common.fields.search"
							/>
							<FillterItem>
								<LocalizationProvider
									dateAdapter={AdapterDayjs}
									adapterLocale="vi"
								>
									<DatePicker
										label="Tháng"
										inputFormat="MM/YYYY"
										value={date}
										views={["month", "year"]}
										onChange={(newValue) => setDate(newValue)}
										renderInput={(params) => (
											<InputText
												className="mb-23"
												{...params}
												fullWidth
												variant="outlined"
											/>
										)}
									/>
								</LocalizationProvider>
							</FillterItem>
						</Box>
					)}
				</Form>
			</Item>
			<Item className="shadow-none">
				{commonFunction.checkPermission("WAGE_ADD") && (
					<>
						<Button
							variant="outlined"
							startIcon={<AddIcon />}
							color="primary"
							onClick={() => setOpenCreateDialog(true)}
						>
							{translate("resources.common.fields.add")}
						</Button>
						{openCreateDialog && (
							<WageAdd
								openDialog={openCreateDialog}
								onCloseDialog={() => setOpenCreateDialog(false)}
							/>
						)}
					</>
				)}
			</Item>
		</Box>
	);
};

const WageDatagrid = (props: DatagridProps) => {
	// @ts-ignore
	const translate = useTranslate();
	return (
		<>
			<div className={`list-data-tdn`}>
				<Datagrid
					optimized
					bulkActionButtons={false}
				>
					<TextField
						source={"name"}
						label={"Tên"}
					/>
					<FunctionField
						label={"Doanh số"}
						render={(record: any) => {
							if (!record.min_sale || record.min_sale == 0) {
								return `Dưới ${record?.max_sale.toLocaleString("vi", {
									style: "decimal",
								})}`;
							}
							if (!record.max_sale || record.max_sale == 0) {
								return (
									<span style={{ textAlign: "right" }}>
										{`Trên ${record?.min_sale.toLocaleString("vi", {
											style: "decimal",
										})}`}
									</span>
								);
							} else {
								return `Từ ${record?.min_sale.toLocaleString("vi", {
									style: "decimal",
								})} -- ${record?.max_sale.toLocaleString("vi", {
									style: "decimal",
								})}`;
							}
						}}
					/>

					<FunctionField
						label={"Lương cơ bản"}
						render={(record: any) => {
							return record?.basic_salary
								? record?.basic_salary.toLocaleString("vi", {
										style: "decimal",
										currency: "VND",
								  })
								: "";
						}}
					/>
					<FunctionField
						label={"Khoảng"}
						render={(record: any) => {
							return record?.space
								? record?.space.toLocaleString("vi", {
										style: "decimal",
										currency: "VND",
								  })
								: "";
						}}
					/>
					<FunctionField
						label={"Lương KPI"}
						render={(record: any) => {
							return record?.kpi
								? record?.kpi.toLocaleString("vi", {
										style: "decimal",
										currency: "VND",
								  })
								: "";
						}}
					/>

					<TextField
						source={"rate"}
						label={"Tỉ lệ (%)"}
					/>
					<FunctionField
						label={"Thời gian tạo"}
						render={(record: any) => {
							return moment(record?.created_at).format("DD/MM/YYYY");
						}}
					/>
					<FunctionField
						label={"Tình trạng"}
						render={(record: any) => {
							if (record.status === 1) {
								return (
									<Chip
										label={"Hoạt động"}
										color="success"
										variant="outlined"
									/>
								);
							} else {
								return (
									<Chip
										label={"Khoá"}
										color="error"
										variant="outlined"
									/>
								);
							}
						}}
					/>
					<RowActions label={translate("resources.common.fields.action")} />
				</Datagrid>
			</div>
		</>
	);
};
