import axios from 'axios';
import { authService } from './dataProvider/auth-service';

const defaultHeaders = () => {
    const authorizationToken = authService.getToken();
    // console.log(authorizationToken)

    return {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${authorizationToken}`,
    };
};

export default axios.create({
    baseURL: process.env.REACT_APP_BASE_MODULE_URL,
    headers: defaultHeaders(),
});
