import {
  useNotify,
  useRecordContext,
  useRefresh,
  useTranslate,
} from "react-admin";
import { Button, DialogContent, TextField, Typography } from "@mui/material";

import { EditorWrapper } from "./styledMui";

import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Item from "antd/es/list/Item";
import SaveIcon from "@mui/icons-material/Save";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Editor from "../component/Editor";
import classnames from "classnames/bind";
import styleImage from "../users/User.module.scss";
import PageLoadingAPI from "../component/PageLoadingAPI";
import { Wrapper } from "../projectEstate/dialogs/styledMui";
import InputLabel from "../component/InputLabel";
import * as request from "../utils/httpRequest";
import Toolbar from "@mui/material/Toolbar";
import HttpDataService from "../dataProvider/http.service";
import ImagePreview from "../component/ImgPreview";
import Image from "../component/Image";
import PriceInput from "../component/PriceInput";

const styled = makeStyles((theme: any) =>
  createStyles({
    root: {
      "& .css-k4qjio-MuiFormHelperText-root.Mui-error": {
        position: "absolute",
        paddingTop: "41px",
      },
    },
  })
);

export const GeneralList = () => {
  const translate = useTranslate();
  const classes = styled();

  const [initialState, setInitialState] = React.useState<any>();

  //LOADING STATE
  const [loading, setLoading] = React.useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = React.useState<boolean>(false);

  //ERROR STATE
  const [nameError, setNameError] = useState(false);
  const [textNameError, setTextNameError] = useState("");

  const [emailError, setEmailError] = useState(false);
  const [textEmailError, setTextEmailError] = useState("");

  const [addressError, setAddressError] = useState(false);
  const [textAddressError, setTextAddressError] = useState("");

  const [phoneError, setPhoneError] = useState(false);
  const [textPhoneError, setTextPhoneError] = useState("");

  const [name, setName] = React.useState<any>();
  const [phone, setPhone] = React.useState<any>();
  const [address, setAddress] = React.useState<any>();
  const [email, setEmail] = React.useState<any>();

  // image about

  const cx = classnames.bind(styleImage);
  const [image, setImage] = React.useState<any[]>([]);
  const removeImageUtilities = (imgIndex: number) => {
    const newListImage = image.filter((_, index) => index !== imgIndex);
    if (image.length === 1) {
      setImage([]);
    }
    setImage(newListImage);
  };

  //logo
  const [logo, setLogo] = React.useState<any[]>([]);
  const removeImageUtilitiesLogo = (imgIndex: number) => {
    const newListLogo = logo.filter((_, index) => index !== imgIndex);
    if (logo.length === 1) {
      setLogo([]);
    }
    setLogo(newListLogo);
  };

  //favicon
  const [favicon, setFavicon] = React.useState<any[]>([]);
  const removeImageUtilitiesFavicon = (imgIndex: number) => {
    const newListFavicon = favicon.filter((_, index) => index !== imgIndex);
    if (favicon.length === 1) {
      setFavicon([]);
    }
    setFavicon(newListFavicon);
  };

  // banner
  const [banner, setBanner] = React.useState<any[]>([]);
  const removeImageUtilitiesBanner = (imgIndex: number) => {
    const newListBanner = banner.filter((_, index) => index !== imgIndex);
    if (banner.length === 1) {
      setBanner([]);
    }
    setBanner(newListBanner);
  };

  const notify = useNotify();
  const refresh = useRefresh();

  //CALL API
  React.useEffect(() => {
    setLoading(true);
    const fetchApi = async () => {
      const data = await request.get(`general/getData`);
      setInitialState(data.data);
      setLoading(false);
    };
    fetchApi();
  }, []);

  /**
   * fn validation form
   */
  function fnValidation() {
    let error = false;
    if (name.trim().length === 0) {
      error = true;
      setNameError(true);
      setTextNameError(translate("resources.general.fields.val_name"));
    }
    if (email.trim().length === 0) {
      error = true;
      setEmailError(true);
      setTextEmailError(translate("resources.user.fields.val_email"));
    }
    if (address.trim().length === 0) {
      error = true;
      setAddressError(true);
      setTextAddressError(translate("resources.general.fields.val_address"));
    }
    if (phone.trim().length === 0) {
      error = true;
      setPhoneError(true);
      setTextPhoneError(translate("resources.general.fields.val_phone"));
    }
    return error;
  }

  function fnResetFrm() {
    setName("");
    setNameError(false);
    setTextNameError("");
    setEmail("");
    setEmailError(false);
    setTextEmailError("");
    setPhone("");
    setPhoneError(false);
    setTextPhoneError("");
    setAddress("");
    setAddressError(false);
    setTextAddressError("");
  }

  const editGeneral = async (data: any) => {
    const submitObj = new FormData();
    console.log(Object.keys(data));
    Object.keys(data).map((key) => submitObj.append(key, data[key]));
    if (image) {
      image.map((item: any) => {
        submitObj.append("image", item);
      });
    }
    if (logo) {
      logo.map((item: any) => {
        submitObj.append("logo", item);
      });
    }
    if (favicon) {
      favicon.map((item: any) => {
        submitObj.append("favicon", item);
      });
    }
    if (banner) {
      banner.map((item: any) => {
        submitObj.append("banner", item);
      });
    }
    try {
      const id = submitObj.get("id");
      const response = await request.post(
        `general/editGeneral?id=${id}`,
        submitObj
      );
      return response.status;
    } catch (error) {
      console.log(error);
    }
  };
  /**
   * handle save
   */
  const handleSave = () => {
    // if (!fnValidation()) {
    let data = {
      id: initialState?.id,
      name: initialState?.name,
      email: initialState?.email,
      address: initialState?.address,
      phone: initialState?.phone,
      title_about: initialState?.title_about,
      content_about: initialState?.content_about,
      image: initialState?.image,
      gg_map: initialState?.gg_map,
      logo: initialState?.logo,
      favicon: initialState?.favicon,
      banner: initialState?.banner,
      meta_title: initialState?.meta_title,
      meta_description: initialState?.meta_description,
      meta_keyword: initialState?.meta_keyword,
      link_fb: initialState?.link_fb,
      link_mess: initialState?.link_mess,
      link_youtube: initialState?.link_youtube,
      dollar_rate: initialState?.dollar_rate
    };
    console.log(data, "data");

    const submit = async (data: any) => {
      const result = await editGeneral(data);
      console.log(data);
      if (result === 200) {
        notify(`Cập nhật thành công`, {
          type: "success",
          undoable: true,
        });
        refresh();
      } else {
        notify(`Cập nhật thất bại`, {
          type: "error",
          undoable: true,
        });
      }
    };
    submit(data);
    fnResetFrm();
    // }
  };

  return (
    <Box className="header-channel-sales">
      <Box className={"box_header"}>
        <Typography className={"title_header"}>Cài đặt chung</Typography>
      </Box>
      {loading ? (
        <PageLoadingAPI />
      ) : (
        <Wrapper>
          <div className={`header-top-detail header-top-tdn`}>
            <Box className={""}>
              <DialogContent className={"dialog_content"}>
                <p className={"title-add-user"}>Thông tin chung</p>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={4}>
                    <Item className={"item_li"}>
                      <TextField
                        className={classes.root}
                        disabled={submitLoading}
                        fullWidth
                        error={nameError}
                        helperText={textNameError}
                        variant="outlined"
                        label={
                          <InputLabel
                            title={translate("resources.general.fields.name")}
                            required
                          />
                        }
                        value={initialState?.name}
                        onChange={(e) =>
                          setInitialState({
                            ...initialState,
                            name: e.target.value,
                          })
                        }
                      />
                    </Item>
                    <Item className={"item_li"}>
                      <TextField
                        className={classes.root}
                        disabled={submitLoading}
                        fullWidth
                        multiline
                        maxRows={3}
                        error={addressError}
                        helperText={textAddressError}
                        variant="outlined"
                        label={
                          <InputLabel
                            title={translate(
                              "resources.general.fields.address"
                            )}
                            required
                          />
                        }
                        value={initialState?.address}
                        onChange={(e) =>
                          setInitialState({
                            ...initialState,
                            address: e.target.value,
                          })
                        }
                      />
                    </Item>
                    <Item className={"item_li"}>
                      <TextField
                        className={classes.root}
                        disabled={submitLoading}
                        fullWidth
                        error={phoneError}
                        helperText={textPhoneError}
                        variant="outlined"
                        label={
                          <InputLabel
                            title={translate("resources.general.fields.phone")}
                            required
                          />
                        }
                        value={initialState?.phone}
                        onChange={(e) =>
                          setInitialState({
                            ...initialState,
                            phone: e.target.value,
                          })
                        }
                      />
                    </Item>
                    <Item className={"item_li"}>
                      <TextField
                        className={classes.root}
                        disabled={submitLoading}
                        fullWidth
                        error={emailError}
                        helperText={textEmailError}
                        variant="outlined"
                        label={
                          <InputLabel
                            title={translate("resources.general.fields.email")}
                            required
                          />
                        }
                        value={initialState?.email}
                        onChange={(e) =>
                          setInitialState({
                            ...initialState,
                            email: e.target.value,
                          })
                        }
                      />
                    </Item>
                    <Item className={"item_li"}>
                    <PriceInput title="Tỉ giá $" val={initialState?.dollar_rate.toString() || ''} required={true} onChange={(e) => {
                      setInitialState({
                        ...initialState,
                        dollar_rate: e,
                      })
                      }}
                    />
                    </Item>
                  </Grid>
                  <Grid item xs={8}>
                    <Item className={"item_li"}>
                      <TextField
                        className={classes.root}
                        disabled={submitLoading}
                        fullWidth
                        multiline
                        maxRows={4}
                        label={
                          <InputLabel
                            title={translate(
                              "resources.general.fields.title_about"
                            )}
                          />
                        }
                        value={initialState?.title_about}
                        onChange={(e) =>
                          setInitialState({
                            ...initialState,
                            title_about: e.target.value,
                          })
                        }
                        variant="outlined"
                      />
                    </Item>
                    <Item className={"item_li"}>
                      <EditorWrapper className={"wrapper-editor"}>
                        <Editor
                          data={initialState?.content_about}
                          onChange={(event: any, editor: any) => {
                            const data = editor.getData();
                            setInitialState({
                              ...initialState,
                              content_about: data,
                            });
                          }}
                        />
                      </EditorWrapper>
                    </Item>
                    <Item className={"item_li_general"}>
                      <div className={"main-image-general"}>
                        <div className={cx("upload-image-wrapper")}>
                          <div className={"title-image"}>
                            Hình ảnh giới thiệu
                          </div>
                          <input
                            type="file"
                            accept=".png, .jpg, .jpeg"
                            onChange={(e: any) => {
                              setImage([...image, ...e.target.files]);
                              e.target.value = null;
                            }}
                          ></input>
                          {image?.length > 0 ? (
                            <div className={cx("image-show-wrapper")}>
                              {image.map((image: any, index: number) => (
                                <ImagePreview
                                  key={index}
                                  src={URL.createObjectURL(image)}
                                  onRemove={() => removeImageUtilities(index)}
                                />
                              ))}
                            </div>
                          ) : (
                            <Image
                              src={initialState?.image}
                              alt={""}
                              className={cx("img-user-edit")}
                            />
                          )}
                        </div>
                      </div>
                    </Item>
                    <Item className={"item_li"}>
                      <TextField
                        className={classes.root}
                        disabled={submitLoading}
                        fullWidth
                        multiline
                        maxRows={4}
                        label={
                          <InputLabel
                            title={translate("resources.general.fields.maps")}
                          />
                        }
                        value={initialState?.gg_map}
                        onChange={(e) =>
                          setInitialState({
                            ...initialState,
                            gg_map: e.target.value,
                          })
                        }
                        variant="outlined"
                      />
                    </Item>
                    <p className={"title-add-user"}>Công cụ tìm kiếm tối ưu</p>
                    <Item className={"item_li"}>
                      <TextField
                        className={classes.root}
                        disabled={submitLoading}
                        fullWidth
                        multiline
                        maxRows={4}
                        label={
                          <InputLabel
                            title={translate(
                              "resources.general.fields.meta_title"
                            )}
                          />
                        }
                        value={initialState?.meta_title}
                        onChange={(e) =>
                          setInitialState({
                            ...initialState,
                            meta_title: e.target.value,
                          })
                        }
                        variant="outlined"
                      />
                    </Item>
                    <Item className={"item_li"}>
                      <TextField
                        className={classes.root}
                        disabled={submitLoading}
                        fullWidth
                        multiline
                        maxRows={4}
                        label={
                          <InputLabel
                            title={translate(
                              "resources.general.fields.meta_description"
                            )}
                          />
                        }
                        value={initialState?.meta_description}
                        onChange={(e) =>
                          setInitialState({
                            ...initialState,
                            meta_description: e.target.value,
                          })
                        }
                        variant="outlined"
                      />
                    </Item>
                    <Item className={"item_li"}>
                      <TextField
                        className={classes.root}
                        disabled={submitLoading}
                        fullWidth
                        multiline
                        maxRows={4}
                        label={
                          <InputLabel
                            title={translate(
                              "resources.general.fields.meta_keyword"
                            )}
                          />
                        }
                        value={initialState?.meta_keyword}
                        onChange={(e) =>
                          setInitialState({
                            ...initialState,
                            meta_keyword: e.target.value,
                          })
                        }
                        variant="outlined"
                      />
                    </Item>
                  </Grid>
                </Grid>
                <p className={"title-add-user-general"}>
                  Logo
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    <Grid item xs={4}></Grid>
                    <Grid item xs={8}>
                      <Item className={"item_li_general"}>
                        <div className={"main-image-general"}>
                          <div className={cx("upload-image-wrapper")}>
                            <div className={"title-image"}>Logo</div>
                            <input
                              type="file"
                              accept=".png, .jpg, .jpeg"
                              onChange={(e: any) => {
                                setLogo([...e.target.files]);
                                e.target.value = null;
                              }}
                            ></input>
                            {logo?.length > 0 ? (
                              <div className={cx("image-show-wrapper")}>
                                {logo.map((logo: any, index: number) => (
                                  <ImagePreview
                                    key={index}
                                    src={URL.createObjectURL(logo)}
                                    onRemove={() =>
                                      removeImageUtilitiesLogo(index)
                                    }
                                  />
                                ))}
                              </div>
                            ) : (
                              <Image
                                src={initialState?.logo}
                                alt={""}
                                className={cx("img-user-edit")}
                              />
                            )}
                          </div>
                        </div>
                      </Item>
                      <Item className={"item_li_general"}>
                        <div className={"main-image-general"}>
                          <div className={cx("upload-image-wrapper")}>
                            <div className={"title-image"}>Favicon</div>
                            <input
                              type="file"
                              accept=".png, .jpg, .jpeg"
                              onChange={(e: any) => {
                                setFavicon([...e.target.files]);
                                e.target.value = null;
                              }}
                            ></input>
                            {favicon?.length > 0 ? (
                              <div className={cx("image-show-wrapper")}>
                                {favicon.map((favicon: any, index: number) => (
                                  <ImagePreview
                                    key={index}
                                    src={URL.createObjectURL(favicon)}
                                    onRemove={() =>
                                      removeImageUtilitiesFavicon(index)
                                    }
                                  />
                                ))}
                              </div>
                            ) : (
                              <Image
                                src={initialState?.favicon}
                                alt={""}
                                className={cx("img-user-edit")}
                              />
                            )}
                          </div>
                        </div>
                      </Item>

                      <Item className={"item_li_general"}>
                        <div className={"main-image-general"}>
                          <div className={cx("upload-image-wrapper")}>
                            <div className={"title-image"}>Banner</div>
                            <input
                              type="file"
                              accept=".png, .jpg, .jpeg"
                              onChange={(e: any) => {
                                setBanner([...e.target.files]);
                                e.target.value = null;
                              }}
                            ></input>
                            {banner?.length > 0 ? (
                              <div className={cx("image-show-wrapper")}>
                                {banner.map((banner: any, index: number) => (
                                  <ImagePreview
                                    key={index}
                                    src={URL.createObjectURL(banner)}
                                    onRemove={() =>
                                      removeImageUtilitiesBanner(index)
                                    }
                                  />
                                ))}
                              </div>
                            ) : (
                              <Image
                                src={initialState?.banner}
                                alt={""}
                                className={cx("img-user-edit")}
                              />
                            )}
                          </div>
                        </div>
                      </Item>
                    </Grid>
                  </Grid>
                </p>
                <p className={"title-add-user-general"}>Mạng xã hội</p>

                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={4}></Grid>
                  <Grid item xs={8}>
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      <Grid item xs={6}>
                        <TextField
                          className={classes.root}
                          disabled={submitLoading}
                          fullWidth
                          error={nameError}
                          helperText={textNameError}
                          variant="outlined"
                          label={<InputLabel title="Facebook" required />}
                          value={initialState?.link_fb}
                          onChange={(e) =>
                            setInitialState({
                              ...initialState,
                              link_fb: e.target.value,
                            })
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          className={classes.root}
                          disabled={submitLoading}
                          fullWidth
                          error={nameError}
                          helperText={textNameError}
                          variant="outlined"
                          label={<InputLabel title="Youtube" required />}
                          value={initialState?.link_youtube}
                          onChange={(e) =>
                            setInitialState({
                              ...initialState,
                              link_youtube: e.target.value,
                            })
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          className={classes.root}
                          disabled={submitLoading}
                          fullWidth
                          error={nameError}
                          helperText={textNameError}
                          variant="outlined"
                          label={<InputLabel title="Messenger" required />}
                          value={initialState?.link_mess}
                          onChange={(e) =>
                            setInitialState({
                              ...initialState,
                              link_mess: e.target.value,
                            })
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContent>
            </Box>
          </div>
          <Toolbar>
            <Box display="flex" justifyContent="center" width={"100%"}>
              <Button
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={handleSave}
              >
                {translate("resources.common.fields.update")}
              </Button>
              &nbsp;&nbsp;
            </Box>
          </Toolbar>
        </Wrapper>
      )}
    </Box>
  );
};

interface ButtonProps {
  basePath?: string;
  label: string;
  record?: any;
}
