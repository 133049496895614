import { constants } from "../shared/constants";
import { fetchUtils, Identifier } from "react-admin";
import axios from "axios";
import HttpDataService from "./http.service";

type LoginForm = {
	username: string;
	password: string;
};

type LoginResult = {
	token: string;
};

export type CustomUserIdentity = {
	id: Identifier;
	fullName?: string;
	avatar?: string;
	username?: string;
};

export default class AuthService {
	private userIdentity?: CustomUserIdentity;
	login2(form: LoginForm) {
		// console.log(`${constants.BASE_MODULE_URL}/login`);
		const { username, password } = form;
		return fetchUtils
			.fetchJson(`${constants.BASE_MODULE_URL}/login`, {
				method: "POST",
				body: JSON.stringify({ username, password }),
				headers: new Headers({
					"Content-Type": "application/json",
					LdapType: "normal",
				}),
			})
			.then((res) => {
				// console.log(res)
				const { token } = JSON.parse(res.body) as LoginResult;
				localStorage.setItem(constants.LOCAL_STORAGE.TOKEN, token);
			})
			.catch((error) => {
				// fallover call original fetch, because fetch-mock treats
				// any unmatched call as an error - its target is testing
				console.log(error);
			});
		// const request = new Request(`${constants.BASE_MODULE_URL}/login`, {
		//     method: 'POST',
		//     body: JSON.stringify({ username, password }),
		//     headers: new Headers({ 'Content-Type': 'application/json' }),
		// })
		// return fetch(request)
		//     .then(response => {
		//         if (response.status < 200 || response.status >= 300) {
		//             throw new Error(response.statusText);
		//         }
		//         return response.json();
		//     })
		//     .then(({ token }) => {
		//         localStorage.setItem('token', token);
		//     });
	}

	loginGoogle(token: any) {
		console.log(token);
		return axios
			.post(`${constants.BASE_MODULE_URL}/login_google`, {
				name: token.name,
				email: token.email,
			})
			.then((res) => {
				console.log(res);
				const token = res.data.data.access_token;
				const data = res.data.data;
				//const { token } = JSON.parse(res.body) as LoginResult;
				localStorage.setItem(constants.LOCAL_STORAGE.TOKEN, token);
				localStorage.setItem(constants.LOCAL_STORAGE.USER_IDENTITY, JSON.stringify(data.user));
				localStorage.setItem(constants.LOCAL_STORAGE.DOLLAR_RATE, JSON.stringify(data.dollar_rate));
				localStorage.setItem(constants.LOCAL_STORAGE.PERMISSION_API_LIST, JSON.stringify(data.permission));
			});
	}

	login(form: LoginForm) {
		return axios.post(`${constants.BASE_MODULE_URL}/login`, form).then((res) => {
			// console.log(res);
			const token = res.data.data.access_token;
			console.log(res);
			const data = res.data.data;
			//const { token } = JSON.parse(res.body) as LoginResult;
			localStorage.setItem(constants.LOCAL_STORAGE.TOKEN, token);
			localStorage.setItem(constants.LOCAL_STORAGE.USER_IDENTITY, JSON.stringify(data.user));
			localStorage.setItem(constants.LOCAL_STORAGE.DOLLAR_RATE, JSON.stringify(data.dollar_rate));
			localStorage.setItem(constants.LOCAL_STORAGE.PERMISSION_API_LIST, JSON.stringify(data.permission));
		});
	}
	logout() {
		localStorage.removeItem(constants.LOCAL_STORAGE.TOKEN);
		localStorage.removeItem(constants.LOCAL_STORAGE.USER_IDENTITY);
		localStorage.removeItem(constants.LOCAL_STORAGE.PERMISSION_API_LIST);
		localStorage.removeItem(constants.LOCAL_STORAGE.LIST_MENU);
		localStorage.removeItem(constants.LOCAL_STORAGE.DOLLAR_RATE);
	}

	isAuthenticated() {
		return localStorage.getItem(constants.LOCAL_STORAGE.TOKEN);
	}

	getToken(): string {
		// console.log(localStorage.getItem(constants.LOCAL_STORAGE.TOKEN));
		return localStorage.getItem(constants.LOCAL_STORAGE.TOKEN) || "";
	}

	getUserIdentity(): any {
		return Promise.resolve(localStorage.getItem(constants.LOCAL_STORAGE.USER_IDENTITY));
	}
}

export const authService = new AuthService();
