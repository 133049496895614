import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';

import DialogCustom from '../component/DialogCustom';
import DialogSubmitLoading from '../component/DialogSubmitLoading';
import InputPassword from '../component/InputPassword/InputPassword';

import { constants } from '../shared/constants';

//Services
import * as userProfileService from '../Services/userProfileService';
import { useNotify } from 'react-admin';

const Wrapper = styled(Box)({});

const InputWrapper = styled(Box)({
    padding: '20px 60px',
});

const Item = styled(Box)({
    padding: '5px 0',
});

type Props = {
    isOpen: boolean;
    onClose: () => void;
};

type ErrorType = {
    isError: boolean;
    message: string;
};

function ChangePassword({ isOpen, onClose }: Props) {
    //STATE
    const [oldPassword, setOldPassword] = React.useState<string>('');
    const [newPassword, setNewPassword] = React.useState<string>('');
    const [confirmPassword, setConfirmPassword] = React.useState<string>('');

    //SHOW PASSWORD
    const [showOldPass, setShowOldPass] = React.useState<boolean>(false);
    const [showNewPass, setShowNewPass] = React.useState<boolean>(false);
    const [showConfirmPass, setShowConfirmPass] = React.useState<boolean>(false);

    //ERROR STATE
    const [oldPassErr, setOldPassErr] = React.useState<ErrorType | null>(null);
    const [newPassErr, setNewPassErr] = React.useState<ErrorType | null>(null);
    const [confirmPassErr, setConfirmPassErr] = React.useState<ErrorType | null>(null);

    //LOADING
    const [submitLoading, setSubmitLoading] = React.useState<boolean>(false);

    //HOOKS
    const notify = useNotify();

    const resetError = () => {
        setOldPassErr(null);
        setNewPassErr(null);
        setConfirmPassErr(null);
    };

    const resetForm = () => {
        setOldPassword('');
        setNewPassword('');
        setConfirmPassword('');
    };

    React.useEffect(() => {
        if (!isOpen) {
            setSubmitLoading(false);
            resetError();
            resetForm();
        }
    }, [isOpen]);

    const handleSubmit = () => {
        setSubmitLoading(true);
        let pass = true;
        resetError();
        if (!oldPassword) {
            pass = false;
            setSubmitLoading(false);
            setOldPassErr({
                isError: true,
                message: 'Mật khẩu cũ không được để trống!',
            });
        }
        if (!newPassword) {
            pass = false;
            setSubmitLoading(false);
            setNewPassErr({
                isError: true,
                message: 'Mật khẩu mới không được để trống!',
            });
        }
        if (!confirmPassword) {
            pass = false;
            setSubmitLoading(false);
            setConfirmPassErr({
                isError: true,
                message: 'Bạn vui lòng nhập lại mật khẩu!',
            });
        } else if (newPassword && newPassword != confirmPassword) {
            pass = false;
            setSubmitLoading(false);
            setConfirmPassErr({
                isError: true,
                message: 'Mật khẩu không khớp. Hãy thử lại!',
            });
        } else {
            setConfirmPassErr(null);
        }

        if (pass && !submitLoading) {
            const user = JSON.parse(
                localStorage.getItem(constants.LOCAL_STORAGE.USER_IDENTITY) as string,
            );
            const submitObj = {
                username: user?.username,
                oldPassword: oldPassword,
                newPassword: newPassword,
            };

            const fetchApi = async () => {
                const status = await userProfileService.changePassword(submitObj);

                if (status === 200) {
                    notify(`Đổi mật khẩu thành công`, {
                        type: 'success',
                        undoable: true,
                    });
                    setSubmitLoading(false);
                    resetForm();
                    onClose();
                } else if (status == 401) {
                    setOldPassErr({
                        isError: true,
                        message: 'Mật khẩu cũ không đúng',
                    });
                    notify(`Mật khẩu cũ không đúng. Hãy thử lại!`, {
                        type: 'error',
                        undoable: true,
                    });
                    setSubmitLoading(false);
                } else {
                    notify(`Đổi mật khẩu thất bại!`, {
                        type: 'error',
                        undoable: true,
                    });
                    setSubmitLoading(false);
                }
            };
            fetchApi();
        }
    };

    return (
        <DialogCustom
            title="Đổi mật khẩu"
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={handleSubmit}
            minHeight={300}
        >
            <Wrapper>
                {submitLoading && <DialogSubmitLoading />}
                <InputWrapper>
                    <Item>
                        <InputPassword
                            error={oldPassErr}
                            label="Mật khẩu cũ"
                            showPassword={showOldPass}
                            onShow={() => {
                                setShowOldPass((prev) => !prev);
                            }}
                            value={oldPassword}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (!value.startsWith(' ')) {
                                    setOldPassword(value);
                                }
                            }}
                        />
                    </Item>
                    <Item>
                        <InputPassword
                            error={newPassErr}
                            label="Mật khẩu mới"
                            showPassword={showNewPass}
                            onShow={() => {
                                setShowNewPass((prev) => !prev);
                            }}
                            value={newPassword}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (!value.startsWith(' ')) {
                                    setNewPassword(value);
                                }
                            }}
                        />
                    </Item>
                    <Item>
                        <InputPassword
                            error={confirmPassErr}
                            label="Nhập lại mật khẩu "
                            showPassword={showConfirmPass}
                            onShow={() => {
                                setShowConfirmPass((prev) => !prev);
                            }}
                            value={confirmPassword}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (!value.startsWith(' ')) {
                                    setConfirmPassword(value);
                                }
                            }}
                        />
                    </Item>
                </InputWrapper>
            </Wrapper>
        </DialogCustom>
    );
}

export default React.memo(ChangePassword);
