import {
  CreateButton,
  CreateProps,
  useNotify,
  useRefresh,
  useTranslate,
} from "react-admin";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
} from "@mui/material";
import {
  Wrapper,
  InputRowWrapper,
  ItemWrapper,
  EditorWrapper,
} from "./styledMui";
import Editor from "../component/Editor";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import React, { useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import ReplyIcon from "@mui/icons-material/Reply";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import HttpDataService from "../dataProvider/http.service";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import ImagePreview from "../component/ImgPreview";

//Reformat Style
const styles = makeStyles((theme: any) =>
  createStyles({
    root: {
      "& .css-k4qjio-MuiFormHelperText-root.Mui-error": {
        position: "absolute",
        paddingTop: "41px",
      },
      "& .css-1z10yd4-MuiFormControl-root-MuiTextField-root": {
        marginTop: 20,
        marginBottom: 20,
      },
    },
  })
);

export const VideoAdd = (props: any) => {
  const classes = styles();
  const { onCloseDialog, openDialog } = props;
  const [name, setName] = useState<string>("");
  const [note, setNote] = useState<string>("");
  const [link, setLink] = useState<string>("");

  //avatar
  const [image, setImage] = React.useState<any[]>([]);
  // checker

  const [nameError, setNameError] = useState(false);
  const [linkError, setLinkError] = useState(false);
  const [textNameError, setTextNameError] = useState("");
  const [textLinkError, setTextLinkError] = useState("");
  const translate = useTranslate();
  const dataStatus = [
    { label: translate("resources.common.fields.active"), id: 1 },
    { label: translate("resources.common.fields.block"), id: 0 },
  ];
  const [status, setStatus] = useState<any>(dataStatus[1]);
  const refresh = useRefresh();
  const notify = useNotify();

  /**
   * handle change name
   * @param e
   */
  const handleNameChange = (e: any) => {
    setNameError(false);
    setTextNameError("");
    setName(e.target.value);
  };

  const handleLinkChange = (e: any) => {
    setLink(e.target.value);
  };
  const handleNoteChange = (e: any) => {
    setNote(e.target.value);
  };
  /**
   * handle save
   */

  const handleSave = async () => {
    if (!fnValidation()) {
    let data = {
      name: name.trim(),
      note: note,
      link: link,
      status: 0,
    };

    // const submitObj = new FormData();
    // //avatar
    // if (image) {
    //   image.map((item: any) => {
    //     submitObj.append("image", item);
    //   });
    //   console.log(image, "image");
    // }

    // Object.keys(data).map(
    //   (key) =>
    //     // @ts-ignore
    //     submitObj.append(key, data[key]),
    //   console.log(submitObj, "submitObj")
    // );
    console.log(data)
    await HttpDataService.create("video/add", data)
      .then((response: any) => {
        if (response.data.status) {
          console.log(response.data, "response");
          // @ts-ignore
          notify(response.data.message, { type: "success" });
        } else {
          // @ts-ignore
          notify(response.data.message, { type: "error" });
        }
      })
      .catch((e: Error) => {
        console.log(e);
      });

    fnResetFrm();
    onCloseDialog();
    refresh();
    }
  };

  const handleCloseDialog = () => {
    fnResetFrm();
    onCloseDialog();
  };

  /**
   * fn validation form
   */
  function fnValidation() {
    let error = false;
    if (name.trim().length === 0) {
      error = true;
      setNameError(true);
      setTextNameError(translate("resources.news.fields.val_name"));
    }
    if (link.trim().length === 0) {
      error = true;
      setLinkError(true);
      setTextLinkError("Đường dẫn không được để trống");
    } 
    if (!link.includes(".")) {
      error = true;
      setLinkError(true);
      setTextLinkError("Định dạng đường dẫn không đúng");
    }
    return error;
  }

  function fnResetFrm() {
    setName("");
    setNameError(false);
    setTextNameError("");
    setNote("");
    setStatus(null);
  }

  return (
    <>
      <Dialog
        open={openDialog}
        fullWidth
        disableEnforceFocus
        PaperProps={{
          style: {
            borderRadius: 2,
            minWidth: "35%",
          },
        }}
      >
        <div className={"common__dialogTitle_area"}>
          <DialogTitle className={"member__dialogTitle"}>
            <div>{"Thêm mới video"}</div>
          </DialogTitle>
          <div className={"common__dialogTitle_actions"}>
            <IconButton
              aria-label="exists"
              color={"error"}
              onClick={() => {
                onCloseDialog(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <Box className={"dialog_box"}>
          <DialogContent className={"dialog_content"}>
            <Grid item xs={8}>
              <TextField
                className={`${classes.root} mb-23`}
                fullWidth
                label={
                  <span className={"span_style"}>
                    {translate("resources.news.fields.title")}
                    <span style={{ color: "red" }}>(*)</span>
                  </span>
                }
                value={name}
                error={nameError}
                helperText={textNameError}
                onChange={handleNameChange}
                variant="outlined"
              />

              <TextField
                className={`${classes.root} mb-23`}
                fullWidth
                label={
                  <span className={"span_style"}>
                    {translate("resources.news.fields.slug")}
                    <span style={{ color: "red" }}>(*)</span>
                  </span>
                }
                value={link}
                error={linkError}
                helperText={textLinkError}
                onChange={handleLinkChange}
                variant="outlined"
              />
              <TextField
                className={`${classes.root} mb-23`}
                fullWidth
                label={
                  <span className={"span_style"}>
                    {"Note"}
                  </span>
                }
                minRows={3}
                multiline
                value={note}
                onChange={handleNoteChange}
                variant="outlined"
              />
              {/* <EditorWrapper className={"editor-news"}>
                <Editor
                  data={content}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setContent(data);
                  }}
                />
              </EditorWrapper> */}
            </Grid>
          </DialogContent>
        </Box>
        <Toolbar>
          <Box display="flex" justifyContent="center" width={"100%"}>
            <Button
              variant="contained"
              startIcon={<SaveIcon />}
              onClick={handleSave}
            >
              {translate("resources.common.fields.add")}
            </Button>
            &nbsp;&nbsp;
            <Button
              startIcon={<ReplyIcon />}
              className={"button-close"}
              variant="contained"
              onClick={handleCloseDialog}
            >
              {translate("resources.common.fields.cancel")}
            </Button>
          </Box>
        </Toolbar>
      </Dialog>
    </>
  );
};
