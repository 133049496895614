// import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import { Card, CardHeader, CardContent } from "@mui/material";
import { ResponsiveContainer } from "recharts";
import { useMediaQuery, Theme } from "@mui/material";

const ProjectPieChart = (props: any) => {
  const dataResult = props.data;
  const data: any = dataResult.map((dt: any, key: any) => ({
    key: key,
    value: dt.total,
    label: dt.status,
  }));
 
  const color = ["#02b2af", "#5e5ec3", "#2e96ff"];
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));
  const size = {
    height: isXSmall ? 170 : 200,
  };
  return (
    <Card>
      <CardHeader title={"Thống kê dự án theo trạng thái"} />
      <ResponsiveContainer>
        <CardContent style={{ width: "100%" }}>
          <PieChart
            colors={color}
            series={[
              {
                arcLabel: (item: any) => `${item?.value}`,
                arcLabelMinAngle: 10,
                cx: isXSmall ? 80 : ( isSmall ? 90 : 120),
                outerRadius: isXSmall ? 80 : ( isSmall ? 80 : 100),
                data,
              },
            ]}
            sx={{
              [`& .${pieArcLabelClasses.root}`]: {
                fill: "white",
                fontWeight: "thin",
              },
            }}
            slotProps={{
              legend: {
                direction: "column",
                position: { vertical: "middle", horizontal: "right" },
                padding: isXSmall ? 10 : 0,
                labelStyle: {
                  fontSize: isXSmall ? 12 : 15,
                },
              },
            }}
            {...size}
          />
        </CardContent>
      </ResponsiveContainer>
    </Card>
  );
};

export default ProjectPieChart;
