import React, { useEffect, useState } from "react";
import {
	Dialog,
	DialogTitle,
	Button,
	Stack,
	Box,
	Typography,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Chip,
} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { styled } from "@mui/system";
import { useNotify, useRecordContext, useRefresh } from "react-admin";
import HttpDataService from "../../../dataProvider/http.service";

// call api
import * as request from "../../../utils/httpRequest";

import * as projectService from "../../../Services/projectService";
import DialogSubmitLoading from "../../../component/DialogSubmitLoading";

const Wrapper = styled(Box)({});

const HeaderWrapper = styled(Box)({
	backgroundColor: "whitesmoke",
});

const ContentWrapper = styled(Box)({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	padding: "30px 0",
});

const WarningText = styled(Typography)({
	color: "#ed6c02",
	fontSize: "1.5rem",
});

const ButtonWrapper = styled(Box)({
	display: "flex",
	justifyContent: "flex-end",
	backgroundColor: "whitesmoke",
	padding: 20,
});

type Props = {
	isOpen: boolean;
	onClose: () => void;
};

function ProjectFloor({ isOpen, onClose }: Props) {
	//LOADING STATE
	const [submitLoading, setSubmitLoading] = React.useState<boolean>(false);
	const [dataFloor, setDataFloor] = useState<any>([]);

	//HOOKS
	const record = useRecordContext();

	useEffect(() => {
		const fetchAPI = async () => {
			await HttpDataService.findById("project_estate/getFloor", record?.id.toString())
				.then((response: any) => {
					if (response.data.data) {
						setDataFloor(response.data.data);
					}
				})
				.catch((e: Error) => {
					console.log(e);
				});
		};

		fetchAPI();
	}, []);

	return (
		<Dialog
			open={isOpen}
			onClose={onClose}
			maxWidth={"xl"}
		>
			<Wrapper>
				{submitLoading ? <DialogSubmitLoading /> : <></>}
				<HeaderWrapper>
					<DialogTitle>Danh sách tầng</DialogTitle>
				</HeaderWrapper>
				<ContentWrapper>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>{"Tên tầng"}</TableCell>
								<TableCell>{"Diện tích"}</TableCell>
								<TableCell>{"Giá"}</TableCell>
								<TableCell>{"Phí dịch vụ"}</TableCell>
								<TableCell>{"Trạng thái"}</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{dataFloor?.map((item: any) => (
								<TableRow>
									<TableCell>{item?.name}</TableCell>
									<TableCell>{item?.area}</TableCell>
									<TableCell>{item?.price}</TableCell>
									<TableCell>{item?.service}</TableCell>
									<TableCell>
										{item.status == 1 ? (
											<Chip
												label="Chưa cho thuê"
												color="success"
												variant="outlined"
											/>
										) : (
											<Chip
												label="Đã cho thuê"
												color="error"
												variant="outlined"
											/>
										)}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</ContentWrapper>
				<ButtonWrapper>
					<Stack
						direction="row"
						spacing={2}
					>
						<Button
							variant="outlined"
							color="error"
							onClick={onClose}
						>
							Đóng
						</Button>
					</Stack>
				</ButtonWrapper>
			</Wrapper>
		</Dialog>
	);
}

export default React.memo(ProjectFloor);
