import React from 'react';
import { Chip } from '@mui/material';

type Props = {
    label: string;
    id: number;
};

function ChipCustom({ label, id }: Props) {
    const [color, setColor] = React.useState<
        | 'default'
        | 'primary'
        | 'secondary'
        | 'error'
        | 'info'
        | 'success'
        | 'warning'
        | undefined
    >();

    const [variant, setVariant] = React.useState<'filled' | 'outlined' | undefined>(
        'filled',
    );

    React.useEffect(() => {
        if (id == 1) {
            setColor('default');
            setVariant('outlined');
        } else if (id == 2) {
            setVariant('filled');
            setColor('error');
        } else if (id == 3) {
            setVariant('filled');
            setColor('warning');
        } else if (id == 4) {
            setVariant('filled');
            setColor('primary');
        } else if (id == 5) {
            setVariant('filled');
            setColor('success');
        } else if (id == 6) {
            setVariant('filled');
            setColor('default');
        }
    }, [id]);

    return <Chip color={color} variant={variant} label={label} />;
}
export default ChipCustom;
