import React from 'react';
import classNames from 'classnames/bind';
import styles from './Table.module.scss';

import { TableContainer, Table, TableBody, TableHead } from '@mui/material';
import MuiTableCell from '@mui/material/TableCell';
import MuiTableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/system';
import { currencyFormatter } from '../../../utils/convertData';
import { MUC_DICH_SD, TRANG_THAI } from '../../../shared/status';
import ChipCustom from '../ChipCustom';

const TableRow = styled(MuiTableRow)({
    '& td:last-child, & th:last-child': { borderRight: 0 },
});

const TableCell = styled(MuiTableCell)({});
const PrimaryText = styled('span')({
    fontSize: '0.5rem',
});
const HeaderText = styled(PrimaryText)({
    fontSize: '1rem',
});

type Props = {
    data: any[];
    deleteItem?: any;
};

const cx = classNames.bind(styles);

function ProjectTable({ data, deleteItem }: Props) {
    return (
        <div className={cx('wrapper')}>
            <div className={cx('table')}>
                <TableContainer>
                    <Table aria-label="simple-table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="right">
                                    <HeaderText>STT</HeaderText>
                                </TableCell>
                                <TableCell>
                                    <HeaderText>Mã lô</HeaderText>
                                </TableCell>
                                <TableCell>
                                    <HeaderText>Mặt tiền (M)</HeaderText>
                                </TableCell>
                                <TableCell>
                                    <HeaderText>Mặt đường (M)</HeaderText>
                                </TableCell>
                                <TableCell>
                                    <HeaderText>Chiều sâu (M)</HeaderText>
                                </TableCell>
                                <TableCell>
                                    <HeaderText>Diện tích (M2)</HeaderText>
                                </TableCell>
                                <TableCell>
                                    <HeaderText>Mục đích sử dụng đất</HeaderText>
                                </TableCell>
                                <TableCell>
                                    <HeaderText>Giá trị chuyển nhượng</HeaderText>
                                </TableCell>
                                <TableCell>
                                    <HeaderText>Ghi nhận</HeaderText>
                                </TableCell>
                                <TableCell>
                                    <HeaderText>Trạng thái</HeaderText>
                                </TableCell>
                                {deleteItem && (
                                    <TableCell>
                                        <HeaderText></HeaderText>
                                    </TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell align="center">{index + 1}</TableCell>
                                    <TableCell>
                                        {item?.ma_lo ? item?.ma_lo : item?.code}
                                    </TableCell>
                                    <TableCell>
                                        {item?.mat_tien
                                            ? item?.mat_tien
                                            : item?.land_frontage}
                                    </TableCell>
                                    <TableCell>
                                        {item?.mat_duong
                                            ? item?.mat_duong
                                            : item?.road_surface}
                                    </TableCell>
                                    <TableCell>
                                        {item?.chieu_sau ? item?.chieu_sau : item?.depth}
                                    </TableCell>
                                    <TableCell>
                                        {item?.dien_tich ? item?.dien_tich : item?.area}
                                    </TableCell>
                                    <TableCell>
                                        {item?.muc_dich?.label
                                            ? item?.muc_dich?.label
                                            : MUC_DICH_SD.find(
                                                  (data: any) => data.id == item?.uses,
                                              )?.label}
                                    </TableCell>
                                    <TableCell>
                                        {currencyFormatter.format(
                                            item?.gia_tri ? item?.gia_tri : item?.price,
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {item?.ghi_nhan ? item?.ghi_nhan : item?.note}
                                    </TableCell>

                                    <TableCell>
                                        {item?.trang_thai?.label ? (
                                            <ChipCustom
                                                id={item?.trang_thai?.id}
                                                label={item?.trang_thai.label}
                                            />
                                        ) : (
                                            <ChipCustom
                                                id={item.status}
                                                label={String(
                                                    TRANG_THAI.find(
                                                        (data: any) =>
                                                            data.id == item?.status,
                                                    )?.label,
                                                )}
                                            />
                                        )}
                                    </TableCell>
                                    {deleteItem && (
                                        <TableCell>
                                            <IconButton
                                                aria-label="delete"
                                                onClick={() => deleteItem(index)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
}
export default React.memo(ProjectTable);
