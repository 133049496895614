import request from "../utils/httpRequest";

export const getCustomerSources = async () => {
    try {
        const response = await request.get('customer_source/getAll');
        return response.data.data;
    } catch (error) {
        console.log(error);
    }
};

export const getAllCustomerSources = async () => {
    try {
        const response = await request.get('customer_source/selectCustomerSource');
        return response.data.data;
    } catch (error) {
        console.log(error);
    }
};


export const getAllCustomerSourcesType = async () => {
    try {
        const response = await request.get('customer_source_type/selectSourceType');
        return response.data.data;
    } catch (error) {
        console.log(error);
    }
};

export const getParentSource = async () => {
    try {
        const response = await request.get('customer_source/getParent');
        return response.data.data;
    } catch (error) {
        console.log(error);
    }
};


export const getOneCustomerSources = async (id:any) => {
    try {
        const response = await request.get(`customer_source/getSource/${id}`);
        return response.data.data;
    } catch (error) {
        console.log(error);
    }
};
export const updateCustomerSource = async (data: any) => {
    try {
      const response = await request.post(`customer_source/updateSource`, data);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error; 
    }
  };
export const deleteCustomerSource = async (id: number) => {
    try {
        const response = await request.delete(`customer_source/deleteSource/${id}`);
        return response.data;
    } catch (error) {
        console.log(error);
    }
}