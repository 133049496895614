import * as request from '../utils/httpRequest';

export const getListParts = async () => {
    try {
        const response = await request.get('parts/getAllParts');
        return response.data;
    } catch (error) {
        console.log(error);
    }
};
