import React from "react";
import { Autocomplete, Box, TextField } from "@mui/material";

import DialogForm from "../../../../component/DialogForm";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import InputLabel from "../../../../component/InputLabel";
import { addCustomer, getOneCustomer, updateCustomer } from "../../../../Services/customerService";
import { useNotify, useRecordContext, useRefresh, useTranslate } from "react-admin";
import { STATUS } from "../../../../shared/status";
import PageLoadingAPI from "../../../../component/PageLoadingAPI";
import DialogFormCustomer from "../../../../component/DialogFormCustomer";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { InputRowWrapper, ItemWrapper, dataStatus } from "../customercreate/index";
import { useState } from "react";
import { commonFunction } from "../../../../component/Common";

import * as districtService from "../../../../Services/districtsService";
import * as request from "../../../../utils/httpRequest";
import LimitTags from "../../components/LimitTags";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import ImagePreview from "../../../../component/ImgPreview";

const styled = makeStyles((theme: any) =>
	createStyles({
		root: {
			"& .css-k4qjio-MuiFormHelperText-root.Mui-error": {
				position: "absolute",
				paddingTop: "41px",
			},
		},
		box_image: {
			"& ": {
				border: "1px solid #ccc",
				padding: "10px",
				borderRadius: "10px",
				marginBottom: "10px",
				marginTop: "15px",
			},
		},
	})
);

type ErrorTypes = {
	isError: boolean;
	message: String;
};
type Props = {
	isOpen: boolean;
	onClose: () => void;
};

type InittialState = {
	name: string;
	acreage: string;
	contact: string;
	other_request: string;
	finance: string;
	status: any;
	source: any;
	district: any;
	project_type_id: any;
	assigner: any;
	person_charge: any;
};

// const idToName: any = (
//   list: any,
//   id: any,
//   listFormat: any,
//   fieldName: any
// ): any => {
//   const labela = list.find((itm: any) => itm.id == listFormat.fieldName);
//   return <span>{labela?.name}</span>;
// };

function EditCustomer({ isOpen, onClose }: Props) {
	const [listProjectTypeEstate, setListProjectTypeEstate] = useState<any[]>([]);
	const [listSourceState, setListSource] = useState<any[]>([]);
	const [listDistrict, setListDistrict] = useState<any[]>([]);
	const [listPersonCharge, setListPersonCharge] = useState<any[]>([]);
	const [listAssigner, setListAssigner] = useState<any[]>([]);
	const [pageLoading, setPageLoading] = React.useState<boolean>(false);
	const classes = styled();
	const { id } = useRecordContext();
	const notify = useNotify();
	const refresh = useRefresh();
	const [loading, setLoading] = React.useState(false);
	const {
		register,
		reset,
		handleSubmit,
		formState: { errors },
		control,
	} = useForm<InittialState>();
	const [projectErr, setProjectErr] = React.useState<ErrorTypes | null>();

	const [nameChange, setNameChange] = useState<string>("");
	const [projectTypeChange, setProjectTypeChange] = useState<any>("");
	const [sourceChange, setSourceChange] = useState<any>("");
	const [assignerChange, setAssignerChange] = useState<any>("");
	const [person_chargeChange, setPerson_chargeChange] = useState<any>();

	const [districtChange, setDistrictChange] = useState<any[]>([]);
	const [financeChange, setFinanceChange] = useState<string>("");
	const [acreageChange, setAcreageChange] = useState<string>("");
	const [contactChange, setContactChange] = useState<string>("");
	const [ortherChange, setOrtherChange] = useState<string>("");
	const [imageExist, setImageExist] = useState<any>();
	const [imageNew, setImageNew] = useState<any>();
	const [timeTransfer, setTimeTransfer] = useState<any>();

	// const [statusChange, setStatusChange] = useState<any>();
	const [initialState, setInitialState] = React.useState<any>();

	React.useEffect(() => {
		if (isOpen) {
			setLoading(true);
			const fetchAPI = async () => {
				// const listProjectTypeResult =
				//   await projectTypeService.getListProjectType();
				const listsourceResult = await request.get("customer_source/selectCustomerSource");
				const listAssignerResult = await request.get("users/selectUserByType?type=2");
				const listDistrictResult = await districtService.getDistricts();
				if (listDistrictResult && listsourceResult && listAssignerResult) {
					setListSource(listsourceResult?.data);
					setListDistrict(listDistrictResult);
					setListAssigner(listAssignerResult?.data);
				}
			};
			fetchAPI();
		}
	}, [isOpen]);

	React.useEffect(() => {
		if (listDistrict.length > 0 && listSourceState.length > 0 && listAssigner.length > 0) {
			const fetchAPI = async () => {
				const { data } = await getOneCustomer(id);
				setInitialState(data);
				if (data) {
					setNameChange(data.name);
					setAcreageChange(data.acreage || 0);
					setContactChange(data.contact);
					setOrtherChange(data.other_request);
					setFinanceChange(data.finance || 0);
					setPerson_chargeChange(data.person_charge);
					setDistrictChange(data.district);
					setImageExist(data.avatar);
					setTimeTransfer(data.time_transfer || null);
					// const find_district = listDistrict.find(
					//   (s) => s.id == data.district
					// );
					const find_assigner = listAssigner.find((s) => s.id == data.assigner);
					setAssignerChange(find_assigner);
					// const find_project_type = listProjectTypeEstate.find(
					//   (s) => s.id == data.project_type_id
					// );
					// setProjectTypeChange(find_project_type);
					const find_source = listSourceState.find((s) => s.id == data.source);
					setSourceChange(find_source);
				}
			};
			fetchAPI();
		}
	}, [listAssigner]);

	React.useEffect(() => {
		setPerson_chargeChange(null);
		if (assignerChange?.id != undefined) {
			const fetchAPI = async () => {
				const listPerson_chargeResult = await request.get(
					`users/selectUserByType?type=3&id=${assignerChange.id}`
				);
				if (listPerson_chargeResult.status) {
					setListPersonCharge(listPerson_chargeResult.data);
					const find_person_charge = listPerson_chargeResult.data.find(
						(s: any) => s.id == person_chargeChange
					);
					setPerson_chargeChange(find_person_charge);
					setLoading(false);
				}
			};
			fetchAPI();
		}
	}, [assignerChange]);

	const handleNameChange = (e: any) => {
		setNameChange(e.target.value);
	};
	const handleAcreageChange = (e: any) => {
		setAcreageChange(e.target.value);
	};
	const handleContactChange = (e: any) => {
		setContactChange(e.target.value);
	};
	const handleOrtherChange = (e: any) => {
		setOrtherChange(e.target.value);
	};
	const handleFinanceChange = (e: any) => {
		setFinanceChange(e.target.value);
	};
	const handleSourceChange = (e: any) => {
		setSourceChange(e);
	};
	const handleAssignerChange = (e: any) => {
		setAssignerChange(e);
	};
	const handlePerson_chargeChange = (e: any) => {
		setPerson_chargeChange(e);
	};
	const handleDistrictChange = (e: any) => {
		setDistrictChange(e);
	};
	// const handleProjectTypeChange = (e: any) => {
	//   setProjectTypeChange(e);
	// };
	// const handleStatusChange = (e: any) => {
	//   setStatusChange(e);
	// };
	const onSubmit: SubmitHandler<InittialState> = async (data) => {
		// setLoading(true);
		let data_temp: any = {
			name: nameChange,
			acreage: acreageChange,
			assigner: assignerChange?.id,
			contact: contactChange,
			other_request: ortherChange,
			finance: financeChange,
			source: sourceChange?.id,
			districtIds: districtChange?.map((s: any) => s.id),
			project_type_id: projectTypeChange?.id,
			status: initialState?.status,
			projectIds: initialState?.project?.map((s: any) => s.id),
			person_charge: person_chargeChange?.id ?? null,
		};

		const formData = new FormData();
		formData.append("name", nameChange);
		formData.append("source", sourceChange?.id);
		formData.append("contact", contactChange);
		formData.append("finance", financeChange);
		formData.append("acreage", acreageChange);
		formData.append("status", initialState?.status);
		formData.append("assigner", assignerChange?.id);
		formData.append("other_request", ortherChange);
		formData.append("districtIds", JSON.stringify(districtChange.map((s: any) => s.id)));
		formData.append("projectIds", JSON.stringify(initialState?.project?.map((s: any) => s.id)));

		if (person_chargeChange?.id) formData.append("person_charge", person_chargeChange?.id);
		if (timeTransfer) formData.append("time_transfer", timeTransfer);
		if (imageNew) formData.append("avatar", imageNew);

		const response = await updateCustomer(id, formData);
		if (response.status == true) {
			// @ts-ignore
			notify(response.message, { type: "success" });
			// setLoading(false);
		} else {
			// @ts-ignore
			notify(response.statusText, { type: "error" });
		}
		// setLoading(false);
		refresh();
		onClose();
	};
	const onSelectKeyword = (value: any) => {
		// let lstResult: any = [];
		// if (value.length > 0) {
		//   value.map((i: any) => {
		//     if (i.inputValue) {
		//       lstResult = [...lstResult, i.inputValue];
		//     } else {
		//       lstResult = [...lstResult, i];
		//     }
		//   });
		// }
		setInitialState({ ...initialState, project: value });
		setProjectErr(null);
	};
	const userPermission = Number(commonFunction.checkUserIdentity().permission_data);
	let showAssigner = false;
	let showPerson_charge = false;
	if (userPermission === 2) {
		showPerson_charge = true;
	} else if (userPermission === 1) {
		showAssigner = true;
	} else if (userPermission === 0) {
		showAssigner = true;
		showPerson_charge = true;
	}

	return (
		<DialogFormCustomer
			loading={loading}
			minHeight="auto"
			title="Sửa khách hàng"
			size="md"
			isOpen={isOpen}
			onClose={onClose}
			onSubmit={handleSubmit(onSubmit)}
		>
			{loading ? (
				<PageLoadingAPI />
			) : (
				<>
					<InputRowWrapper>
						<ItemWrapper>
							<TextField
								fullWidth
								variant="outlined"
								label={<InputLabel title="Tên khách hàng" />}
								error={Boolean(errors?.name)}
								className={`${classes.root} mb-23`}
								value={nameChange}
								helperText={errors?.name && "Tên khách hàng không được để trống!"}
								// {...register("name", { required: true })}
								onChange={handleNameChange}
							/>
						</ItemWrapper>
						<ItemWrapper>
							<TextField
								fullWidth
								variant="outlined"
								label={<InputLabel title="Liên hệ" />}
								className={`${classes.root} mb-23`}
								onChange={handleContactChange}
								value={contactChange}
								disabled={userPermission === 3}
							/>
						</ItemWrapper>
					</InputRowWrapper>
					<InputRowWrapper>
						<ItemWrapper>
							<TextField
								fullWidth
								variant="outlined"
								label={<InputLabel title="Tài chính" />}
								className={`${classes.root} mb-23`}
								onChange={handleFinanceChange}
								value={financeChange}
							/>
						</ItemWrapper>
						<ItemWrapper>
							<TextField
								fullWidth
								variant="outlined"
								label={<InputLabel title="Diện tích" />}
								// {...register("acreage", { required: true })}
								className={`${classes.root} mb-23`}
								onChange={handleAcreageChange}
								value={acreageChange}
							/>
						</ItemWrapper>
					</InputRowWrapper>
					<InputRowWrapper>
						<ItemWrapper>
							<Controller
								name="district"
								control={control}
								render={({ field: { onChange, value } }) => (
									<Autocomplete
										disableCloseOnSelect
										multiple={true}
										limitTags={2}
										value={districtChange}
										fullWidth
										id="combo-box-demo"
										options={listDistrict}
										isOptionEqualToValue={(option, value) => {
											return option.id == value.id;
										}}
										getOptionLabel={(option) => option.name}
										renderInput={(params) => (
											<TextField
												margin="dense"
												variant="outlined"
												label={<InputLabel title="Khu vực" />}
												{...params}
											/>
										)}
										onChange={(event, value) => {
											handleDistrictChange(value);
										}}
									/>
								)}
							/>
						</ItemWrapper>
						<ItemWrapper>
							<LimitTags
								submitLoading={loading}
								title="Sản phẩm"
								onSelect={onSelectKeyword}
								val={initialState?.project}
								statusError={projectErr || null}
							/>
						</ItemWrapper>
					</InputRowWrapper>
					<InputRowWrapper>
						<ItemWrapper>
							<Controller
								name="source"
								control={control}
								render={({ field: { onChange, value } }) => (
									<Autocomplete
										value={sourceChange}
										fullWidth
										id="combo-box-demo"
										options={listSourceState}
										getOptionLabel={(option) => option.name}
										renderInput={(params) => (
											<TextField
												margin="dense"
												variant="outlined"
												{...params}
												label={
													<InputLabel
														title="Nguồn"
														required
													/>
												}
											/>
										)}
										onChange={(event, value) => {
											handleSourceChange(value);
										}}
										disabled={userPermission === 3}
									/>
								)}
							/>
						</ItemWrapper>
						<ItemWrapper>
							<LocalizationProvider
								dateAdapter={AdapterDayjs}
								adapterLocale="vi"
							>
								<DatePicker
									label="Thời gian cần chuyển"
									inputFormat="DD/MM/YYYY"
									value={timeTransfer}
									onChange={(value) => {
										setTimeTransfer(value.format("YYYY-MM-DD"));
									}}
									renderInput={(params) => (
										<TextField
											className="mb-23"
											{...params}
											fullWidth
											variant="outlined"
										/>
									)}
								/>
							</LocalizationProvider>
						</ItemWrapper>
					</InputRowWrapper>
					<InputRowWrapper>
						<ItemWrapper>
							<Controller
								name="assigner"
								control={control}
								render={({ field: { onChange, value } }) => (
									<Autocomplete
										value={assignerChange}
										fullWidth
										id="combo-box-demo"
										options={listAssigner}
										getOptionLabel={(option) => option.name}
										renderInput={(params) => (
											<TextField
												margin="dense"
												variant="outlined"
												{...params}
												label={<InputLabel title="Quản lý" />}
											/>
										)}
										onChange={(event, value) => {
											handleAssignerChange(value);
										}}
										disabled={userPermission === 3}
									/>
								)}
							/>
						</ItemWrapper>
						<ItemWrapper>
							<Controller
								name="person_charge"
								control={control}
								render={({ field: { onChange, value } }) => (
									<Autocomplete
										value={person_chargeChange}
										fullWidth
										id="combo-box-demo"
										options={listPersonCharge}
										getOptionLabel={(option) => option.name}
										renderInput={(params) => (
											<TextField
												margin="dense"
												variant="outlined"
												{...params}
												label={<InputLabel title="Nhân viên" />}
											/>
										)}
										onChange={(event, value) => {
											handlePerson_chargeChange(value);
										}}
										disabled={userPermission === 3}
									/>
								)}
							/>
						</ItemWrapper>
					</InputRowWrapper>
					<InputRowWrapper>
						<TextField
							fullWidth
							rows="4"
							variant="outlined"
							label={<InputLabel title="Nhu cầu khác" />}
							className={`${classes.root} mb-23`}
							value={ortherChange}
							onChange={handleOrtherChange}
							sx={{
								mx: "20px",
								my: "10px",
							}}
						/>
					</InputRowWrapper>
				</>
			)}
		</DialogFormCustomer>
	);
}
export default React.memo(EditCustomer);
