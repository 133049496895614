import {
    DefaultDataProvider,
    httpClient,
    serialize,
} from '../default-data-provider';
import {constants} from "../../shared/constants";

export const baseApiUrl = 'project_estate';

export default class ProjectEstateService extends DefaultDataProvider{

};

export const projectEstateService = new ProjectEstateService(
    constants.RESOURCE.PROJECT_ESTATE,
    constants.BASE_MODULE_URL,
    baseApiUrl
);