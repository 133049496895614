import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import React, { useState } from 'react';
import { FilterLiveSearch, useRecordContext, useTranslate } from 'react-admin';
import {
    Pagination,
    List,
    Datagrid,
    DatagridProps,
    useListContext,
    TextField,
    FunctionField,
} from 'react-admin';
import { Box, Button, Chip, IconButton, Typography } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Form } from 'react-final-form';
import { ProjectTypeDelete } from './ProjectTypeDelete';
import { ProjectTypeAdd } from './ProjectTypeAdd';
import { ProjectTypeEdit } from './ProjectTypeEdit';
import PaginationCustom from '../component/PaginationCustom';
import { commonFunction } from '../component/Common';

const Item = styled(Paper)(({ theme }) => ({
    textAlign: 'right',
}));

export const Project_typeList = (props: any) => {
    const PostPagination = (props: any) => <PaginationCustom {...props} />;
    const translate = useTranslate();
    return (
        <Box className="header-channel-sales">
            <Box className={'box_header'}>
                <Typography className={'title_header'}>
                    {translate('resources.project_type.list_project_type')}
                </Typography>
            </Box>
            <div className={`header-top-detail header-top-tdn`}>
                <List
                    {...props}
                    actions={false}
                    empty={false}
                    exporter={false}
                    filters={<FilterAndActions />}
                    sort={{ field: '', order: '' }}
                    pagination={<PostPagination />}
                    perPage={50}
                >
                    <DepartmentDatagrid {...props} />
                </List>
            </div>
        </Box>
    );
};

interface ButtonProps {
    basePath?: string;
    label: string;
    record?: any;
}

const EditButton = (props: any) => {
    const [onCloseDialog, setOpenEditDialog] = useState(false);
    return (
        <>
            <IconButton onClick={() => setOpenEditDialog(true)}>
                <EditIcon className={'btn_edit'} />
            </IconButton>
            {onCloseDialog && (
                <ProjectTypeEdit
                    {...props}
                    openDialog={onCloseDialog}
                    onCloseDialog={() => setOpenEditDialog(false)}
                />
            )}
        </>
    );
};

const DeleteButton = (props: any) => {
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    return (
        <>
            <IconButton onClick={() => setOpenDeleteDialog(true)}>
                <DeleteIcon className={'btn_del'} />
            </IconButton>
            <ProjectTypeDelete
                {...props}
                openDialog={openDeleteDialog}
                onCloseDialog={() => setOpenDeleteDialog(false)}
            />
        </>
    );
};

const RowActions = ({ record }: ButtonProps) => {
    const menu = useRecordContext();
    const per_edit = commonFunction.checkPermission('PROJECT_TYPE_EDIT');
    const per_delete = commonFunction.checkPermission('PROJECT_TYPE_DELETE');

    return (
        <Box className={'care_plan_rowActions'}>
            {per_edit && <EditButton id={menu.id}/> }
            {per_delete && <DeleteButton id={menu.id}/> }
        </Box>
    );
};
const FilterAndActions = (props: any) => {
    const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);

    return (
        <Box
            sx={{
                p: 1,
                m: 1,
                bgcolor: 'background.paper',
                borderRadius: 1,
            }}
            className={'box_search'}
        >
            <Item className="shadow-none">
                <Form onSubmit={() => undefined}>
                    {() => (
                        <FilterLiveSearch
                            variant="outlined"
                            source="name"
                            label="resources.common.fields.search"
                        />
                    )}
                </Form>
            </Item>
            {commonFunction.checkPermission('PROJECT_TYPE_ADD') && <Item className="shadow-none">
                <Button
                    variant="outlined"
                    startIcon={<AddIcon />}
                    color="primary"
                    onClick={() => setOpenCreateDialog(true)}
                >
                    Thêm mới
                </Button>
                <ProjectTypeAdd
                    openDialog={openCreateDialog}
                    onCloseDialog={() => setOpenCreateDialog(false)}
                />
            </Item>}
        </Box>
    );
};

const menuShow = () => {
    const { id, children } = useRecordContext();
    const [menu, setMenu] = useState<any[]>([]);
    
    return (
        <>
            {children?.map((item: any, index: any) => (
                // <div key={index} className={ last  ? 'h-0 row ' : 'h-0 lastrow'}>
                <div key={index} className={'h1-0 row'}>
                    <td className="grid-center">{index + 1}</td>
                    <td className="grid-center">{item.name}</td>
                    <td className="grid-center">{item.project_type_name}</td>
                    <td className="grid-center">{item.depth}</td>
                    <td className="grid-center"></td>

                    {/* <td className="grid-center">
                        <FunctionField
                            label={'resources.common.fields.status'}
                            render={(item: any) => {
                                if (item.status === 1) {
                                    return (
                                        <Chip
                                            label={`Đang hoạt động ${item.status}`}
                                            color="success"
                                            variant="outlined"
                                        />
                                    );
                                } else {
                                    return <Chip label={`Khóa ${item.id}`} color="error" variant="outlined" />;
                                }
                            }}
                        />
                    </td> */}
                    <td>
                        <EditButton id={item.id} />
                        <DeleteButton id={item.id} />
                    </td>
                </div>
            ))}
        </>
    );
};

const DepartmentDatagrid = (props: DatagridProps) => {
    // @ts-ignore
    const { page, perPage } = useListContext();

    return (
        <>
            <div className={`list-data-tdn`}>
                <Datagrid
                    expand={menuShow}
                    isRowExpandable={(row: any) => row.children.length > 0}
                    bulkActionButtons={false}
                    className={`table-default criteria_type_datagrid table_department`}
                >
                    <TextField
                        source={'name'}
                        label={'resources.project_type.fields.name_project_type'}
                    />
                    <TextField
                        source={'project_type_name'}
                        label={'resources.project_type.fields.type'}
                    />
                    <TextField
                        source={'depth'}
                        label={'resources.project_type.fields.depth_project_type'}
                    />
                    <FunctionField
                        label={'resources.common.fields.status'}
                        render={(record: any) => {
                            if (record.status == 1) {
                                return (
                                    <Chip
                                        label="Đang hoạt động"
                                        color="success"
                                        variant="outlined"
                                    />
                                );
                            } else {
                                return <Chip label="Khóa" color="error" variant="outlined" />;
                            }
                        }}
                    />
                    <RowActions label={'resources.common.fields.action'} />
                </Datagrid>
            </div>
        </>
    );
};
