import * as React from "react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import HttpDataService from "../../../../dataProvider/http.service";
import InputLabel from "../../../../component/InputLabel";
import { Key } from "@mui/icons-material";

type Props = {
	title: string;
	submitLoading: boolean;
	onSelect: (value: any) => void;
	val?: any;
	statusError: ErrorTypes | null;
};

type ErrorTypes = {
	isError: boolean;
	message: String;
};

const filter = createFilterOptions<any>();

export default function LimitTags({ submitLoading, title, onSelect, val, statusError }: Props) {
	const [listData, setListData] = React.useState<any[]>([]);
	// const [value, setValue] = React.useState<any[]>(val);
	const [loading, setLoading] = React.useState<boolean>(submitLoading);
	React.useEffect(() => {
		const fetchAPI = async () => {
			const listData = (await HttpDataService.getAll("project_estate/getProjectSelect")).data.data;

			setListData(listData);
		};
		fetchAPI();
	}, []);
	const handleChange = (e: any, newValue: any) => {
		onSelect(newValue);
		// setValue(newValue1);
	};
	return (
		<>
			<Autocomplete
				disableCloseOnSelect
				multiple
				limitTags={2}
				id="multiple-limit-tags"
				value={val ?? []}
				options={listData}
				isOptionEqualToValue={(option, value) => {
					return option.id == value.id;
				}}
				getOptionLabel={(option) => `${option.code} - ${option.address}`}
				filterOptions={(options, params) => {
					const filtered = filter(options, params);
					if (params.inputValue !== "") {
						filtered.push({
							inputValue: params.inputValue,
							name: `Thêm mới "${params.inputValue}"`,
						});
					}

					return filtered;
				}}
				renderInput={(params) => (
					<TextField
						{...params}
						error={statusError?.isError}
						helperText={statusError?.message}
						placeholder={title}
						variant="outlined"
						label={<InputLabel title={title} />}
					/>
				)}
				onChange={(event, newValue) => {
					handleChange(event, newValue);
				}}
			/>
		</>
	);
}
