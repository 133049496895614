import { AxiosError } from 'axios';
import * as request from '../utils/httpRequest';
import { FORM_DATA_HEADER } from './reqHeader';

export const createProject = async (data: any) => {
    const {
        name,
        department,
        type,
        legal,
        content,
        status,
        area,
        lst_file,
        lst_img_ground,
        lst_file_legal,
        lst_img_location,
        lst_file_sales,
        project_goods,
    } = data;
    const submitObj = new FormData();
    submitObj.append('name', name);
    submitObj.append('department', department);
    submitObj.append('type', type);
    submitObj.append('legal', legal);
    submitObj.append('content', content);
    submitObj.append('status', status);
    submitObj.append('area', area);
    if (lst_file) {
        submitObj.append('lst_file[]', JSON.stringify([...lst_file]));
    }
    if (lst_img_ground) {
        submitObj.append('lst_img_ground', JSON.stringify([...lst_img_ground]));
    }
    if (lst_file_legal) {
        submitObj.append('lst_file_legal', JSON.stringify([...lst_file_legal]));
    }
    if (lst_img_location) {
        submitObj.append('lst_img_location', JSON.stringify([...lst_img_location]));
    }
    if (lst_file_sales) {
        submitObj.append('lst_file_sales', JSON.stringify([...lst_file_sales]));
    }
    submitObj.append('project_goods', JSON.stringify([...project_goods]));

    try {
        const response = await request.post(
            'project/addProject',
            submitObj,
            FORM_DATA_HEADER,
        );
        return response.status;
    } catch (error) {
        const err: any = error as AxiosError;
        console.log(error);
        return err.response;
    }
};

export const editProject = async (data: any) => {
    const {
        id,
        name,
        department,
        type,
        legal,
        content,
        status,
        area,
        lst_file,
        lst_img_ground,
        lst_file_legal,
        lst_img_location,
        lst_file_sales,
        project_goods,
    } = data;
    const submitObj = new FormData();
    submitObj.append('name', name);
    submitObj.append('department', department);
    submitObj.append('type', type);
    submitObj.append('legal', legal);
    submitObj.append('content', content);
    submitObj.append('status', status);
    submitObj.append('area', area);
    if (lst_file) {
        submitObj.append('lst_file[]', JSON.stringify([...lst_file]));
    }
    if (lst_img_ground) {
        submitObj.append('lst_img_ground', JSON.stringify([...lst_img_ground]));
    }
    if (lst_file_legal) {
        submitObj.append('lst_file_legal', JSON.stringify([...lst_file_legal]));
    }
    if (lst_img_location) {
        submitObj.append('lst_img_location', JSON.stringify([...lst_img_location]));
    }
    if (lst_file_sales) {
        submitObj.append('lst_file_sales', JSON.stringify([...lst_file_sales]));
    }
    submitObj.append('project_goods', JSON.stringify([...project_goods]));

    try {
        const response = await request.post(
            `/project/editProject?id=${id}`,
            submitObj,
            FORM_DATA_HEADER,
        );
        return response.status;
    } catch (error) {
        console.log(error);
    }
};

export const getProjectDetail = async (id: Number) => {
    try {
        const response = await request.get(`/project/getProject/${id}`);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const deleteProject = async (id: Number) => {
    try {
        const response = await request.del(`project/delete/${id}`);
        return response.status;
    } catch (error) {
        console.log(error);
    }
};

export const getListProjectDetail = async (id: Number, code: string, status: number) => {
    try {
        const response = await request.get(`project/getProjectGoods/${id}`, {
            params: {
                code,
                status,
            },
        });
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const uploadImage = async (data: any) => {
    try {
        const response = await request.post(`/project/uploadImage`);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};
