import {
  DefaultDataProvider,
  httpClient,
  serialize,
} from "../default-data-provider";
import { constants } from "../../shared/constants";

export const baseApiUrl = "backups";

export default class BackupService extends DefaultDataProvider {}

export const backupService = new BackupService(
  constants.RESOURCE.BACKUP,
  constants.BASE_MODULE_URL,
  baseApiUrl
);
