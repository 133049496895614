import {
  Autocomplete,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Grid,
  DialogTitle,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import ReplyIcon from "@mui/icons-material/Reply";
import Toolbar from "@mui/material/Toolbar";
import ImagePreview from "../component/ImgPreview";
import Image from "../component/Image";
import {
  useNotify,
  useRefresh,
  useRecordContext,
  EditProps,
  useTranslate,
} from "react-admin";

import {
  Wrapper,
  InputRowWrapper,
  ItemWrapper,
  EditorWrapper,
} from "./styledMui";
// Switch  component
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Editor from "../component/Editor";
import HttpDataService from "../dataProvider/http.service";
import { createStyles, makeStyles } from "@material-ui/core/styles";

//Reformat Style
const styles = makeStyles((theme: any) =>
  createStyles({
    root: {
      "& .css-k4qjio-MuiFormHelperText-root.Mui-error": {
        position: "absolute",
        paddingTop: "41px",
      },
      "& .css-1z10yd4-MuiFormControl-root-MuiTextField-root": {
        marginTop: 20,
        marginBottom: 20,
      },
    },
  })
);

interface Add extends EditProps {
  openDialog: boolean;
  onCloseDialog: (openDialog: boolean) => void;
}

export const VideoEdit = ({ openDialog, onCloseDialog, ...rest }: Add) => {
  const classes = styles();
  const [name, setName] = useState<string>("");
  const [note, setNote] = useState<string>("");
  const [link, setLink] = useState<string>("");

  const [image, setImage] = React.useState<any[]>([]);
  const removeImageUtilities = (imgIndex: number) => {
    const newListImage = image.filter((_, index) => index !== imgIndex);
    if (image.length === 1) {
      setImage([]);
    }
    setImage(newListImage);
  };

  const [nameError, setNameError] = useState(false);
  const [textNameError, setTextNameError] = useState("");
  const [linkError, setLinkError] = useState(false);
  const [textLinkError, setTextLinkError] = useState("");
  const translate = useTranslate();
  const dataStatus = [
    { label: translate("resources.common.fields.active"), id: 1 },
    { label: translate("resources.common.fields.block"), id: 0 },
  ];
  const [status, setStatus] = useState<any>(dataStatus[0]);
  const refresh = useRefresh();
  const notify = useNotify();
  const record = useRecordContext();

  useEffect(() => {
    HttpDataService.findById("video/getVideo", record.id as string)
      .then((response: any) => {
        if (response.data.status) {
          const res_record = response.data.data;
          setName(res_record.name);
          setNote(res_record.note);
          setLink(res_record.link);
        } else {
          // @ts-ignore
          notify("Lỗi", { type: "error" });
        }
      })
      .catch((e: Error) => {
        console.log(e);
      });
  }, [record.id]);

  /**
   * handle change name
   * @param e
   */
  const handleNameChange = (e: any) => {
    setNameError(false);
    setTextNameError("");
    setName(e.target.value);
  };

  const handleNoteChange = (e: any) => {
    setNote(e.target.value);
  };
  const handleLinkChange = (e: any) => {
    setLink(e.target.value);
    setLinkError(false);
    setTextLinkError("");

  };

  // checker
  /**
   * handle save
   */
  const handleSave = async () => {
    if (!fnValidation()) {
      let data = {
        id: record.id,
        name: name,
        note: note,
        link: link,
        status: 0,
      };

      await HttpDataService.update("video/edit", data)
        .then((response: any) => {
          if (response.data.status) {
            // @ts-ignore
            notify(response.data.message, { type: "success" });
          } else {
            // @ts-ignore
            notify(response.data.message, { type: "error" });
          }
        })
        .catch((e: Error) => {
          console.log(e);
        });

      fnResetFrm();
      onCloseDialog(false);
      refresh();
    }
  };

  const handleCloseDialog = () => {
    fnResetFrm();
    onCloseDialog(false);
  };

  /**
   * fn validation form
   */
  function fnValidation() {
    let error = false;
    if (name.trim().length === 0) {
      error = true;
      setNameError(true);
      setTextNameError(translate("resources.name.fields.val_name"));
    }
    if (link.trim().length === 0) {
      error = true;
      setLinkError(true);
      setTextLinkError("Đường dẫn không được để trống");
    } 
    return error;
  }

  function fnResetFrm() {
    setName("");
    setNameError(false);
    setTextNameError("");
    setLinkError(true);
    setTextLinkError("")
    setStatus(null);
  }

  return (
    <>
      <Dialog
        open={openDialog}
        fullWidth
        disableEnforceFocus
        PaperProps={{
          style: {
            borderRadius: 2,
            minWidth: "35%",
          },
        }}
      >
        <div className={"common__dialogTitle_area"}>
          <DialogTitle className={"member__dialogTitle"}>
            <div>{"Chỉnh sửa video"}</div>
          </DialogTitle>
          <div className={"common__dialogTitle_actions"}>
            <IconButton
              aria-label="exists"
              color={"error"}
              onClick={() => {
                onCloseDialog(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <Box className={"dialog_box"}>
          <DialogContent className={"dialog_content"}>
            <Grid item xs={8}>
              <TextField
                className={`${classes.root} mb-23`}
                fullWidth
                label={
                  <span className={"span_style"}>
                    {"Tiêu đề"}
                    <span style={{ color: "red" }}>(*)</span>
                  </span>
                }
                value={name}
                error={nameError}
                helperText={textNameError}
                onChange={handleNameChange}
                variant="outlined"
              />

              <TextField
                className={`${classes.root} mb-23`}
                fullWidth
                error={linkError}
                helperText={textLinkError}
                label={
                  <span className={"span_style"}>
                    {"Đường dẫn"}
                    <span style={{ color: "red" }}>(*)</span>
                  </span>
                }
                value={link}
                onChange={handleLinkChange}
                variant="outlined"
              />
              <TextField
                className={`${classes.root} mb-23`}
                fullWidth
                multiline
                minRows={3}
                label={<span className={"span_style"}>{"Note"}</span>}
                value={note}
                onChange={handleNoteChange}
                variant="outlined"
              />
            </Grid>
          </DialogContent>
        </Box>
        <Toolbar>
          <Box display="flex" justifyContent="center" width={"100%"}>
            <Button
              variant="contained"
              startIcon={<SaveIcon />}
              onClick={handleSave}
            >
              {translate("resources.common.fields.update")}
            </Button>
            &nbsp;&nbsp;
            <Button
              startIcon={<ReplyIcon />}
              className={"button-close"}
              variant="contained"
              onClick={handleCloseDialog}
            >
              {translate("resources.common.fields.cancel")}
            </Button>
          </Box>
        </Toolbar>
      </Dialog>
    </>
  );
};
