import {
    DefaultDataProvider,
    httpClient,
    serialize,
} from '../default-data-provider';
import {constants} from "../../shared/constants";

export const baseApiUrl = 'roles';

class RolesService extends DefaultDataProvider {

}

export const rolesService = new RolesService (
    constants.RESOURCE.PERMISSION,
    constants.BASE_MODULE_URL,
    baseApiUrl
);