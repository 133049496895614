import React from 'react';
import {
    Dialog,
    DialogProps,
    DialogTitle,
    IconButton,
    Box,
    Stack,
    Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';
import SaveButton from './SaveButton';

type Props = {
    title: String;
    size?: DialogProps['maxWidth'];
    isOpen: boolean;
    onClose: () => void;
    onSubmit?: () => void;
    children: JSX.Element;
    minHeight?: number | 'auto';
};

const Wrapper = styled(Box)({
    overflowY: 'auto',
});
const ButtonWrapper = styled(Box)({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: 20,
    backgroundColor: 'whitesmoke',
});

function DialogCustom({
    isOpen,
    onClose,
    children,
    title,
    size = 'sm',
    onSubmit,
    minHeight = 500,
}: Props) {
    return (
        <Dialog fullWidth maxWidth={size} open={isOpen} onClose={onClose}>
            <DialogTitle
                sx={{
                    backgroundColor: 'whitesmoke',
                    position: 'relative',
                }}
            >
                {title}
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Wrapper
                sx={{
                    minHeight: minHeight,
                }}
            >
                {children}
            </Wrapper>

            <ButtonWrapper>
                <Stack spacing={2} direction="row">
                    {onSubmit && <SaveButton title="Lưu" onClick={onSubmit} />}
                    <Button variant="outlined" color="error" onClick={onClose}>
                        Đóng
                    </Button>
                </Stack>
            </ButtonWrapper>
        </Dialog>
    );
}
export default React.memo(DialogCustom);
