import AddIcon from '@mui/icons-material/Add';
import {styled} from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import React, {useContext, useEffect, useState} from 'react';
import {
    ImageField,
    Pagination,
    List,
    Datagrid,
    DatagridProps,
    useListContext,
    TextField,
    FunctionField,
    FilterLiveSearch,
    FilterList,
    FilterListItem,
    useTranslate,
} from 'react-admin';
import {Box, Button, Chip, DialogContent, Grid, IconButton, Typography} from '@mui/material';
import TextFieldMUI from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import ReplyIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import {UserEdit} from './UserEdit';
import {UserAdd} from './UserAdd';
import {UserDelete} from './UserDelete';
import {UserLock} from './UserLock';
import {UserLockOpen} from './UserLockOpen';
import Tooltip from '@mui/material/Tooltip';
import Autocomplete from '@mui/material/Autocomplete';
// import {Autocomplete} from "@mui/material";
// @ts-ignore
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import HttpDataService from '../dataProvider/http.service';
import AutoCompleteNoLabel from '../component/AutoCompleteNoLabel';
import InputNoLabel from '../component/InputNoLabel';
import PaginationCustom from '../component/PaginationCustom';
import {commonFunction} from "../component/Common";
import {constants} from "../shared/constants";
import styles from "../projectEstate/Project.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

const dataDepartment_id: any[] = [{label: 'Tất cả', id: ''}];
const dataOffice_id: any[] = [{label: 'Tất cả', id: ''}];
const dataRoles_id: any[] = [{label: 'Tất cả', id: ''}];

const Item = styled(Paper)(({theme}) => ({
    textAlign: 'right',
}));

// @ts-ignore
const UserPolicyContext = React.createContext();

const user = JSON.parse(
    localStorage.getItem(constants.LOCAL_STORAGE.USER_IDENTITY) as string,
);

export const UserList = (props: any) => {
    const PostPagination = (props: any) => <PaginationCustom {...props} />;
    const [nameContext, setNameContext] = useState<string>();
    const [emailContext, setEmailContext] = useState<string>();
    const [usernameContext, setUsernameContext] = useState<string>();
    const [office_idContext, setOffice_idContext] = useState<any>(null);
    const [department_idContext, setDepartment_idContext] = useState<any>(null);
    const [roles_idContext, setRoles_idContext] = useState<any>(null);
    const translate = useTranslate();

    return (
        <Box className="header-channel-sales">
            <Box className={'box_header'}>
                <Typography className={'title_header'}>
                    {translate('resources.user.list_user')}
                </Typography>
            </Box>

            <div className={`header-top-detail header-top-tdn`}>
                <UserPolicyContext.Provider
                    value={{
                        nameContext: [nameContext, setNameContext],
                        emailContext: [emailContext, setEmailContext],
                        usernameContext: [usernameContext, setUsernameContext],
                        office_idContext: [office_idContext, setOffice_idContext],
                        department_idContext: [department_idContext, setDepartment_idContext],
                        roles_idContext: [roles_idContext, setRoles_idContext],
                    }}
                >
                    <List
                        {...props}
                        filter={{
                            name: nameContext,
                            email: emailContext,
                            username: usernameContext,
                            office_id: office_idContext,
                            department_id: department_idContext,
                            roles_id: roles_idContext,
                        }}
                        actions={false}
                        empty={false}
                        exporter={false}
                        filters={<Actions/>}
                        sort={{field: '', order: ''}}
                        pagination={<PostPagination/>}
                        perPage={10}
                        filterDefaultValues={{
                            name: '',
                            email: '',
                            username: '',
                            office_id: '',
                            department_id: '',
                        }}
                    >
                        <UserDatagrid {...props} />
                    </List>
                </UserPolicyContext.Provider>
            </div>
        </Box>
    );
};

interface ButtonProps {
    basePath?: string;
    label: string;
    record?: any;
}

const EditButton = (props: any) => {
    const [onCloseDialog, setOpenEditDialog] = useState(false);
    return (
        <>
            <Tooltip title="Chỉnh sửa">
                <IconButton onClick={() => setOpenEditDialog(true)}>
                    <EditIcon className={'btn_edit'}/>
                </IconButton>
            </Tooltip>
            {onCloseDialog && (
                <UserEdit
                    {...props}
                    openDialog={onCloseDialog}
                    onCloseDialog={() => setOpenEditDialog(false)}
                />
            )}
        </>
    );
};

const DeleteButton = (props: any) => {
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    return (
        <>
            <Tooltip title="Xóa">
                <IconButton onClick={() => setOpenDeleteDialog(true)}>
                    <DeleteIcon className={'btn_del'}/>
                </IconButton>
            </Tooltip>
            <UserDelete
                openDialog={openDeleteDialog}
                onCloseDialog={() => setOpenDeleteDialog(false)}
            />
        </>
    );
};
const LockButton = (props: any) => {
    const [openLockDialog, setOpenLockDialog] = useState(false);
    return (
        <>
            <Tooltip title="Khóa">
                <IconButton onClick={() => setOpenLockDialog(true)}>
                    <LockIcon className={'btn_lock'}/>
                </IconButton>
            </Tooltip>
            <UserLock openDialog={openLockDialog} onCloseDialog={() => setOpenLockDialog(false)}/>
        </>
    );
};
const LockOpenButton = (props: any) => {
    const [openLockOpenDialog, setOpenLockOpenDialog] = useState(false);
    return (
        <>
            <Tooltip title="Mở khóa">
                <IconButton onClick={() => setOpenLockOpenDialog(true)}>
                    <LockOpenIcon className={'btn_lock_open'}/>
                </IconButton>
            </Tooltip>

            <UserLockOpen
                openDialog={openLockOpenDialog}
                onCloseDialog={() => setOpenLockOpenDialog(false)}
            />
        </>
    );
};
const RowActionsLookUp = (props: any) => {
    const per_edit = commonFunction.checkPermission('USERS_EDIT');
    const per_delete = commonFunction.checkPermission('USERS_DELETE');
    const per_lock = commonFunction.checkPermission('USERS_LOCK');

    return (
        <Box className={cx('care_plan_rowActions')}>
            {per_edit && (<EditButton/>)}
            {per_delete && (<DeleteButton/>)}
            {per_lock && <LockButton/>}
        </Box>
    );
};

const RowActionsLookOpen = (props: any) => {
    const per_edit = commonFunction.checkPermission('USERS_EDIT');
    const per_delete = commonFunction.checkPermission('USERS_DELETE');
    const per_lock = commonFunction.checkPermission('USERS_LOCK');

    return (
        <Box className={cx('care_plan_rowActions')}>
            {per_edit && (<EditButton/>)}
            {per_delete && (<DeleteButton/>)}
            {per_lock && <LockOpenButton/>}
        </Box>
    );
};

// const RowActions = ({record}: ButtonProps) => {
//     console.log(record)
//     return (
//         <Box className={'care_plan_rowActions'}>
//             <EditButton/>
//             <DeleteButton/>
//             <LockButton/>
//         </Box>
//     );
// };

const Actions = (props: any) => {
    // @ts-ignore
    const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);
    const {filterValues} = useListContext();
    // @ts-ignore
    const {nameContext, emailContext, usernameContext, office_idContext, department_idContext, roles_idContext,} = useContext(UserPolicyContext);

    const [name, setName] = nameContext;
    const [nameSearch, setNameSearch] = useState<string>();
    const [email, setEmail] = emailContext;
    const [emailSearch, setEmailSearch] = useState<string>();
    const [username, setUsername] = usernameContext;
    const [usernameSearch, setUsernameSearch] = useState<string>();
    const [office_id, setOffice_id] = office_idContext;
    const [officeSearch, setOfficeSearch] = useState<any>(null);
    const [department_id, setDepartment_id] = department_idContext;
    const [departmentSearch, setDepartmentSearch] = useState<any>(null);
    const [roles_id, setRoles_id] = roles_idContext;
    const [rolesSearch, setRolesSearch] = useState<any>(null);
    const translate = useTranslate();

    // ################## -API- ##############

    // api phòng ban
    useEffect(() => {
        HttpDataService.getAll('department/getAllDepartment').then((res) => {
            let data = res.data.data;
            data.map((item: any) => {
                dataDepartment_id.push({
                    id: item.id,
                    label: item.name,
                });
            });
        });
    }, []);

    // api chức vụ
    useEffect(() => {
        HttpDataService.getAll('office/getAllOffice').then((res) => {
            let data = res.data.data;
            data.map((item: any) => {
                dataOffice_id.push({
                    id: item.id,
                    label: item.name,
                });
            });
        });
    }, []);

    // api nhóm quyền
    useEffect(() => {
        HttpDataService.getAll('roles/getAllRoles').then((res) => {
            let data = res.data.data;
            data.map((item: any) => {
                dataRoles_id.push({
                    id: item.id,
                    label: item.name,
                });
            });
        });
    }, []);
    /**
     * handle change name
     * @param e
     */
        // const
    const handleNameChange = (e: any) => {
            setNameSearch(e.target.value);
        };
    const handleEmailSearchChange = (e: any) => {
        setEmailSearch(e.target.value);
    };
    const handleUsernameChange = (e: any) => {
        setUsernameSearch(e.target.value);
    };
    const handleDepartmentChange = (e: any) => {
        setDepartmentSearch(e);
    };
    const handleOfficeChange = (e: any) => {
        setOfficeSearch(e);
    };
    const handleRolesChange = (e: any) => {
        setRolesSearch(e);
    };

    function searchForm(e: any) {
        setName(nameSearch);
        setEmail(emailSearch);
        setUsername(usernameSearch);
        setDepartment_id(departmentSearch?.id);
        setOffice_id(officeSearch?.id);
        setRoles_id(rolesSearch?.id);
    }

    function resetForm() {
        setNameSearch('');
        setEmailSearch('');
        setUsernameSearch('');
        setOfficeSearch(dataOffice_id[0]);
        setDepartmentSearch(dataDepartment_id[0]);
        setRolesSearch(dataRoles_id[0]);
    }

    const [isOpen, setIsOpen] = useState(false);
    // const isOpen
    const handlePopupSearch = () => {
        resetForm();
        setIsOpen((prev) => !prev);
    };
    const style = {
        overflow: 'hidden',
        height: isOpen ? 365 : 0,
        transition: '500',
    };

    return (
        <Box className={'header-user'}>
            <Item>
                <Box className={'Search_main'}>
                    <div className="App">
                        <div style={style} className={'animation-search'}>
                            <div className={'title-main-search'}>Bộ lọc tìm kiếm</div>
                            <Button
                                onClick={() => setIsOpen((prev) => !prev)}
                                startIcon={<CloseIcon/>}
                                color="primary"
                                className={'close-search'}
                            ></Button>
                            <form onSubmit={() => undefined} autoComplete="off">
                                <Grid
                                    className={'content-serch-form'}
                                    container
                                    rowSpacing={1}
                                    columnSpacing={{xs: 1, sm: 2, md: 3}}
                                >
                                    <Grid item xs={4} className={'item-search'}>
                                        <Item>
                                            <label className={'lable-input-search'}>
                                                {translate('resources.user.fields.name_user')}
                                            </label>
                                            <InputNoLabel
                                                onChange={handleNameChange}
                                                value={nameSearch}
                                                placeholder={translate(
                                                    'resources.user.fields.name_search',
                                                )}
                                            />
                                        </Item>
                                    </Grid>
                                    <Grid item xs={4} className={'item-search'}>
                                        <Item>
                                            <label className={'lable-input-search'}>
                                                {translate('resources.user.fields.email_user')}
                                            </label>
                                            <InputNoLabel
                                                onChange={handleEmailSearchChange}
                                                value={emailSearch}
                                                placeholder={translate(
                                                    'resources.user.fields.email_search',
                                                )}
                                            />
                                        </Item>
                                    </Grid>
                                    <Grid item xs={4} className={'item-search'}>
                                        <Item>
                                            <label className={'lable-input-search'}>
                                                {translate('resources.user.fields.account_user')}
                                            </label>
                                            <InputNoLabel
                                                onChange={handleUsernameChange}
                                                value={usernameSearch}
                                                placeholder={translate(
                                                    'resources.user.fields.user_name_search',
                                                )}
                                            />
                                        </Item>
                                    </Grid>
                                    <Grid item xs={4} className={'item-search'}>
                                        <Item>
                                            <label className={'lable-input-search'}>
                                                {translate('resources.user.fields.department')}
                                            </label>
                                            <AutoCompleteNoLabel
                                                dataOptions={dataDepartment_id}
                                                value={departmentSearch}
                                                onChange={handleDepartmentChange}
                                            />
                                        </Item>
                                    </Grid>
                                    <Grid item xs={4} className={'item-search'}>
                                        <Item>
                                            <label className={'lable-input-search'}>
                                                {translate('resources.user.fields.office')}
                                            </label>
                                            <AutoCompleteNoLabel
                                                dataOptions={dataOffice_id}
                                                value={officeSearch}
                                                onChange={handleOfficeChange}
                                            />
                                        </Item>
                                    </Grid>
                                    <Grid item xs={4} className={'item-search'}>
                                        <Item>
                                            <label className={'lable-input-search'}>
                                                {translate(
                                                    'resources.user.fields.group_permission',
                                                )}
                                            </label>
                                            <AutoCompleteNoLabel
                                                dataOptions={dataRoles_id}
                                                value={rolesSearch}
                                                onChange={handleRolesChange}
                                            />
                                        </Item>
                                    </Grid>
                                </Grid>
                            </form>
                            <div className={'btn-action'}>
                                <Button
                                    onClick={searchForm}
                                    className={'btn-serch-form'}
                                    startIcon={<SearchIcon/>}
                                >
                                    {translate('resources.common.fields.search')}
                                </Button>
                                <Button
                                    onClick={resetForm}
                                    className={'btn-reset-user'}
                                    startIcon={<RestartAltIcon/>}
                                >
                                    {translate('resources.common.fields.reset')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Box>
            </Item>
            <Item className={'btn-add-user'}>
                <Button
                    onClick={handlePopupSearch}
                    className={'btn-search-user'}
                    startIcon={<SearchIcon/>}
                >
                    Lọc
                </Button>
                {commonFunction.checkPermission('USERS_ADD') && (
                    <>
                        <Button
                            variant="outlined"
                            className={'btnAdd-user'}
                            startIcon={<AddIcon/>}
                            color="primary"
                            onClick={() => setOpenCreateDialog(true)}
                        >
                            {translate('resources.common.fields.add')}
                        </Button>
                 
                        <UserAdd
                            openDialog={openCreateDialog}
                            onCloseDialog={() => setOpenCreateDialog(false)}
                        />
                    </>
                )}
            </Item>
        </Box>
    );
};

const UserDatagrid = (props: DatagridProps) => {
    // @ts-ignore
    const {page, perPage} = useListContext();
    const translate = useTranslate();

    return (
        <>
            <div className={`list-data-tdn`}>
                <Datagrid
                    optimized
                    bulkActionButtons={false}
                    className={`table-default criteria_type_datagrid table_user`}
                >
                    <TextField
                        source={'name'}
                        label={translate('resources.user.fields.name_user')}
                    />
                    <TextField
                        source={'email'}
                        label={translate('resources.user.fields.email_user')}
                    />
                    <TextField
                        source={'username'}
                        label={translate('resources.user.fields.account_user')}
                    />
                    <FunctionField
                        label={'Avatar'}
                        render={(record: any) => {
                            return (
                                <ImageField
                                    source="image"
                                    src="url"
                                    title="desc"
                                    sx={{
                                        '& img': {
                                            maxWidth: 50,
                                            maxHeight: 50,
                                            objectFit: 'contain',
                                        },
                                    }}
                                />
                            );
                        }}
                    />
                    <FunctionField
                        label={translate('resources.user.fields.sex_user')}
                        render={(record: any) => {
                            if (record.sex === 1) {
                                return <span>{translate('resources.common.fields.male')}</span>;
                            } else {
                                return <span>{translate('resources.common.fields.female')}</span>;
                            }
                        }}
                    />
                    <TextField
                        source={'office_name'}
                        label={translate('resources.user.fields.office')}
                    />
                    <TextField
                        source={'department_name'}
                        label={translate('resources.user.fields.department')}
                    />
                    <TextField
                        source={'roles_name'}
                        label={translate('resources.user.fields.group_permission')}
                    />
                    <FunctionField
                        label={translate('resources.common.fields.status')}
                        render={(record: any) => {
                            if (record.account_status === 1) {
                                return (
                                    <Chip
                                        label={translate('resources.common.fields.active')}
                                        color="success"
                                        variant="outlined"
                                    />
                                );
                            } else {
                                return (
                                    <Chip
                                        label={translate('resources.common.fields.block')}
                                        color="error"
                                        variant="outlined"
                                    />
                                );
                            }
                        }}
                    />
                    <FunctionField
                        label={'Thao tác'}
                        render={(record: any) => {
                            if (record.account_status === 1) {
                                return <RowActionsLookUp/>;
                            } else {
                                return <RowActionsLookOpen/>;
                            }
                        }}
                    />
                </Datagrid>
            </div>
        </>
    );
};
