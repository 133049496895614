import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { getIcon } from "../utils/menuUtils";
import {
  useTranslate,
  DashboardMenuItem,
  MenuItemLink,
  MenuProps,
  useSidebarState,
  usePermissions,
} from "react-admin";
import HttpDataService from "../dataProvider/http.service";
import SubMenu from "./SubMenu";
import axios from "axios";
import request from "../utils/httpRequest";
import { constants } from "../shared/constants";
import { authService } from "../dataProvider/auth-service";
import { commonFunction } from "../component/Common";

const Menu = ({ dense = false }: MenuProps) => {
  const [state, setState] = useState<any>();
  const [open] = useSidebarState();
  const handleToggle = (menu: string) => {
    setState({ ...state, [menu]: !state[menu] });
  };
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    const getMenu = async () => {
      let dataMenu = JSON.parse(
        localStorage.getItem(constants.LOCAL_STORAGE.LIST_MENU) as string
      );

      const authorizationToken = localStorage.getItem(
        constants.LOCAL_STORAGE.TOKEN
      );
      if (!dataMenu) {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASE_MODULE_URL}/menu_admin/getAll`,
          {
            headers: {
              Authorization: `Bearer ${authorizationToken}`,
            },
          }
        );
        const { items } = data?.data;
        setData(items);
        localStorage.setItem(
          constants.LOCAL_STORAGE.LIST_MENU,
          JSON.stringify(items)
        );
      } else {
        setData(dataMenu);
      }
      let parent = {};
      data?.map((item: any) => {
        const name = item.code;
        // @ts-ignore
        parent[name] = false;
      });

      setState(parent);
    };
    getMenu();
  }, []);

  return (
    <Box
      sx={{
        width: open ? 200 : 50,
        marginTop: 1,
        marginBottom: 1,
        transition: (theme) =>
          theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}
      className={"menu-left"}
    >
      {data?.map((item: any, index: number) => {
        if (commonFunction.checkPermission(item.code)) {
          return (
            <SubMenu
              key={index}
              handleToggle={() => handleToggle(item.code)}
              isOpen={state == undefined ? false : state[item.code]}
              name={item.name}
              icon={getIcon(item.icon)}
              dense={dense}
            >
              <>
                {item.children
                  .filter((childItem: any) =>
                    commonFunction.checkPermission(childItem.code)
                  )
                  .map((item: any, index: number) => (
                    <MenuItemLink
                      key={index}
                      to={item.slug ? item.slug : "/"}
                      state={{ _scrollToTop: true }}
                      primaryText={`${item.name}`}
                      leftIcon={getIcon(item.icon)}
                      dense={dense}
                      className={"menu_cus"}
                    />
                  ))}
              </>
            </SubMenu>
          );
        } else if (
          commonFunction.checkPermission(item.code) ||
          item.code == "HOME"
        ) {
          return (
            <MenuItemLink
              key={index}
              to={item.slug ? item.slug : "/"}
              state={{ _scrollToTop: true }}
              primaryText={item.name}
              leftIcon={getIcon(item.icon)}
              dense={dense}
              className={"menu_cus menu_dasboard"}
            />
          );
        }
      })}

      {/*<DashboardMenuItem />*/}
      {/* <MenuItemLink
                to="/"
                state={{ _scrollToTop: true }}
                primaryText={translate(`resources.home.name`, {
                    smart_count: 2,
                })}
                leftIcon={<DashboardIconActive className={'color_icon_menu'}/>}
                dense={dense}
                className={'menu_cus menu_dasboard'}
            />
            <SubMenu
                handleToggle={() => handleToggle('menuProject')}
                isOpen={state.menuProject}
                name="pos.menu.project"
                icon={<ArticleIcon className={'menu_icon color_icon_menu'}/>}
                dense={dense}
            >
                <MenuItemLink
                    to="/project"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.project.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<ArticleIcon className={'menu_icon color_icon_menu'}/>}
                    dense={dense}
                    className={'menu_cus'}
                />
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('menuDepartment')}
                isOpen={state.menuDepartment}
                name="pos.menu.catalog"
                icon={<ListIcon className={'color_icon_menu'}/>}
                dense={dense}
            >
                <MenuItemLink
                    to="/department"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.department.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<Diversity1Icon className={'menu_icon color_icon_menu'}/>}
                    dense={dense}
                    className={'menu_cus'}
                />
                <MenuItemLink
                    to="/office"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.office.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<AssignmentIndIcon className={'color_icon_menu'}/>}
                    dense={dense}
                    className={'menu_cus'}
                />
                <MenuItemLink
                    to="/parts"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.parts.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<BallotIcon className={'color_icon_menu'}/>}
                    dense={dense}
                    className={'menu_cus'}
                />
                 <MenuItemLink
                    to="/project_type"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.project_type.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<AccountTreeIcon className={'menu_icon color_icon_menu'}/>}
                    dense={dense}
                    className={'menu_cus'}
                />
            </SubMenu>

            <SubMenu
                handleToggle={() => handleToggle('menuUsers')}
                isOpen={state.menuUsers}
                name="pos.menu.system"
                icon={<ManageAccountsIcon className={'color_icon_menu'}/>}
                dense={dense}
            >
                {
                    commonFunction.checkPermission('USER_LIST') && (
                        <MenuItemLink
                            to="/users"
                            state={{ _scrollToTop: true }}
                            primaryText={translate(`resources.users.name`, {
                                smart_count: 2,
                            })}
                            leftIcon={<PersonIcon className={'color_icon_menu'}/>}
                            dense={dense}
                            className={'menu_cus'}
                        />
                    )
                }
                {
                    commonFunction.checkPermission('ROLES_LIST') && (
                        <MenuItemLink
                            to="/roles"
                            state={{ _scrollToTop: true }}
                            primaryText={translate(`resources.roles.name`, {
                                smart_count: 2,
                            })}
                            leftIcon={<GroupsIcon className={'color_icon_menu'}/>}
                            dense={dense}
                            className={'menu_cus'}
                        />
                    )
                }
                {
                    commonFunction.checkPermission('PERMISSION_LIST') && (
                        <MenuItemLink
                            to="/permission"
                            state={{ _scrollToTop: true }}
                            primaryText={translate(`resources.permission.name`, {
                                smart_count: 2,
                            })}
                            leftIcon={<AdminPanelSettingsIcon className={'color_icon_menu'}/>}
                            dense={dense}
                            className={'menu_cus'}
                        />
                    )
                }
                 {
                    commonFunction.checkPermission('PERMISSION_LIST') && (
                        <MenuItemLink
                            to="/menu_admin"
                            state={{ _scrollToTop: true }}
                            primaryText={translate(`resources.menu.name`, {
                                smart_count: 2,
                            })}
                            leftIcon={<MenuIcon className={'color_icon_menu'}/>}
                            dense={dense}
                            className={'menu_cus'}
                        />
                    )
                }
            </SubMenu> */}
    </Box>
  );
};

export default Menu;
