import React from 'react';

import {
    Wrapper,
    InputRowWrapper,
    ItemWrapper,
    EditorWrapper,
    FileWrapper,
    LabelText,
    ImagePreviewWrapper,
    FilesPreviewWrapper,
    TableWrapper,
    TableHeaderWrapper,
} from '../styledMui';

//Services
import * as departmentService from '../../../Services/departmentService';
import * as projectTypeService from '../../../Services/projectTypeService';
import * as projectService from '../../../Services/projectService';

//Library
import { useNotify, useRecordContext, useRefresh } from 'react-admin';

import { Container, TextField, Autocomplete } from '@mui/material';

//Component
import FullScreenDialogWrapper from '../../../component/FullScreenDialogWrapper';
import PageLoadingAPI from '../../../component/PageLoadingAPI';
import ImagePreview from '../../../component/ImgPreview';
import InputLabel from '../../../component/InputLabel';
import PDFPreview from '../../../component/PDFPreview';
import ProjectTable from '../../components/Table';
import ProjectDetail from '../../dialogs/ProjectDetail';
import CreateButtonMUI from '../../../component/CreateButtonMUI';

//Shared data
import { STATUS } from '../../../shared/status';

import DialogSubmitLoading from '../../../component/DialogSubmitLoading';
import Editor from '../../../component/Editor';

import { createStyles, makeStyles } from '@material-ui/core/styles';

//Reformat Style
const styles = makeStyles((theme: any) =>
    createStyles({
        root: {
            '& .css-k4qjio-MuiFormHelperText-root.Mui-error':{
                position: 'absolute',
                paddingTop: '41px',
            },
            '& .css-1z10yd4-MuiFormControl-root-MuiTextField-root':{
                marginTop: 20,
                marginBottom: 20,
            }
        }
    }));
    


type Props = {
    isOpen: boolean;
    onClose: () => void;
};
type ErrorTypes = {
    isError: boolean;
    message: String;
};

function ProjectEdit({ isOpen, onClose }: Props) {
    const classes = styles()
    const [initialState, setInitialState] = React.useState<any>();
    const [department, setDepartment] = React.useState<any>();
    const [projectType, setProjectType] = React.useState<any>();
    const [status, setStatus] = React.useState<any>();

    //STATE REST API
    const [listDepartment, setListDepartment] = React.useState<any[]>([]);
    const [listProjectType, setListProjectType] = React.useState<any[]>([]);

    //DIALOG
    const [isOpenDetail, setIsOpenDetail] = React.useState<boolean>(false);
    const [dialogData, setDialogData] = React.useState<any>();

    //LOADING STATE
    const [loading, setLoading] = React.useState<boolean>(false);
    const [submitLoading, setSubmitLoading] = React.useState<boolean>(false);

    //ERROR STATE
    const [nameErr, setNameErr] = React.useState<ErrorTypes | null>();
    const [areaErr, setAreaErr] = React.useState<ErrorTypes | null>();
    const [legalErr, setLegalErr] = React.useState<ErrorTypes | null>();
    const [typeErr, setTypeErr] = React.useState<ErrorTypes | null>();
    const [departmentErr, setDepartmentErr] = React.useState<ErrorTypes | null>();
    const [statusErr, setStatusErr] = React.useState<ErrorTypes | null>();

    //HOOKS
    const record = useRecordContext();

    const notify = useNotify();
    const refresh = useRefresh();

    //CALL API
    React.useEffect(() => {
        if (isOpen) {
            setLoading(true);
            const fetchApi = async () => {
                const data = await projectService.getProjectDetail(Number(record.id));
                setInitialState(data);
                const lisDepartmentResult = await departmentService.getListDepartment();
                const listProjectTypeResult =
                    await projectTypeService.getListProjectType();
                const departmentObj = await lisDepartmentResult.find(
                    (item: any) => item.id === data.department,
                );
                const projectTypeObj = await listProjectTypeResult.find(
                    (item: any) => item.id === data.type,
                );
                const statusObj = await STATUS.find(
                    (item: any) => item.id === data.status,
                );

                //SET STATE
                setListDepartment(lisDepartmentResult);
                setListProjectType(listProjectTypeResult);
                setDepartment(departmentObj);
                setProjectType(projectTypeObj);
                setStatus(statusObj);

                setLoading(false);
            };
            fetchApi();
        }
    }, [isOpen]);

    React.useEffect(() => {
        if (isOpen) {
            const setData = async () => {
                if (dialogData && initialState) {
                    const {
                        ma_lo,
                        chieu_sau,
                        mat_tien,
                        dien_tich,
                        mat_duong,
                        gia_tri,
                        muc_dich,
                        trang_thai,
                        ghi_nhan,
                    } = await dialogData;
                    const submitDialog = await {
                        code: ma_lo,
                        road_surface: mat_duong,
                        land_frontage: mat_tien,
                        depth: chieu_sau,
                        area: dien_tich,
                        uses: muc_dich?.id,
                        price: gia_tri,
                        note: ghi_nhan,
                        status: trang_thai?.id,
                    };
                    setInitialState({
                        ...initialState,
                        project_goods: [...initialState.project_goods, submitDialog],
                    });
                }
            };
            setData();
        }
    }, [dialogData, isOpen]);

    const removeImageUtilities = (imgIndex: number) => {
        const newListImage = initialState?.project_grounds?.filter(
            (_: any, index: any) => index !== imgIndex,
        );
        if (initialState?.project_grounds?.length === 1) {
            setInitialState({
                ...initialState,
                project_grounds: [],
            });
        } else {
            setInitialState({ ...initialState, project_grounds: newListImage });
        }
    };
    const removeImage3D = (imgIndex: number) => {
        const newListImage = initialState?.projetc_locations?.filter(
            (_: any, index: any) => index !== imgIndex,
        );
        if (initialState?.projetc_locations?.length === 1) {
            setInitialState({
                ...initialState,
                projetc_locations: [],
            });
        } else {
            setInitialState({ ...initialState, projetc_locations: newListImage });
        }
    };
    const removePDFJuridical = (pdfIndex: number) => {
        const newListPDF = initialState?.project_contract?.filter(
            (_: any, index: any) => index !== pdfIndex,
        );
        if (initialState?.project_contract?.length === 1) {
            setInitialState({
                ...initialState,
                project_contract: [],
            });
        } else {
            setInitialState({ ...initialState, project_contract: newListPDF });
        }
    };
    const removePDFLegal = (pdfIndex: number) => {
        const newListPDF = initialState?.projetc_legals?.filter(
            (_: any, index: any) => index !== pdfIndex,
        );
        if (initialState?.projetc_legals?.length === 1) {
            setInitialState({
                ...initialState,
                projetc_legals: [],
            });
        } else {
            setInitialState({ ...initialState, projetc_legals: newListPDF });
        }
    };
    const removePDFSalePolicy = (pdfIndex: number) => {
        const newListPDF = initialState?.projetc_sales_policys?.filter(
            (_: any, index: any) => index !== pdfIndex,
        );
        if (initialState?.projetc_sales_policys?.length === 1) {
            setInitialState({
                ...initialState,
                projetc_sales_policys: [],
            });
        } else {
            setInitialState({ ...initialState, projetc_sales_policys: newListPDF });
        }
    };

    const handleDeleteItem = (itemIndex: number) => {
        const newList = initialState?.project_goods.filter(
            (_: any, index: any) => index !== itemIndex,
        );
        if (initialState?.project_goods.length === 1) {
            setInitialState({
                ...initialState,
                project_goods: [],
            });
        } else {
            setInitialState({
                ...initialState,
                project_goods: newList,
            });
        }
    };
    const handleSubmit = () => {
        let pass = true;

        // setSubmitLoading(true);

        setNameErr(null);
        setAreaErr(null);
        setLegalErr(null);
        setTypeErr(null);
        setStatusErr(null);
        setDepartmentErr(null);
        if (!initialState?.name?.trim()) {
            pass = false;
            setSubmitLoading(false);
            setNameErr({
                isError: true,
                message: 'Tên dự án không được để trống!',
            });
        } else if (initialState?.name?.length < 3) {
            pass = false;
            setSubmitLoading(false);
            setNameErr({
                isError: true,
                message: 'Tên dự án phải chứa ít nhât 3 ký tự!',
            });
        }
        if (!department?.id) {
            pass = false;
            setSubmitLoading(false);
            setDepartmentErr({
                isError: true,
                message: 'Tên đơn vị không được để trống!',
            });
        }

        if (!initialState?.area?.trim()) {
            pass = false;
            setSubmitLoading(false);
            setAreaErr({
                isError: true,
                message: 'Diện tich không được để trống!',
            });
        } else if (initialState?.area < 0) {
            pass = false;
            setSubmitLoading(false);
            setAreaErr({
                isError: true,
                message: 'Diện tich phải lớn hơn hoặc bằng 0!',
            });
        }
        if (!initialState?.legal?.trim()) {
            pass = false;
            setSubmitLoading(false);
            setLegalErr({
                isError: true,
                message: 'Pháp lý không được để trống!',
            });
        }
        if (!projectType?.id) {
            pass = false;
            setSubmitLoading(false);
            setTypeErr({
                isError: true,
                message: 'Loại hình không được để trống!',
            });
        }
        if (!status?.label) {
            pass = false;
            setSubmitLoading(false);
            setStatusErr({
                isError: true,
                message: 'Trạng thái không được để trống!',
            });
        }

        if (pass && !submitLoading) {
            const {
                id,
                name,
                content,
                area,
                legal,
                project_contract,
                project_grounds,
                projetc_legals,
                projetc_locations,
                projetc_sales_policys,
                project_goods,
            } = initialState;

            const data = {
                id: id,
                name: name,
                department: department?.id,
                type: projectType?.id,
                legal: legal,
                content: content,
                status: status.id === 1 ? 1 : 0,
                area: area,
                lst_file: project_contract.length > 0 ? project_contract : null,
                lst_img_ground: project_grounds.length > 0 ? project_grounds : null,
                lst_file_legal: projetc_legals.length > 0 ? projetc_legals : null,
                lst_img_location: projetc_locations.length > 0 ? projetc_locations : null,
                lst_file_sales:
                    projetc_sales_policys.length > 0 ? projetc_sales_policys : null,
                project_goods: [...project_goods],
            };
            console.log(data);

            const submit = async () => {
                const result = await projectService.editProject(data);
                if (result === 200) {
                    notify(`Sửa dự án thành công`, {
                        type: 'success',
                        undoable: true,
                    });
                    onClose();
                    setTimeout(() => {
                        setSubmitLoading(false);
                    }, 500);
                    refresh();
                } else {
                    notify(`Sửa dự án thất bại`, {
                        type: 'error',
                        undoable: true,
                    });
                    setSubmitLoading(false);
                }
            };
            submit();
        }
    };

    const onChangeFiles = (name: string) => {
        // @ts-ignore
        CKFinder.popup({
            chooseFiles: true,
            width: 800,
            height: 600,
            onInit: function (finder: any) {
                finder.on('files:choose', function (evt: any) {
                    let file = evt.data.files.first();
                    setInitialState({
                        ...initialState,
                        [name]: [...initialState?.[name], file.getUrl()],
                    });
                });
            },
        });
    };

    React.useEffect(() => {
        if (initialState?.project_grounds) {
            console.log(initialState.project_grounds);
        }
    }, [initialState?.project_grounds]);

    return (
        <FullScreenDialogWrapper
            title="Chỉnh sửa dự án"
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={handleSubmit}
            isSubmit={true}
        >
            {loading ? (
                <PageLoadingAPI />
            ) : (
                <Wrapper>
                    {submitLoading && <DialogSubmitLoading />}
                    <Container>
                        <InputRowWrapper>
                            <ItemWrapper>
                                <TextField
                                className={classes.root}
                                    disabled={submitLoading}
                                    fullWidth
                                    error={nameErr?.isError}
                                    helperText={nameErr?.message}
                                    variant="outlined"
                                    label={<InputLabel title="Tên dự án" required />}
                                    value={initialState?.name}
                                    onChange={(e) =>
                                        setInitialState({
                                            ...initialState,
                                            name: e.target.value,
                                        })
                                    }
                                />
                            </ItemWrapper>
                            <ItemWrapper>
                                <TextField
                                className={classes.root}
                                    disabled={submitLoading}
                                    type="number"
                                    fullWidth
                                    error={areaErr?.isError}
                                    helperText={areaErr?.message}
                                    variant="outlined"
                                    label={<InputLabel title="Diện tich" required />}
                                    value={initialState?.area}
                                    onChange={(e) => {
                                        setInitialState({
                                            ...initialState,
                                            area: e.target.value,
                                        });
                                    }}
                                />
                            </ItemWrapper>
                        </InputRowWrapper>
                        <InputRowWrapper>
                            <ItemWrapper>
                                <Autocomplete
                                    disabled={submitLoading}
                                    fullWidth
                                    id="combo-box-demo"
                                    options={listDepartment}
                                    renderInput={(params) => (
                                        <TextField
                                        className={classes.root}
                                            error={departmentErr?.isError}
                                            helperText={departmentErr?.message}
                                            {...params}
                                            variant="outlined"
                                            label={<InputLabel title="Đơn vị" required />}
                                        />
                                    )}
                                    value={department}
                                    onChange={(e, value) => {
                                        setDepartment(value);
                                    }}
                                    getOptionLabel={(option) => option.name}
                                />
                            </ItemWrapper>
                            <ItemWrapper>
                                <Autocomplete
                                    disabled={submitLoading}
                                    fullWidth
                                    id="combo-box-demo"
                                    options={listProjectType}
                                    renderInput={(params) => (
                                        <TextField
                                        className={classes.root}
                                            error={typeErr?.isError}
                                            helperText={typeErr?.message}
                                            {...params}
                                            variant="outlined"
                                            label={
                                                <InputLabel title="Loại hình" required />
                                            }
                                        />
                                    )}
                                    value={projectType}
                                    onChange={(e, value) => {
                                        setProjectType(value);
                                    }}
                                    getOptionLabel={(option) => option.name}
                                />
                            </ItemWrapper>
                        </InputRowWrapper>
                        <InputRowWrapper>
                            <ItemWrapper>
                                <TextField
                                className={classes.root}
                                    disabled={submitLoading}
                                    fullWidth
                                    error={legalErr?.isError}
                                    helperText={legalErr?.message}
                                    id="outlined-basic"
                                    label={<InputLabel title="Pháp lý" required />}
                                    variant="outlined"
                                    value={initialState?.legal}
                                    onChange={(e) => {
                                        setInitialState({
                                            ...initialState,
                                            legal: e.target.value,
                                        });
                                    }}
                                />
                            </ItemWrapper>
                            <ItemWrapper>
                                <Autocomplete
                                    disabled={submitLoading}
                                    fullWidth
                                    id="combo-box-demo"
                                    options={STATUS}
                                    renderInput={(params) => (
                                        <TextField
                                        className={classes.root}
                                            error={statusErr?.isError}
                                            helperText={statusErr?.message}
                                            {...params}
                                            variant="outlined"
                                            label={
                                                <InputLabel title="Trạng thái" required />
                                            }
                                        />
                                    )}
                                    value={status}
                                    onChange={(e, value) => {
                                        setStatus(value);
                                    }}
                                    getOptionLabel={(option) => option.label}
                                />
                            </ItemWrapper>
                        </InputRowWrapper>
                        <EditorWrapper>
                            <Editor
                                data={initialState?.content}
                                onChange={(event: any, editor: any) => {
                                    const data = editor.getData();
                                    setInitialState({
                                        ...initialState,
                                        content: data,
                                    });
                                }}
                            />
                        </EditorWrapper>
                        <FileWrapper>
                            <LabelText>Vị trí, các tiện ích liền kề:</LabelText>
                            <input
                                disabled={submitLoading}
                                type="file"
                                multiple
                                accept=".png, .jpg, .jpeg"
                                onClick={() => onChangeFiles('project_grounds')}
                            ></input>
                            {initialState?.project_grounds?.length > 0 && (
                                <ImagePreviewWrapper>
                                    {initialState.project_grounds.map(
                                        (image: any, index: number) => (
                                            <ImagePreview
                                                key={index}
                                                src={image?.image ? image.image : image}
                                                onRemove={() =>
                                                    removeImageUtilities(index)
                                                }
                                            />
                                        ),
                                    )}
                                </ImagePreviewWrapper>
                            )}
                        </FileWrapper>
                        <FileWrapper>
                            <LabelText>Mặt bằng phân lô 3D:</LabelText>
                            <input
                                disabled={submitLoading}
                                type="file"
                                multiple
                                accept=".png, .jpg, .jpeg"
                                onClick={() => onChangeFiles('projetc_locations')}
                            ></input>
                            {initialState?.projetc_locations?.length > 0 && (
                                <ImagePreviewWrapper>
                                    {initialState.projetc_locations.map(
                                        (image: any, index: number) => (
                                            <ImagePreview
                                                key={index}
                                                src={image?.image ? image.image : image}
                                                onRemove={() => removeImage3D(index)}
                                            />
                                        ),
                                    )}
                                </ImagePreviewWrapper>
                            )}
                        </FileWrapper>
                        <FileWrapper>
                            <LabelText>Mẫu HĐCN:</LabelText>
                            <input
                                disabled={submitLoading}
                                type="file"
                                accept=".xls, .pdf"
                                onClick={() => onChangeFiles('project_contract')}
                            ></input>
                            {initialState?.project_contract?.length > 0 && (
                                <FilesPreviewWrapper>
                                    {initialState.project_contract.map(
                                        (item: any, index: number) => (
                                            <PDFPreview
                                                key={index}
                                                fileName={
                                                    item.file
                                                        ? item.file
                                                              .split('/uploads/files/')
                                                              .join('')
                                                        : item
                                                              .split('/uploads/files/')
                                                              .join('')
                                                }
                                                onRemove={() => removePDFJuridical(index)}
                                            />
                                        ),
                                    )}
                                </FilesPreviewWrapper>
                            )}
                        </FileWrapper>
                        <FileWrapper>
                            <LabelText>Pháp lý:</LabelText>
                            <input
                                disabled={submitLoading}
                                type="file"
                                accept=".xls, .pdf"
                                onClick={() => onChangeFiles('projetc_legals')}
                            ></input>
                            {initialState?.projetc_legals?.length > 0 && (
                                <FilesPreviewWrapper>
                                    {initialState.projetc_legals.map(
                                        (item: any, index: number) => (
                                            <PDFPreview
                                                key={index}
                                                fileName={
                                                    item.file
                                                        ? item.file
                                                              .split('/uploads/files/')
                                                              .join('')
                                                        : item
                                                              .split('/uploads/files/')
                                                              .join('')
                                                }
                                                onRemove={() => removePDFLegal(index)}
                                            />
                                        ),
                                    )}
                                </FilesPreviewWrapper>
                            )}
                        </FileWrapper>
                        <FileWrapper>
                            <LabelText>Chính sách bán hàng:</LabelText>
                            <input
                                disabled={submitLoading}
                                type="file"
                                accept=".xls, .pdf"
                                onClick={() => onChangeFiles('projetc_sales_policys')}
                            ></input>
                            {initialState?.projetc_sales_policys?.length > 0 && (
                                <FilesPreviewWrapper>
                                    {initialState.projetc_sales_policys.map(
                                        (item: any, index: number) => (
                                            <PDFPreview
                                                key={index}
                                                fileName={
                                                    item.file
                                                        ? item.file
                                                              .split('/uploads/files/')
                                                              .join('')
                                                        : item
                                                              .split('/uploads/files/')
                                                              .join('')
                                                }
                                                onRemove={() =>
                                                    removePDFSalePolicy(index)
                                                }
                                            />
                                        ),
                                    )}
                                </FilesPreviewWrapper>
                            )}
                        </FileWrapper>
                        <TableWrapper>
                            <TableHeaderWrapper>
                                <CreateButtonMUI
                                    title="Thêm bản ghi"
                                    onClick={() => setIsOpenDetail(true)}
                                />
                                <ProjectDetail
                                    isOpen={isOpenDetail}
                                    onClose={() => setIsOpenDetail(false)}
                                    submitData={setDialogData}
                                />
                            </TableHeaderWrapper>
                            <ProjectTable
                                data={initialState?.project_goods}
                                deleteItem={(id: number) => handleDeleteItem(id)}
                            />
                        </TableWrapper>
                    </Container>
                </Wrapper>
            )}
        </FullScreenDialogWrapper>
    );
}
export default React.memo(ProjectEdit);
