import React from 'react';
import { styled } from '@mui/system';

const RequredIcon = styled('span')({
    color: 'red',
    padding: '4px',
});

type Props = {
    title: String;
    required?: boolean;
};

function InputLabel({ title, required }: Props) {
    return (
        <span>
            {title}
            {required && <RequredIcon>*</RequredIcon>}
        </span>
    );
}

export default React.memo(InputLabel);
