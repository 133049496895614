import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import React, { useState, useEffect } from 'react';
import {
    FilterLiveSearch,
    ReferenceField,
    SimpleForm,
    SimpleShowLayout,
    useRecordContext,
    useResourceContext,
    useTranslate,
} from 'react-admin';
import {
    Pagination,
    List,
    Datagrid,
    DatagridProps,
    useListContext,
    TextField,
    FunctionField,
} from 'react-admin';
import PaginationCustom from '../component/PaginationCustom';
import { Box, Grid, Button, Chip, IconButton, Typography } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Form } from 'react-final-form';
import { MenuDelete } from './MenuDelete';
import { MenuAdd } from './MenuAdd';
import { MenuEdit } from './MenuEdit';
import HttpDataService from '../dataProvider/http.service';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { commonFunction } from '../component/Common';

const Item = styled(Paper)(({ theme }) => ({
    textAlign: 'right',
}));

// const styles =  makeStyles((theme: any) => 
// createStyles({
//     root: {
//         '.MuiPaper-elevation1':{
//             boxShadow: 'none',
//         }
//     }
// }))
export const MenuList = (props: any) => {
    const PostPagination = (props: any) => <PaginationCustom {...props} />;
    const translate = useTranslate();
    return (
        <Box className="header-channel-sales">
            <Box className={'box_header'}>
                <Typography className={'title_header'}>
                    {translate('resources.menu.list_menu')}
                </Typography>
            </Box>
            <div className={`header-top-detail header-top-tdn`}>
                <List
                    {...props}
                    actions={false}
                    empty={false}
                    exporter={false}
                    filters={<FilterAndActions />}
                    sort={{ field: '', order: '' }}
                    pagination={<PostPagination />}
                    perPage={50}
                >
                    <DepartmentDatagrid {...props} />
                </List>
            </div>
        </Box>
    );
};

interface ButtonProps {
    basePath?: string;
    label: string;
    record?: any;
}

const EditButton = (props: any) => {
    const [onCloseDialog, setOpenEditDialog] = useState(false);
    return (
        <>
            <IconButton onClick={() => setOpenEditDialog(true)}>
                <EditIcon className={'btn_edit'} />
            </IconButton>
            {onCloseDialog && (
                <MenuEdit
                    {...props}
                    openDialog={onCloseDialog}
                    onCloseDialog={() => setOpenEditDialog(false)}
                />
            )}
        </>
    );
};

const DeleteButton = (props: any) => {
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    return (
        <>
            <IconButton onClick={() => setOpenDeleteDialog(true)}>
                <DeleteIcon className={'btn_del'} />
            </IconButton>
            <MenuDelete
                id={props?.id}
                openDialog={openDeleteDialog}
                onCloseDialog={() => setOpenDeleteDialog(false)}
            />
        </>
    );
};

const RowActions = (props: any) => {
    const menu = useRecordContext();
    const per_edit = commonFunction.checkPermission('MENU_EDIT');
    const per_delete = commonFunction.checkPermission('MENU_DELETE');

    return (
        <Box className={'care_plan_rowActions'}>
            {per_edit && <EditButton id={menu.id} />}
            {per_delete && <DeleteButton id={menu.id} />}
        </Box>
    );
};
const FilterAndActions = (props: any) => {
    const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);
    // const classes = styles()
    return (
        <Box
            sx={{
                p: 1,
                m: 1,
                bgcolor: 'background.paper',
                borderRadius: 1,
            }}
            className={'box_search'}
        >
            <Item className="shadow-none">
                <Form onSubmit={() => undefined}>
                    {() => (
                        <FilterLiveSearch
                            variant="outlined"
                            source="name"
                            label="resources.common.fields.search"
                        />
                    )}
                </Form>
            </Item>
            <Item className="shadow-none">
                {commonFunction.checkPermission('MENU_ADD') && (
                    <>
                        <Button
                            variant="outlined"
                            startIcon={<AddIcon />}
                            color="primary"
                            onClick={() => setOpenCreateDialog(true)}
                        >
                            Thêm mới
                        </Button>
                        <MenuAdd
                            openDialog={openCreateDialog}
                            onCloseDialog={() => setOpenCreateDialog(false)}
                        />
                    </>
                )}
            </Item>
        </Box>
    );
};

const menuShow = () => {
    const { id, children } = useRecordContext();
    const [menu, setMenu] = useState<any[]>([]);
    // console.log(menu)
    // useEffect(() => {
    //     const getMenu = async () => {
    //         const {data :{data}} = await HttpDataService.getAll(`menu_admin/getParentChild?parent_id=${id}`)
    //         setMenu(data)
    //     }
    //     getMenu()
    // },[])
    return (
        <>
            {children?.map((item: any, index: any) => (
                // <div key={index} className={ last  ? 'h-0 row ' : 'h-0 lastrow'}>
                <div key={index} className={'h-0 row'}>
                    <td className="grid-center">{index + 1}</td>
                    <td className="grid-center">{item.name}</td>
                    <td className="grid-center">{item.code}</td>
                    <td className="grid-center">{item.description}</td>
                    <td className="grid-center">1</td>
                    <td className="grid-center">
                        <FunctionField
                            label={'resources.common.fields.status'}
                            render={(record: any) => {
                                if (record.status === 1) {
                                    return (
                                        <Chip
                                            label="Đang hoạt động"
                                            color="success"
                                            variant="outlined"
                                        />
                                    );
                                } else {
                                    return <Chip label="Khóa" color="error" variant="outlined" />;
                                }
                            }}
                        />
                    </td>
                    <td>
                        <EditButton id={item.id} />
                        <DeleteButton id={item.id} />
                    </td>
                </div>
            ))}
        </>
    );
};

const DepartmentDatagrid = (props: DatagridProps) => {
    // @ts-ignore
    // const {page, perPage} = useListContext();
    // const [menu, setMenu] = useState<any[]>([])
    // console.log(menu)
    // useEffect(() => {
    //     const getMenu = async () => {
    //         const {data :{data}} = await HttpDataService.getAll(`menu_admin/getAll`)
    //         console.log(data)
    //     }
    //     getMenu()
    // },[])
 
    return (
        <>
            <div className={`list-data-tdn`}>
                <Datagrid
                    expand={menuShow}
                    bulkActionButtons={false}
                    isRowExpandable={(row: any) => row.children.length > 0}
                    className={`table-default criteria_type_datagrid table_department`}
                >
                    <TextField source={'name'} label={'resources.menu.fields.name_menu'} />
                    <TextField source={'code'} label={'resources.menu.fields.code_menu'} />
                    <TextField source={'description'} label={'resources.menu.fields.des_menu'} />
                    <TextField source={'order'} label={'resources.menu.fields.depth_menu'} />
                    <FunctionField
                        label={'resources.common.fields.status'}
                        render={(record: any) => {
                            if (record.status === 1) {
                                return (
                                    <Chip
                                        label="Đang hoạt động"
                                        color="success"
                                        variant="outlined"
                                    />
                                );
                            } else {
                                return <Chip label="Khóa" color="error" variant="outlined" />;
                            }
                        }}
                    />
                    <RowActions label={'resources.common.fields.action'} />
                </Datagrid>
            </div>
        </>
    );
};
