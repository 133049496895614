import {
    DefaultDataProvider,
    httpClient,
    serialize,
} from '../default-data-provider';
import {constants} from "../../shared/constants";

export const baseApiUrl = 'project_type';

class projectTypeService extends DefaultDataProvider {

}

export const project_typeService = new projectTypeService(
    constants.RESOURCE.PROJECT,
    constants.BASE_MODULE_URL,
    baseApiUrl
);